import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo
} from './tabSwitch.styles';

const TabSwitch = ({
  tabVal, handleTabChange
}) => {
  const Tab = [
    {
      label: 'As a Buyer', value: 'buyer', width: '50%'
    },
    {
      label: 'As a Seller', value: 'seller', width: '50%'
    },
  ];

  return (
    <TabsGrid container item>
      {Tab.map(({
        label, value, width
      }) => (
        <UnderlineGrid
          key={value}
          item
          container
          onClick={() => handleTabChange(value)}
          active={value === tabVal}
          width={width}
        >
          <Typo active={value === tabVal}>
            {label}
          </Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid width="0%" style={{ padding: '10.5px 0' }}>
        <Typo />
      </UnderlineGrid>
    </TabsGrid>
  );
};

TabSwitch.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default TabSwitch;
