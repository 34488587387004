import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import {
  UserGridContainer,
  TitleHeader,
  Description
} from './userAccountsContainer.styles';
import Users from './userAccounts/users';
import { useStateValue } from '../../providers/stateProvider';

const UserAccounts = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  return (
    <MainContent>
      <UserGridContainer container>
        <TitleHeader>Manage Accounts</TitleHeader>
        <Description>View all accounts here</Description>
        <Grid item container xs={12} mt={3}>
          <Users />
        </Grid>
      </UserGridContainer>
    </MainContent>
  );
};

export default UserAccounts;
