import React from 'react';
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import {
  DetailsCard, DeliveryCardHeaderTitle, DeliveryCardHeader,
  ActionBtn, NoteContainer,
  NoteTextField, SaveNoteButton
} from './manageOrdersDispatch.styles';

const DeliveryNote = () => (
  <DetailsCard>
    <DeliveryCardHeader>
      <DeliveryCardHeaderTitle>
        Note
      </DeliveryCardHeaderTitle>
      <ActionBtn>
        <Add sx={{ color: '#235A91' }} />
      </ActionBtn>
    </DeliveryCardHeader>
    <Grid style={{ marginBottom: '20px' }}>
      <NoteContainer>
        <NoteTextField
          value=""
          multiline
          rows={5}
          placeholder="Enter note here"
          onChange={() => null}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
        />

      </NoteContainer>
      <SaveNoteButton
        variant="contained"
        fullWidth
      >
        Save Note
      </SaveNoteButton>
    </Grid>
  </DetailsCard>
);

export default DeliveryNote;
