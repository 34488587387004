import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  InputWrapper,
  ProceedBtn
} from '../register.styles';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';

const LocationRegStep = ({ state, setState }) => {
  const { location } = state;
  const { pathname } = useLocation();
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const mpSignUpLocations = ['Lagos State', 'Oyo State', 'Ogun State', 'Kwara State', 'Kano State', 'Abuja (FCT)'];

  const redirect = () => {
    if (pathname.indexOf('uat') > 0) window.location.href = 'https://marketplace.uat.ogapharmacy.com/register';
    else if (pathname.indexOf('staging') > 0) window.location.href = 'https://marketplace.staging.ogapharmacy.com/register';
    else window.location.href = 'https://marketplace.ogapharmacy.com/register';
  };

  const handleChangeStep = () => {
    if (mpSignUpLocations.includes(location)) {
      redirect();
    } else {
      setState({ ...state, currentStep: 'category' });
    }
  };

  const availLocations = [
    'Rivers State', 'Bayelsa State', 'Akwa Ibom State', 'Edo State', 'Lagos State', 'Oyo State',
    'Ogun State', 'Kwara State', 'Kano State', 'Abuja (FCT)'
  ];

  const categoryField = [
    {
      name: 'location', label: 'Location', options: availLocations, secured: false,
      show: true, onChange: handleSelectChange, placeholder: 'Select', required: true,
      isTooltip: true, tooltipDesc: "Location means the state you're placing your orders from.", val: location
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText,
      secured, placeholder, onChange, val, isTooltip, tooltipDesc
    } = field;
    const value = state[fieldName];
    if (['location'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
          isTooltip={isTooltip}
          tooltipDesc={tooltipDesc}
        />
      );
    }
  };

  return (
    <>
      <InputWrapper item container>
        <Grid item xs={12} lg={12}>
          {categoryField
            .filter((item) => item.show)
            .map((field) => (
              <Grid item key={field?.name} xs={12}>
                {returnTextField(field)}
              </Grid>
            ))}
        </Grid>
      </InputWrapper>
      <ProceedBtn disabled={state.location === ''} onClick={handleChangeStep}>Proceed</ProceedBtn>
    </>
  );
};

LocationRegStep.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

export default LocationRegStep;
