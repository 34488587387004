import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useQuery } from '@apollo/client';
import ProductPreview from './productPreview';
import {
  OrderGridContainer, ProductsBox, ContentBox, Header, SubHeaderWrapper, HeaderWrapper,
  GoBack,
  SearchContainer,
  SearchTextField,
  SearchButton,
  ClearIcon,
  SearchImage,
  SearchBox
} from './productPreviewContainer.styles';
import lightSearchIcon from '../../../../assets/images/lightSearchIcon.png';
import useSearchParamsState from '../../../shared/helpers/ulrSearchParams';
import { ERP_PRODUCT_QUERY } from '../../../../queries/products';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';

const ProductPreviewContainer = () => {
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '25');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const navigate = useNavigate();

  const handleSearch = (text) => {
    setSearchFieldValue(text);
  };

  const handleClearSearch = () => {
    setSearchFieldValue('');
  };

  const variables = {
    search: '',
    productName: searchFieldValue?.length >= 3 ? searchFieldValue : '',
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
  };

  const {
    loading, error, data,
  } = useQuery(ERP_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  if (error) return <div>{error.message}</div>;
  const {
    products = [], productsTotalNumber = 0
  } = data || {};

  const renderSearchField = (placeholder, searchValue, handleChange, ClearSearch, joyClass = 'joy-search-product') => (
    <SearchContainer className={joyClass}>
      <SearchTextField
        variant="outlined"
        label=""
        autoFocus={placeholder === 'Search products item'}
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          startAdornment:
  <InputAdornment position="start" style={{ marginRight: '25px' }}>
    <SearchButton
      aria-label={placeholder}
      edge="start"
      disabled={!searchValue}
      onClick={ClearSearch}
    >
      {searchValue ? (
        <ClearIcon />
      ) : (
        <SearchImage alt="search icon" src={lightSearchIcon} />
      )}
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  return (
    <ContentBox data-testid="nonAuthOrderspage">
      <OrderGridContainer container>
        <HeaderWrapper>
          <GoBack onClick={() => navigate(-1)}>
            <ArrowBackIcon style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
            &nbsp;
            Back
          </GoBack>
          <SubHeaderWrapper container>
            <Header>All Products Preview</Header>
            <SearchBox item>
              {renderSearchField('Search for Products', searchFieldValue, handleSearch, handleClearSearch)}
            </SearchBox>
          </SubHeaderWrapper>
        </HeaderWrapper>

        <ProductsBox>
          {loading
            ? <NewOrdersLoader />
            : (
              <ProductPreview
                products={products}
                productsTotalNumber={productsTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
        </ProductsBox>
      </OrderGridContainer>
    </ContentBox>
  );
};

export default ProductPreviewContainer;
