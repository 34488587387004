import { createTheme, styled, adaptV4Theme } from '@mui/material/styles';
import {
  Typography, Grid, Box
} from '@mui/material';

export const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        marginTop: '15px'
      },
      root: {
        backgroundColor: '#FFF',
        width: '100%',
        cursor: 'pointer',
        borderRadius: '12px',
        border: '1px solid #EAECF0',
        minHeight: '0',
        height: '4.5rem'
      }
    }
  }
}));

export const TitleText = styled(Typography)`
  font-size: .85rem;
  font-family: Montserrat,medium,sans-serif;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #000;

`;

export const MiddleText = styled(Typography)`
  font-size: .75rem;
  color: #475467;
  font-weight: 400;
  text-align: left;
  font-family: Montserrat,medium,sans-serif;
`;

export const DropZoneContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
`;

export const ClickUploadText = styled('span')`
  color: #3498DB;
  font-size: .875rem;
  font-weight: 600;
`;

export const UploadText = styled(Typography)`
  color: #475467;
  font-size: .875rem;
  font-weight: 500;
  text-align: left;
  font-family: Montserrat,medium,sans-serif;
`;

export const UploadBox = styled(Box)`
  width: ${({ name }) => (name.length > 0 ? '70%' : '85%')};
  text-align: left;
  margin-top: -10px;
  margin-left: 5px;
`;

export const FileText = styled(Typography)`
  font-size: .875rem;
  font-weight: 600;
  color: #344054;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Montserrat,medium,sans-serif;
`;

export const UploadedText = styled(Typography)`
  font-size: .875rem;
  color: #475467;
  font-weight: 400;
  text-align: left;
  font-family: Montserrat,medium,sans-serif;
`;
