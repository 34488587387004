import React from 'react';
import PropTypes from 'prop-types';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  ProductDetail, ProductDetailBrandNameText,
  ProductDetailPackSizeText,
  ProductDetailItemPriceText, ProductDetailNameMainContainer,
  MainTableRow, BodyCell,
  TableValueText,
  ProductDetailFlex,
} from '../../../cart/product-item/styles';
import {
  ProductDetailMainContainer, ImageWrapper, ProductDetailImage, ProductCounter
} from './styles';

const ProductItem = ({
  product: {
    name, unitCost, price, quantityReceived,
    image, quantityConfirmed, packSize
  }
}) => (
  <MainTableRow>
    <ProductDetail container>
      <ProductDetailFlex>
        <ImageWrapper>
          <ProductDetailImage src={image} alt="product" />
        </ImageWrapper>
        <ProductDetailNameMainContainer>
          <ProductDetailBrandNameText>
            { name }
          </ProductDetailBrandNameText>
          <ProductDetailPackSizeText>
            Pack Size:&nbsp;
            { packSize }
          </ProductDetailPackSizeText>
        </ProductDetailNameMainContainer>
      </ProductDetailFlex>
    </ProductDetail>
    <BodyCell>
      <ProductCounter>
        <TableValueText>
          {Number(quantityReceived)}
        </TableValueText>
      </ProductCounter>
    </BodyCell>
    <BodyCell>
      <ProductCounter>
        <TableValueText>
          {Number(quantityConfirmed)}
        </TableValueText>
      </ProductCounter>
    </BodyCell>
    <BodyCell>
      <ProductDetailMainContainer>
        <ProductDetailItemPriceText>
          ₦&nbsp;
          {currencyFormatter(unitCost)}
        </ProductDetailItemPriceText>
      </ProductDetailMainContainer>
    </BodyCell>
    <BodyCell>
      <ProductDetailItemPriceText>
        {`₦${currencyFormatter(Number(price))}`}
      </ProductDetailItemPriceText>
    </BodyCell>
  </MainTableRow>
);

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
