import React from 'react';
import { Add } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  DetailsCard, DeliveryCardHeaderTitle, DeliveryCardHeader,
  ActionBtn, SelectOutletText, DeliveryAddress, Address
} from './manageOrdersDispatch.styles';
import ReturnSelectField from '../orders/dispatch/returnSelectField';
import { JSONParse } from '../../utils/json';

const DeliveryAddressContainer = ({ businessOrder }) => {
  const { meta } = businessOrder.order || {};

  const { delivery_address } = JSONParse(meta) || {};

  return (
    <DetailsCard>
      <DeliveryCardHeader>
        <DeliveryCardHeaderTitle>
          Delivery Location
        </DeliveryCardHeaderTitle>
        <ActionBtn>
          <Add sx={{ color: '#235A91' }} />
        </ActionBtn>
      </DeliveryCardHeader>
      <Grid style={{ marginBottom: '20px' }}>
        <SelectOutletText>
          Select the outlet you want your order to be delivered to
        </SelectOutletText>
        <ReturnSelectField
          field={[]}
          value=""
          showCheckBox={false}
          label=""
          handleCreditDaysOpen={() => ({})}
          fullWidth
          handleChange={() => null}
          showLabel={false}
        />
      </Grid>

      <DeliveryAddress>
        Delivery Address
      </DeliveryAddress>
      <Address>
        {delivery_address || 'N/A'}
      </Address>
    </DetailsCard>
  );
};

DeliveryAddressContainer.propTypes = {
  businessOrder: PropTypes.instanceOf(Object),
};

DeliveryAddressContainer.defaultProps = {
  businessOrder: {},
};

export default DeliveryAddressContainer;
