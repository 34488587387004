import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  CDialog, DialogTitleText, CircularProgressLoader,
  GridWrapper, CloseIcon, AdornmentStyled, CustomButton
} from './editProfileDialog.styles';
import { CustomInputBase, CustomSelectInputBase } from '../customInput';
import { UPDATE_BUSINESS_PROFILE } from '../../../../mutations/business';
import SuccessDialog from '../../../shared/successDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const EditProfileDialog = ({
  dialogOpen, closeDialog, businessId, accountType,
  profileData
}) => {
  const initialState = {
    fullName: '',
    businessName: '',
    salesRep: '',
    contactPersonName: '',
    phoneNumber: '',
    deliveryAddress: '',
    landmark: '',
    state: 'Lagos',
    city: '',
    businessType: '',
    emailAddress: '',
    gender: ''
  };

  const initialErrorState = {
    fullNameError: false,
    businessNameError: false,
    salesRepError: false,
    emailAddressError: false,
    contactPersonNameError: false,
    phoneNumberError: false,
    deliveryAddressError: false,
    landmarkError: false,
    stateError: false,
    cityError: false,
    genderError: false,
    businessTypeError: false
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (profileData) {
      const {
        fullName, businessName, emailAddress, phoneNumber,
        salesRep, location, deliveryAddress, contactPersonName, landmark,
        city, businessType, gender
      } = profileData;
      setState({
        fullName,
        businessName,
        emailAddress,
        phoneNumber,
        salesRep,
        location,
        deliveryAddress,
        contactPersonName,
        landmark,
        city,
        businessType,
        gender
      });
    }
  }, [profileData]);

  const {
    fullNameError, businessNameError, salesRepError, contactPersonNameError, phoneNumberError, emailAddressError,
    deliveryAddressError, landmarkError, stateError, genderError, cityError, businessTypeError
  } = errorState;

  const handleCloseDialog = () => {
    setState(initialState);
    closeDialog();
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const {
      fullName, businessName, salesRep, contactPersonName, phoneNumber, gender,
      deliveryAddress, landmark, city, businessType, emailAddress
    } = state;
    const updatedInfo = {
      userFullName: fullName, userPhoneNumber: phoneNumber, businessName, businessType,
      gender, deliveryEmail: emailAddress, contactPersonName, landmark,
      salesRepresentativeName: salesRep, city, deliveryAddress
    };

    updateBusinessInfo({
      variables: {
        businessId, updatedInfo, accountType
      }
    })
      .then(() => {
        setState(initialState);
        setErrorState(initialErrorState);
        setOpenDialog(true);
        closeDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveButtonClick = () => {
    const {
      fullName, businessName, salesRep, contactPersonName, phoneNumber, gender,
      deliveryAddress, landmark, state: userState, city, businessType, emailAddress
    } = state;

    if (fullName === '') {
      toast.error('Full Name is required');
    } else if (businessName === '') {
      toast.error('Business Name is required');
    } else if (salesRep === '') {
      toast.error('Sales Representative is required');
    } else if (contactPersonName === '') {
      toast.error('Contact Person Full Name is required');
    } else if (phoneNumber === '') {
      toast.error('Phone Number is required');
    } else if (gender === '') {
      toast.error('Gender is required');
    } else if (deliveryAddress === '') {
      toast.error('Delivery Address is required');
    } else if (landmark === '') {
      toast.error('Landmark is required');
    } else if (city === '') {
      toast.error('City is required');
    } else if (state === '') {
      toast.error('State is required');
    } else if (businessType === '') {
      toast.error('Business Type is required');
    } else if (emailAddress === '') {
      toast.error('Email Address is required');
    } else if (userState === '') {
      toast.error('State is required');
    } else {
      return handleSaveChanges();
    }
    return setErrorState({
      ...errorState,
      fullNameError: fullName === '',
      deliveryAddressError: deliveryAddress === '',
      businessTypeError: businessType === '',
      businessNameError: businessName === '',
      contactPersonNameError: contactPersonName === '',
      emailAddressError: emailAddress === '',
      salesRepError: salesRep === '',
      landmarkError: landmark === '',
      stateError: userState === '',
      genderError: gender === '',
      cityError: city === '',
      phoneNumberError: phoneNumber === '',
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'fullName':
      case 'businessName':
      case 'salesRep':
      case 'contactPersonName':
      case 'phoneNumber':
      case 'gender':
      case 'deliveryAddress':
      case 'landmark':
      case 'state':
      case 'city':
      case 'businessType':
      case 'emailAddress':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !(value.length > 0)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    const valueMap = {
      Retail: 'RETAILER',
      Retail_Chain: 'RETAIL_CHAIN',
      Wholesale: 'WHOLESALER',
      Distributor: 'DISTRIBUTOR',
      Institution: 'INSTITUTION',
      Private_Hospital: 'PRIVATE_HOSPITAL',
      PPMV: 'PPMV',
    };
    const newValue = valueMap[value] || 'RETAILER';
    setState({ ...state, [name]: newValue });
  };

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const formFields = [
    {
      name: 'fullName', label: 'Full Name', required: true, placeholder: 'e.g Esther Howard',
      error: fullNameError, helperText: 'Full Name required', secured: false, show: true
    },
    {
      name: 'businessName', label: 'Business Name', required: true, placeholder: 'e.g Esther Howard',
      error: businessNameError, helperText: 'Business Name required', secured: false, show: true,
    },
    {
      name: 'emailAddress', label: 'Email Address', required: true, placeholder: 'e.g Maestropaul@example.com',
      error: emailAddressError, helperText: 'Email Address required', secured: false, show: true,
    },
    {
      name: 'deliveryAddress', label: 'Delivery Address', required: true, placeholder: 'e.g 19, Maestropaul Street, Jakande Lagos',
      error: deliveryAddressError, helperText: 'Delivery Address required', secured: false, show: true,
    },
    {
      name: 'phoneNumber', label: 'Phone Number', required: true, placeholder: 'e.g 7012345678', type: 'number',
      error: phoneNumberError, helperText: 'Phone Number required', secured: false, show: true, sAdornment: phoneAdornment
    },
    {
      name: 'contactPersonName', label: "Contact Person's Full Name", required: true, placeholder: 'e.g John Doe',
      error: contactPersonNameError, helperText: 'Contact Person Name required', secured: false, show: true,
    },
    {
      name: 'salesRep', label: 'Sales Representative', required: true, placeholder: 'e.g John Doe',
      error: salesRepError, helperText: 'Sales Representative required', secured: false, show: true,
    },
    {
      name: 'landmark', label: 'Landmark', required: true, placeholder: 'e.g ICM Ikeja',
      error: landmarkError, helperText: 'Landmark required', secured: false, show: true,
    },
    {
      name: 'state', label: 'State', required: true, placeholder: 'e.g Lagos & Ibadan',
      error: stateError, helperText: 'State required', secured: true, show: true,
    },
    {
      name: 'city', label: 'City', required: true, placeholder: 'e.g Ikeja',
      error: cityError, helperText: 'City required', secured: false, show: true,
    },
    {
      name: 'gender', label: "Owner's Gender", required: true, placeholder: 'e.g Male',
      error: genderError, helperText: 'Gender required', secured: false, show: true,
      options: ['Male', 'Female']
    },
    {
      name: 'businessType', label: 'Business Type', required: true, placeholder: 'e.g Retail',
      error: businessTypeError, helperText: 'Business Type required', secured: false, show: true,
      options: ['Retail', 'Retail_Chain', 'Wholesale', 'Distributor', 'Institution', 'Private_Hospital', 'PPMV'], onChange: handleSelectChange
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, isLoading, secured, placeholder, onChange, val, type, readOnly, sAdornment
    } = field;
    const value = state[fieldName];
    if (['gender', 'businessType'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={isLoading}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={readOnly}
        sAdornment={sAdornment}
      />
    );
  };

  return (
    <>
      <CDialog
        data-testid="profile-dialog"
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>
                Edit Profile Details
              </DialogTitleText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2} style={{ padding: '0 1rem' }}>
              {formFields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <CustomButton
              style={{ marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleSaveButtonClick}
              disabled={loading}
              style={{ color: '#ffffff', background: '#235a91' }}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : 'Save'}
            </CustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>
      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={() => setOpenDialog(false)}
        title="Details Saved"
        desc="Your details has been updated successfully!"
        option="Ok"
      />
    </>
  );
};

EditProfileDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  profileData: PropTypes.instanceOf(Object).isRequired
};

export default EditProfileDialog;
