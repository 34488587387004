import React, { useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { ALL_ORDER_QUERY } from '../../../queries/orders';
import { useStateValue } from '../../../providers/stateProvider';
import Orders from './orders/orders';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, PrimaryTitle, OrderWrapper,
  SubTitle
} from './ordersContainer.styles';
import TabsButtons from './ordersTabsButtons/tabsButtons';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';

const OrdersAdminContainer = () => {
  const [, dispatch] = Object.values(useStateValue());
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');

  const [active, setActive] = useSearchParamsState('status', 'buyers');

  useLayoutEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  const handleStatusValueChange = (newValue) => {
    setActive(newValue);
  };

  const variables = {
    tab: active === 'sellers' ? 'SELLER' : 'BUYER',
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
  };
  const {
    data, loading, error, refetch
  } = useQuery(ALL_ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables,
  });

  if (error) return `Error! ${error.message}`;

  return (
    <MainContent>
      <OrderGridContainer container>
        {!isSmall && (
          <TitleGridContainer item>
            <PrimaryTitle xs={6}> Manage Orders</PrimaryTitle>
            <SubTitle>View all Orders of buyers and sellers here</SubTitle>
          </TitleGridContainer>
        )}
        <OrderWrapper item container xs={12}>
          {!isSmall && (
          <Grid item container xs={12}>
            <TabsButtons
              active={active}
              setActive={setActive}
              handleStatusValueChange={handleStatusValueChange}
            />
          </Grid>
          )}
          <Orders
            data={data}
            error={error}
            loading={loading}
            activeTab={active}
            refetch={refetch}
            pageCount={+pageCount}
            pageNumber={+pageNumber}
            setPageCount={setPageCount}
            setPageNumber={setPageNumber}
          />
        </OrderWrapper>
      </OrderGridContainer>
    </MainContent>
  );
};

export default OrdersAdminContainer;
