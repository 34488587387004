import React from 'react';
import { useQuery } from '@apollo/client';
import {
  BoxWrapper, CardContainer, ViewWrapper, Title, TitleWrapper, ContainerWrapper,
  CountTypo, CountBox
} from './overview.styles';
import { ORDER_COUNTS_QUERY } from '../../queries/orders';
import { addHyphen } from '../shared/methods';
import { PersonalIcon, PeopleIcon, CompletedIcon } from '../../assets/svgs';
import currencyFormatter from '../shared/currencyFormatter';

const OverviewCard = () => {
  const {
    data: order
  } = useQuery(ORDER_COUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    totalOrders, totalOpenOrders, totalClosedOrders,
    totalValueOfOrdersPlaced, totalAmountPayable
  } = order?.orderCounts || {};

  const cards = [
    {
      id: 0, title: 'Total Orders', value: totalOrders,
    },
    {
      id: 1, title: 'Total Open Orders', value: totalOpenOrders,
    },
    {
      id: 2, title: 'Total Completed Orders', value: totalClosedOrders,
    },
    {
      id: 3, title: 'Total Value of Orders Placed', value: `₦ ${currencyFormatter(totalValueOfOrdersPlaced)}`
    },
    {
      id: 4, title: 'Total Amount Payable', value: `₦ ${currencyFormatter(totalAmountPayable)}`
    }
  ];

  const returnIcon = (id) => {
    switch (id) {
      case (0):
        return <PersonalIcon style={{ fontSize: '3rem' }} />;
      case (1):
        return <PeopleIcon style={{ fontSize: '2.5rem' }} />;
      case (2):
        return <CompletedIcon style={{ fontSize: '2.8rem' }} />;
      case (3):
        return <PersonalIcon style={{ fontSize: '3rem' }} />;
      case (4):
        return <PeopleIcon style={{ fontSize: '2.5rem' }} />;
      default:
        return <PersonalIcon style={{ fontSize: '3rem' }} />;
    }
  };

  return (
    <ContainerWrapper container spacing={3} data-testid="overview-card">
      {cards?.map((card) => (
        <BoxWrapper
          item
          sm={12}
          md={4}
          key={card?.id}
          className={`mpAdmin-uat-dashboardPage-${addHyphen(card.title)}`}
        >
          <CardContainer>
            <TitleWrapper container>
              <Title>{card.title}</Title>
              <CountBox>
                {returnIcon(card.id)}
              </CountBox>
            </TitleWrapper>
            <ViewWrapper container>
              <CountTypo>{card.value}</CountTypo>
            </ViewWrapper>
          </CardContainer>
        </BoxWrapper>
      ))}
    </ContainerWrapper>
  );
};
export default OverviewCard;
