import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabsButtons.styles';

const TabsButtons = ({ tab, handleSetTab, businessType }) => {
  const hideTab = () => {
    switch (businessType) {
      case 'MANUFACTURER':
      case 'IMPORTER':
        return true;
      default:
        return false;
    }
  };

  const tabs = [
    {
      value: 'OPEN', text: 'Open Orders', width: '10%', hidden: false
    },
    {
      value: 'CLOSED', text: 'Completed Orders', width: '14%', hidden: false
    },
    {
      value: 'PLACED', text: 'Orders Placed', width: '11%', hidden: hideTab()
    }
  ];

  const underlineLength = () => {
    const l = tabs.filter((_t) => !_t.hidden);
    if (l.length < 3) return '76%';
    return '65%';
  };

  return (
    <StatusesGrid container item>
      {
        tabs.filter((_t) => !_t.hidden).map((t) => {
          const { value, text, width } = t;
          return (
            <UnderlineGrid
              key={value}
              item
              container
              onClick={() => handleSetTab(value)}
              active={tab === value}
              width={width}
              className="puremp-uat-ordersPageTab-open-orders"
            >
              <Typo active={tab === value}>{text}</Typo>
            </UnderlineGrid>
          );
        })
      }
      <UnderlineGrid container width={underlineLength()} marginTop="1.3rem">
        <Typo>{' '}</Typo>
      </UnderlineGrid>
    </StatusesGrid>
  );
};
TabsButtons.propTypes = {
  tab: PropTypes.string,
  handleSetTab: PropTypes.func.isRequired,
  businessType: PropTypes.string,
};

TabsButtons.defaultProps = {
  tab: '',
  businessType: ''
};

export default TabsButtons;
