import {
  Grid, Typography, Paper, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowIcon } from '../../assets/svgs';

export const ContainerWrapper = styled(Grid)`
  @media(max-width: 991px) {
    padding: 2rem 2.5rem;
  }
`;

export const BoxWrapper = styled(Grid)`
  @media(max-width: 991px) {
    display: flex;
  }
`;

export const CardContainer = styled(Paper)`
  border-radius: 8px;
  height: 8.125rem;
  padding: 1.125rem 1.25rem;
  box-shadow: 0px 11px 18px 0px rgba(0, 0, 0, 0.04);
`;

export const ViewWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;

`;

export const TitleWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: .875rem;
  color: #6c7278;
`;

export const Description = styled(Typography)`
  opacity: 0.5;
  font-size: 2.5rem;
  @media(min-width: 992px) {
    font-size: .9rem;
  }

  @media(min-width: 1445px){
    font-size: 1rem;
  }
`;

export const CountBox = styled(Box)`
  margin-left: auto;
`;

export const CountTypo = styled(Typography)`
  font-weight: 700;
  font-size: 1.5rem;
`;

export const Arrows = styled(ArrowIcon)`
&.MuiSvgIcon-root {

}

@media (max-width: 991px) {
  font-size: 6rem;
}
`;
