import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BellNotifyIcon } from '../../assets/svgs';
import { CHANGE_NOTIFICATION_STATUS } from '../../mutations/notifications';
import {
  CListItem, CListItemAvatar, CAvatar, CListItemSecondaryAction, TimingText,
  RedBadge, SmallText, HeaderContainer, NotificationImg, NotifyTextBody, NotifyTextContainer, NotifyTextHeader
} from './notificationListItem.styles';
import { JSONParse } from '../../utils/json';


const NotificationListItem = ({ item }) => {
  const navigate = useNavigate();

  const {
    id, title, productName, dateCreated, status, deletedBy, productId, meta, message
  } = item;

  const { fullName } = deletedBy ?? {};
  const { order_id: orderId } = JSONParse(meta) || {}

  const [updateNotification] = useMutation(CHANGE_NOTIFICATION_STATUS);
  const changeNotificationHandler = (notificationId, _status) => {
    updateNotification({
      variables: { notificationId, status: _status }
    });
  };

  const handleCLick = () => {
    if (status === 'NOT_READ') changeNotificationHandler(id, 'read');
    if (orderId) {
      navigate(`/orders-admin/dispatch/${orderId}`);
    } else {
      navigate(`/new-order/All Categories/0/${productId}`);
    }
  };
  const notificationTitle = (type) => {
    switch (type) {
      case 'new_product_alert':
        return 'New Product Alert';
      case 'order_approval':
        return 'Order Approved';
      case 'order_sorted':
        return 'Order Sorted';
      case 'order_dispatched':
        return 'Order Dispatched';
      case 'order_ready_for_dispatched':
        return 'Order Ready For Dispatch';
      case 'order_canceled':
        return 'Order Canceled';
      case 'order_returned':
        return 'Order Returned';
      default:
        return 'Product Back in Stock';
    }
  };

  return (
    <CListItem disableGutters button onClick={handleCLick}>
      <CListItemAvatar>
        {status === 'NOT_READ' && <RedBadge />}
        <CAvatar>
          <BellNotifyIcon />
        </CAvatar>
      </CListItemAvatar>
      <NotifyTextContainer>
        <HeaderContainer>
          <NotifyTextHeader>{notificationTitle(title)}</NotifyTextHeader>
          <NotificationImg />
        </HeaderContainer>
        <NotifyTextBody>{productName || message}</NotifyTextBody>
      </NotifyTextContainer>
      <CListItemSecondaryAction>
        <TimingText>{moment.utc(dateCreated).local().startOf('seconds').fromNow()}</TimingText>
        {!!fullName && <SmallText>{`Archived By ${fullName}`}</SmallText>}
      </CListItemSecondaryAction>
    </CListItem>

  );
};

NotificationListItem.propTypes = {
  item: PropTypes.shape(Object),

};

NotificationListItem.defaultProps = {
  item: {},
};

export default NotificationListItem;
