import { useQuery } from '@apollo/client';
import {
  Box, Button, Grid, Typography, useMediaQuery
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TRACK_ORDER } from '../../../../../queries/orders';
import MainContent from '../../../../customComponents/mainContent';

import {
  BottomPaper, ContentBox, EmptyBox, HeaderTypo, IdPaper, SubHeader,
  TrackText, Typo, PackagePaper, OrderPackTitle, OrderPackSub, PackageColor,
  HeaderContainer, CardWrapper, BackArrowIconContainer, BackText, KeyboardArrowLeft
} from './packageListContainer.styles';
import PackageListStepper from './packageListStepper';
import { PackageIcon, ParcelTrack } from '../../../../../assets/svgs';

export default function PackageListContainer() {
  const params = useParams();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [steps, setSteps] = useState([]);
  const [displayTrack, setDisplayTrack] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderPackage, setOrderPackage] = useState([]);

  const {
    loading, error, data
  } = useQuery(TRACK_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { orderId: +params.id, adminPage: true }
  });

  const initialSteps = [
    {
      header: 'Order Placed',
      title: 'Your order has been placed! Sit back and relax while we take care of the rest.',
      date: ''
    },
    {
      header: 'Order Approved',
      title: 'Congratulations! Your order has been approved and is now on its way to been Sorted.',
      date: ''
    },
    {
      header: 'Order Package Sorted',
      title: 'Hi, your package order has been sorted based on its destination and other factors such as weight, size and delivery speed.',
      date: ''
    },
    {
      header: 'Order Ready for Dispatch',
      title: "Hi, your package will be on its way soon, it's ready for delivery.",
      date: ''
    },
    {
      header: 'Order Package Dispatched',
      title: 'Hi, Your package has been delivered to the "recipient\'s address or a designated pickup location".',
      date: ''
    },
  ];

  const handlePartialFulfilled = () => initialSteps.splice(4, 0);

  const handleSteps = (item) => {
    const {
      orderDispatched, orderReadyForDispatch, orderSorted,
      orderApproved, orderPlaced, orderRejected
    } = item || {};

    let color;
    let sub;

    if (orderRejected) {
      color = '#ff0000';
      sub = 'Order Rejected';
      setActiveStep(1);
      setSteps([
        {
          header: 'Order Placed',
          title: 'Your order has been placed! Sit back and relax while we take care of the rest.',
          date: orderPlaced
        },
        {
          header: 'Order Rejected',
          title: 'Your order has been rejected.',
          date: orderRejected
        }
      ]);
    } else {
      const updatedSteps = initialSteps.map((step) => {
        switch (step.header) {
          case 'Order Placed':
            return { ...step, date: orderPlaced };
          case 'Order Approved':
            return { ...step, date: orderApproved };
          case 'Order Package Sorted':
            return { ...step, date: orderSorted };
          case 'Order Ready for Dispatch':
            return { ...step, date: orderReadyForDispatch };
          case 'Order Package Dispatched':
            return { ...step, date: orderDispatched };
          default:
            return step;
        }
      });
      setSteps(updatedSteps);
      if (orderDispatched) {
        color = '#5465ff';
        sub = 'Order Dispatched';
        setActiveStep(4);
      } else if (orderReadyForDispatch) {
        color = '#00bfff';
        sub = 'Ready for Dispatch';
        setActiveStep(3);
      } else if (orderSorted) {
        color = '#9747ff';
        sub = 'Sorted';
        setActiveStep(2);
      } else if (orderApproved) {
        color = '#008000';
        sub = 'Order Approved';
        setActiveStep(1);
      } else if (orderPlaced) {
        color = '#f4bb47';
        sub = 'Order Placed';
        setActiveStep(0);
      }
    }
    return { color, sub };
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (data?.trackOrder && !error) {
      setSteps(initialSteps);
      setOrders(data?.trackOrder);
      handlePartialFulfilled();
      const packages = data?.trackOrder.map((item, index) => {
        const id = index + 1;
        const { color, sub } = handleSteps(item);
        const packageItem = {
          id, name: `Order Package ${id}`, color, item,
          sub
        };
        return packageItem;
      });
      setOrderPackage(packages);
    }
  }, [loading, data]);

  const handleDisplayStepper = (id, item) => {
    setDisplayTrack(displayTrack === id ? 0 : id);
    if (item) handleSteps(item);
  };

  const handleViewDetails = (_id, index, sellerBusinessId) => {
    navigate(`/order-details/${_id}`, { state: { index, businessId: sellerBusinessId } });
  };

  return (
    <MainContent>
      <ContentBox>
        <HeaderContainer item>
          <BackArrowIconContainer onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
            <BackText>Back</BackText>
          </BackArrowIconContainer>
          <HeaderTypo>Package List & Status</HeaderTypo>
          <SubHeader>View List of orders and their status</SubHeader>
        </HeaderContainer>

        {(!loading && !error && orders.length) ? (
          <>
            <IdPaper>
              <Box>
                <Typography
                  style={{
                    fontSize: isSmall ? '2rem' : '1.313rem', fontWeight: '700', marginBottom: '.5rem'
                  }}
                  data-testid="orderId"
                >
                  Order ID
                </Typography>
                <Typography
                  style={{
                    fontSize: isSmall ? '2.5rem' : '1.5rem', fontWeight: '700', marginBottom: '1rem'
                  }}
                >
                  {params.id}
                </Typography>
              </Box>
            </IdPaper>

            {orderPackage.map(({
              name, sub, id, color, item
            }, index) => (
              <PackagePaper key={id} isOpen={(displayTrack === id && true)} rejected={item?.orderRejected}>
                <CardWrapper container>
                  <Grid container spacing={3} item xs={8}>
                    <Grid item>
                      <PackageIcon style={{ fontSize: '3.5rem' }} />
                    </Grid>
                    <Grid item>
                      <OrderPackTitle active={(displayTrack === id && true)}>
                        {name}
                      </OrderPackTitle>
                      {displayTrack !== id && (
                        <OrderPackSub>
                          <PackageColor color={color} />
                          {sub}
                        </OrderPackSub>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="outlined"
                      style={{
                        width: '11rem', height: '3rem', marginRight: '1rem', borderRadius: '15px'
                      }}
                      onClick={() => handleViewDetails(item?.sellerOrderId, index + 1, item?.sellerBusinessId)}
                    >
                      View Products
                    </Button>
                    <Box style={{ marginTop: '.6rem' }}>
                      {displayTrack === id
                        ? (
                          <KeyboardArrowUpIcon
                            style={{
                              fill: '#235a91', fontSize: '2rem', cursor: 'pointer'
                            }}
                            onClick={() => handleDisplayStepper(id, item)}
                          />
                        )
                        : (
                          <KeyboardArrowDownIcon
                            style={{
                              fill: '#235a91', fontSize: '2rem', cursor: 'pointer',
                            }}
                            onClick={() => handleDisplayStepper(id, item)}
                          />
                        )}
                    </Box>
                  </Grid>
                </CardWrapper>
                {displayTrack === id && <hr style={{ marginTop: '1rem', border: '0.5px solid #f5f5f5' }} />}
                {displayTrack === id && (
                  <PackageListStepper
                    activeStep={activeStep}
                    steps={steps}
                  />
                )}
              </PackagePaper>
            ))}
          </>
        ) : (
          <BottomPaper elevation={0}>
            <EmptyBox>
              <ParcelTrack style={{ fontSize: '11rem' }} />
              <Typo>Nothing to display yet!</Typo>
              <TrackText>
                Kindly enter your order number to be able to track your order
              </TrackText>
            </EmptyBox>
          </BottomPaper>
        )}
      </ContentBox>
    </MainContent>
  );
}

PackageListContainer.propTypes = {
};

PackageListContainer.defaultProps = {
};
