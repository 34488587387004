import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  DropZoneContainer, SelectButton, UploadIcon, UploadedText,
  MiddleText, UploadBox, UploadText, theme, FileText
} from './fileDropzone.styles';

const DropzoneContent = ({
  state, name: typeName
}) => {
  const [fileInfo] = state?.[typeName] ?? [];
  const { file: { name, size } } = fileInfo ?? { file: { name: '', size: 0 } };
  const formatFileSize = (fileSize) => {
    const units = ['Bytes', 'KB', 'MB'];
    let index = 0;
    while (fileSize >= 1024 && index < units.length - 1) {
      fileSize /= 1024;
      index++;
    }
    const sizeFormatted = `${fileSize.toFixed(2)} ${units[index]}`;
    return sizeFormatted;
  };

  const formattedSize = formatFileSize(size);

  return (
    <DropZoneContainer>
      <Box>
        <UploadIcon />
      </Box>
      <UploadBox>
        {name.length > 0 ? (
          <>
            <FileText>
              {name}
            </FileText>
            <UploadedText>
              {formattedSize}
              {' '}
              - 100% uploaded
            </UploadedText>
          </>
        ) : (
          <>
            <UploadText>Upload or drag and drop here</UploadText>
            <MiddleText>
              .png, .jpg or .jpeg size no more than 2MB
            </MiddleText>
            <SelectButton>Select File</SelectButton>
          </>
        )}
      </UploadBox>
    </DropZoneContainer>
  );
};

DropzoneContent.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
};

const DropZone = ({
  handleFile, name, state
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <DropzoneAreaBase
        acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'image/gif']}
        dropzoneText={<DropzoneContent state={state} name={name} />}
        fileslimit={1}
        onAdd={(filesData) => handleFile(filesData, name)}
        maxFileSize={2097152}
        clearOnUnmount
      />
    </ThemeProvider>
  </StyledEngineProvider>
);

DropZone.propTypes = {
  handleFile: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired
};

export default DropZone;
