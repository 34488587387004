import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import CustomInputBase from '../../../customComponents/customInputBase';
import { FormGrid, InputWrapper } from './createBusiness.styles';

const initialErrorState = {
  businessNameError: false,
  contactPersonError: false,
  storeAddressError: false,
  landmarkError: false,
  cityError: false,
  stateError: false
};

const SellerCreateBusiness = ({
  state, setState, setProceed, sellerState
}) => {
  const [errorState, setErrorState] = useState(initialErrorState);

  const {
    businessName, contactPerson, storeAddress, landmark,
    city, location
  } = state || {};

  const {
    businessNameError, contactPersonError, storeAddressError,
    landmarkError, cityError, stateError
  } = errorState;

  const { name: buyerName, location: buyerLocation } = sellerState || {};

  const validateFields = () => (
    ((businessName?.length || buyerName?.length)) && contactPerson?.length && storeAddress?.length && landmark?.length
    && city?.length && ((location?.length || buyerLocation?.length))
  );

  const validateState = (name, value) => {
    switch (name) {
      case 'businessName':
      case 'contactPerson':
      case 'storeAddress':
      case 'landmark':
      case 'city':
      case 'state':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    if (validateFields()) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({
      ...state,
      [name]: value
    });
  };

  const infoField = [
    {
      name: 'businessName', label: 'Store Name', secured: buyerName?.length, show: true,
      onChange: handleChange, placeholder: 'e.g Kings Pharmacy', required: true, type: 'text',
      error: businessNameError, helperText: 'Store name is required', val: buyerName?.length && buyerName
    },
    {
      name: 'contactPerson', label: 'Contact Person Full Name', secured: false, show: true,
      onChange: handleChange, placeholder: 'e.g John Doe', required: true, type: 'text',
      error: contactPersonError, helperText: 'Contact Person full name is required'
    },
    {
      name: 'storeAddress', label: 'Store Address', secured: false, show: true,
      onChange: handleChange, placeholder: 'e.g 10B Adeola Odeku Street, Victoria Island, Lagos State', required: true, type: 'text',
      error: storeAddressError, helperText: 'Store Address is required'
    },
    {
      name: 'landmark', label: 'Landmark', secured: false, show: true,
      onChange: handleChange, placeholder: 'e.g Shoprite Ikeja', required: true, type: 'text',
      error: landmarkError, helperText: 'Landmark is required',
      isTooltip: true, tooltipDesc: 'Landmark is a well-known building or a unique crossroads closer to your business'
    },
    {
      name: 'city', label: 'City', secured: false, show: true,
      onChange: handleChange, placeholder: 'e.g Somolu', required: true, type: 'text',
      error: cityError, helperText: 'City is required'
    },
    {
      name: 'state', label: 'State', secured: true, show: true,
      onChange: handleChange, placeholder: 'e.g Oyo', required: true, type: 'text',
      error: stateError, helperText: 'State is required'
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      isTooltip, tooltipDesc
    } = field;
    if (fieldName === 'state') {
      return (
        <CustomSelectInputBase
          field={field}
          value={location || buyerLocation}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        isTooltip={isTooltip}
        tooltipDesc={tooltipDesc}
      />
    );
  };

  return (
    <InputWrapper item container>
      <FormGrid item xs={12} lg={12} data-testid="formTextfield">
        {infoField.filter((item) => item.show).map((field) => (
          <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem', width: '97%' }}>
            {returnTextField(field)}
          </Grid>
        ))}
      </FormGrid>
    </InputWrapper>
  );
};

SellerCreateBusiness.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired,
  sellerState: PropTypes.instanceOf(Object)
};

SellerCreateBusiness.defaultProps = {
  sellerState: {}
};

export default SellerCreateBusiness;
