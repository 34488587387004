import React from 'react';
import PropTypes from 'prop-types';
import {
  Step,
} from '@mui/material';
import {
  StepperStyled, MUIStepper, MUIStepLabel, CircleStyled, CheckCircleStyled
} from './stepper.styles';

const Stepper = ({ activeStep }) => {
  const steps = [
    'firstStep',
    'secondStep',
    'thirdStep',
  ];

  const renderIcon = (key) => {
    if (key <= activeStep) return CircleStyled;
    return CheckCircleStyled;
  };

  return (
    <StepperStyled>
      <MUIStepper>
        {steps.map((label, index) => (
          <Step key={label}>
            <MUIStepLabel StepIconComponent={renderIcon(index)} />
          </Step>
        ))}
      </MUIStepper>
    </StepperStyled>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired
};

export default Stepper;
