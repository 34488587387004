import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import {
  FiltersGrid, SetFilter, MenuItems, FilterText,
  DeleteBtn,
  DeleteText
} from './productsFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const ProductFilter = ({
  state, setState, handleFilterChange, handleSetFilter, handleDeleteProduct, handleStatusChange
}) => {
  const statusOption = [
    { name: 'Enable', value: 'ACTIVE' },
    { name: 'Disable', value: 'IN_ACTIVE' },
  ];

  const handleSubmit = (dateRange) => {
    const { dateFrom: from, dateTo: to } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(from).format('YYYY-MM-DD'),
      dateTo: moment(to).format('YYYY-MM-DD')
    }));
  };

  const FilterFields = [
    { name: 'productName', label: 'Enter Product Name' },
    { name: 'sku', label: 'Enter SKU' },
  ];

  const productStatuses = [
    { name: 'Enabled', value: 'enabled' },
    { name: 'Disabled', value: 'disabled' },
    { name: 'Out of stock', value: 'out_of_stock' },
  ];

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item>
        <CustomNewTextField
          field={{ name: 'status', label: 'Product Status' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '16%', borderRadius: '.5rem' }}
          marginLeft="2.5rem"
        >
          {productStatuses.map(({ name, value }) => (
            <MenuItems key={value} value={value} data-testid={`pure-mp-uat-filter-by-${name}`} className={`pure-mp-uat-filter-by-${name}`}>
              {name}
            </MenuItems>
          ))}
        </CustomNewTextField>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '16%', borderRadius: '.5rem' }}
            className={`pure-mp-uat-filter-by-${field?.name}`}
            data-testid={`pure-mp-uat-filter-by-${field?.name}`}
          />
        ))}
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            styles={{
              height: '47px', width: '16rem', border: '1px solid #E7E8E9', borderRadius: '.5rem'
            }}
            xs={12}
            className="pure-mp-uat-filter-by-date"
            data-testid="pure-mp-uat-filter-by-date"
          />
        </Grid>
        <DeleteBtn
          className="pure-mp-uat-bulk-delete"
          type="tertiary"
          onClick={handleDeleteProduct}
          data-testid="pure-mp-uat-bulk-delete"
        >
          <DeleteText>Delete</DeleteText>
        </DeleteBtn>
        <CustomNewTextField
          field={{ name: 'changeStatus', label: 'Change status' }}
          state={state}
          select
          handleChange={handleStatusChange}
          style={{ width: '16%', borderRadius: '.5rem' }}
          marginLeft="2.5rem"
          maxHeight="20rem"
        >
          {statusOption.map(({ name, value }) => (
            <MenuItems key={value} value={value} data-testid={`pure-mp-uat-filter-by-${name}`} className={`pure-mp-uat-filter-by-${name}`}>
              {name}
            </MenuItems>
          ))}
        </CustomNewTextField>
        <SetFilter
          className="pure-mp-uat-set-filter"
          type="tertiary"
          onClick={handleSetFilter}
          data-testid="pure-mp-uat-set-filter"
        >
          <FilterText>Set Filter</FilterText>
        </SetFilter>
      </FiltersGrid>
    </Grid>
  );
};

ProductFilter.propTypes = {
  state: PropTypes.shape(Object),
  setState: PropTypes.shape(Object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

ProductFilter.defaultProps = {
  state: {},
};

export default ProductFilter;
