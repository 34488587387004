import React from 'react';
import PropTypes from 'prop-types';
import {
  StatisticWrapper, StatisticGrid, StatisticHeader,
  StatisticContent, StatisticPaper, IconGrid,
} from './statisticCard.styles';

export default function StatisticCard({
  statistic, statIcon
}) {
  const {
    id, title, value
  } = statistic;

  return (
    <StatisticPaper elevation={2}>
      <StatisticWrapper container item>
        <StatisticGrid item>
          <StatisticHeader item>
            {title}
          </StatisticHeader>
          <IconGrid item>
            {statIcon(id)}
          </IconGrid>
        </StatisticGrid>
      </StatisticWrapper>
      <StatisticContent>
        {value}
      </StatisticContent>
    </StatisticPaper>
  );
}

StatisticCard.propTypes = {
  statistic: PropTypes.instanceOf(Object),
  value: PropTypes.number,
  statIcon: PropTypes.func.isRequired
};
StatisticCard.defaultProps = {
  statistic: {},
  value: 0,
};
