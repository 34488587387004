import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
  mutation SignUp($signupData: SignUpInputType!) {
    signup(signupData: $signupData) {
      message
      user {
        id
        businessesVerified {
          id
          name
        }
        fullName
      }
      authToken
      business {
        id
        name
        status
        businessType
      }
    }
  }
`;

export const BECOME_SELLER_MUTATION = gql`
  mutation becomeSeller (
    $city: String!
    $contactPersonName: String!
    $landmark: String!
    $sellerInfo: SellerInfoInputType!
    $storeAddress: String!
  ) {
    becomeASeller(
      city: $city
      contactPersonName: $contactPersonName
      landmark: $landmark
      sellerInfo: $sellerInfo
      storeAddress: $storeAddress
    ) {
      message
    }
  }
`;

// export const SIGNUP_MUTATION = gql`
//   mutation signup(
//     $businessName: String!
//     $location: String!
//     $businessType: BusinessEnum!
//     $buyerType: BuyerEnum
//     $sellerType: SellerEnum
//     $salesRepresentativeName: String!
//     $contactPersonName: String!
//     $city: String!
//     $deliveryAddress: String!
//     $landmark: String!
//     $registrationNumber: String
//     $pharmacyCouncilRegistrationLicence: String
//     $cac: String
//     $nafdac: String
//     $userFullName: String!
//     $email: String!
//     $phoneNumber: String!
//     $password: String!
//   ) {
//     signup(
//       businessName: $businessName
//       location: $location
//       businessType: $businessType
//       salesRepresentativeName: $salesRepresentativeName
//       contactPersonName: $contactPersonName
//       city: $city
//       deliveryAddress: $deliveryAddress
//       landmark: $landmark
//       userFullName: $userFullName
//       email: $email
//       phoneNumber: $phoneNumber
//       password: $password
//     ) {
//       message
//       user {
//         id
//         businessesVerified {
//           id
//           name
//         }
//         fullName
//       }
//       authToken
//       business {
//         id
//         name
//         status
//         businessType
//         buyerType
//         sellerType
//       }
//     }
//   }
// `;
