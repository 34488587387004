import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ORDERS } from '../../../queries/orders';
import Order from './order';
import { JSONParse } from '../../../utils/json';

export function OrderContainer() {
  const count = sessionStorage.getItem('rowsPerPage') || 10;
  const [pageCount, setPageCount] = useState(count);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState('');

  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    id: '',
    orderTotalFrom: '',
    orderTotalTo: '',
    productName: '',
  };

  const [state, setState] = useState(initialState);
  const { id: orderId } = state;

  const [dateFilter, setDateFilter] = useState({
    dateFrom: '',
    dateTo: ''
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    const newDateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const newDateTo = moment(dateTo).format('YYYY-MM-DD');

    setDateFilter({
      dateFrom: newDateFrom,
      dateTo: newDateTo
    });
  };

  const handleSearch = (value) => {
    setState({ ...state, id: +value });
  };

  const { dateFrom, dateTo } = dateFilter;

  const {
    loading, error, data
  } = useQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount,
      pageNumber,
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...(orderId && { id: orderId }),
    }
  });

  if (error) return `Error! ${error.message}`;

  const orders = data?.orders || [];
  const ordersTotalNumber = data?.ordersTotalNumber || 0;
  const ordersItems = orders
    .map(
      (items) => {
        const {
          datePlaced, id, paymentSummary
        } = items;
        const { total_amount: total } = JSONParse(paymentSummary);
        return {
          datePlaced, id, total
        };
      }
    );

  return (
    <Order
      orders={ordersItems}
      totalCount={ordersTotalNumber}
      loading={loading}
      pageCount={pageCount}
      pageNumber={pageNumber}
      setPageCount={setPageCount}
      setPageNumber={setPageNumber}
      handleSearch={handleSearch}
      handleSubmit={handleSubmit}
      setFilter={setFilter}
      orderId={orderId}
    />
  );
}

export default OrderContainer;
