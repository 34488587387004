import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Slide, TableBody
} from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CartGridContainer, DialogActionButtonText, BackArrowIconContainer,
  PageTitleContainer, CartGridContainerHeader,
  DialogCancelButton, DialogOkButton, KeyboardArrowLeft, TableHeader, MainTableRow,
} from '../../../cart/cart.styles';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, PageTitleText, PageTitleSubText,
  SubTotalText, SubTotalPrice, SubTotalBox, TableWrapper, TableComponent, Header,
  BackText
} from './styles';
import { GET_ORDER_DETAIL } from '../../../../queries/orders';
import { CANCEL_SUPPLIER_ORDERS } from '../../../../mutations/orders';
import MainContent from '../../../customComponents/mainContent';
import SupplierOrderDetailProductItem from './product-item';
import { JSONParse } from '../../../../utils/json';
import PaymentSummary from '../../../shared/paymentSummaryCard/PaymentSummary';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';

import { Product } from '../../../../providers/reducers/product/classes/Product';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';
import { parseJson } from '../../../affiliates/utils';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const initialState = {
  businessContact: null,
  supplier: null,
  deliveryDate: null,
  dateCreated: null,
  cart: [],
  totalPromo: 0,
  showCancelOrderDialog: false,
  editNote: false,
  note: '',
  orderId: null,
  supplierPaymentTerm: null,
  orderMeta: null,
  showPaymentDetails: false,
  orderStatus: null,
  deliveryId: null,
  business: {},
  products: []
};
const SupplierOrderDetailIndex = () => {
  const [state, setState] = useState(initialState);

  const params = useParams();
  const navigate = useNavigate();

  const [cancelSupplierOrders] = useMutation(CANCEL_SUPPLIER_ORDERS);

  const retrieveProducts = (orderSet) => {
    let products = [];
    let totalPromo = 0;

    products = orderSet?.map((p) => {
      const {
        id,
        name,
        quantity,
        quantityRecieved,
        meta: orderProductMeta,
        product: { meta }
      } = p;

      const {
        supposed_amount: supposedAmount,
        amount_paid: amountPaid,
        supposed_unit_cost: supposedUnitCost,
        unit_cost_paid: unitCostPaid,
        market_rpp: marketRpp,
      } = JSONParse(orderProductMeta?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
      totalPromo += (supposedUnitCost - unitCostPaid) * quantity;

      return {
        id,
        name,
        quantity,
        ...new Product(p.product),
        meta: JSON.parse(meta),
        supposedAmount,
        amountPaid,
        supposedUnitCost,
        unitCostPaid,
        marketRpp,
        quantityRecieved,
      };
    });
    setState((s) => ({
      ...s, cart: products, totalPromo
    }));
  };

  const { data } = useQuery(GET_ORDER_DETAIL, {
    variables: { orderId: +params.id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data && data?.order) {
      const {
        order: {
          id: orderId,
          datePlaced: dateCreated,
          orderProducts,
          business,
          paymentSummary
        }
      } = data;
      const datePlaced = moment(dateCreated).format('MMMM DD, YYYY [at] h:mma');
      const { buyerContact } = business;
      const parseSummary = parseJson(paymentSummary);
      const serviceCharge = parseSummary?.service_charge;

      setState((s) => ({
        ...s,
        orderId,
        buyerContact,
        dateCreated: datePlaced,
        business,
        serviceCharge,
        paymentSummary: parseSummary,
        products: orderProducts
      }));
    }
  }, [data]);

  const openDetailsPage = () => {
    setState((s) => ({ ...s, showPaymentDetails: true }));
  };

  const handleCancelOrder = () => {
    setState((s) => ({ ...s, loading: true, showCancelOrderDialog: false }));

    cancelSupplierOrders({
      variables: { supplierOrderIds: [params.id] }
    }).then((results) => {
      const {
        data: { cancelSupplierOrders: { message } }
      } = results;

      toast.success(message);
      setTimeout(() => navigate('/orders'), 1500);
    }).catch((err) => {
      toast.error(err?.message);
      setState((s) => ({ ...s, loading: false }));
    });
  };

  const {
    buyerContact, deliveryDate, dateCreated, products,
    showCancelOrderDialog, supplierPaymentTerm, business, paymentSummary
  } = state;

  const {
    service_charge: serviceCharge, total_amount: totalAmount, net_amount: netAmount
  } = paymentSummary || {};

  return (
    <MainContent>
      <CartGridContainer>
        <CartGridContainerHeader container>
          <Grid xs={12}>
            <BackArrowIconContainer onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
              <BackText>Back</BackText>
            </BackArrowIconContainer>
          </Grid>
          <Grid item container xs={12} md={6} alignItems="center">
            <PageTitleContainer>
              <PageTitleText>Order Detail</PageTitleText>
              <PageTitleSubText style={{ color: '#303030', paddingBottom: '20px' }}>View your order details</PageTitleSubText>
            </PageTitleContainer>
          </Grid>
        </CartGridContainerHeader>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item container lg={4}>
            <OrderInfoCard
              id="ID0D123-SU12"
              business={business}
              dateCreated={dateCreated}
              businessDateDelivered={deliveryDate}
              supplierPaymentTerm={supplierPaymentTerm}
              displayId="hide"
              affiliateInfoCard="affiliateInfoCard"
              orderId={params.id}
            />
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard elevation={0}>
                <DeliveryInfoCard
                  businessContact={buyerContact}
                  openDetailsPage={openDetailsPage}
                />
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>

          <Grid item container lg={4}>
            <SupplierDetailCardWrapper>
              <SupplierDetailCard elevation={0}>
                <PaymentSummary
                  orderTotal={totalAmount}
                  serviceFee={serviceCharge}
                />
              </SupplierDetailCard>
            </SupplierDetailCardWrapper>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item container lg={12}>
            <SupplierDetailCardWrapper elevation={2}>
              <TableWrapper>
                <TableComponent aria-label="affiliates table">
                  <TableHeader>
                    <MainTableRow>
                      <Header style={{ paddingLeft: '1rem' }}> Product name</Header>
                      <Header> Qty Received</Header>
                      <Header> Qty Ordered</Header>
                      <Header> Unit Cost</Header>
                      <Header>Price</Header>
                    </MainTableRow>
                  </TableHeader>
                  <TableBody>
                    {products.map((productItem) => (
                      <SupplierOrderDetailProductItem
                        key={productItem}
                        product={productItem}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              </TableWrapper>
              <SubTotalBox>
                <SubTotalText>
                  Subtotal
                </SubTotalText>
                <SubTotalPrice>
                  {`₦${currencyFormatter(netAmount)}`}
                </SubTotalPrice>
              </SubTotalBox>
            </SupplierDetailCardWrapper>
          </Grid>
        </Grid>
      </CartGridContainer>

      <Dialog
        open={showCancelOrderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Cancel Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => setState((s) => ({ ...s, showCancelOrderDialog: false }))}
          >
            <DialogActionButtonText>
              Cancel
            </DialogActionButtonText>
          </DialogCancelButton>

          <DialogOkButton onClick={() => handleCancelOrder()}>
            <DialogActionButtonText>
              OK
            </DialogActionButtonText>
          </DialogOkButton>
        </DialogActions>
      </Dialog>
    </MainContent>
  );
};

export default SupplierOrderDetailIndex;
