import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  DialogContainer,
  ListContainer,
  ErrorIconWrapper,
  SuccessContainer,
  ListItems,
  ErrDetails,
  SuccessTitle,
  SuccessDesc,
  ItemContainer,
  ItemHeader,
  ItemDesc,
  CartIcon,
  BtnContainer,
  TextContainer,
  IconContainer
} from './productStatusDialog.style';
import { FilledCustomButton } from '../customComponents/customButton';
import {
  CartSvg, ErrorIcon, SuccessIcon
} from '../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const FailedProductDialog = ({
  openDialog, toggleHandler, data, title, type, totalEntries, failedEntries
}) => {
  const navigate = useNavigate();

  const handleProceedToCart = () => navigate('/cart');

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem', marginBottom: '3rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>{type === 'orders' ? 'Bulk Order Upload' : title}</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer', color: '#235A91' }}
              />
            </Box>
            <TitleDesc>
              View the failed uploads in your csv and the reason why
            </TitleDesc>
            {totalEntries ? (
              <errorDesc>
                {failedEntries}
                &nbsp;of&nbsp;
                {totalEntries}
                &nbsp;
                uploads failed
              </errorDesc>
            ) : ''}
            {type === 'orders' ? (
              <ItemContainer container>
                <IconContainer>
                  <CartIcon />
                </IconContainer>
                <TextContainer item>
                  <ItemHeader>Proceed to Cart</ItemHeader>
                  <ItemDesc>
                    Click the &apos;Proceed&apos; button to continue to the cart, or close the modal to return to
                    correct any errors listed.
                  </ItemDesc>
                </TextContainer>
                <BtnContainer item>
                  <FilledCustomButton onClick={handleProceedToCart} style={{ width: '5.5rem', height: '2.5rem' }}>
                    Proceed
                  </FilledCustomButton>
                </BtnContainer>
              </ItemContainer>
            ) : ''}
            {data?.length < 1 ? (
              <SuccessContainer>
                {type !== 'orders' ? <SuccessIcon style={{ fontSize: '120px' }} /> : <CartSvg style={{ fontSize: '120px' }} />}
                <SuccessTitle>No Failed Uploads!</SuccessTitle>
                <SuccessDesc>All your uploads were successful.</SuccessDesc>
                {type === 'orders' ? (
                  <FilledCustomButton onClick={handleProceedToCart}>
                    Proceed to Cart
                  </FilledCustomButton>
                ) : (
                  <FilledCustomButton onClick={toggleHandler}>
                    Ok
                  </FilledCustomButton>
                )}
              </SuccessContainer>
            ) : (
              data?.map((item, idx) => {
                const { row, desc } = item;
                return (
                  <ListContainer key={idx}>
                    <ListItems>
                      <ErrorIconWrapper>
                        <ErrorIcon />
                      </ErrorIconWrapper>
                      <ErrDetails>
                        <strong>{`Row ${row || 'N/A'} : `}</strong>
                        {desc || 'Error not defined'}
                      </ErrDetails>
                    </ListItems>
                  </ListContainer>
                );
              })
            )}
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
}

FailedProductDialog.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  totalEntries: PropTypes.number.isRequired,
  failedEntries: PropTypes.number.isRequired
};

FailedProductDialog.defaultProps = {
  openDialog: false,
  data: []
};

export default FailedProductDialog;
