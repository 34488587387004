import { styled } from '@mui/material/styles';
import {
  Box, Grid, IconButton, Typography
} from '@mui/material';

export const DispatchButton = styled(IconButton)`
  display: flex;
  width: 18rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #235a91;
  color: #ffffff;
  border-radius: 8px;
  height: 75px;
  &:hover {
    background-color: #235a91;
  }
  &:disabled {
    color: #ffffff;
    background-color: #235a91;
    opacity: 0.6;
  }
  @media (min-width: 992px) {
    height: 47px;
    width: 14rem;
    padding: 0px 15px;
    border-radius: 8px;
  }
`;

export const MultiSelectContainer = styled(Box)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MulTextStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: #303030;
`;

export const OrderListStyled = styled(Grid)`
  margin-top: 1rem;
  gap: 1%;
`;

export const Dispatch = styled(Grid)`
  width: 32.5%;
  background: #fff;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.18);
  padding: 25px 20px;
  border-radius: 12px;
  margin-bottom: 1rem;
`;

export const CardCheck = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckDate = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #82849F;
`;

export const CheckTime = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #82849F;
`;

export const BusinessName = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #606060;
  margin-top: 1rem;
`;

export const BusinessId = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: #606060;
  margin-top: 1rem;
`;

export const Price = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: #606060;
  margin-top: 2.5rem;
`;
