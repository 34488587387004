import React from 'react';
import {
  Dialog, DialogContent, DialogContentText,
  Slide, Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CircularProgressLoader, DialogHeader, DialogDesc } from './processingModal.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ProcessingModal = ({
  orderProcessing,
  handleClose,
}) => (
  <Dialog
    open={orderProcessing}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    onBackdropClick="false"
  >
    <DialogContent>
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={7}>
            <CircularProgressLoader
              size={80}
            />
          </Box>
          <DialogHeader>
            Uploading Orders
          </DialogHeader>
          <DialogDesc>
            Please wait while we upload your orders.
          </DialogDesc>
        </Box>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

ProcessingModal.propTypes = {
  orderProcessing: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProcessingModal;
