import {
  TableRow, TableCell, Paper, Typography, Grid, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
    tr:nth-child(odd): {
      background-color: red;
    }
  }
  tr:nth-child(odd): {
    background-color: red;
  }
  &:nth-of-type(odd): {
    background-color: red;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1rem;
  padding: 15px 10px;
  color: #606060;
  font-weight: 400;
`;

export const MobileContainer = styled(Paper)`
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1rem;
  width: 100%;
`;

export const DateText = styled(Typography)`
  font-weight: 600;
  font-size: 2.5rem;
  padding: 2rem;
`;

export const HeaderText = styled(Grid)`
  font-weight: 600;
  font-size: 2rem;
`;

export const DetailText = styled(Grid)`
  text-align: right;
  font-size: 2rem;
  justify-content: flex-end;
`;

export const TextBox = styled(Grid)`
  padding: 0 2rem 2rem 2rem;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;
