import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo
} from './stepper.styles';

const Stepper = ({
  stepVal, handleStepChange
}) => {
  const Step = [
    {
      label: '', value: 1, width: '33.5%'
    },
    {
      label: '', value: 2, width: '33.5%'
    },
    {
      label: '', value: 3, width: '33%'
    },
  ];

  return (
    <TabsGrid container item>
      {Step.map(({
        label, value, width
      }) => (
        <UnderlineGrid
          key={value}
          item
          container
          onClick={() => handleStepChange(value)}
          active={value <= stepVal}
          width={width}
        >
          <Typo active={value === stepVal}>
            {label}
          </Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid width="0%" style={{ padding: '10.5px 0' }}>
        <Typo />
      </UnderlineGrid>
    </TabsGrid>
  );
};

Stepper.propTypes = {
  stepVal: PropTypes.string.isRequired,
  handleStepChange: PropTypes.func.isRequired,
};

export default Stepper;
