import React, { useState, useEffect } from 'react';
import {
  Paper, TableContainer, TableBody, Grid, Table
} from '@mui/material';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { TableContainerWrapper, FooterGrid } from './users.styles';
import ReturnRow from './returnRow';
import UserFilter from './userFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';
import {
  TableComponent,
  TableColumnHeaders,
  TableHeader
} from '../../shared/tableGlobalStyle.styles';
import { MANAGE_USERS_ACCOUNT } from '../../../queries/manageAccounts';
import { TitleHeader } from '../userAccountsContainer.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { JSONParse } from '../../../utils/json';

const headers = [
  { name: 'Created Date', width: '200px' },
  { name: 'Business Name', width: '300px' },
  { name: 'Account Type', width: '250px' },
  { name: 'Location', width: '250px' },
  { name: 'Status', width: '150px' },
  { name: 'Action', width: '150px' }
];

const Users = () => {
  const initialFilterState = {
    dateFrom: '',
    dateTo: '',
    name: '',
    email: '',
    location: '',
  };

  const [state, setState] = useState(initialFilterState);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [accountType, setAccountType] = useState('Buyers');

  const [{ user: { session } }] = Object.values(useStateValue()) || {};
  const [tabVal, setTabVal] = useState('BUYER');

  useEffect(() => {
    if (session && session.businessuserSet && session.businessuserSet.length > 0) {
      const { businessuserSet: [{ meta }] } = session;
      const parsedMeta = JSONParse(meta) || {};
      const role = parsedMeta?.role;
      setTabVal(role === 'BUYER' ? 'BUYER' : 'SELLER');
      setAccountType(role === 'BUYER' ? 'Buyers' : 'Sellers');
    }
  }, [session]);

  const handleSetFilter = () => {
    setFilterState(state);
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const handleDateFilter = (dateRange) => {
    const { dateFrom: _dateFrom, dateTo: _dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(_dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(_dateTo).format('YYYY-MM-DD')
    }));
  };

  const { loading, data, refetch } = useQuery(MANAGE_USERS_ACCOUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      ...filterState,
      businessType: tabVal,
      pageCount,
      pageNumber
    }
  });

  const allBusinesses = data?.allBusinesses ?? [];
  const totalRecords = data?.allBusinessesTotalNumber ?? 0;

  return (
    <TableContainerWrapper component={Paper}>
      <TitleHeader>{`All Accounts For ${accountType}`}</TitleHeader>
      <Grid container xs={12}>
        <Grid item xs={12} style={{ marginLeft: 'auto' }}>
          <UserFilter
            state={state}
            handleFilterChange={handleFilterChange}
            handleSetFilter={handleSetFilter}
            handleDateFilter={handleDateFilter}
            tab={tabVal}
          />
        </Grid>
      </Grid>
      <TableContainer>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                <TableHeader />
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {allBusinesses.map((business, index) => (
                  <ReturnRow
                    key={business.id}
                    row={business}
                    index={index}
                    refetch={refetch}
                    tab={tabVal}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainer>
      <FooterGrid item container>
        <TablePagination
          total={totalRecords}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </TableContainerWrapper>
  );
};

export default Users;
