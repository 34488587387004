import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import {
  LabelText, InputWrapper, TermsStyled, CheckBoxContainer, CustomCheckbox,
  TermsDescription, TermsTitle, TermsContent, ViewTerms
} from './businessFile.styles';
import CustomInputBase from '../../../customComponents/customInputBase';
import DropZone from './fileDropzone';
import TermsAndConditionsPopUp from '../../alerts/TermsAndConditionsPopUp';

const initialErrorState = {
  companyRegNumError: false,
  cacFileError: false,
  pharmRegFileError: false,
  permitFileError: false,
};

const UploadBusinessFile = ({ state, setState, setProceed }) => {
  const [errorState, setErrorState] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);

  const { category } = state;
  const isImporter = category === 'Importer';

  const {
    companyRegNumError, cacFileError, pharmRegFileError, permitFileError
  } = errorState;

  const {
    companyRegNum, cacFile, pharmRegFile, permitFile
  } = state || {};

  const confirmTermChange = () => {
    if (terms) setTerms(false);
    else setTerms(true);
  };

  const validateFields = () => {
    if (!isImporter && (!permitFile || permitFile.length <= 0)) {
      return companyRegNum?.length && cacFile?.length && pharmRegFile?.length && terms;
    }
    return companyRegNum?.length && cacFile?.length && pharmRegFile?.length && terms && permitFile?.length;
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'companyRegNum':
      case 'cacFile':
      case 'pharmRegFile':
      case 'permitFile':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    if (validateFields()) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [state, terms]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'companyRegNum') {
      const sanitizedValue = value.replace(/[^0-9/]/g, '');
      validateState(name, value);
      setState({
        ...state,
        [name]: sanitizedValue
      });
    } else {
      validateState(name, value);
      setState({
        ...state,
        [name]: value
      });
    }
  };

  const categoryField = [
    {
      name: 'companyRegNum', label: 'Company Registration Number', secured: false, show: true,
      onChange: handleChange, placeholder: 'e.g 198605/0726/027', required: true, type: 'text',
      error: companyRegNumError, helperText: 'Company Registration Number is required',
      val: state?.companyRegNum || ''
    },
  ];

  const uploadFields = [
    {
      name: 'cacFile', label: 'Corporate Affairs Commission (CAC)', required: true,
      error: cacFileError, helperText: 'Company Registration Number is required'
    },
    {
      name: 'pharmRegFile', label: 'Pharmacist Council Registration Licence', required: true,
      error: pharmRegFileError, helperText: 'Company Registration Number is required'
    },
    {
      name: 'permitFile', label: 'NAFDAC Import Permit', required: isImporter && true,
      error: permitFileError, helperText: 'Company Registration Number is required'
    },
  ];

  const handleFile = (filesData, fileName) => {
    setState({
      ...state,
      [fileName]: filesData
    });
  };

  const handleDelete = (fileName) => {
    toast.success('File successfully removed');
    setState({
      ...state,
      [fileName]: []
    });
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type
    } = field;
    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
      />
    );
  };

  return (
    <>
      <InputWrapper item container>
        <Grid item xs={12} lg={12}>
          {categoryField.filter((item) => item.show).map((field) => (
            <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem' }} data-testid="formField">{returnTextField(field)}</Grid>
          ))}
          {uploadFields.map(({ label, name, required }) => (
            <div key={name} style={{ marginTop: '1rem' }}>
              <LabelText>
                {label}
                {' '}
                <span style={{ color: '#EE4223', fontSize: '16px' }}>{required && '*'}</span>
              </LabelText>
              <DropZone
                handleDelete={handleDelete}
                handleFile={handleFile}
                name={name}
                state={state}
              />
            </div>
          ))}
        </Grid>

        <TermsStyled>
          <CheckBoxContainer>
            <CustomCheckbox
              checked={terms}
              onChange={confirmTermChange}
            />
          </CheckBoxContainer>
          <TermsDescription data-testid="termsCondition">
            <TermsTitle>Terms and Conditions</TermsTitle>
            <TermsContent>
              By clicking the checkbox, you agree to the terms and conditions on OGApharmacy marketplace.
              {' '}
              <ViewTerms onClick={() => setOpen(true)}>Click here</ViewTerms>
            </TermsContent>
          </TermsDescription>
        </TermsStyled>
      </InputWrapper>

      <TermsAndConditionsPopUp
        open={open}
        setOpen={setOpen}
        terms={terms}
        confirmTermChange={confirmTermChange}
      />
    </>
  );
};

UploadBusinessFile.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired
};

export default UploadBusinessFile;
