import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer, DetailsContainer,
  HeaderText, TypoContainer, PackSizeTypo, PricingAndSaving, PricingWrapper, Pricing,
  ChangeWrapper, CardWrapper
} from './productCard.styles';
import currencyFormatter from '../../../../utils/currencyFormatter';

const ProductCard = ({
  product, index,
}) => {
  const {
    brandName, sellingPrice, meta
  } = product;

  const obj = JSON.parse(meta);
  const { pack_size: packSize, image } = obj;

  return (
    <CardPaper
      className={`mpFe-uat-new-order-card-${index}`}
    >
      <CardWrapper>
        <ImgBox>
          <ProductImg component="img" src={image} alt={brandName} />
        </ImgBox>
        <DetailsBox>
          <DetailsContainer>
            <Box>
              <HeaderContainer>
                <HeaderText variant="subtitle2">
                  {brandName}
                </HeaderText>
              </HeaderContainer>
              <TypoContainer item container xs={12}>
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypo item xs={6} variant="body1">
                    Pack Size:
                    &nbsp; &nbsp;
                    {packSize}
                  </PackSizeTypo>
                </ChangeWrapper>
                <PricingAndSaving item container justify-content="space-between">
                  <PricingWrapper item xs={6} md={6}>
                    <Pricing>
                      {currencyFormatter(sellingPrice)}
                    </Pricing>
                  </PricingWrapper>
                </PricingAndSaving>
              </TypoContainer>
            </Box>
          </DetailsContainer>
        </DetailsBox>

      </CardWrapper>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ProductCard.defaultProps = {
  product: {},
};

export default ProductCard;
