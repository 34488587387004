import React from 'react';
import MarketOrderContainer from './marketOrderContainer';

const MpOrderContainer = () => (
  <div>
    <MarketOrderContainer />
  </div>
);

export default MpOrderContainer;
