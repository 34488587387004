import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import MainContent from '../../../customComponents/mainContent';
import {
  UserGridContainer, BackArrowIconContainer, KeyboardArrowLeft,
  HeaderText, HeaderBox, SubHeaderText, BackText,
  DetailsPaper, UsernameText, DetailFieldGrid, AdornmentStyled,
  VerifyButton
} from './sellerDetails.styles';
import { VERIFY_BUSINESS } from '../../../../mutations/business';
import { GET_SELLER_BUSINESS } from '../../../../queries/businesses';
import { CircularProgressLoader } from '../../../auth/login.styles';
import Loader from '../../../shared/loader';
import ConfirmDialog from '../../../shared/confirmDialog';
import MarkupDialog from '../../userAccounts/markupDialog';
import { CustomInputBase, CustomSelectInputBase } from '../../../shared/customInput';
import { JSONParse } from '../../../../utils/json';
import IdUploads from './idUpload';
import { useStateValue } from '../../../../providers/stateProvider';
import SellerProfile from './sellerProfile';

const initialState = {
  cac: {
    url: '',
    fileName: '',
    fileSize: '',
  },
  nafdac: {
    url: '',
    fileName: '',
    fileSize: '',
  },
  pharmacyCouncilRegistrationLicence: {
    url: '',
    fileName: '',
    fileSize: '',
  }
};

const SellerAccountsDetails = () => {
  const { id: businessId } = useParams();
  const navigate = useNavigate();
  const [businessDetails, setBusinessDetails] = useState(initialState);
  const [openModal, setOpenModal] = useState(false);
  const [markup, setMarkup] = useState('');
  const [openMarkupDialog, setOpenMarkupDialog] = useState(false);
  const [tab, setTab] = useState('profile');

  const [{ user }] = Object.values(useStateValue());
  const { session } = user;
  const { isSeller, isBuyer } = session || null;
  const accountType = (isBuyer && !isSeller) ? 'BUYER' : 'SELLER';

  const [verifyAccount, { loading: vLoading }] = useMutation(VERIFY_BUSINESS);

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const handleChangeTab = () => {
    if (tab === 'profile') {
      setTab('orders');
    } else {
      setTab('profile');
    }
  };

  const {
    loading, error: businessError, data, refetch
  } = useQuery(GET_SELLER_BUSINESS, {
    variables: { businessId: Number(businessId) },
    fetchPolicy: 'network-only',
    skip: !businessId
  });

  const {
    name, location, sellerMeta, sellerContact, businessType,
    createdBy, fullname, contact, settings
  } = data?.business || {};

  useEffect(() => {
    setBusinessDetails((_s) => ({ ..._s, ...sellerMeta }));
  }, [sellerMeta]);

  if (loading) return <Loader />;
  if (businessError) return <div>{businessError.message}</div>;

  const { meta, email } = createdBy || {};
  const parseContact = JSONParse(contact);
  const parseSettings = JSONParse(settings);
  const parseMeta = JSONParse(meta);

  const { seller_contact: userContact } = parseContact;
  const { contact_person_phone_number: contactPersonNumber } = userContact || {};
  const { gender } = parseMeta;
  const { kyc } = parseSettings;
  const { business_type: busType, territory, files } = kyc || {};

  const {
    cac: { url: cacUrl, fileName: cacFileName, fileSize: cacFileSize },
    pharmacyCouncilRegistrationLicence: { url: pcrUrl, fileName: pcrFileName, fileSize: pcrFileSize },
    registrationNumber,
    verificationStatus: status
  } = businessDetails;

  const {
    url: nafdacUrl,
    fileName: nafdacFileName,
    fileSize: nafdacFileSize
  } = businessDetails?.nafdac || {};

  const formatText = (inputString) => (
    inputString
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(' ')
  );

  const {
    city, contactPersonName, landmark, storeAddress, emailAddress,
    phoneNumber, salesRepresentativeName
  } = sellerContact || {};

  const infoField = [
    {
      name: 'storeName', label: 'Store Name', secured: true, show: true,
      val: formatText(name), type: 'text',
    },
    {
      name: 'contactName', label: 'Contact Person Full Name', secured: true, show: true,
      val: formatText(contactPersonName), type: 'text',
    },
    {
      name: 'emailAddress', label: 'Email Address', secured: true, show: true,
      val: emailAddress, type: 'text',
    },
    {
      name: 'contactPersonPhoneNumber', label: 'Contact Person Phone Number', secured: true, show: true,
      val: contactPersonNumber, type: 'text', sAdornment: phoneAdornment, placeholder: 'e.g. 07012345678'
    },
    {
      name: 'phoneNumber', label: 'Phone Number', secured: true, show: true,
      val: phoneNumber, type: 'text', sAdornment: phoneAdornment, placeholder: 'e.g. 07012345678'
    },
    {
      name: 'salesRep', label: 'Sales Representative', secured: true, show: true,
      val: salesRepresentativeName, type: 'text',
    },
    {
      name: 'registrationNumber', label: 'Company Registration Number', secured: true, show: true,
      val: registrationNumber, type: 'text',
    },
    {
      name: 'territory', label: 'Territory', secured: true, show: true,
      val: territory, type: 'text',
    },
    {
      name: 'businessType', label: 'Business Type', placeholder: 'e.g. Retail',
      options: ['Male', 'Female'], show: true, secured: true, val: busType ?? businessType
    },
    {
      name: 'storeAddress', label: 'Store Address', secured: true, show: true,
      val: formatText(storeAddress), type: 'text',
    },
    {
      name: 'ownerName', label: "Owner's Name", secured: true, show: true,
      val: fullname, type: 'text',
    },
    {
      name: 'landmark', label: 'Landmark', secured: true, show: true,
      val: formatText(landmark), type: 'text',
    },
    {
      name: 'ownerEmail', label: "Owner's Email Address", secured: true, show: true,
      val: email, type: 'text',
    },
    {
      name: 'city', label: 'City', secured: true, show: true,
      val: formatText(city), type: 'text',
    },
    {
      name: 'gender', label: 'Gender', placeholder: 'Select Gender', val: gender,
      options: ['Male', 'Female'], show: true, secured: true
    },
    {
      name: 'state', label: 'State', secured: true, show: true,
      val: formatText(location), type: 'text',
    }
  ];

  const filesField = [
    {
      name: 'cacFile', label: 'Corporate Affairs Commission (CAC)',
      fileName: cacFileName, size: cacFileSize, url: cacUrl
    },
    {
      name: 'pharmRegFile', label: 'Pharmacist Council Registration Licence',
      fileName: pcrFileName, size: pcrFileSize, url: pcrUrl
    },
    nafdacUrl && {
      name: 'permitFile', label: 'NAFDAC Import Permit',
      fileName: nafdacFileName, size: nafdacFileSize, url: nafdacUrl
    }
  ].filter(Boolean);

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      isTooltip, tooltipDesc, sAdornment
    } = field;
    if (['state', 'gender', 'businessType'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }

    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        isTooltip={isTooltip}
        tooltipDesc={tooltipDesc}
        sAdornment={sAdornment}
      />
    );
  };

  const handleVerifyAccount = () => {
    verifyAccount({
      variables: { businessId: Number(businessId), businessType: 'SELLER', markup: Number(markup) }
    }).then(({ data: v }) => {
      const { message } = v.verifyBusiness;
      refetch();
      setOpenModal(false);
      toast.success(message);
    }).catch((err) => toast.error(err?.message));
  };

  const confirmDialogHandler = () => {
    setOpenModal(true);
    setOpenMarkupDialog(false);
  };

  const markupHandler = () => {
    setOpenMarkupDialog(!openMarkupDialog);
  };

  return (
    <MainContent>
      <UserGridContainer container>
        <BackArrowIconContainer onClick={() => navigate(-1)}>
          <KeyboardArrowLeft />
          <BackText>Back</BackText>
        </BackArrowIconContainer>
        <HeaderBox>
          <HeaderText>
            {formatText(name)}
          </HeaderText>
          <SubHeaderText>
            {status === 'ACTIVE' ? 'View profile, documents & Orders' : 'View profile & documents'}
          </SubHeaderText>
        </HeaderBox>

        <DetailsPaper>
          {status !== 'ACTIVE' ? (
            <>
              <Box style={{ backgroundColor: '#F3F9FF80', padding: '1.5rem 2rem' }}>
                <Grid container>
                  <Grid item xs={7}>
                    <UsernameText data-testid="username">
                      {formatText(name)}
                    </UsernameText>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: 'right' }}>
                    <VerifyButton onClick={markupHandler} data-testid="verifyButton">
                      {vLoading ? <CircularProgressLoader />
                        : 'Verify Account'}
                    </VerifyButton>
                  </Grid>
                </Grid>
              </Box>

              <Box style={{ padding: '0 2rem' }}>
                <DetailFieldGrid container spacing={3}>
                  {infoField.filter((item) => item.show).map((field) => (
                    <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
                  ))}
                </DetailFieldGrid>
              </Box>
            </>
          ) : (
            <SellerProfile
              userData={data}
              businessId={+businessId}
              accountType={accountType}
              tab={tab}
              changeTab={handleChangeTab}
            />
          )}
        </DetailsPaper>

        {tab === 'profile' && (
          <DetailsPaper>
            <IdUploads
              files={files}
              businessId={+businessId}
              accountType="SELLER"
              businessDetails={businessDetails}
              filesField={filesField}
            />
          </DetailsPaper>
        )}
      </UserGridContainer>
      <MarkupDialog markup={markup} setMarkup={setMarkup} openDialog={openMarkupDialog} setOpenDialog={setOpenMarkupDialog} confirmDialogHandler={confirmDialogHandler} />

      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Verify Account?"
        desc="Are you sure you want to verify this account?"
        options={['Cancel', 'Yes, Verify']}
        buttonAction={handleVerifyAccount}
        loading={vLoading}
      />
    </MainContent>
  );
};

export default SellerAccountsDetails;
