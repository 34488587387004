import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { RenderPopper } from './renderPopper';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';
import { OgaToken } from '../../graphql/token';
import { getAxios } from '../../axios';

export default function ActionPopper({
  actionPopper, handleAction, handleClose, handleActionView, handleCartList, cart, handleNoCart,
  cartOrder, handleUploadDescDialog
}) {
  const open = Boolean(actionPopper);

  const handleViewSaveCartWithData = () => {
    handleActionView();
    handleClose();
  };
  const NoCart = () => {
    handleNoCart();
    handleClose();
  };

  const handleViewSaveCart = () => {
    if (!cartOrder?.length) {
      return NoCart();
    }
    return handleViewSaveCartWithData();
  };

  const downloadCartPDF = () => {
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    getAxios().get(`${serverUrl}print-cart`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      responseType: 'blob',
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'cart-items';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    handleClose();
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={actionPopper}
      popperPlacement="bottom"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh'
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => {
            handleAction(e);
            handleClose();
          }}
          disabled={!cart?.length}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Save Cart
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={handleViewSaveCart}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              View Saved Carts
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={downloadCartPDF}
          disabled={!cart?.length}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Download Cart PDF
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => {
            handleUploadDescDialog();
            handleClose();
          }}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              Upload Bulk Order CSV
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  actionPopper: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleActionView: PropTypes.func.isRequired,
  handleCartList: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf.isRequired,
  cartOrder: PropTypes.arrayOf.isRequired,
  handleNoCart: PropTypes.func.isRequired,
  handleUploadDescDialog: PropTypes.func.isRequired,
};
