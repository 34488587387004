import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BusinessId, BusinessName, CardCheck, CheckDate, CheckTime, Dispatch, Price
} from './dispatchOrderContainer.styles';
import { FieldCheckbox } from '../manageOrdersDispatch.styles';
import currencyFormatter from '../../shared/currencyFormatter';

const ReturnRow = ({ row, selected, handleSelect }) => {
  const {
    id, business: { name }, orderproductSet, order: { datePlaced }, sortedValue
  } = row;
  const totalProduct = orderproductSet.length;
  return (
    <Dispatch>
      <CardCheck>
        <Box display="flex" alignItems="center" gap={2}>
          <CheckDate>{moment(datePlaced).format('DD/MM/YYYY')}</CheckDate>
          <CheckTime>{moment(datePlaced).format('h:m a')}</CheckTime>
        </Box>
        <FieldCheckbox onClick={(e) => handleSelect(e, id)} checked={selected.includes(id)} />
      </CardCheck>

      <BusinessName>{name}</BusinessName>
      <BusinessId>{`ID: ${id}`}</BusinessId>
      <BusinessId>
        {`${totalProduct} Product(s)`}
      </BusinessId>
      <Price>
        {`₦ ${currencyFormatter(sortedValue)}`}
      </Price>
    </Dispatch>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.shape(Object),
  selected: PropTypes.instanceOf(Array),
  handleSelect: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selected: [],
};

export default ReturnRow;
