import React, { useEffect } from 'react';
import MainContent from '../customComponents/mainContent';
import {
  UserGridContainer, EmptyImage, SubText, TitleText,
  DetailBox,
} from './buyerLandingPage.styles';
import EmptyBag from '../../assets/images/EmptyBag.png';
import { useStateValue } from '../../providers/stateProvider';

const BuyerLandingPage = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  return (
    <MainContent>
      <UserGridContainer container>
        <DetailBox>
          <EmptyImage src={EmptyBag} alt="Empty Bag" />
          <TitleText>Account not verified yet!</TitleText>
          <SubText>
            You can&apos;t buy or sell any item until your account has been verified. Please
            <br />
            reach out to our customer support
            {' '}
            <a href="mailto:support@ogapharmacy.com">
              support@ogapharmacy.com
            </a>
            {' '}
            if your
            <br />
            verification is taking time
          </SubText>
        </DetailBox>
      </UserGridContainer>
    </MainContent>
  );
};

export default BuyerLandingPage;
