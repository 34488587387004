import {
  Grid, Box, Typography, Button, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FiltersGrid = styled('div')`
  padding: 0.7rem 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-bottom: 1.8rem;
`;

export const DatePicker = styled(Grid)`
  margin: 20px 50px;
  margin-bottom: 0px;

  @media(min-width: 992px) {
    margin: 0px;
    margin-right: 15px;
  }
`;

export const TextFieldBox = styled(Box)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const FilterGrid = styled(Grid)`
`;

export const FilterText = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
`;

export const MenuButton = styled(Button)`
  color: #424242;
`;

export const CloseText = styled(Typography)`
  color: #BA1A1A;
`;

export const CustomNewTextField = styled(TextField)`
  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  & .MuiOutlinedInput-root {
    border-radius: 17px;
  }
  & .MuiInputBase-root {
    font-size: .8rem;
  }
  & .MuiFormLabel-root {
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.45rem;
  }

  @media(max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 3.8rem;
      padding: 35px 20px 10px;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(20px, 12px) scale(1.5);
    }
  }
`;
