import { styled } from '@mui/material/styles';
import { MenuItem, Menu } from '@mui/material';

export const MenuStyled = styled(Menu)`
  & .MuiPaper-root {
    background-color: #fff;
    box-shadow: rgba(163, 171, 185, 0.24) 0px 2.96px 66.64px;
    padding: 0.3rem 0;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.6rem 1.5rem;
  font-size: 14px;
  background-color: #fff;
  color: #606060;
  display: flex;
  gap: 5px;
  align-items: center;

  &:hover {
    background: #f3f9ff;
  }

  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
`;
