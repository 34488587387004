import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const sm = '960px';

export const ProductCardsContainer = styled(Grid)`

`;

export const ProductCardGrid = styled(Grid)`
  width: 20%;
  margin: auto;
  @media (max-width: 1200px) {
    width: 25%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const NoResultsText = styled(Typography)`
  font-size: 18px;
  color: #4D4F5C;
  padding: 10px 15px;

  @media(max-width: ${sm}) {
    font-size: 14px;
  }
`;

export const NoResultsContainer = styled(Grid)`
  align-items: center;
  margin: 40% auto;
  width: 65%;
  text-align: center;
`;

export const NoResultsHeader = styled(Typography)`
  font-size: 22px;
  font-weight: 700;
  color: #303030;
  padding: 10px;

  @media(max-width: ${sm}) {
    font-size: 14px;
  }
`;


export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin: 2rem 0px 10px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;
