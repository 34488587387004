import {
  DialogContent, Typography, Dialog
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CardTitle = styled('span')`
  font-weight: 700;
  color: #303030;
  font-size: 1.125rem;

  @media(max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  padding: 1rem 1.5rem 2rem 1.5rem;

  @media(max-width: 991px) {
    min-width: 100% !important;
    max-height: 63rem;
    padding: 3rem;
  }
`;

export const TitleDesc = styled(Typography)`
  font-size: 14px;
  margin-top: 0.4rem;

  @media(max-width: 991px) {
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
`;

export const CDialog = styled(Dialog)`
  @media (max-width: 991px) {
    & .MuiDialog-paperScrollPaper {
      min-width: 55rem;
      border-radius: 3rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;

  @media(max-width: 991px) {
    font-size: 2.5rem !important;
  }
`;
