import { styled } from '@mui/material/styles';
import {
  Typography, Box,
  Button
} from '@mui/material';

export const ReviewContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReviewText = styled(Typography)`
  color: #235A91;
  font-weight: 600;
  font-size: 20px;
`;

export const DeliveryText = styled(Typography)`
  color: #606060;
  font-weight: 500;
  font-size: 18px;
`;

export const DeliveryNo = styled(Typography)`
  color: #235A91;
  font-weight: 500;
  font-size: 16px;
`;

export const ReviewCol = styled(Box)`
  width: 200px;
  display: flex;
  flex-direction: column;
`;

export const OrderTitle = styled(Typography)`
  color: #82849F;
  font-weight: 500;
  font-size: 16px;
`;

export const OrderText = styled(Typography)`
  color: #606060;
  font-weight: 500;
  font-size: 24px;
`;

export const OutlinedButton = styled(Button)`
  padding: 10px 15px;
  border: 1.5px solid #235A91;
  color: #235A91;
  border-radius: 8px;
  height: 44px;
  width: 176px;
  font-weight: 700;
  margin-top: 2rem;
  cursor: pointer;
`;
