import {
  DialogContent,
  Button, Dialog,
  Typography,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: .75rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 420px;
  min-height: 250px;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  padding: 0 0rem 1rem 0;
  @media(max-width: 991px) {
    width: 100%;
    height: 600px;
  }
`;

export const TextLabel = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin-top: 1rem;
  margin-bottom: .2rem;
`;

export const DescStyled = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 164.2%;
  color: #606060;

  @media(max-width: 991px) {
    font-size: 1.7rem;
  }
`;

export const ButtonDarkStyled = styled(Button)`
  width: 100%;
  height: 48px;
  background: ${({ disabled }) => (disabled ? '#B2DDFF' : '#235A91 !important')};
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
  margin-top:  1rem;
  
  @media(max-width: 991px) {
    height: 90px;
    font-size: 27px;
  }
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
  font-weight: 700;
  font-size: 12px;
`;

export const TextFieldStyled = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
 
  & .MuiInputBase-root {
    font-size: 16px;
    padding: 6.5px 5px;
    height: 3.3rem;
  }

  & .MuiFilledInput-input {
    height: 3.8rem;
    padding: 35px 20px 10px;
  }

  & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ccc;
  }

  & .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  
  & .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(20px, 12px) scale(1.5);
  }
`;
