import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Grid, TableBody } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { ALL_PRODUCT_BATCHES_QUERY } from '../../queries/products';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import UpdateBatchDialog from './updateBatchDialog';
import ReturnRow from './returnRow';
import TablePagination from '../shared/tablePagination';
import CustomNewTextField from '../shared/CustomNewTextField';
import SuccessDialog from '../shared/successDialog';
import StatusDropdown from './statusDropdown';
import CustomButton from '../customComponents/customButton';
import { useStateValue } from '../../providers/stateProvider';
import {
  TableGrid, TableHeader,
  TableComponent, TableColumnHeaders, FooterWrapper, FilterGrid, TCustomButton, MenuItems,
  DeleteBtn,
  DeleteText,
  CustomCheckbox
} from './batchDetails.styles';
import { addHyphen } from '../shared/methods';
import useSearchParamsState from '../shared/helpers/ulrSearchParams';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import ConfirmDialog from '../shared/confirmDialog';
import { DELETE_BATCH_MUTATION, UPDATE_PRODUCT_BATCH_STATUS } from '../../mutations/products';

const headers = [
  { name: 'Date Created', width: '250px' }, { name: 'Product Name', width: '250px' },
  { name: 'Batch No.', width: '150px' }, { name: 'Expiry Date', width: '150px' }, { name: 'Status', width: '170px' }, { name: 'Quantity', width: '150px' },
  { name: 'Last Updated Field', width: '175px' }, { name: 'Last Update By', width: '150px' }, { name: 'Last Update Date', width: '250px' },
  { name: 'Previous (Dynamic) Info', width: '230px' }, { name: 'Current (Dynamic) Info', width: '220px' }, { name: 'Action', width: '220px' }
];
const BatchDetails = () => {
  const { batchNumber, productName } = useParams();
  const [state, setState] = useState({
    searchProductName: '',
    searchBatchNumber: '',
    status: '',
    dateFilterBy: '',
    sku: ''
  });
  useEffect(() => {
    if ((productName && productName.length >= 3) && (batchNumber && batchNumber.length >= 3)) {
      setState({ ...state, searchProductName: productName, searchBatchNumber: batchNumber });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchNumber, productName]);

  const [batchToEdit, setBatchToEdit] = useState('');
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [dateRange, setDateRange] = useState({
    dateFrom: moment().startOf('day'),
    dateTo: moment().endOf('day')
  });
  const [dates, setDates] = useState(null);

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [searchProductName, setSearchProductName] = useSearchParamsState('searchP', '');
  const [searchBatchNumber, setSearchBatchNumber] = useSearchParamsState('searchB', '');
  const [status, setStatus] = useSearchParamsState('status', '');
  const [dFilter, setDFilter] = useSearchParamsState('dts', '');
  const [dateFilterBy, setDateFilterBy] = useSearchParamsState('dtb', '');
  const [searchSku, setSearchSku] = useSearchParamsState('sku', '');
  const valState = {
    searchProductName, searchBatchNumber, dateFilterBy, status, sku: searchSku
  };
  const [selected, setSelected] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [{
    navbar: { openSideDrawer }
  }] = Object.values(useStateValue());

  useEffect(() => {
    if (dFilter) setDates(JSON.parse(dFilter));
  }, [dFilter]);

  useEffect(() => {
    if ((productName && productName.length >= 3) && (batchNumber && batchNumber.length >= 3)) {
      setSearchProductName(productName);
      setSearchBatchNumber(batchNumber);
    }
  }, [batchNumber, productName]);
  const [updateProductBatchStatus] = useMutation(UPDATE_PRODUCT_BATCH_STATUS);

  useEffect(() => {
    if (status) {
      setState({ ...state, status });
    }
  }, [status]);
  const FilterFields = [
    { name: 'searchProductName', label: 'Enter Product Name' },
    { name: 'searchBatchNumber', label: 'Batch No' },
    { name: 'sku', label: 'Enter SKU' }
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'searchProductName': return setSearchProductName(value);
      case 'sku': return setSearchSku(value);
      case 'status': return setStatus(value);
      default: return setSearchBatchNumber(value);
    }
  };

  const handleBatchEdit = (row) => {
    setBatchToEdit(row);
    setOpenBatchDialog(true);
  };

  const handleUrlDates = (dateProps) => {
    // const { dateFrom, dateTo } = dateRange;
    setDFilter(JSON.stringify(dateProps));
  };

  const handleSubmit = (range) => {
    setDates(range);
    handleUrlDates(dateRange);
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader data-testid={`erp-uat-batch-details-${addHyphen(name)}`} className={`erp-uat-batch-details-${addHyphen(name)}`} style={{ minWidth: width }} key={name}>
      {name}
    </TableHeader>
  ));

  const options = [
    { name: 'sellable', disabled: false },
    { name: 'damaged', disabled: false },
    { name: 'expired', disabled: false }
  ];

  const statusOptions = [
    { name: 'Sellable', value: 'sellable' },
    { name: 'Damaged', value: 'damaged' },
    { name: 'Expired', value: 'expired' }
  ];

  const variables = {
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    ...(dates && { dateFrom: dates?.dateFrom }),
    ...(dates && { dateTo: dates?.dateTo }),
    ...(status && { status }),
    ...(searchProductName.length >= 3 ? { searchProductName } : { searchProductName: '' }),
    ...(searchBatchNumber.length >= 3 ? { searchBatchNumber } : { searchBatchNumber: '' }),
    ...((dates) && { dateFilterBy: 'created_date' }),
    ...(searchSku.length >= 3 ? { sku: searchSku } : { sku: '' }),
  };

  const {
    loading, error, data, refetch
  } = useQuery(ALL_PRODUCT_BATCHES_QUERY, {
    fetchPolicy: 'no-cache',
    variables
  });

  const handleDeleteBatch = () => {
    setOpenConfirmDialog(true);
  };

  const [deleteBatchMutatation, { loading: laodingBatch }] = useMutation(DELETE_BATCH_MUTATION);

  const handleDelete = () => {
    if (!selected?.length) return toast.error('Select a Batch');
    deleteBatchMutatation({
      variables: {
        batchIds: [...selected],
      }
    })
      .then(({ data: resData }) => {
        const { message } = resData?.deleteBatch || {};
        toast.success(message);
        setOpenConfirmDialog(false);
        refetch();
        setSelected([]);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleBulkStatusChange = (_status) => {
    if (!selected?.length) return toast.error('Select a Batch');
    setDotsButtonEl(null);
    updateProductBatchStatus({
      variables: {
        batchIds: [...selected],
        status: _status,
      }
    }).then(({ data: statusRes }) => {
      const { updateProductBatchStatus: { message } } = statusRes ?? {};
      toast.success(message);
      refetch();
      setSelected([]);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  if (error) return <div>{error.message}</div>;
  const {
    allProductBatches = [], allProductBatchesTotalNumber = 0
  } = data || {};
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = allProductBatches?.map((batch) => batch.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };
  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === allProductBatches?.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select batch' }}
    />
  );

  const filterWidth = selected?.length ? '13.6%' : '18%';
  return (
    <>
      <FilterGrid item container spacing={1}>
        {FilterFields.map((field) => (
          <Grid item container style={{ width: openSideDrawer ? '13%' : filterWidth }} key={field?.name}>
            <CustomNewTextField
              field={field}
              state={valState}
              handleChange={handleFilterChange}
              straight
              style={{ width: '100%', marginRight: 0 }}
            />
          </Grid>
        ))}
        <Grid item container style={{ width: selected?.length ? '12%' : '18%' }}>
          <CustomNewTextField
            field={{ name: 'status', label: 'status' }}
            state={state}
            select
            handleChange={handleFilterChange}
            style={{ width: '100%', borderRadius: '.5rem' }}
          >
            {statusOptions.map(({ name, value }) => (
              <MenuItems key={value} value={value} data-testid={`erp-uat-filter-by-${name}`} className={`erp-uat-filter-by-${name}`}>
                {name}
              </MenuItems>
            ))}
          </CustomNewTextField>
        </Grid>
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            styles={{
              height: '49px', width: '100%', border: '1px solid #E7E8E9', borderRadius: '.5rem'
            }}
            xs={12}
            className="pure-mp-uat-filter-by-date"
            data-testid="pure-mp-uat-filter-by-date"
          />
        </Grid>
        {selected?.length ? (
          <>
            <Grid item container style={{ width: '9%' }}>
              <TCustomButton
                type="tertiary"
                header
                onClick={(e) => setDotsButtonEl(e.currentTarget)}
              >
                Change Status
              </TCustomButton>
            </Grid>
            <Grid item container style={{ width: '9%' }}>
              <DeleteBtn
                className="pure-mp-uat-bulk-delete"
                type="tertiary"
                onClick={handleDeleteBatch}
                data-testid="pure-mp-uat-bulk-delete"
              >
                <DeleteText>Delete</DeleteText>
              </DeleteBtn>
            </Grid>
          </>
        ) : ''}
        <Grid item container style={{ width: '10%' }}>
          <CustomButton
            type="tertiary"
            header
            style={{ height: '3rem', background: '#235A91', color: '#ffffff' }}
            data-testid="download"
          >
            Set Filter
          </CustomButton>
        </Grid>
      </FilterGrid>
      <TableGrid item container>
        {loading
          ? <ProductsTableLoader />
          : (
            <TableComponent item container>
              <TableColumnHeaders item container>
                <TableHeader style={{ width: '50px' }}>
                  {renderCheckbox()}
                </TableHeader>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {allProductBatches.map((_row, indx) => (
                  <ReturnRow
                    key={_row.id}
                    row={_row}
                    rowIndx={indx}
                    handleBatchEdit={handleBatchEdit}
                    refetch={refetch}
                    selected={selected}
                    handleSelect={handleSelect}
                  />
                ))}
              </TableBody>
            </TableComponent>
          )}
      </TableGrid>
      <FooterWrapper item container>
        {allProductBatchesTotalNumber > 0 && (
          <TablePagination
            total={allProductBatchesTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </FooterWrapper>

      <UpdateBatchDialog
        open={openBatchDialog}
        onClose={setOpenBatchDialog}
        batchToEdit={batchToEdit}
        setSuccessOpenDialog={setSuccessOpenDialog}
      />
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={`${batchToEdit?.product?.brandName} Edited Successfully!`}
        desc={`Hi Pharm, You have successfully updated ${batchToEdit?.product?.brandName}`}
        option="Ok"
        refetch={refetch}
      />
      <StatusDropdown
        dotsButtonEl={dotsButtonEl}
        setDotsButtonEl={setDotsButtonEl}
        options={options}
        action={handleBulkStatusChange}
      />
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Delete Selected Batch"
        desc="Are you sure you want to delete selected batch?"
        options={['Cancel', 'Yes, Delete']}
        buttonAction={handleDelete}
        loading={laodingBatch}
      />
    </>
  );
};

export default BatchDetails;
