import {
  Grid, Typography, Box, Card, CardMedia
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardPaper = styled(Card)`
  border-radius: 1rem;
  align-items: center;
  position: relative;
  margin-bottom: 6px;
  cursor: pointer;
  background: #FFFFFF;
  border: 0.476px solid #E7E8E9;
  box-shadow: none;
  padding: 3rem 0px 1.5rem;
  position: relative;
  box-shadow: 0px 5.96125px 23.84502px 0px rgba(32, 30, 93, 0.08);
  &:hover {
    border: 0.476px solid #E7E8E9;
    box-shadow: 0px 5.96125px 23.84502px 0px rgba(32, 30, 93, 0.08);
  }
  @media (max-width: 991px) {
    border-radius: 3rem;
    padding:  4rem 1rem 1.5rem;
  }
`;

export const CardWrapper = styled(Grid)`
  align-items: center;
`;

export const ImgBox = styled('div')`
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 160px;
  position: relative;

  @media (max-width: 991px) {
    height: 60%;
    width: 60%;
    margin: auto;
    padding: 0;
    border-radius: 2rem;

  }
`;

export const ProductImg = styled(CardMedia)`
  width: 100%;
  height: 160px;
  object-fit: contain;

  @media (max-width: 991px) {
    height: 45%;
  }
`;

export const DetailsBox = styled('div')`
  height: 100px;
  padding: 15px 20px;

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderContainer = styled(Grid)`
  width: 100%;
  overflow-y: hidden;

  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const DetailsContainer = styled('div')`
  width: 100%;
`;

export const HeaderText = styled(Typography)`
  font-size: 2.1rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #212121;

  @media(min-width: 992px) {
    font-size: 1rem;
    line-height: 20px;
  }
`;

export const TypoContainer = styled(Grid)`
  margin: 5px 0px;
`;

export const PackSizeTypo = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 500;
  color: #616161;
  line-height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media(min-width: 992px) {
    font-size: .875rem;
    line-height: 27px;

  }
`;

export const PricingAndSaving = styled(Grid)`
  align-items: center;
`;

export const PromoPriceText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration-line: line-through;
  margin-left: auto;
  color: #303030;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    font-weight: 500;
  }
`;

export const PricingWrapper = styled(Grid)`
`;

export const Pricing = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #212121;
  line-height: 35px;

  @media(min-width: 992px) {
    font-size: 1.25rem;
  }

  @media (max-width: 991px) {
     font-weight: 700;
  }
`;

export const ProductImage = styled('div')`
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const ChangeWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;

  @media(min-width: 992px) {
    width: 100%;
    margin-bottom: 0;
    display: block;
  }
`;

export const PromoContainer = styled(Box)`
  background: #FFEDEA;
  border-radius: 5px;
  width: 4.563rem;
  height: 2.313rem;
  position: absolute;
  top: 11px;
  left: 13px;
  z-index: 2;

  @media (max-width: 991px) {
    height: 4.626rem;
    width: 10rem;
    border-radius: 10px;
  }
`;

export const PromoText = styled(Typography)`
  font-size: .75rem;
  color: #E83A25;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;

  @media (max-width: 991px) {
    font-size: 1.6rem;
    padding: 20px 10px;
  }
`;
