import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StatisticPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 18.375rem;
  min-height: 6.063rem;
  border-radius: 8px;
  box-shadow: 0px 11px 18px 0px rgba(0, 0, 0, 0.04);
`;

export const StatisticWrapper = styled(Grid)`
  padding: 1.125rem 1.25rem 0 1.25rem;
  width: 100%;
`;

export const StatisticGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const IconGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
`;

export const StatisticHeader = styled(Grid)`
  color: #6c7278;
  margin-bottom: 10px;
  font-weight: 600;
  font-size .875rem;
`;

export const StatisticContent = styled(Grid)`
  color: #303030;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0 1.25rem 1.125rem 1.25rem;
  width: 100%;
  text-align: left;
`;
