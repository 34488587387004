import { Grid, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../../customComponents/customButton';

export const FiltersGrid = styled(Grid)`
  margin: 5px 0;
  padding-bottom: 1rem;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 7px;
`;

export const ButtonsGrid = styled(Grid)`
  padding: 0 1.8rem 1.8rem;
  // justify-content: flex-end;
  align-items: center;

  @media(min-width: 992px) {
    padding: 0 1.8rem 1.8rem;
  }
`;

export const SetFilter = styled(CustomButton)`
  width: 6.25rem;
  height: 3rem;
  border-radius: 5px;
  background: #235A91;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);

  &:hover {
    background: #235A91;
    color: #FFFFFF;
  }
`;

export const FilterText = styled(Typography)`
  font-weight: 700 !important;
  font-size: .75rem !important;
  color: #FFFFFF;
`;

export const DeleteBtn = styled(CustomButton)`
  width: 6.25rem;
  height: 3rem;
  border-radius: 5px;
  border: 0.8px solid #235A91

  &:hover {
    background: #235A91;
    color: #FFFFFF;
  }
`;

export const DeleteText = styled(Typography)`
  font-weight: 700 !important;
  font-size: .75rem !important;
  color: #235A91;
`;

export const MenuItems = styled(MenuItem)`
  height: 3.063rem;
  width: 15.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.669rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;
