/* eslint-disable no-useless-escape */
export const sanitize = (str) => str.replace(/\"*([\w&.+×'÷=/-<>\[\]()])+\"/g, (m,) => m.replace("'", '`'));

export const parseJson = (obj) => {
  try {
    const result = sanitize(obj).replace(/'/g, '"');
    return JSON.parse(
      result.replace(/'|None/gi, (matched) => (matched === 'None' ? '""' : '"')).replace(/`/g, "'")
    );
  } catch (error) {
    return {};
  }
};

export const parseAffiliateFields = (obj) => {
  const {
    id, name: legalName, logo, dateCreated, contacts, profile,
    grossOrderAmount, orderVolume, tax, status, meta, license, creditScore, salesRepresentative, landMark, state, businessCategory
  } = obj?.business || {};
  // const contactsObj = parseJson(contacts);
  const metaObj = parseJson(meta);
  // const profileObj = parseJson(profile);

  let dataObject = {};

  if (contacts) {
    dataObject = parseJson(contacts);
  }

  const {
    city, country, mobile_number: mobile, address_line_1: addressLine1,
    address_line_2: addressLine2, primary_contact_name: contactName,
    primary_email_address: email, secondary_email_address: secondaryEmailAddress,
  } = dataObject || {};

  const { credit_days: creditDays } = metaObj;
  return {
    id, legalName, logo, dateCreated, contacts, profile, grossOrderAmount,
    orderVolume, landMark, state, tax, status, creditDays, license, creditScore, salesRepresentative, businessCategory,
    city, country, mobile, addressLine1, addressLine2, contactName, email, secondaryEmailAddress,
  };
};

export const cherryPickOrder = (data) => {
  const orders = data?.business?.orderSet;

  const ordersWithIncompletePayment = [];
  const ordersWithoutPayment = [];
  orders.forEach((order) => {
    // eslint-disable-next-line consistent-return
    order.supplierorderSet.forEach((supplierOrder) => {
      const { paymentSet, orderproductSet } = supplierOrder;
      const orderAmount = orderproductSet.reduce((orderProductA, orderProductB) => (
        orderProductA + orderProductB.unitCost * (orderProductB.quantityRecieved || 0)
      ), 0);
      if (paymentSet?.length) {
        const amountPaid = paymentSet?.reduce((paymentA, paymentB) => (
          paymentA + paymentB.amount
        ), 0);

        if (amountPaid < orderAmount) {
          ordersWithIncompletePayment.push({ ...supplierOrder, paymentOutstanding: orderAmount - amountPaid });
        }
      } else if (orderAmount) ordersWithoutPayment.push(supplierOrder);
      else return false;
    });
  });

  return { ordersWithIncompletePayment, ordersWithoutPayment };
};
