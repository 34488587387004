import {
  TableRow, TableCell, Checkbox, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
  // tr:nth-child(odd): {
  //   background-color: red;
  // }
  // &:nth-of-type(odd): {
  //   background-color: red;
  // }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;
  color: #606060;

  :first-child {
    width: 2%;
  }

  @media(min-width: 992px) {
    font-size: 1rem;
    &:nth-child(3) {
      width: 24%;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(4), nth-child(6) {
      width: 16%;
    }
    &:nth-child(5) {
      width: 20%;
      overflow-wrap: anywhere;
    }
    &:last-child) {
      width: 10%;
    }
  }

`;
export const ViewCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 10px;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #424242;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const ColorGrid = styled(Grid)`
  text-transform: capitalize;
  color: #606060;
  font-size: 1rem;
  font-weight: 400;
  justify-content: flex-start;
  padding: 12px 0;
  align-items: center;
  gap: 10px;
`;

export const Dot = styled(Grid)`
  background: ${({ background }) => (background)};
  border-radius: 50%;
  text-transform: capitalize;
  width: 9px;
  height: 9px;
  margin-right: 5px;
`;
