import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  IconButtonWrapper, NavIcon, MenuTypo, GridContainer
} from './affiliatesPopper.styles';
import { LOGOUT_MUTATION, SWITCH_ROLE_MUTATION } from '../../mutations/auth';
import { RenderPopper } from '../../utils/utils';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import { useStateValue } from '../../providers/stateProvider';
import { STORAGE_KEYS } from '../../utils/constants';
import { goToModule } from '../shared/utils';
import { Logout } from '../../assets/svgs';

const AffiliatesPopper = ({ affiliatesLogoEl, handleAffiliateLogoClick }) => {
  const open = Boolean(affiliatesLogoEl);
  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  const [{
    user: {
      allowedPermissionsMap, role
    }
  }, dispatch] = Object.values(useStateValue());
  const [switchRole] = useMutation(SWITCH_ROLE_MUTATION);
  const userRole = localStorage.getItem('oga_user_role');
  const navigate = useNavigate();

  const MENU_ITEMS = [
    [<NavIcon />, 'Account Settings', 'setup'],
  ].filter(Boolean);

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    }).then((resp) => {
      dispatch({ type: AffiliateActionTypes.UPDATE_AFFILIATE, payload: { isBusinessVirtualStore: false } });
      const { status, message } = resp.data.logoutUser;
      const storageKeys = [...STORAGE_KEYS];
      if (status) {
        storageKeys.forEach((storageKey) => {
          localStorage.removeItem(storageKey);
        });
        toast.success(message);
        localStorage.removeItem('receive_manual_order');
        setTimeout(() => navigate('/'), 1500);
        // setTimeout(() => { document.location = ''; }, 2000);
      }
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const controlAlert = (params) => {
    if (params.alertSeverity === 'error') {
      toast.error('Module not available, coming soon...');
    }
  };

  const handleAccountNavigate = (item) => {
    handleAffiliateLogoClick();
    goToModule(navigate, switchRole, allowedPermissionsMap, controlAlert, item);
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={affiliatesLogoEl}
      onClickAway={() => handleAffiliateLogoClick()}
      popperPlacement="bottom"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ]}
    >
      <GridContainer>
        {MENU_ITEMS.map((menuItem) => (
          <IconButtonWrapper
            key={menuItem[2]}
            onClick={() => handleAccountNavigate(menuItem[2])}
          >
            {menuItem[0]}
            <MenuTypo variant="subtitle1">{menuItem[1]}</MenuTypo>
          </IconButtonWrapper>
        ))}
        <IconButtonWrapper onClick={() => handleLogout()}>
          <Logout />
          <MenuTypo variant="subtitle1">Logout</MenuTypo>
        </IconButtonWrapper>
      </GridContainer>
    </RenderPopper>
  );
};

AffiliatesPopper.propTypes = {
  affiliatesLogoEl: PropTypes.instanceOf(Element),
  handleAffiliateLogoClick: PropTypes.func.isRequired
};

AffiliatesPopper.defaultProps = {
  affiliatesLogoEl: {},
};

export default AffiliatesPopper;
