import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  DetailsCard, OrderDetailLogo, OrderDetailInfoWrapper, OrderDetailText,
  OrderText
} from './manageOrdersDispatch.styles';

const DeliveryContainer = ({ businessOrder }) => {
  const { id, order } = businessOrder || {};
  return (
    <DetailsCard>
      <OrderDetailLogo src="https://res.cloudinary.com/health-id/image/upload/v1715031845/Frame_625229.svg" />
      <OrderDetailInfoWrapper>
        <OrderDetailText>
          <span>Order ID: </span>
          <OrderText>
            {`#${id}`}
          </OrderText>
        </OrderDetailText>
        <OrderDetailText>
          <span>Date Placed: </span>
          <OrderText>
            {moment(order?.dateCreated).format('MMM DD, YYYY [at] hh:mm a')}
          </OrderText>
        </OrderDetailText>
        <OrderDetailText>
          <span>Estimated Delivery Date: </span>
          <OrderText>Feb 21, 2023</OrderText>
        </OrderDetailText>
      </OrderDetailInfoWrapper>
    </DetailsCard>
  );
};

DeliveryContainer.propTypes = {
  businessOrder: PropTypes.instanceOf(Object),
};

DeliveryContainer.defaultProps = {
  businessOrder: {},
};

export default DeliveryContainer;
