import {
  DialogContent,
  Button, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 8px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 420px;
  height: auto;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
`;

export const TitleStyled = styled('span')`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
`;

export const DescStyled = styled('p')`
  font-weight: 400;
  font-size: 14px;
  line-height: 164.2%;
  color: #606060;
  padding: 0.6rem 0 .8rem 0;
`;

export const ButtonDarkStyled = styled(Button)`
  width: 178px;
  height: 48px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 12px;
`;

export const ButtonWhiteStyled = styled(Button)`
  width: 178px;
  height: 48px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #303030 !important;
  font-weight: 700;
  font-size: 12px;
`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: space-around;
  gap: 2;
  margin-bottom: 2rem;
`;
