import React, { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import {
  IdentificationPaper, EmptyBox, EmptyText, ClickAddText, EmptySubText, BusinesssName,
  ContentBox, IdTypeText, GridPaper, FileName, FileSize, ViewText, AgreementPaper,
  EditButton, EditButtonText
} from './idUpload.styles';
import { Editicon, FileUploadIcon, ImageUploadIcon } from '../../../../assets/svgs';
import UploadFile from './uploadFile';
import DisplayId from '../../../shared/displayId';

const IdUploads = ({
  businessId, accountType, files, status
}) => {
  const [openFileDialog, setOpenFileDialog] = useState(null);
  const [dialogType, setDialogType] = useState('');
  const [state, setState] = useState({});
  const [openDisplay, setOpenDisplay] = useState(null);
  const [cardTitle, setCardTitle] = useState('');
  const [titleDesc, setTitleDesc] = useState('');
  const [idUrl, setIdUrl] = useState('');

  const ownerIdCard = files?.owner_id_card || {};
  const storePicture = files?.store_frontage_picture || {};
  const agreementForm = files?.ogapharmacy_agreement_form || {};

  const {
    url: idCardUrl, file_name: idCardFileName, file_size: idCardFileSize, file_type: fileType
  } = ownerIdCard ?? {};
  const { url: storePicUrl, file_name: storePicFileName, file_size: storePicFileSize } = storePicture ?? {};
  const { back: ogapharmacyAgreementFormBack, front: ogapharmacyAgreementFormFront } = agreementForm ?? {};
  const { url: backFormUrl, file_name: backFormFileName, file_size: backFormFileSize } = ogapharmacyAgreementFormBack ?? {};
  const { url: frontFormUrl, file_name: frontFormFileName, file_size: frontFormFileSize } = ogapharmacyAgreementFormFront ?? {};

  const handleFileDialog = (type) => {
    setOpenFileDialog(true);
    setDialogType(type);
  };

  const handleFile = (filesData, fileName) => {
    setState({
      ...state,
      [fileName]: filesData
    });
  };

  const formatFileSize = (fileSize) => {
    const units = ['Bytes', 'KB', 'MB'];
    let index = 0;
    while (fileSize >= 1024 && index < units.length - 1) {
      fileSize /= 1024;
      index++;
    }
    const sizeFormatted = `${fileSize.toFixed(2)} ${units[index]}`;
    return sizeFormatted;
  };

  const handleViewFile = (type) => {
    if (['ownerIdCard', 'storeFrontagePicture'].includes(type)) {
      setDialogType(type);
      setOpenDisplay(true);

      if (type === 'ownerIdCard') {
        setCardTitle("Owner's Means of Identification");
        setTitleDesc('View your Means of Identification');
        setIdUrl(idCardUrl);
      } else if (type === 'storeFrontagePicture') {
        setCardTitle('Store Frontage Picture');
        setTitleDesc('View your store frontage picture');
        setIdUrl(storePicUrl);
      }
    } else {
      window.open(type, '_blank');
    }
  };

  const handleClose = () => {
    setOpenFileDialog(false);
    setState({});
  };

  return (
    <>
      <IdentificationPaper elevation={0} available={idCardUrl}>
        <Grid container style={{ marginBottom: '1.5rem' }}>
          <Grid item xs={6}>
            <BusinesssName>Owner&apos;s Means of Identification</BusinesssName>
          </Grid>
          {idCardUrl && (
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <EditButton startIcon={<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />} onClick={() => handleFileDialog('ownerIdCard')}>
                <EditButtonText>Edit Identification</EditButtonText>
              </EditButton>
            </Grid>
          )}
        </Grid>
        <hr style={{ border: '1px solid #e8e8e8' }} />

        {idCardUrl ? (
          <ContentBox>
            <IdTypeText>{fileType}</IdTypeText>
            <GridPaper container component={Paper}>
              <Grid item xs={0.6}>
                <FileUploadIcon style={{ fontSize: '2.563rem', marginBottom: '.5rem' }} />
              </Grid>
              <Grid item xs={10.9}>
                <FileName>{idCardFileName}</FileName>
                <FileSize>{formatFileSize(idCardFileSize)}</FileSize>
              </Grid>
              <Grid item xs={0.5}>
                <ViewText onClick={() => handleViewFile('ownerIdCard')}>View</ViewText>
              </Grid>
            </GridPaper>
          </ContentBox>
        ) : (
          <EmptyBox>
            <FileUploadIcon style={{ fontSize: '3.688rem', marginBottom: '.5rem' }} />
            <EmptyText>No Owner&apos;s Means of Identification added yet!</EmptyText>
            <EmptySubText>
              <ClickAddText onClick={() => handleFileDialog('ownerIdCard')}>Click here</ClickAddText>
              {' '}
              to add
            </EmptySubText>
          </EmptyBox>
        )}
      </IdentificationPaper>

      {status === 'ACTIVE' && (
        <>
          <IdentificationPaper elevation={0} available={storePicUrl}>
            <Grid container style={{ marginBottom: '1.5rem' }}>
              <Grid item xs={6}>
                <BusinesssName>Store Frontage Picture</BusinesssName>
              </Grid>
              {storePicUrl && (
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <EditButton startIcon={<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />} onClick={() => handleFileDialog('storeFrontagePicture')}>
                    <EditButtonText>Edit Photo</EditButtonText>
                  </EditButton>
                </Grid>
              )}
            </Grid>
            <hr style={{ border: '1px solid #e8e8e8' }} />

            {storePicUrl ? (
              <ContentBox style={{ marginTop: '2rem' }}>
                <GridPaper container component={Paper}>
                  <Grid item xs={0.6}>
                    <ImageUploadIcon style={{ fontSize: '2.563rem', marginBottom: '.5rem' }} />
                  </Grid>
                  <Grid item xs={10.9}>
                    <FileName>{storePicFileName}</FileName>
                    <FileSize>{formatFileSize(storePicFileSize)}</FileSize>
                  </Grid>
                  <Grid item xs={0.5}>
                    <ViewText onClick={() => handleViewFile('storeFrontagePicture')}>View</ViewText>
                  </Grid>
                </GridPaper>
              </ContentBox>
            ) : (
              <EmptyBox>
                <ImageUploadIcon style={{ fontSize: '3.688rem', marginBottom: '.5rem' }} />
                <EmptyText>No Store Frontage Picture added yet!</EmptyText>
                <EmptySubText>
                  <ClickAddText onClick={() => handleFileDialog('storeFrontagePicture')}>Click here</ClickAddText>
                  {' '}
                  to add
                </EmptySubText>
              </EmptyBox>
            )}
          </IdentificationPaper>

          <AgreementPaper elevation={0} available={frontFormUrl}>
            <Grid container style={{ marginBottom: '1.5rem' }}>
              <Grid item xs={6}>
                <BusinesssName>OGApharmacy Agreement Form</BusinesssName>
              </Grid>
              {frontFormUrl && (
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <EditButton startIcon={<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />} onClick={() => handleFileDialog('ogapharmacyAgreementForm')}>
                    <EditButtonText>Edit Form</EditButtonText>
                  </EditButton>
                </Grid>
              )}
            </Grid>
            <hr style={{ border: '1px solid #e8e8e8' }} />

            {frontFormUrl ? (
              <>
                <ContentBox style={{ marginTop: '2rem' }}>
                  <GridPaper container component={Paper}>
                    <Grid item xs={0.6}>
                      <FileUploadIcon style={{ fontSize: '2.563rem', marginBottom: '.5rem' }} />
                    </Grid>
                    <Grid item xs={10.9}>
                      <FileName>{frontFormFileName}</FileName>
                      <FileSize>{formatFileSize(frontFormFileSize)}</FileSize>
                    </Grid>
                    <Grid item xs={0.5}>
                      <ViewText onClick={() => handleViewFile(frontFormUrl)}>View</ViewText>
                    </Grid>
                  </GridPaper>
                </ContentBox>

                <ContentBox style={{ marginTop: '.6rem' }}>
                  <GridPaper container component={Paper}>
                    <Grid item xs={0.6}>
                      <FileUploadIcon style={{ fontSize: '2.563rem', marginBottom: '.5rem' }} />
                    </Grid>
                    <Grid item xs={10.9}>
                      <FileName>{backFormFileName}</FileName>
                      <FileSize>{formatFileSize(backFormFileSize)}</FileSize>
                    </Grid>
                    <Grid item xs={0.5}>
                      <ViewText onClick={() => handleViewFile(backFormUrl)}>View</ViewText>
                    </Grid>
                  </GridPaper>
                </ContentBox>
              </>
            ) : (
              <EmptyBox style={{ marginTop: '4rem' }}>
                <FileUploadIcon style={{ fontSize: '3.688rem', marginBottom: '.5rem' }} />
                <EmptyText>No OGApharmacy Agreement Form added yet!</EmptyText>
                <EmptySubText>
                  <ClickAddText onClick={() => handleFileDialog('ogapharmacyAgreementForm')}>Click here</ClickAddText>
                  {' '}
                  to add
                </EmptySubText>
              </EmptyBox>
            )}
          </AgreementPaper>
        </>
      )}

      <UploadFile
        open={openFileDialog}
        close={handleClose}
        type={dialogType}
        handleFile={handleFile}
        state={state}
        businessId={businessId}
        accountType={accountType}
        setState={setState}
      />

      <DisplayId
        open={openDisplay}
        close={() => setOpenDisplay(false)}
        title={cardTitle}
        desc={titleDesc}
        url={idUrl}
      />
    </>
  );
};

IdUploads.propTypes = {
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string.isRequired
};

export default IdUploads;
