import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const FormGrid = styled(Grid)`
  overflow-y: scroll;
  height: 55vh;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ECF6FF;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent;
    border-top: 200rem solid #235A91;
  }
`;

export const InputWrapper = styled('div')`
  margin-top: 5px;
`;
