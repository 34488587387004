import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';
import { useStateValue } from '../../../providers/stateProvider';

export default function ActionPopper({
  action, row, handleAction, handleClose,
}) {
  const { status } = row;
  const open = Boolean(action);
  const statusOption = () => {
    if (status !== 'IN_ACTIVE') return true;
    return false;
  };
  const [{
    user: { session: { businessType } }
  }] = Object.values(useStateValue());
  const isWholesaler = businessType === 'WHOLESALER';

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom"
      onClickAway={handleClose}
      marginLeft="-150px !important"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 2vh',
        }
      }}
    >
      <GridWrapper container item>
        {!isWholesaler && (
          <GridContainer
            className="erp-uat-inventory-add-batches"
            container
            item
            onClick={(e) => handleAction(e, 'add batch', row)}
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Add batch
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        )}
        <GridContainer
          className="pure-mp-uat-inventory-status"
          container
          item
          onClick={(e) => handleAction(e, statusOption(), row)}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              {statusOption() ? 'Disable' : 'Enable'}
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
  handleClose: PropTypes.func.isRequired,
};
