import {
  Grid, Typography, Paper, TableRow,
  TableCell
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  background-color: #f4f5f7;
  @media (max-width: 991px) {
    padding: 0px 30px;
    background-color: #fff;
  }
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 0 1rem 0.7rem;
  align-items: center;
  justify-content: space-between;
`;

export const OrderWrapper = styled(Grid)`
  background: #ffffff;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  padding: 1rem 2rem 2rem 2rem;
  @media (max-width: 991px) {
    background: none;
  }
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 29px;
  color: #24292e;
  margin-bottom: 0.6rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const TableGrid = styled(Grid)`
  overflow-x: auto;
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
`;

export const MainTableHead = styled(TableRow)`
  width: 100%;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const THeader = styled(TableCell)`
  padding: 17px 2px;
  background: #F3F9FF;
  color: #303030;
  font-weight: 700;
  font-size: .95rem;
  border-bottom: 0;
  width: 15rem
`;
