import {
  Typography, CircularProgress, InputBase
} from '@mui/material';
import { HelpOutlineRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CInputBase = styled(InputBase)`
  border: ${({ error }) => (error ? '0.5px solid #BA1A1A' : '0.5px solid #E7E8E9')};
  background: ${({ disabled }) => (disabled ? '#F3F9FF' : '')};
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 3.2;


  & .MuiFilledInput-root: {
    background-color: transparent;
  }

  & .MuiInputBase-input {
    background-color: transparent;
  }

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }

  & .MuiInputBase-input {
    padding: ${({ cSize }) => (cSize === 'lg' ? '18px' : '12px')};
    padding-right: 5px;
    border-radius: 10px;
    height: .5rem;
  }

  & .MuiInputAdornment-positionEnd {
    margin-right: 12px;
    margin-left: 8px;
  }

  &:active, &:focus, &:focus-within {
    border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #303030')};
    background: transparent !important;
    border-radius: 10px !important;
  }

  @media (max-width: 991px) {
    & .MuiInputBase-input {
      height: 3.5rem;
      font-size: 1.5rem;
    }
  }
`;

export const Label = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ error }) => (error ? '#BA1A1A' : '#303030')};
  margin-bottom: .5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${({ grey }) => (grey ? '0.5' : '1')};
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #606060;
  margin-top: 4px;
`;

export const HelpOutlineStyled = styled(HelpOutlineRounded)`
  width: 15px;
  height: 15px;
  color: #78aada;
`;

export const TooltipTitle = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #F5F5F5;
`;

export const ToolTipContent = styled(Typography)`
  font-size: .75rem;
  font-weight: 400;
  color: #ECF6FF;
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
