import { styled } from '@mui/material/styles';
import { Typography, Checkbox } from '@mui/material';

export const InputWrapper = styled('div')`
  margin-top: 5px;
`;

export const LabelText = styled(Typography)`
  font-size: .875rem;
  font-weight: 500;
  color: ${({ error }) => (error ? '#ff0000' : '#344054')};
  opacity: 0.5;
  margin-bottom: .5rem;
`;

export const TermsStyled = styled('div')`
  margin-top: 1.5rem;
  border-radius: 8px;
  background-color: #f1f8ff;
  display: flex;
  padding: 10px;
`;

export const CheckBoxContainer = styled('div')`
  height: 5px;
  margin-top: 5px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  height: fit;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;

export const TermsDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TermsTitle = styled(Typography)`
  color: #235a91;
  font-weight: 600;
`;

export const TermsContent = styled(Typography)`
  font-size: 14px;
  color: #606060;
`;

export const ViewTerms = styled('span')`
  color: #235a91;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
`;
