import React from 'react';
import { useQuery } from '@apollo/client';
import { useStateValue } from '../../../providers/stateProvider';
import { ContentWrapper, TitleText } from './business.styles';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import SellerSettings from './sellerSettings/sellerSettings';
import BuyerSettings from './buyerSettings/buyerSettings';
import { GET_BUYER_BUSINESS, GET_SELLER_BUSINESS } from '../../../queries/businesses';

export default function Outlets() {
  const [{ user }] = Object.values(useStateValue());
  const { session } = user;
  const { businessuserSet = [], isSeller, isBuyer } = session;
  const business = businessuserSet[0]?.business || {};
  const { id } = business;

  const accountType = (isBuyer && !isSeller) ? 'BUYER' : 'SELLER';

  const {
    loading: buyerLoading, error: buyerError, data: buyerData
  } = useQuery(GET_BUYER_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      businessId: Number(id)
    },
    skip: accountType === 'SELLER'
  });

  const {
    loading: sellerLoading, error: sellerError, data: sellerData
  } = useQuery(GET_SELLER_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      businessId: Number(id)
    },
    skip: accountType === 'BUYER'
  });

  if (buyerLoading || sellerLoading) return <Loader />;
  if (buyerError || sellerError) return <Loader />;

  return (
    <MainContent>
      <ContentWrapper>
        <TitleText>Account Settings</TitleText>
        {(isBuyer && !isSeller) && (
          <BuyerSettings
            userData={buyerData}
            businessId={+id}
            accountType={accountType}
          />
        )}
        {isSeller && (
          <SellerSettings
            userData={sellerData}
            businessId={+id}
            accountType={accountType}
          />
        )}
      </ContentWrapper>
    </MainContent>
  );
}
