import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@mui/material';
import CustomNewTextField from '../shared/CustomNewTextField';
import CustomButton from '../customComponents/customButton';
import {
  FiltersGrid, MenuItems, ExportIconStyled, ExportText
} from './ordersFilter.styles';
import { OgaToken } from '../../graphql/token';
import { useStateValue } from '../../providers/stateProvider';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';

const OrdersFilter = ({
  state, handleFilterChange, handleSetFilter, handleDateSubmit,
}) => {
  const [{
    user: { session: { id } }
  }] = Object.values(useStateValue());

  const { tab, orderStatus: status, orderNo } = state;

  const handleOrdersDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}export-seller-order/manage_orders/?user_id=${id}&&tab_status=${tab}&&status=${status}&&order_id=${orderNo}`,
      '_blank'
    );
  };

  const openOrderStatus = [
    { name: 'Awaiting Approval', value: 'ORDER_AWAITING_APPROVAL' },
    { name: 'Rejected', value: 'ORDER_REJECTED' },
    { name: 'Order Received', value: 'ORDER_RECEIVED' },
    { name: 'Order Sorted', value: 'ORDER_SORTED' },
    { name: 'Ready for Dispatch', value: 'ORDER_QUEUED_FOR_DISPATCH' },
    { name: 'Dispatched', value: 'ORDER_DISPATCHED' },
  ];

  const orderStatus = () => {
    switch (tab) {
      case 'OPEN':
      case 'PLACED':
      case 'CLOSED': return openOrderStatus;
      default: return openOrderStatus;
    }
  };

  const buttons = [
    {
      name: 'Export',
      type: 'secondary',
      handleClick: handleOrdersDownload,
      styles: { width: '8%', borderRadius: '8px' },
    },
  ];

  return (
    <Grid item container xs={12}>
      <FiltersGrid container item justifyContent="space-between">
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleDateSubmit}
            xs={12}
            state={state}
            styles={{ height: '3rem', width: '100%', border: '1px solid #bababa' }}
          />
        </Grid>
        <Grid item>
          <CustomNewTextField
            field={{ name: 'orderNo', label: 'Enter Order Number' }}
            state={state}
            handleChange={handleFilterChange}
            style={{ width: '100%' }}
          />
        </Grid>
        {
          tab !== 'PLACED'
          && (
          <Grid item sx={{ width: '13rem' }}>
            <CustomNewTextField
              field={{ name: 'orderStatus', label: 'Select Order Status' }}
              state={state}
              select
              handleChange={handleFilterChange}
              style={{ width: '100%', borderRadius: '8px' }}
            >
              {orderStatus().map(({ name, value }) => (
                <MenuItems
                  key={value}
                  value={value}
                >
                  {name}
                </MenuItems>
              ))}
            </CustomNewTextField>
          </Grid>
          )
        }

        <CustomButton
          style={{ width: '8%', height: '3rem', borderRadius: '8px' }}
          onClick={handleSetFilter}
        >
          Set Filter
        </CustomButton>
        {
          tab !== 'PLACED' && buttons.map(({
            name, type, styles, handleClick
          }) => (
            <CustomButton
              key={name}
              type={type}
              style={{ ...styles, height: '3rem' }}
              onClick={handleClick}
            >
              <ExportIconStyled />
              <ExportText>{name}</ExportText>
            </CustomButton>
          ))
        }
      </FiltersGrid>
    </Grid>
  );
};

OrdersFilter.propTypes = {
  tab: PropTypes.string,
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleDateSubmit: PropTypes.func.isRequired,
};

OrdersFilter.defaultProps = {
  tab: '',
  state: {},
};

export default OrdersFilter;
