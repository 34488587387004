const user = {
  session: {},
  promos: {},
  priceFactor: null,
  isBusinessVirtualStore: false,
  businessCanUseMastersList: false,
  role: null,
  parentId: null,
  businessName: '',
  businessId: '',
  businessUserId: '',
  onlyBusinessProducts: false,
  isSupplyChain: false,
  platform: '',
  allowedPermissionsMap: [],
  allPermissionsMap: [],
  businessLocation: null,
  userInfoRefetch: () => {}
};

export default user;
