import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Tooltip, tooltipClasses,
  Select
} from '@mui/material';
import styled from 'styled-components';
import {
  CInputBase, Label, HelperText, CircularProgressLoader, HelpOutlineStyled, TooltipTitle, ToolTipContent,
  CFormControl, SelectNameTypo, FieldCheckbox, CheckboxImage, MenuItems
} from './customInput.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#235A91',
    color: '#fff',
    fontSize: theme?.typography?.pxToRem(12),
    border: '1px solid #235a91',
    borderRadius: '8px',
    padding: '.5rem 0.5rem .5rem 1rem',
    minWidth: '21rem !important',
    marginLeft: '15rem',
    height: '5rem',
    '&[x-placement*="top"] $arrow': {
      bottom: '-8px',
      left: '50%',
      borderWidth: '8px 8px 0',
      borderTopColor: '#235A91',
    },
  },
  '.MuiTooltip-arrow': {
    color: '#235A91',
    marginLeft: '-7.5rem'
  },
}));

export const CustomInputBase = ({
  label, sideLabel, required, error, loading, sAdornment, helperText, isTooltip, tooltipDesc, createPromotion,
  ...props
}) => (
  <Grid item container direction="column">
    <Grid item container justifyContent="space-between">
      {label
        ? (
          <Label error={error} createPromotion={createPromotion} className="label">
            {label}
            {' '}
            {required && <span style={{ color: 'red' }}>*</span> }

            {isTooltip && (
            <HtmlTooltip
              title={(
                <>
                  <TooltipTitle>Quick Guide</TooltipTitle>
                  <ToolTipContent>{tooltipDesc}</ToolTipContent>
                </>
            )}
              arrow
            >
              <HelpOutlineStyled />
            </HtmlTooltip>
            )}
          </Label>
        )
        : ''}
      {sideLabel ? <Label style={{ fontSize: '10px' }}>{sideLabel}</Label> : ''}
    </Grid>
    <CInputBase
      className={`mp-uat-${label.replace(/ /g, '-')}`}
      error={error}
      inputProps={{ 'data-testid': label }}
      {...props}
      startAdornment={sAdornment ? (
        <InputAdornment position="start">
          {sAdornment}
        </InputAdornment>
      ) : ''}
      endAdornment={loading ? (
        <InputAdornment position="end">
          <CircularProgressLoader
            disableShrink
            size={18}
            thickness={4}
          />
        </InputAdornment>
      ) : ''}
    />
    {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
  </Grid>
);

CustomInputBase.propTypes = {
  label: PropTypes.string,
  sideLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  props: PropTypes.shape(Object),
  createPromotion: PropTypes.bool,
  isTooltip: PropTypes.bool,
  tooltipDesc: PropTypes.string,
  sAdornment: PropTypes.element,
};

CustomInputBase.defaultProps = {
  label: '',
  sideLabel: '',
  required: false,
  error: false,
  loading: false,
  helperText: '',
  createPromotion: false,
  props: {},
  isTooltip: false,
  tooltipDesc: '',
  sAdornment: null,
};

export const CustomSelectInputBase = ({
  field, value, handleChange, showCheckBox, placeholder, helperText, error, required,
  isTooltip, tooltipDesc, ...props
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected([event.target.value]);
    handleChange(event);
  };

  const renderFunc = (val) => val;

  return (
    <Grid item container direction="column">
      {field.label ? (
        <Label error={error}>
          {field.label}
          {' '}
          {required && <span style={{ color: 'red' }}>*</span>}
          <span style={{ marginLeft: '10px' }}>
            {isTooltip && (
              <HtmlTooltip
                title={(
                  <>
                    <TooltipTitle>Quick Guide</TooltipTitle>
                    <ToolTipContent>{tooltipDesc}</ToolTipContent>
                  </>
              )}
                arrow
              >
                <HelpOutlineStyled />
              </HtmlTooltip>
            )}
          </span>
        </Label>
      ) : ''}

      <CFormControl
        fullWidth
        variant="filled"
        size="small"
        ref={selectRef}
        {...props}
      >
        <Select
          className={`erp-uat-${field.label.replace(/ /g, '-')}`}
          labelId="select-filled-label"
          id="select-filled"
          value={value}
          displayEmpty
          renderValue={(val) => (
            value !== ''
              ? renderFunc(val)
              : <span style={{ color: '#bdbdbd' }}>{placeholder}</span>
          )}
          onChange={handleInput}
          inputProps={{
            name: field.name,
            'data-testid': field.name
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {field.options?.map(
            (option) => (
              <MenuItems key={option} value={option}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <SelectNameTypo>{option}</SelectNameTypo>
                  { showCheckBox && (
                    <FieldCheckbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected.indexOf(option) > -1}
                    />
                  )}
                </Grid>
              </MenuItems>
            )
          )}
        </Select>
      </CFormControl>
      {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
    </Grid>
  );
};

CustomSelectInputBase.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  isTooltip: PropTypes.bool,
  tooltipDesc: PropTypes.string,
};

CustomSelectInputBase.defaultProps = {
  field: {},
  value: '',
  showCheckBox: true,
  placeholder: '',
  error: false,
  required: false,
  helperText: '',
  isTooltip: false,
  tooltipDesc: '',
};
