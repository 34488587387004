import {
  Typography, Checkbox, InputBase, FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormField = styled(FormControl)`
  & .MuiFilledInput-root {
    border: 1px solid #F3F9FF;
    background-color:  #FFF;
    border-radius: 15px;
    @media(max-width: 991px) {
      font-size: 1.7rem;
      padding: .7rem 0rem;
      height: 100px;
    }
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  & .MuiSelect-filled.MuiSelect-filled {
    text-align: initial;;
    background-color:  #F3F9FF;
    border-radius: 10px;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
  & .MuiFormLabel-root.Mui-disabled {
    color: #000000;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense{
    @media(max-width: 991px) {
      font-size: 1.7rem;
    }
  }
  & .MuiFilledInput-underline:after {
    border-bottom: none;
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CreditNameTypo = styled(Typography)`
  font-size: .7rem;
`;

export const CreditDaysInput = styled(InputBase)`
  font-size: .7rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;
