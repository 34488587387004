import React from 'react';
import {
  DialogContent,
  Slide,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import warning from '../../assets/images/Warning.gif';
import {
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  ButtonDarkStyled,
  ButtonWhiteStyled,
  FooterStyled, DialogContainer, InfoText
} from './confirmDialog.styles';
import bell from '../../assets/images/bell.gif';
import { CircularProgressLoader } from '../auth/login.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openDialog,
  setOpenDialog,
  title,
  desc,
  options,
  loading,
  setStatus,
  buttonAction,
  therapeuticType,
  handleDelete,
  type
}) => {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const confirmHandler = () => {
    setStatus(true);
  };

  return (
    <DialogContainer
      open={openDialog}
      data-testid="confirmDialog"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled therapeuticType={therapeuticType}>
        <Close
          fontSize="medium"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '1rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={1}>
              <img src={type ? bell : warning} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled therapeuticType={therapeuticType}>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <ButtonWhiteStyled onClick={handleDelete || handleClose}>
            {options[0]}
          </ButtonWhiteStyled>
          <ButtonDarkStyled disabled={loading} onClick={!buttonAction ? confirmHandler : buttonAction}>
            {loading ? <CircularProgressLoader /> : options[1]}
          </ButtonDarkStyled>
        </FooterStyled>
        {type ? (
          <InfoText>We promise not to spam you!</InfoText>
        ) : ''}
      </DialogContentStyled>
    </DialogContainer>
  );
};

ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  setStatus: PropTypes.func,
  buttonAction: PropTypes.func,
  therapeuticType: PropTypes.bool,
  handleDelete: PropTypes.func,
  type: PropTypes.string
};

ConfirmDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  options: [],
  loading: false,
  setStatus: () => false,
  buttonAction: () => false,
  therapeuticType: false,
  handleDelete: null,
  type: ''
};

export default ConfirmDialog;
