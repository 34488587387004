import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  MainContainer, HeaderTypo, HeaderWrapper, HeaderButton, NNotificationBody, HeaderRight, BellBg,
  NotificationIcon, DescTypo, BellNotifyIconStyled, ArchiveStyled, IconWrapper, Tooltip,
  ListN, NotRead, NotifyTextContainer, NotifyTextHeader, NotifyTextBody, NotifyContainer, Track,
  FooterWrapper, ArrowRightIcon, HeaderContainer, NotificationImg
} from './notificationsPopper.styles';
import { CHANGE_NOTIFICATION_STATUS, UPDATE_ALL_NOTIFICATION_STATUS } from '../../mutations/notifications';
import { RenderPopper } from '../../utils/utils';
import NotificationsActionTypes from '../../providers/reducers/notifications/notificationsTypes';
import { useStateValue } from '../../providers/stateProvider';
import { Subscriber } from '../../utils/subscriber';
import { JSONParse } from '../../utils/json';

const NotificationsPopper = ({
  notificationsEl, handleBellClick, refetch, notifications
}) => {
  const open = Boolean(notificationsEl);
  const navigate = useNavigate();

  const [, dispatch] = Object.values(useStateValue());
  const handleArchive = (tab) => {
    handleBellClick();
    navigate(`/notifications/${tab}`);
  };

  useEffect(() => {
    refetch();
  }, [open]);

  const [updateNotification] = useMutation(CHANGE_NOTIFICATION_STATUS);
  const updateNotificationHandler = (notificationId, status) => {
    updateNotification({
      variables: { notificationId, status }
    }).then(({ data }) => {
      const { message } = data.updateErpNotification;
      toast.success(message);
      refetch();
    })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [updateAllNotification, { loading }] = useMutation(UPDATE_ALL_NOTIFICATION_STATUS);
  const updateAllNotificationHandler = (status) => {
    updateAllNotification({
      variables: { status }
    }).then(({ data }) => {
      const { message } = data.updateAllErpNotification;
      toast.success(message);
      Subscriber.report('NotificationCount', 0);
      dispatch({
        type: NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT,
        payload: {
          count: 0
        }
      });
      refetch();
    })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const changeNotificationHandler = (item) => {
    const {
      id, status, productId, meta, title
    } = item;
    const { order_id: orderId } = JSONParse(meta) || {};
    if (status === 'NOT_READ') {
      updateNotificationHandler(id, 'read');
    } else updateNotificationHandler(id, 'deleted');
    handleBellClick();
    if (orderId) {
      navigate(`/orders-admin/dispatch/${orderId}`);
    } else {
      navigate(`/new-order/All Categories/0/${productId}`);
    }
  };

  const notificationTitle = (type) => {
    switch (type) {
      case 'new_product_alert':
        return 'New Product Alert';
      case 'order_approval':
        return 'Order Approved';
      case 'order_sorted':
        return 'Order Sorted';
      case 'order_dispatched':
        return 'Order Dispatched';
      case 'order_ready_for_dispatched':
        return 'Order Ready For Dispatch';
      case 'order_canceled':
        return 'Order Canceled';
      case 'order_returned':
        return 'Order Returned';
      default:
        return 'Product Back in Stock';
    }
  };

  return (
    <RenderPopper
      open={open}
      anchorEl={notificationsEl}
      onClickAway={() => handleBellClick()}
      popperPlacement="bottom-end"
      modifiers={{
        offset: {
          enabled: true,
          offset: '1vw, 2vh',
        }
      }}
    >
      <MainContainer container>
        <HeaderWrapper container item>
          <HeaderTypo variant="caption">Ogapharmacy Notifications</HeaderTypo>
          <HeaderRight container item xs={5}>
            <HeaderButton disabled={loading} onClick={() => updateAllNotificationHandler('deleted')}>{ loading ? 'Loading  ...' : 'Clear All' }</HeaderButton>
            <IconWrapper item onClick={() => handleArchive('archive')}>
              <ArchiveStyled aria-label="Archive" />
              <Tooltip variant="caption">Archive</Tooltip>
            </IconWrapper>
          </HeaderRight>
        </HeaderWrapper>
        <NotifyContainer container item>
          {
            !notifications?.length ? (
              <NNotificationBody item>
                <NotificationIcon />
                <DescTypo variant="caption">No Notifications Yet!</DescTypo>
                All notifications will be displayed here.
              </NNotificationBody>
            ) : (
              notifications?.map((item) => {
                const {
                  id, title, productName, dateCreated, status, message
                } = item;
                return (
                  <ListN
                    key={id}
                    onClick={() => changeNotificationHandler(item)}
                  >
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <BellBg>
                        {status === 'NOT_READ' && <NotRead />}
                        <BellNotifyIconStyled />
                      </BellBg>
                      <NotifyTextContainer>
                        <HeaderContainer>
                          <NotifyTextHeader>{notificationTitle(title)}</NotifyTextHeader>
                          <NotificationImg />
                        </HeaderContainer>
                        <NotifyTextBody>{productName || message}</NotifyTextBody>
                      </NotifyTextContainer>
                    </div>
                    <Track>{moment.utc(dateCreated).local().startOf('seconds').fromNow()}</Track>
                  </ListN>
                );
              })
            )
          }
        </NotifyContainer>
        {notifications?.length ? (
          <FooterWrapper container item>
            <HeaderButton onClick={() => handleArchive('all')}>
              View More
              <ArrowRightIcon />
            </HeaderButton>
          </FooterWrapper>
        ) : ''}
      </MainContainer>
    </RenderPopper>
  );
};

NotificationsPopper.propTypes = {
  notifications: PropTypes.instanceOf(Array),
  notificationsEl: PropTypes.shape(Object),
  handleBellClick: PropTypes.func.isRequired,
  refetch: PropTypes.func
};

NotificationsPopper.defaultProps = {
  notifications: [],
  notificationsEl: {},
  refetch: () => null
};

export default NotificationsPopper;
