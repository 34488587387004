import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableRow, TableBody, Box
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  TableContainerWrapper, TableComponent, ErpHeader, FooterGrid, MainTableHead,
} from './orderLogs.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import { addHyphen } from '../../../shared/methods';
import TablePagination from '../../../shared/tablePagination';
import Filter from './filter';
import { PRODUCT_ORDER_LOGS_QUERY } from '../../../../queries/products';

const orderHeaders = [
  'Date', 'Order ID', 'Batch Sold', 'Previous QTY', 'Qty Sold',
];

const returnOrderHeaders = [
  'Date', 'Order ID', 'Batch Recieved', 'QTY Recieved', 'Qty Returned',
];

const initialState = {
  searchText: '',
  dateFrom: '',
  dateTo: '',
};

const OrderLogs = ({
  tabVal,
}) => {
  const [state, setState] = useState(initialState);
  const { id } = useParams();
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchError, setSearchError] = useState(false);
  const headers = tabVal === 'return' ? returnOrderHeaders : orderHeaders;
  const { searchText, dateFrom, dateTo } = state;

  const returnHeaders = () => headers.map((header) => (
    <ErpHeader key={header} className={`mpAdmin-uat-ordersPage-${addHyphen(header)}`}>{header}</ErpHeader>
  ));

  useEffect(() => {
    if (searchText && searchText.length >= 3 && !searchError) {
      setSearchValue(searchText);
    } else if (!searchText) {
      setSearchValue('');
    }
  }, [searchText]);

  const handleSearch = (text) => {
    const isValidNumber = /^-?\d+$/.test(text);
    if (!isValidNumber) {
      setSearchError(true);
      toast.error('Invalid Input value');
    } else {
      setSearchError(false);
    }
    setState({ ...state, searchText: text });
  };

  const handleDateSubmit = (dateRange) => {
    const { dateFrom: newDateFrom, dateTo: newDateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: newDateFrom,
      dateTo: newDateTo,
    }));
  };
  const variables = {
    productId: id,
    pageCount,
    pageNumber,
    dateTo,
    dateFrom,
    orderId: searchValue,
  };

  const {
    loading, error, data,
  } = useQuery(PRODUCT_ORDER_LOGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const {
    productOrderLogs = [], productOrderLogsTotalNumber = 0,
  } = data || {};

  if (error) return <div>{error.message}</div>;

  return (
    <Box container style={{ width: '100%' }}>
      <TableContainerWrapper component={Paper}>
        <Filter
          state={state}
          handleDateSubmit={handleDateSubmit}
          handleSearch={handleSearch}
          id={id}
          tabVal={tabVal}
        />
        {loading ? <ProductsTableLoader /> : (
          <TableComponent aria-label="Orders table">
            <MainTableHead>
              <TableRow>
                {returnHeaders()}
              </TableRow>
            </MainTableHead>
            {productOrderLogs && (
              <TableBody>
                {productOrderLogs?.map((logs, index) => (
                  <ReturnRow
                    key={logs?.id}
                    row={logs}
                    index={index}
                  />
                ))}
              </TableBody>
            )}
          </TableComponent>
        )}
      </TableContainerWrapper>
      <FooterGrid item container>
        <TablePagination
          total={productOrderLogsTotalNumber}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </Box>
  );
};
OrderLogs.propTypes = {
  tabVal: PropTypes.string.isRequired,
};

OrderLogs.defaultProps = {
};

export default OrderLogs;
