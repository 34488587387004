import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { BUYER_REORDER } from '../../mutations/orders';
import currencyFormatter from '../shared/currencyFormatter';
import { JSONParse } from '../../utils/json';
import {
  BodyCell, MainTableRow
} from './returnRow.styles';
import ActionPopper from './actionPopper';
import { GET_CART_TOTAL_COUNT } from '../../queries/cart';

const PlacedReturnRow = ({
  row, index
}) => {
  const { datePlaced, id, paymentSummary } = row;
  const { total_amount } = JSONParse(paymentSummary);

  const navigate = useNavigate();
  const dPlaced = moment(datePlaced).format('DD/MM/YY (h:mm a)');
  const orderId = `#${id}`;

  const totalAmount = `₦ ${currencyFormatter(total_amount)}`;

  const erpCells = [dPlaced, orderId, totalAmount];

  const returnCell = (val) => val;

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell key={cell} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  const [reorder] = useMutation(BUYER_REORDER);

  const reorderHandler = () => {
    reorder({
      variables: { orderId: Number(id) },
      refetchQueries: [GET_CART_TOTAL_COUNT]
    }).then(({ data }) => {
      const { message } = data.repeatOrder;
      toast.success(message);
    })
      .catch((err) => toast.error(err.message));
  };

  const handleAction = (event, type) => {
    switch (type) {
      case 'view-order':
        return navigate(`/orders/${id}/affiliate`);
      case 'tracked-order':
        return navigate('/track-order', { state: { orderId: id } });
      case 're-order':
        return reorderHandler();
      default:
        return null;
    }
  };
  return (
    <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      {renderBodyCells()}
      <BodyCell>
        <ActionPopper
          handleAction={handleAction}
          row={row}
        />
      </BodyCell>
    </MainTableRow>
  );
};

PlacedReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

PlacedReturnRow.defaultProps = {
  row: {},
};

export default PlacedReturnRow;
