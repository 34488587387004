import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Grid } from '@mui/material';
import getCroppedImg from './cropImage';
import {
  MainWrapper, CropContainer, ControlsContainer, HeaderText,
  PlusButton, ZoomScrollbar, ZoomSlider, MinusButton, ActionContainer, ImageWrapper, ZooomInIcon, ZooomOutIcon
} from './cropImageContainer.styles';
import { ButtonText, CircularProgressLoader, EditButton } from '../styles';

const CropImageContainer = ({
  files, handleFinalImage, isLicense, handleSave, loading
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoomed, setZoomed] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAPixels) => {
    setCroppedAreaPixels(croppedAPixels);
  }, []);

  const fileType = isLicense ? '' : 'round';
  const readFile = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

  const setDataUrl = async (_files) => {
    const imageDataUrl = await readFile(_files[0]);
    setImageSrc(imageDataUrl);
  };

  useEffect(() => {
    setDataUrl(files);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const showCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(image);
      handleFinalImage(image);
      handleSave(image);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, handleFinalImage, handleSave]);

  return (
    <Grid item container>
      <MainWrapper item container>
        <ImageWrapper container>
          <CropContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoomed}
              aspect={1 / 1}
              cropShape={fileType}
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoomed}
              overlay={{
                mask: {
                  borderRadius: '50%',
                  overflow: 'hidden',
                },
                maskColor: 'red',
                maskOpacity: 0.6,
                strokeColor: '#fff',
                strokeOpacity: 0.6,
              }}
            />
          </CropContainer>
        </ImageWrapper>
        <ActionContainer container xs={12}>
          <Grid xs={12} md={6}>
            <HeaderText>Zoom</HeaderText>
            <ControlsContainer>
              <MinusButton
                onClick={() => setZoomed((_zoomed) => _zoomed - 0.1)}
                disabled={zoomed === 1}
              >
                <ZooomInIcon />
              </MinusButton>
              <ZoomScrollbar>
                <ZoomSlider value={zoomed * 10} onChange={(_, value) => setZoomed(value)} />
              </ZoomScrollbar>
              <PlusButton
                onClick={() => setZoomed((_zoomed) => _zoomed + 0.1)}
                disabled={zoomed === 3}
              >
                <ZooomOutIcon />
              </PlusButton>
            </ControlsContainer>
          </Grid>

          <Grid container xs={12} md={6} style={{ justifyContent: 'flex-end' }}>
            <EditButton
              onClick={showCroppedImage}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <ButtonText>
                  Save
                </ButtonText>
              )}
            </EditButton>
          </Grid>
        </ActionContainer>
      </MainWrapper>
    </Grid>
  );
};

CropImageContainer.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
  isLicense: PropTypes.func.isRequired,
  handleFinalImage: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default CropImageContainer;
