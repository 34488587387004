import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle, SupplierOrderImage,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
  ManageProductImage, OrderCreateButton, OrdersPrimaryTitle, KeyboardArrowLeft, BackArrowIconContainer, FlexDiv,
  OrdersSecTitle
} from './titleGrid.styles';
import SupplierOrderIcon from '../../../assets/images/order/View Supplier Orders.png';
import BagIcon from '../../../assets/images/navigation/Product Icon (black).png';
import OrderDropdown from '../../shared/orderDropdown/orderDropdown';
import AffiliateModal from '../admin/affiliate-modal';
import VerificationDialog from '../newOrder/verificationDialog';
import MapOrderCsvDialog from './mapOrderCsvDialog';
import UploadFlow from './uploadFlow';

export function TitleGrid() {
  const role = localStorage.getItem('oga_user_role');
  const [orderButtonEl, setOrderButtonEl] = useState(null);
  const [route, setRoute] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const affiliateStatus = localStorage.getItem('affiliateStatus');
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [uploadDescDialog, setUploadDescDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';

  const handleOrderButtonClick = (event, routeTo) => {
    setRoute(routeTo || event.currentTarget.name);
    setOrderButtonEl(orderButtonEl ? null : event.currentTarget);
  };

  const handleSelectAffiliate = (event, routeTo) => {
    if (isAdmin) setOpenModal(true);
    else handleOrderButtonClick(event, routeTo);
  };

  const handleCloseDialog = (event) => {
    setOpenDialog(openDialog ? false : Boolean(event.currentTarget));
  };

  const handleVerification = (event, routeTo) => {
    // if (affiliateStatus !== 'VERIFIED') {
    //   setOpenDialog(true);
    //   return;
    // }
    handleOrderButtonClick(event, routeTo);
  };

  const handleCsvDialog = () => {
    setUploadCsvDialog(!uploadCsvDialog);
    setOrderButtonEl(null);
  };

  const handleUploadDescDialog = () => {
    setUploadDescDialog(!uploadDescDialog);
    setOrderButtonEl(null);
  };

  const actionHandler = (actionType) => {
    switch (actionType) {
      case 'add_individually':
        return navigate(route);
      case 'upload_order_csv':
        return handleUploadDescDialog();
      default:
        break;
    }
  };

  return (
    <>
      <TitleGridContainer container item spacing={2}>
        <FlexDiv item lg={2} md={2} sm={8}>
          {path?.split('/')[1] !== 'orders' && (
            <BackArrowIconContainer onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </BackArrowIconContainer>
          )}
          <TitleTextGridContainer>
            {path?.split('/')[1] === 'orders' ? (
              <OrdersPrimaryTitle> Manage Orders </OrdersPrimaryTitle>
            ) : <PrimaryTitle> Order </PrimaryTitle> }

            {path?.split('/')[1] === 'orders' ? (
              <OrdersSecTitle> View Orders and Analytics </OrdersSecTitle>
            ) : (
              <SecondaryTitle>
                View Orders and Analytics
              </SecondaryTitle>
            )}
          </TitleTextGridContainer>
        </FlexDiv>
        <ButtonsGrid container item md={10} lg={10} sm={12}>
          {path?.split('/')[1] === 'orders-admin' && (
            <>
              {(role === 'oga-pharmacy-admin') ? (
                <OrderButton type="secondary" onClick={() => navigate('/dispatch-orders')}>
                  <Hidden smDown>
                    <SupplierOrderImage alt="supplier order icon" src={SupplierOrderIcon} />
                  </Hidden>
                  <OrderButtonText>Dispatch Orders</OrderButtonText>
                </OrderButton>
              ) : ''}
              <OrderButton onClick={(event) => handleSelectAffiliate(event, '/new-order')} name="/new-order">
                <Hidden smDown>
                  <PlusIcon />
                </Hidden>
                <OrderButtonText>Create New Order</OrderButtonText>
              </OrderButton>
              <AffiliateModal
                afterMath={handleOrderButtonClick}
                open={openModal}
                handleCloseModal={() => setOpenModal(false)}
              />
            </>
          )}
          {path?.split('/')[1] === 'manual-order-dashboard' && (
            <>
              <OrderButton type="secondary" onClick={() => navigate('/open-orders')}>
                <Hidden smDown>
                  <SupplierOrderImage alt="supplier order icon" src={SupplierOrderIcon} />
                </Hidden>
                <OrderButtonText>View Open Orders</OrderButtonText>
              </OrderButton>
              <OrderButton onClick={(event) => handleSelectAffiliate(event, '/manual-order')} name="/manual-order">
                <Hidden smDown>
                  <PlusIcon />
                </Hidden>
                <OrderButtonText>Create New Order</OrderButtonText>
              </OrderButton>
              <AffiliateModal
                afterMath={handleOrderButtonClick}
                open={openModal}
                handleCloseModal={() => setOpenModal(false)}
              />
            </>
          )}

          {path?.split('/')[1] === 'orders' && (
            <OrderCreateButton className="mpFe-uat-orders-create-new-order" onClick={((event) => handleVerification(event, '/new-order'))}>
              <OrderButtonText>Create New Order</OrderButtonText>
              <KeyboardArrowDownIcon style={{ fill: '#235a91' }} />
            </OrderCreateButton>
          )}
          {path?.split('/')[1] === 'orders-supplier' && (
          <OrderButton onClick={() => navigate('/products')}>
            <Hidden mdDown>
              <ManageProductImage alt="supplier order icon" src={BagIcon} />
            </Hidden>
            <OrderButtonText>Manage Products</OrderButtonText>
          </OrderButton>
          )}
        </ButtonsGrid>

        <OrderDropdown
          route={route}
          isAdmin={isAdmin}
          orderButtonEl={orderButtonEl}
          setOrderButtonEl={setOrderButtonEl}
          handleProductButtonClick={handleOrderButtonClick}
          navigate={navigate}
          actionHandler={actionHandler}
        />
        <UploadFlow openDialog={uploadDescDialog} toggleHandler={handleUploadDescDialog} handleUploadModal={handleCsvDialog} />
        <MapOrderCsvDialog
          openProdDialog={uploadCsvDialog}
          toggleHandler={handleCsvDialog}
        />
      </TitleGridContainer>
      {openDialog && (
        <VerificationDialog
          openDialog={openDialog}
          handleClose={handleCloseDialog}
          type="affiliateUser"
          status={affiliateStatus}
        />
      )}
    </>
  );
}

TitleGrid.propTypes = {
};

TitleGrid.defaultProps = {
};

export default TitleGrid;
