import {
  Grid, Typography, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 10px 25px;
    width: 24rem;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #F3F9FF;
    }

    @media(min-width: 992px) {
      padding: 10px 15px;
      width: 230px;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    margin-left: 15px;
    font-size: .875rem;
    font-weight: 400;
    color: #606060;
  }
`;

export const ItemWrapper = styled(IconButton)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: #F3F9FF;
  }
`;
