import React, { useLayoutEffect, useState } from 'react';
import moment from 'moment';
import {
  Box, Grid, Table, TableBody
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import MainContent from '../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, PrimaryTitle, OrderWrapper, TableContainerWrapper,
  FooterGrid, TableGrid, MainTableHead, THeader, TableComponent
} from './manageOrdersContainer.styles';
import TabsButtons from './tabsButtons';
import TablePagination from '../shared/tablePagination';
import OrdersFilter from './ordersFilter';
import { GET_MANAGE_ORDERS, GET_ORDERS } from '../../queries/orders';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import CustomToolTip from '../shared/toolTip';
import ReturnRow from './returnRow';
import CustomButton from '../customComponents/customButton';
import { useStateValue } from '../../providers/stateProvider';
import PlacedReturnRow from './placedReturnRow';

const OpenOrdersHeader = [
  { name: 'Order Date' },
  { name: 'Order Number' },
  { name: 'Total Order Value' },
  { name: 'Sorted Value', toolTipDesc: 'OGApharmacy charges a mark up on the invoice amount for every transaction.' },
  { name: 'Amount Payable', toolTipDesc: 'OGApharmacy charges a mark up on the invoice amount for every transaction.' },
  { name: 'Order Status' },
];

const CompletedOrdersHeader = [
  { name: 'Order Date' },
  { name: 'Order Number' },
  { name: 'Value' },
  { name: 'Order Status' },
];

const OrdersPlacedHeader = [
  { name: 'Order Date' },
  { name: 'Order Number' },
  { name: 'Order Value' },
  // { name: 'Sorted Value', toolTipDesc: 'OGApharmacy charges a 6% mark up on the invoice amount for every transaction.' },
  // { name: 'Order Status' },
];

const ManageOrdersContainer = () => {
  const navigate = useNavigate();

  const initialState = {
    orderNo: '',
    orderStatus: '',
    datePlaced: '',
    dateFrom: '',
    dateTo: '',
    filters: {}
  };

  const [state, setState] = useState(initialState);
  const {
    filters, orderStatus, dateFrom, dateTo, orderNo
  } = state;

  const [pageCount, setPageCount] = useState('10');
  const [pageNumber, setPageNumber] = useState('1');
  const [tab, setTab] = useState('OPEN');

  const [{ user: { session } }] = Object.values(useStateValue());
  const { businessType } = session || null;

  useLayoutEffect(() => {
    setState((_state) => ({
      ..._state,
      tab
    }));
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const handleDateSubmit = (dateRange) => {
    const { dateFrom: newDateFrom, dateTo: newDateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(newDateFrom).format('YYYY-M-DD'),
      dateTo: moment(newDateTo).format('YYYY-M-DD')
    }));
  };

  const handleSetFilter = () => {
    setState((_state) => ({
      ..._state,
      filters: {
        dateFrom,
        dateTo,
        id: Number(orderNo),
        status: orderStatus
      }
    }));
  };

  const handleSetTab = (newValue) => {
    setTab(newValue);
    setState((_state) => ({
      ..._state,
      ...initialState,
      tab: newValue,
    }));
  };

  const variables = {
    tab,
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    ...filters
  };

  const {
    data, loading, error
  } = useQuery(GET_MANAGE_ORDERS, {
    fetchPolicy: 'no-cache',
    variables,
    skip: state.tab === 'PLACED'
  });

  const {
    data: placedData, loading: placedLoading
  } = useQuery(GET_ORDERS, {
    fetchPolicy: 'no-cache',
    variables,
    skip: state.tab !== 'PLACED'
  });

  const placedOrders = placedData?.orders || [];
  const { ordersTotalNumber } = placedData ?? 0;

  if (error) return `Error! ${error.message}`;
  const businessOrders = data?.businessOrders || [];
  const { businessOrdersTotalNumber } = data ?? 0;

  const headerCond = () => {
    switch (tab) {
      case 'OPEN':
        return OpenOrdersHeader;
      case 'CLOSED':
        return CompletedOrdersHeader;
      case 'PLACED':
        return OrdersPlacedHeader;
      default:
        return OpenOrdersHeader;
    }
  };

  const returnHeaders = () => headerCond().map((header) => {
    const { name, toolTipDesc } = header;
    return (
      <THeader key={name}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {name}
          { toolTipDesc && <CustomToolTip title={name} description={toolTipDesc} /> }
        </Box>
      </THeader>
    );
  });

  const handleDispatchOrders = () => navigate('/dispatch-orders');

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <PrimaryTitle xs={6}> Manage Orders</PrimaryTitle>
          {
            tab === 'OPEN'
            && (
            <PrimaryTitle xs={6}>
              <CustomButton
                type="tertiary"
                style={{ width: '11rem', height: '2.8rem' }}
                onClick={handleDispatchOrders}
              >
                Dispatch Orders
              </CustomButton>
            </PrimaryTitle>
            )
          }
        </TitleGridContainer>
        <OrderWrapper item container xs={12}>
          <Grid item container xs={12}>
            <TabsButtons
              tab={tab}
              handleSetTab={handleSetTab}
              businessType={businessType}
            />
          </Grid>
          <TableContainerWrapper>
            <OrdersFilter
              tab={tab}
              state={state}
              handleFilterChange={handleFilterChange}
              handleSetFilter={handleSetFilter}
              handleDateSubmit={handleDateSubmit}
            />
            <TableGrid item container>
              {loading || placedLoading ? <ProductsTableLoader /> : (
                <TableComponent item container aria-label="Orders table">
                  <Table style={{ width: '100%' }} data-testid="puremp-uat-manageOrders">
                    <MainTableHead>
                      {returnHeaders()}
                      { state.tab === 'PLACED' && <THeader>Action</THeader>}
                    </MainTableHead>
                    {
                      state.tab === 'PLACED'
                        ? placedOrders
                        && (
                          <TableBody>
                            {placedOrders?.map((order, index) => (
                              <PlacedReturnRow
                                key={index}
                                index={index}
                                row={order}
                              />
                            ))}
                          </TableBody>
                        )
                        : businessOrders && (
                        <TableBody>
                          {businessOrders?.map((order, index) => (
                            <ReturnRow
                              key={index}
                              index={index}
                              row={order}
                              state={state}
                              tab={tab}
                            />
                          ))}
                        </TableBody>
                        )
                    }
                  </Table>
                </TableComponent>
              )}

            </TableGrid>
          </TableContainerWrapper>
          <FooterGrid item container>
            <TablePagination
              total={businessOrdersTotalNumber || ordersTotalNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </FooterGrid>
        </OrderWrapper>
      </OrderGridContainer>
    </MainContent>
  );
};

export default ManageOrdersContainer;
