import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import {
  TitleText, DropZoneContainer,
  MiddleText, UploadedText,
  ClickUploadText, UploadBox,
  UploadText, theme, FileText,
} from './fileDropzone.styles';
import {
  NewUploadIcon, CheckboxIcon, DelFileIcon
} from '../../../../assets/svgs';

const DropzoneContent = ({
  state, name: typeName, handleDelete
}) => {
  const [fileInfo] = state?.[typeName] ?? [];
  const { file: { name, size } } = fileInfo ?? { file: { name: '', size: 0 } };

  const formatFileSize = (fileSize) => {
    const units = ['Bytes', 'KB', 'MB'];
    let index = 0;
    while (fileSize >= 1024 && index < units.length - 1) {
      fileSize /= 1024;
      index++;
    }
    const sizeFormatted = `${fileSize.toFixed(2)} ${units[index]}`;
    return sizeFormatted;
  };

  const formattedSize = formatFileSize(size);

  return (
    <DropZoneContainer>
      <Box style={{ width: '15%' }}>
        {name.length > 0 ? (
          <CheckboxIcon style={{ fontSize: '3rem', fill: 'none' }} />
        ) : (
          <NewUploadIcon style={{ fontSize: '3rem', fill: 'none' }} />
        )}
      </Box>
      <UploadBox name={name}>
        <TitleText>
          {name.length > 0 ? (
            <>
              <FileText>
                {name}
              </FileText>
              <UploadedText>
                {formattedSize}
                {' '}
                - 100% uploaded
              </UploadedText>
            </>
          ) : (
            <>
              <UploadText>
                <ClickUploadText>Click to upload</ClickUploadText>
                {' '}
                or drag and drop
              </UploadText>
              <MiddleText>
                .PDF, .PNG, .JPG or GIF (max. 2MB)
              </MiddleText>
            </>
          )}
        </TitleText>
      </UploadBox>
      {name.length > 0 && (
        <Box style={{ width: '15%' }}>
          <DelFileIcon
            style={{ fill: 'none', cursor: 'pointer', fontSize: '1.25rem' }}
            onClick={(e) => { e.stopPropagation(); handleDelete(typeName); }}
          />
        </Box>
      )}
    </DropZoneContainer>
  );
};

DropzoneContent.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const DropZone = ({
  handleFile, handleDelete, name, state
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <DropzoneAreaBase
        acceptedFiles={['image/jpeg', 'image/png', 'application/pdf', 'image/gif']}
        dropzoneText={<DropzoneContent state={state} name={name} handleDelete={handleDelete} />}
        fileslimit={1}
        onAdd={(filesData) => handleFile(filesData, name)}
        onDelete={() => handleDelete(name)}
        maxFileSize={2097152}
        clearOnUnmount
      />
    </ThemeProvider>
  </StyledEngineProvider>
);

DropZone.propTypes = {
  handleFile: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired
};

export default DropZone;
