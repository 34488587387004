import { styled } from '@mui/material/styles';
import {
  Box, Grid, IconButton, Typography, Stepper,
  StepLabel,
  Paper,
  TextField,
  Button,
  TableCell,
  TableRow, TableHead,
  Table,
  Checkbox
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { CheckCircleOutline, Circle } from '@mui/icons-material';
import { SyncIcon } from '../../assets/svgs';

export const GridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  background-color: #f7fafe;
  @media (min-width: 992px) {
    padding: 15px 3rem 15px 3rem;
  }
`;

export const BackArrowIconContainer = styled(IconButton)`
  padding: 10px 0px;
  margin: 0;
  background: none;
  color: #235a91;
  font-size: 14px;

  &:hover {
    background-color: transparent;
  }

  @media(max-width: 991px) {
    padding: 15px 0px;
  }
`;

export const KeyboardArrowLeftStyled = styled(KeyboardArrowLeft)`
  font-size: 4rem;
  @media (min-width: 992px) {
    font-size: 1.8rem;
  }
`;

export const ContainerHeader = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-top: 0rem;
  }
`;

export const PageTitleContainer = styled('div')`
  margin-right: auto;
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  padding-top: 1rem;
  @media (min-width: 992px) {
    font-size: 25px;
    padding-top: 0;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 2.1rem;
  font-weight: 400;
  padding-bottom: 1.5rem;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-top: 0px;
  }
`;

export const GroupButtonStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilledButton = styled(IconButton)`
  display: flex;
  width: 18rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #235A91;
  color: #ffffff;
  border-radius: 8px;
  height: 75px;
  &:hover {
    background-color: #235A91;
  }
  &:disabled {
    color: #FFFFFF;
    background-color: #235A91;
    opacity: 0.6;
  }
  @media(min-width: 992px) {
    height: 47px;
    width: 12rem;
    padding: 0px 25px;
    border-radius: 8px;
  }
`;

export const OutlinedButton = styled(IconButton)`
  display: flex;
  width: 18rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: 1.5px solid #235A91;
  color: #235A91;
  border-radius: 8px;
  height: 75px;
 
  @media(min-width: 992px) {
    height: 47px;
    width: 10rem;
    padding: 0px 25px;
    border-radius: 8px;
  }
`;

export const FilledButtonText = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  @media (min-width: 992px) {
    font-size: 12px;
  }
`;

export const OutlinedButtonText = styled(Typography)`
  font-size: 28px;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 12px;
  }
`;

export const SyncBox = styled(Box)`
  display: flex;
  width: 7rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 8px;
  height: 7rem;
  border: 2px solid #E7E8E9;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }
  
  @media(min-width: 992px) {
    height: 47px;
    width: 47px;
    padding: 0px 25px;
    border: 1px solid #E7E8E9;
  }
`;

export const Sync = styled(SyncIcon)`
  width: 17px;
  height: 17px;

  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const DetailsCard = styled(Paper)`
  background: #FFFFFF;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.18);
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 25px;
  height: 450px;
  width: 100%;
`;

export const OrderDetailLogo = styled('img')`
  width: 130px;
  height: 120px;
  margin: 10px 0 5px 0;
  background-color: white;
`;

export const OrderDetailInfoWrapper = styled('div')`
  margin-top: 20px;
`;

export const OrderDetailText = styled('p')`
  margin: 0 0 1.3rem 0;
  font-weight: 700;
  font-size: 15px;
  color: #303030;
`;

export const OrderText = styled('span')`
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 14px;
  color: #303030;
  margin-left: 10px;

  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const DeliveryCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: 17px;
`;

export const DeliveryCardHeaderTitle = styled('p')`
  margin: 0;
  color: #303030;
  font-weight: 700;
  font-size: 18px;
`;

export const ActionBtn = styled('div')`
  width: 40px;
  height: 40px;
  background: #ECF6FF;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectOutletText = styled('p')`
  font-size: 16px;
  color: #606060;
  font-weight: 600;
  margin-top: 25px
`;

export const NoteContainer = styled('div')`
  padding-bottom: 3rem;
`;

export const NoteTextField = styled(TextField)`
  background-color: #F5F5F7;
  height: 55px;
  width: 100%;

  & .MuiOutlinedInput-root {
    &:hover fieldset: {
      borderColor: transparent;
    },
    &.Mui-focused fieldset {
      borderColor: transparent;
      outline: none;
    },
    & fieldset {
      border: none;
    },
  },


  label + .MuiInput-formControl {
    margin-top: 21px;
  }

  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }

  input, textarea {
    padding-left: 20px;
    background-color: #F5F5F7;
    color: #424242;
    font-size: 17px;
    padding-top: 10px;
  }
  & .MuiInputBase-multiline {
      padding: 0;
    }
  & .MuiInput-underline:before {
    border-bottom: none;
  }

  @media (max-width: 991px) {
    height: 80px;

    label + .MuiInput-formControl {
      margin-top: 23px;
    }

    label {
      font-size: 1.6rem;
    }

    input, textarea {
      font-size: 2rem;
    }
  }
`;

export const SaveNoteButton = styled(Button)`
  display: inline-block;
  background-color: #235A91;
  border-radius: 5px;
  color: #fff;
  padding: 15px 10px;
`;

export const ProductsCard = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 52px;
  padding: 40px 1.5rem;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.18);
  overflow:  scroll;

  @media (min-width: 992px) {
    margin-top: 20px;
    border-radius: 12px;
    padding: 30px 1.5rem;
  }
`;

export const Header = styled(TableCell)`
  font-weight: 700;
  font-size: 16px;
  color: #303030;
  white-space: nowrap;
  :first-child {
    width: 0%;
  }
`;

export const TableHeader = styled(TableHead)`
  display: table-header-group;
  background-color: #F5FAFF;
  border: 0;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MainTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`;

export const TableComponent = styled(Table)`
  margin-bottom: 1rem;
`;

export const DeliveryAddress = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
`;

export const Address = styled(Typography)`
  color: #606060;
  font-size: 16px;
  font-weight: 600;
  margin-top: 14px;
`;

export const MarkComplete = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const MarkAsComplete = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #235A91;
  padding: 6px;
`;
