import React, { useEffect } from 'react';
import MainContent from '../customComponents/mainContent';
import OverviewCard from './overviewCard';
import {
  GridContainer, GridWrapper, InnerContainer, TableTitle,
  Subtitle
} from './dashboard.styles';
import TopSellingProduct from './topSellingProduct/topSellingProduct';
import { useStateValue } from '../../providers/stateProvider';

const AdminDashboard = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  },);

  return (
    <MainContent style={{ backgroundColor: '#f7fafe' }}>
      <GridWrapper>
        <InnerContainer>
          <GridContainer container>
            <TableTitle>Dashboard</TableTitle>
            <Subtitle>See Overview and Analytics</Subtitle>
            <OverviewCard />
          </GridContainer>
          <TopSellingProduct />
        </InnerContainer>
      </GridWrapper>
    </MainContent>
  );
};

export default AdminDashboard;
