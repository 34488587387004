import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  ProductCardsContainer, ProductCardGrid, SimilarProductsBox, NextImage, TextHeader, Loading, PrevImage, HeaderContainer, ButtonWrapper
} from './similarProducts.styles';
import { RECOMMENDED_PRODUCTS, CART_RECOMMENDED_PRODUCTS } from '../../../queries/products';
import ProductCard from '../../orders/marketplaceOrder/productCard';

const SimilarProducts = ({
  searchProductId,
  cart,
  refetchCart,
}) => {
  const [similarProduct, setSimilarProducts] = useState();
  const carouselRef = useRef();

  const handleAlertOpen = (msg) => toast(msg);

  const variables = {
    productId: Number(searchProductId),
    pageCount: 10
  };

  const scrollToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
    }
  };

  const scrollToPrevious = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
    }
  };
  const { data, loading, refetch } = useQuery(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const { data: cartData } = useQuery(CART_RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if ((data && data?.recommendedProducts) || cartData?.cartRecommendedProducts) {
      const products = cart ? cartData?.cartRecommendedProducts : data?.recommendedProducts;
      setSimilarProducts(products);
    }
  }, [data, cartData]);

  const buttonColor = carouselRef ? '#DEEFFF' : '#235A91';

  return (
    <SimilarProductsBox>
      <HeaderContainer container>
        <TextHeader>Recommended Products</TextHeader>
        <ButtonWrapper>
          <PrevImage
            onClick={() => scrollToPrevious()}
            color={buttonColor}
          />
          <NextImage
            onClick={() => scrollToNext()}
            color={buttonColor}
          />
        </ButtonWrapper>
      </HeaderContainer>
      <ProductCardsContainer ref={carouselRef} spacing={4}>
        {similarProduct?.length
          ? similarProduct.map((product, index) => (
            <ProductCardGrid key={product.id} md={3}>
              <ProductCard
                product={product}
                openAlert={handleAlertOpen}
                isSimilarProduct={similarProduct?.length}
                cart={cart}
                index={index}
                refetch={refetch}
                refetchCart={refetchCart}
                // similarAction={similarAction}
              />
            </ProductCardGrid>
          )) : (loading
            ? <Loading>Loading...</Loading>
            : <Loading>No similar product</Loading>
          )}
      </ProductCardsContainer>
    </SimilarProductsBox>
  );
};

SimilarProducts.propTypes = {
  searchProductId: PropTypes.instanceOf(Object),
  cart: PropTypes.bool,
  refetchCart: PropTypes.func
};

SimilarProducts.defaultProps = {
  searchProductId: [],
  cart: false,
  refetchCart: () => {}
};

export default SimilarProducts;
