import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Grid, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { UPDATE_BATCH_MUTATION } from '../../mutations/products';
import {
  CDialog, ButtonDarkStyled, HeaderStyled, ButtonOutlineStyled, FooterStyled,
  DialogTitleStyled, GridStyled, DialogContentStyled
} from './updateBatchDialog.styles';
import FormTemplate from '../shared/fieldTemplate/template';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const initialState = {
  batchNo: '',
  quantity: 0,
  dateReceived: defaultReceived,
  expiryDate: defaultExp,
  brandName: ''
};
const UpdateBatchDialog = ({
  open, onClose, batchToEdit, setSuccessOpenDialog
}) => {
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (batchToEdit) {
      const {
        batchNo: bN, dateReceived: dR, expiryDate: eD, product,
        quantityInStock
      } = batchToEdit;
      const _dR = moment(dR).format('YYYY-MM-DD');
      const _eD = moment(eD).format('YYYY-MM-DD');
      setState({
        ...state, batchNo: bN, quantity: quantityInStock, dateReceived: _dR, expiryDate: _eD,
        brandName: product?.brandName
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchToEdit]);

  const {
    batchNo, quantity, dateReceived, expiryDate, brandName
  } = state;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    onClose();
  };

  const validateFields = () => {
    if (quantity === 0 || quantity === '' || expiryDate === '') {
      return true;
    }
    return false;
  };

  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);
  const handleEditBatch = () => {
    if (validateFields()) return toast.error('Kindly validate all input fields');
    setIsLoading(true);
    const { id: batchId } = batchToEdit;
    updateBatch({
      variables: {
        batchId,
        expiryDate,
        quantity: parseFloat(quantity),
      }
    }).then(() => {
      setIsLoading(false);
      handleClose();
      setSuccessOpenDialog(true);
    }).catch((err) => toast.error(err?.message));
  };

  const batchNameField = {
    type: 'text', name: 'batchNo', label: 'Batch No', 'data-testid': 'batchNo', value: batchNo,
    placeholder: 'Enter Batch No', onChange: handleChange, disabled: true
  };
  const quantityField = {
    type: 'number', name: 'quantity', label: 'Quantity', 'data-testid': 'quantity', value: quantity,
    placeholder: 'Enter Quantity', onChange: handleChange
  };
  const dateReceivedField = {
    type: 'date', name: 'dateReceived', label: 'Date Received', 'data-testid': 'dateReceived', value: dateReceived,
    placeholder: 'Enter Date Received', onChange: handleChange, disabled: true
  };
  const expireDateField = {
    type: 'date', name: 'expiryDate', label: 'Expire Date', 'data-testid': 'expiryDate', value: expiryDate,
    placeholder: 'Enter Expire Date', onChange: handleChange
  };

  return (
    <CDialog
      open={open}
      data-testid="singleBatchDialog"
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <HeaderStyled>
          <Box style={{ textAlign: 'right' }}>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <DialogTitleStyled>{brandName}</DialogTitleStyled>
        </HeaderStyled>
        <GridStyled item container xl={12} spacing={3}>
          <Grid item xl={6}>
            <FormTemplate fields={batchNameField} />
          </Grid>
          <Grid item xl={6}>
            <FormTemplate fields={quantityField} />
          </Grid>
        </GridStyled>
        <GridStyled item container spacing={3}>
          <Grid item xl={6}>
            <FormTemplate fields={dateReceivedField} />
          </Grid>
          <Grid item xl={6}>
            <FormTemplate fields={expireDateField} />
          </Grid>
        </GridStyled>
        <FooterStyled>
          <ButtonOutlineStyled onClick={handleClose}>
            Cancel
          </ButtonOutlineStyled>
          <ButtonDarkStyled data-testid="batch_button" disabled={isLoading} onClick={handleEditBatch}>
            Update
          </ButtonDarkStyled>
        </FooterStyled>
      </DialogContentStyled>
    </CDialog>
  );
};

UpdateBatchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  batchToEdit: PropTypes.instanceOf(Object).isRequired,
  setSuccessOpenDialog: PropTypes.func.isRequired,
};

UpdateBatchDialog.defaultProps = {
  onClose: () => { },
};

export default UpdateBatchDialog;
