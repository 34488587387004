import { styled } from '@mui/material/styles';
import { TableCell, Grid, TableRow } from '@mui/material';

export const TableColumnRows = styled(TableRow)`
  width: 100%;
  border: 0;
`;

export const TableGrid = styled(Grid)`
  margin:  1.5rem;
  overflow-x: auto;
`;

export const TableComponent = styled('div')`
  width: 100%;
`;

export const TableColumnHeaders = styled(TableRow)`
  border: 0;
`;

export const TableHeader = styled(TableCell)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26.27px;
  white-space: nowrap;
  background: #F3F9FF;
  padding: 16px 10px;
  border: 0;
  /**text-transform: capitalize;**/

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;

  @media(min-width: 992px) {
    font-size: 16px;
  }
`;
