import React from 'react';
import {
  Slide, Button, Table, TableBody
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  TableComponent, TableColumnHeaders, TableHeader, TableColumnRows, TableColumnRowText
} from '../shared/tableGlobalStyle.styles';
import {
  DialogContentStyled,
  ContentHeader,
  ActionStyled,
  HeaderText,
  ActionButton,
  FooterStyled,
  DialogStyled,
  CloseStyled
} from './batchDialog.style';
import { DeleteIcon, EditIcon } from '../../assets/svgs';
import { WHOLESALER_BATCH } from '../../mutations/orders';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const headers = [
  { name: 'Batch No.', width: '120px' }, { name: 'Qty Available', width: '120px' }, { name: 'Expiry Date', width: '120px' },
  { name: 'Action', width: '200px' }
];

const AllBatchDialog = ({
  modalStatus, modalHandler, product, singleBatchItem, setSingleBatchDetails, refetch, handleOpenCreateBatch, productId
}) => {
  const { name: brandName } = product || {};

  const batches = singleBatchItem;

  const editBatchHandler = (batchNumber) => {
    setSingleBatchDetails({ batchNumber, batches });
    handleOpenCreateBatch(true);
    modalHandler();
  };

  const handleAddBatch = () => {
    handleOpenCreateBatch(false);
  };
  const [deleteBatch] = useMutation(WHOLESALER_BATCH);

  const handleDelet = (batchNo) => {
    deleteBatch({
      variables: {
        batchNo,
        orderProductId: +productId,
        action: 'REMOVE'
      }
    }).then(({ data: { manageBatchOrderProduct: { message } } }) => {
      toast.success(message);
      if (refetch) refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  return (
    <DialogStyled
      open={modalStatus}
      TransitionComponent={Transition}
      keepMounted
      onClose={modalHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <ContentHeader>
          <HeaderText>{brandName}</HeaderText>
          <CloseStyled onClick={modalHandler} />
        </ContentHeader>
        <TableComponent>
          <Table>
            <TableColumnHeaders>
              {returnHeaders()}
            </TableColumnHeaders>
            <TableBody>
              {
              singleBatchItem?.length
                ? singleBatchItem?.map((batchItem) => {
                  const { batch_no: batchNo, quantity, expiry_date: expiryDate } = batchItem;
                  return (
                    <TableColumnRows key={batchNo}>
                      <TableColumnRowText>{batchNo}</TableColumnRowText>
                      <TableColumnRowText>{quantity}</TableColumnRowText>
                      <TableColumnRowText>{moment(expiryDate).format('DD/MM/YY')}</TableColumnRowText>
                      <TableColumnRowText>
                        <ActionStyled>
                          <ActionButton onClick={() => editBatchHandler(batchNo)}>
                            <EditIcon />
                            Edit
                          </ActionButton>
                          <ActionButton onClick={() => handleDelet(batchNo)}>
                            <DeleteIcon />
                            Delete
                          </ActionButton>
                        </ActionStyled>
                      </TableColumnRowText>
                    </TableColumnRows>
                  );
                })
                : (
                  <TableColumnRows>
                    <TableColumnRowText colSpan={4} style={{ textAlign: 'center' }}>No record found</TableColumnRowText>
                  </TableColumnRows>
                )
            }
            </TableBody>
          </Table>
        </TableComponent>
        <FooterStyled>
          <Button
            variant="outlined"
            onClick={modalHandler}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAddBatch}>
            Add batch
          </Button>
        </FooterStyled>
      </DialogContentStyled>

    </DialogStyled>
  );
};

AllBatchDialog.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  singleBatchItem: PropTypes.instanceOf(Object).isRequired,
  modalStatus: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func,
  handleOpenCreateBatch: PropTypes.func.isRequired,
  setSingleBatchDetails: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired
};

AllBatchDialog.defaultProps = {
  modalHandler: () => {},
};

export default AllBatchDialog;
