import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, Table, Box
} from '@mui/material';
import {
  TableContainerWrapper, TableComponent, Header, FooterGrid,
  MainTableHead, TableGrid
} from './orders.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../../shared/tablePagination';
import CustomToolTip from '../../../shared/toolTip';
import { JSONParse } from '../../../../utils/json';

const buyerHeaders = [
  { name: 'Created Date' },
  { name: 'Customer’s Name' },
  { name: 'Account Type' },
  { name: 'Total Order Value' },
  { name: 'Sorted Value', toolTipDesc: 'Sorted value refers to the adjusted total of an order after considering product availability. When certain items are unavailable or quantities are limited, the order is rearranged to prioritize fulfillment based on what can be provided. This reflects the sorted value rather than the original order value.' }
];

const sellerHeaders = [
  { name: 'Created Date' },
  { name: 'Customer' },
  { name: 'Sellers' },
  { name: 'Account Type' },
  { name: 'Total Order Value' },
  { name: 'Sorted Value', toolTipDesc: 'Sorted value refers to the adjusted total of an order after considering product availability. When certain items are unavailable or quantities are limited, the order is rearranged to prioritize fulfillment based on what can be provided. This reflects the sorted value rather than the original order value.' },
  { name: 'Order Status' }
];

const Orders = ({
  data, loading, error, activeTab,
  pageCount, pageNumber, setPageCount, setPageNumber
}) => {
  const headers = activeTab === 'sellers' ? sellerHeaders : buyerHeaders;

  const returnHeaders = () => headers.map((header) => {
    const { name, toolTipDesc } = header;
    return (
      <Header key={name}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {name}
          { toolTipDesc && <CustomToolTip title={name} description={toolTipDesc} /> }
        </Box>
      </Header>
    );
  });

  const businessOrders = data?.allOrders || [];
  const totalCount = data?.allOrdersTotalNumber || 0;

  const orderItems = businessOrders?.map((items) => {
    const {
      business: { name: businessName, businessType, id: businessId }, paymentSummary, id, meta,
      metaStatus, status, dateCreated, order, total, datePlaced, sortedValue
    } = items;
    const { total_amount: orderTotal, savings: orderSavings, main_total: mainOrderTotal } = JSONParse(paymentSummary);

    return {
      businessName, dateCreated, orderTotal, orderSavings, id, metaStatus, status, businessType, mainOrderTotal, order, total, meta, datePlaced, sortedValue, businessId
    };
  });

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <TableContainerWrapper>
        <TableGrid item container>
          {loading ? <ProductsTableLoader /> : (
            <TableComponent item container aria-label="Orders table">
              <Table style={{ width: '100%' }} data-testid="mp-uat-manageOrders">
                <MainTableHead item container>
                  {returnHeaders()}
                </MainTableHead>
                {orderItems && (
                  <TableBody>
                    {orderItems?.map((order, index) => (
                      <ReturnRow
                        key={order?.id}
                        row={order}
                        index={index}
                        tab={activeTab}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableComponent>
          )}
        </TableGrid>
      </TableContainerWrapper>
      <FooterGrid item container>
        <TablePagination
          total={totalCount}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </>
  );
};
Orders.propTypes = {
  data: PropTypes.instanceOf(Object),
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
};

Orders.defaultProps = {
  data: {},
  loading: false,
};

export default Orders;
