import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TablePagination from '../../shared/tablePagination';
import { ERP_PRODUCT_QUERY } from '../../../queries/products';
import {
  TableContainerWrapper, CustomCheckbox, DivFullWidth
} from './products.styles';
import ReturnRow from './returnRow';
import ProductsFilter from './productsFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { TableComponent, TableColumnHeaders, TableHeader } from '../../shared/tableGlobalStyle.styles';
import useSearchParamsState from '../../shared/helpers/ulrSearchParams';
import { ARCHIVE_PRODUCTS_MUTATION, UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';

const erpHeaders = [
  { name: 'Created Date', width: '200px' }, { name: 'SKU', width: '150px' }, { name: 'Product Name', width: '200px' },
  { name: 'Qty', width: '100px' }, { name: 'Price', width: '150px' }, { name: 'Status', width: '150px' }, { name: 'Action', width: '100px' },
];

const Products = ({
  setProductState, setSelected, selected, setProductQuantities
}) => {
  const { status: pStatus, sku: pSku, productName: pProductName } = useParams();

  const initialState = {
    searchText: '',
    searchValue: '',
    productName: '',
    status: pStatus || 'enabled',
    sku: '',
    dateFrom: '',
    dateTo: '',
    filters: { status: pStatus || 'enabled' }
  };

  const [pageNumber, setPageNumber] = useSearchParamsState('pn', '1');
  const [pageCount, setPageCount] = useSearchParamsState('pc', '10');
  const [filters, setFilters] = useSearchParamsState('filters', pStatus || 'enabled');

  const [state, setState] = useState(initialState);
  const [stateRows, setStateRows] = useState([]);
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);
  const [archiveProduct] = useMutation(ARCHIVE_PRODUCTS_MUTATION);

  const vals = filters.split(',');

  useEffect(() => {
    const filtersObj = {
      ...state, productName: pProductName, sku: pSku,
      filters: { status: pStatus || 'enabled', sku: pSku, productName: pProductName }
    };
    setState(filtersObj);
    setProductState(filtersObj);
  }, [pProductName]);

  useEffect(() => {
    setState((s) => ({
      ...s, status: vals[0] || '', productName: vals[1] || '', sku: vals[2] || '',
      dateFrom: vals[3] || '', dateTo: vals[4] || ''
    }));
  }, [filters]);

  const [{
    user,
  }, dispatch] = Object.values(useStateValue());

  const {
    productName, sku, dateFrom,
    dateTo, status
  } = state;

  useEffect(() => {
    setProductState(state);
  }, [dateFrom, dateTo]);

  const handleSetFilter = () => {
    const values = `${status},${productName},${sku},${dateFrom},${dateTo}`;
    setFilters(values);
    const filter = {
      ...state,
      filters: {
        status, productName, sku, dateFrom, dateTo
      }
    };
    setState(filter);
    setProductState(filter);
  };
  useEffect(() => {
    const prodStatus = status || vals[0] || '';
    const prodName = productName || vals[1] || '';
    const prodSku = sku || vals[2] || '';
    const prodDateFrom = dateFrom || vals[3] || '';
    const prodDateTo = dateTo || vals[4] || '';
    const values = `${prodStatus},${prodName},${prodSku},${prodDateFrom},${prodDateTo}`;
    setFilters(values);
    const filter = {
      ...state,
      filters: {
        status, productName, sku, dateFrom, dateTo
      }
    };
    setState(filter);
    setProductState(filter);
  }, [status, productName, sku, dateFrom, dateTo])

  const editProduct = (row) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: row }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
    />
  );

  const returnHeaders = () => erpHeaders.map(({ name, width }) => (
    <TableHeader key={name} data-testid={`pure-mp-uat-products-${name.replace(/ /g, '-')}`} className={`pure-mp-uat-${name.replace(/ /g, '-')}`} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const updateProductState = (products, totalCount, refetch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { products, totalCount, refetch }
    });
  };

  const variables = {
    search: '',
    pageCount: Number(pageCount),
    pageNumber: Number(pageNumber),
    status: vals[0],
    ...(vals[1]?.length >= 3 ? { productName: vals[1] } : { productName: '' }),
    ...(vals[2]?.length >= 3 ? { sku: vals[2] } : { sku: '' }),
    dateFrom: vals[3],
    dateTo: vals[4],
  };

  const {
    loading, error, data, refetch
  } = useQuery(ERP_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const updateProductStatusHandler = (type) => {
    if (!selected?.length) return toast.error('Select a Product');
    updateProductStatus({
      variables: {
        productIds: [...selected],
        status: type
      }
    })
      .then(({ data: resData }) => {
        const { message } = resData?.updateProductStatus || {};
        toast.success(message);
        setSelected([]);
        refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
    setProductState((_state) => ({
      ..._state,
      [name]: value
    }));
    if (name === 'changeStatus') {
      updateProductStatusHandler(value);
    }
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    updateProductStatusHandler(value);
  };

  const handleDeleteProduct = () => {
    if (!selected?.length) return toast.error('Select a Product');
    archiveProduct({
      variables: {
        productIds: [...selected],
      }
    })
      .then(({ data: resData }) => {
        const { message } = resData?.archiveProduct || {};
        toast.success(message);
        refetch();
        setSelected([]);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    if (data && data.products) {
      const { products, productsTotalNumber } = data;
      updateProductState(products, productsTotalNumber, refetch);
      setStateRows(products);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    productsTotalNumber = 0
  } = data || {};

  const updateProductQuantities = (id, newQuantity) => {
    setProductQuantities((prevQuantities) => {
      const productIndex = prevQuantities.findIndex((item) => Number(item.productId) === Number(id));
      if (productIndex !== -1) {
        const updatedQuantities = [...prevQuantities];
        updatedQuantities[productIndex].quantity = newQuantity;
        return updatedQuantities;
      }
      return [...prevQuantities, { productId: Number(id), quantity: newQuantity }];
    });
  };

  const counterHandler = (type, row, val) => {
    const { id, quantityInStock } = row;
    switch (type) {
      case 'increment': {
        setStateRows((prevState) => prevState.map((rowItem) => {
          if (rowItem.id === id) {
            const newQuantity = Number(quantityInStock) + 1;
            updateProductQuantities(id, newQuantity);
            return { ...rowItem, quantityInStock: newQuantity, quantity: newQuantity };
          }
          return rowItem;
        }));

        break;
      }
      case 'decrement': {
        setStateRows((prevState) => prevState.map((rowItem) => {
          if (rowItem.id === id) {
            const newQuantity = Number(quantityInStock) - 1;
            updateProductQuantities(id, newQuantity);
            return newQuantity < 0 ? rowItem : { ...rowItem, quantityInStock: newQuantity, quantity: newQuantity };
          }
          return rowItem;
        }));

        break;
      }
      case 'change': {
        setStateRows((prevState) => prevState.map((rowItem) => {
          if (rowItem.id === id) {
            const newQuantity = Number(val);
            updateProductQuantities(id, newQuantity);
            return newQuantity < 0 ? rowItem : { ...rowItem, quantityInStock: newQuantity, quantity: newQuantity };
          }
          return rowItem;
        }));

        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <ProductsFilter
        state={state}
        setState={setState}
        selected={selected}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        handleDeleteProduct={handleDeleteProduct}
        handleStatusChange={handleStatusChange}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders data-testid="pure-mp-uat-product-table-header" className="pure-mp-uat-product-table-header">
                <TableHeader style={{ minWidth: '50px' }}>
                  {renderCheckbox()}
                </TableHeader>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {stateRows?.map((_row) => (
                  <ReturnRow
                    key={_row.id}
                    row={_row}
                    user={user}
                    selected={selected}
                    handleSelect={handleSelect}
                    editProduct={editProduct}
                    refetchData={refetch}
                    counterHandler={counterHandler}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainerWrapper>
      <DivFullWidth>
        {productsTotalNumber > 0 && (
          <TablePagination
            total={productsTotalNumber}
            pageCount={+pageCount}
            setPageCount={setPageCount}
            pageNumber={+pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </DivFullWidth>
    </>
  );
};

Products.propTypes = {
  setProductState: PropTypes.instanceOf(Object).isRequired,
  setSelected: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
  setProductQuantities: PropTypes.instanceOf(Array).isRequired,
};

export default Products;
