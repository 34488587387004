import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, Slide, Grid, Typography, Box, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  CDialog, DialogTitleText, DialogTitleSubText, CircularProgressLoader,
  GridWrapper, CloseIcon, AccordionDetailsWrapper, AccordionContainer, Header
} from './createRoleDialog.styles';

import { useStateValue } from '../../../../providers/stateProvider';
import CustomInputBase from '../../../customComponents/customInputBase';
import ConfirmDialog from '../confirmDialog';
import { CREATE_BUSINESS_ROLE, UPDATE_BUSINESS_ROLE } from '../../../../mutations/business';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateRoleDialog = ({
  dialogOpen, closeDialog, rolePermissions, isEdit, name, refetch, id: roleId
}) => {
  const initialState = { };

  const [, setState] = useState(initialState);
  const [checkedItems, setCheckedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [{
    user: { platform }
  },] = Object.values(useStateValue());

  const [createSystemRole] = useMutation(CREATE_BUSINESS_ROLE);
  const [editSystemRole] = useMutation(UPDATE_BUSINESS_ROLE);
  const isErp = platform === 'erp';

  useEffect(() => {
    if (isEdit) {
      setEditing(true);
      const perm = rolePermissions.map(({ name: pemName }) => pemName);
      setRoleName(name);
      setCheckedItems([...perm]);
    }
  }, [isEdit]);

  const handleCloseDialog = () => {
    // if (editing) setEditing(false);
    setState(initialState);
    closeDialog();
  };

  const toggleConfirmation = () => {
    setOpen(!open);
  };
  const handleRoleName = (event) => {
    const { value } = event.target;
    setRoleName(value);
  };
  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItems((prevState) => [...prevState, value]);
    } else {
      setCheckedItems((prevState) => prevState.filter((item) => item !== value));
    }
  };

  const convertStr = (str) => {
    const result = str.replace(/_+/g, ' ').toLowerCase().concat(' ');
    const arr = result.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  };

  const handleEdit = () => {
    if (!roleName.length) return setError(true);
    setLoading(true);
    const variables = {
      permissionNames: checkedItems,
      updatedName: roleName,
      roleId
    };
    editSystemRole({ variables })
      .then(({ data }) => {
        const { message } = data?.createSystemRole || {};
        toast.success(message);
        handleCloseDialog();
        toggleConfirmation();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
        setCheckedItems([]);
        setRoleName('');
      });
  };

  const handleSave = () => {
    if (!roleName.length) return setError(true);
    setLoading(true);
    const variables = {
      permissionNames: checkedItems,
      name: roleName
    };
    createSystemRole({ variables })
      .then(({ data }) => {
        const { message } = data?.createSystemRole || {};
        toast.success(message);
        handleCloseDialog();
        toggleConfirmation();
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
        setCheckedItems([]);
        setRoleName('');
      });
  };

  const handleSubmit = () => {
    if (isEdit) {
      handleEdit();
    } else {
      handleSave();
    }
  };

  const erpRoles = [
    { name: 'pricing_categories', permissions: ['view_pricing_categories', 'add_category'] },
    { name: 'affiliate_dashboard', permissions: ['view_affiliate_dashboard', 'receipt', 'return'] },
    { name: 'returns', permissions: ['view_returns'] },
    { name: 'sc_dashboard', permissions: ['view_sc_dashboard', 'set_filter'] },
    { name: 'customers', permissions: ['view_customers', 'add_customer', 'download'] },
    { name: 'suppliers', permissions: ['view_suppliers', 'create_new_supplier', 'download_CSV'] },
    { name: 'inventory', permissions: ['view_inventory', 'set_filter', 'bulk_buy', 'add_batches', 'export', 'add_products', 'add_to_cart', 'disable', 'edit', 'open_orders', 'create_new_order', 'approve_order', 'receive_order', 'close_order'] },
    { name: 'sales', permissions: ['view_sales', 'create_batches', 'create_product', 'view_product', 'edit_product', 'disable', 'discount', 'add_batches', 'make_sale', 'export_all_products'] },
    { name: 'stock_transfer', permissions: ['view_stock_transfer', 'received_transfer', 'view_stock_transfer_logs', ' multi_transfers'] },
    { name: 'stock_count_report', permissions: ['view_stock_count_report', 'create_new_report', 'edit_report', 'export'] },
    { name: 'batch_details', permissions: ['view_batch_details', 'set_filter', 'edit_batch', 'update_batch', 'view_batch_info', 'delete_batch'] },
    { name: 'product_type', permissions: ['view_product_type', 'add_product_type'] },
    { name: 'purchase_order', permissions: ['close_purchase_order', 'cancel_and_replace'] },
    { name: 'role_permissions', permissions: ['view_role_permissions', 'create_new_role', 'view_users', 'add_user', 'view_user', 'delete_role', 'change_role', 'edit_permissions'] },
    { name: 'cm_dashboard', permissions: ['view_cm_dashboard', 'search', 'date_filter', 'switch_stores'] },
    { name: 'promotions', permissions: ['view_promotions', 'create_promo', 'view_actions'] }
  ];
  const mpRoles = [
    { name: 'dashboard', permissions: ['view_dashboard', 'set_filter'] },
    { name: 'manage_orders', permissions: ['view_manage_orders', 'set_filter', 'export', 'dispatch_order', 'create_new_order', 'complete_sorting', 'ready_for_dispatch', 'dispatch', 'approve_order', 'disapprove_order', 'cancel_order', 'view_cancel_order'] },
    { name: 'manage_affiliates', permissions: ['view_manage_affiliates', 'set_filter', 'upload_files', 'edit', 'save'] },
    { name: 'role_permissions', permissions: ['view_role_permissions', 'create_new_role', 'view_users', 'add_user', 'view_user', 'delete_role', 'change_role', 'edit_permissions'] },
    { name: 'payment', permissions: ['view_payment', 'set_filter'] }
  ];
  const roles = isErp ? erpRoles : mpRoles;
  return (
    <>
      <CDialog
        data-testid="product-dialog"
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item xs={6}>
              <DialogTitleText container item xs={12}>
                {editing ? 'Edit Role' : 'Create Role'}
              </DialogTitleText>
              <DialogTitleSubText container item xs={12}>
                You can manage users access here
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <Grid container>
            <CustomInputBase
              label="Name Of Role"
              value={roleName}
              size="small"
              type="text"
              helperText="Role name is required"
              name="roleName"
              onChange={handleRoleName}
              placeholder="Enter Role Name"
              cSize="lg"
              error={error}
            />
          </Grid>
          <DialogContent>
            { roles.map(({ name: rName, permissions }) => (
              <AccordionContainer key={name}>
                <Header
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {convertStr(rName)}
                </Header>
                <AccordionDetailsWrapper>
                  {permissions?.map((permission) => (
                    <Box display="flex" alignItems="center" mt={2}>
                      <Checkbox
                        value={`${rName}_${permission}`}
                        onChange={handleChange}
                        checked={checkedItems.includes(`${rName}_${permission}`)}
                      />
                      <Typography style={{ whiteSpace: 'nowrap' }}>{convertStr(permission)}</Typography>
                    </Box>
                  ))}
                </AccordionDetailsWrapper>
              </AccordionContainer>
            ))}
          </DialogContent>

          <DialogActions>
            <CustomButton
              type="tertiary"
              header
              style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              header
              style={{ width: '11rem', height: '2.7rem' }}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Edit Role' : 'Create Role'}
            </CustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>
      <ConfirmDialog
        openConfirmation={open}
        handleClose={toggleConfirmation}
        title={isEdit ? 'Roles and Permission edited successfully!' : 'Roles and Permission created successfully!'}
        discreption={isEdit ? 'Hi Pharm, Roles and Permission has been successfully edited' : 'Hi Pharm, Roles and Permission has been successfully Created'}
        buttonTyle="single"
        imgType="success"
      />
    </>
  );
};

CreateRoleDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  // businessState: PropTypes.instanceOf(Object),
};

CreateRoleDialog.defaultProps = {
  // businessState: {},
};

export default CreateRoleDialog;
