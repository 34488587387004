import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DialogContainer, CardTitle, DialogContentStyled, TitleDesc,
  IdImage,
} from './displayId.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function DisplayId({
  open, close, title, desc, url, secUrl
}) {
  return (
    <DialogContainer
      data-testid="product-dialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>{title}</CardTitle>
              <Close
                fontSize="small"
                onClick={close}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>{desc}</TitleDesc>
            <IdImage src={url} alt={title} />
            {secUrl && <IdImage src={secUrl} alt={title} />}
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
}

DisplayId.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  secUrl: PropTypes.string.isRequired,
};

DisplayId.defaultProps = {
  open: false,
};
