import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableRow, TableBody, TableHead
} from '@mui/material';
import {
  TableBox, MainTableRow, BodyCell, HeaderCell, ProductTypo, Content
} from './productInfo.styles';

export default function ProductInfo({
  product, businessUser
}) {
  const {
    qpc, sku, meta, business, expiryDate
  } = product;
  const obj = JSON.parse(meta);
  const { pack_size: packSize } = obj;
  const { name } = business;

  const headers = businessUser === 'RETAILER'
    ? ['SKU', 'Qty/Ctn', 'Pack Size', 'Category', 'UOM', 'Expiry Date']
    : ['SKU', 'Qty/Ctn', 'Pack Size', 'Category', 'UOM', 'Manufacturer', 'Expiry Date'];

  return (
    <Content>
      <ProductTypo>Product Information</ProductTypo>
      <TableBox>
        <TableContainer>
          <Table aria-label="payments table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <HeaderCell key={header}>{header}</HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MainTableRow>
                <BodyCell>
                  {sku}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {packSize || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                {businessUser !== 'RETAILER' && <BodyCell>{name || 'N/A'}</BodyCell>}
                <BodyCell>
                  {expiryDate ? moment(expiryDate).format('MMM DD, YYYY') : 'N/A'}
                </BodyCell>
              </MainTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox>
    </Content>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.instanceOf(Object),
  businessUser: PropTypes.string
};

ProductInfo.defaultProps = {
  product: {},
  businessUser: ''
};
