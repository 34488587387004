import { styled } from '@mui/material/styles';
import { Stepper, StepLabel } from '@mui/material';
import { CheckCircleOutline, Circle } from '@mui/icons-material';

export const StepperStyled = styled('div')`
  width: 100%;
  background-color: #fff;
  border: 0;
  padding: 25px;
  border-radius: 16px;
`;

export const MUIStepper = styled(Stepper)`
  & .MuiStep-root {
    padding: 0px;
  }

  & .MuiStepConnector-line {
    min-height: 3px;
    background: #07DE10;
    border: none;
    outline: none;
  }
`;

export const MUIStepLabel = styled(StepLabel)`
  & .MuiStepLabel-iconContainer {
    padding: 0px;
  }

  & .MuiStepIcon-root {
    background: #2ECC71;
    border-radius: 50%;
  }
`;

export const CircleStyled = styled(Circle)`
    color: #2ECC71;
    width: 1.7rem;
    height: 1.7rem;
`;

export const CheckCircleStyled = styled(CheckCircleOutline)`
    color: #2ECC71;
    width: 1.7rem;
    height: 1.7rem;
`;
