import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';

export default function ActionPopper({
  action, handleAction, handleClose
}) {
  const open = Boolean(action);

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="bottom"
      onClickAway={handleClose}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, '')}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              All Price
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'MIN')}
        >
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              0-5,000
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer container item onClick={(e) => handleAction(e, 'MAX')}>
          <ItemWrapper>
            <ItemTypo variant="subtitle1">
              5,001 - 10,000 & Above
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};
