import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Paper, useMediaQuery } from '@mui/material';
import {
  DetailGrid, DetailContentGrid, ImgLogo, DetailHeader, DetailTypo, QuantityGrid,
  ActionButton, ActionTextField, Content, AddButton, AddCartTypo, CircularProgressLoader,
  ShoppingCartImg, Typo, QuantityContentGrid, InputButtonGroup, ContentGrid, MinusIcon, PlusIcon
} from './productDetail.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import { ADD_TO_CART_MUTATION } from '../../../../mutations/orders';
import CartActionTypes from '../../../../providers/reducers/cart/cartTypes';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { JSONParse } from '../../../../utils/json';

export default function ProductDetail({
  product
}) {
  const isSmall = useMediaQuery('(max-width:991px)');
  const {
    id, brandName, marketSellingPrice, molecule, meta, quantityInStock
  } = product;

  const obj = JSON.parse(meta);
  const { pack_size: packSize, description, image } = obj;
  const [, dispatch] = Object.values(useStateValue());
  const [counter, setCounter] = useState(0);

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (value > quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');
    setCounter(value);
  };

  const [updateOrder, { loading }] = useMutation(ADD_TO_CART_MUTATION);
  const handleAlertOpen = (msg) => toast(msg);

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        cart: [
          { productId: Number(id), quantity: counter }
        ]
      }
    })
      .then(({ data: response }) => {
        const { message, order: { orderProducts } } = response?.updateCart || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: orderProducts?.length
        });
        dispatch({
          type: CartActionTypes.UPDATE_CART_ITEMS,
          payload: orderProducts
        });
        handleAlertOpen(message);
      });
  };
  const moleculeStr = molecule ? JSONParse(molecule).toString().replaceAll(',', ', ') : '';

  return (
    <DetailGrid component={Paper} container>
      <DetailContentGrid item md={12} lg={6}>
        <ImgLogo src={image} alt={brandName} />
      </DetailContentGrid>
      <ContentGrid item md={12} lg={6}>
        <Content>
          <DetailHeader> Molecules </DetailHeader>
          <DetailTypo>
            {moleculeStr || 'N/A'}
          </DetailTypo>
          <DetailHeader> Description </DetailHeader>
          <DetailTypo>
            {description}
          </DetailTypo>
          <DetailHeader> Pack Size </DetailHeader>
          <DetailTypo>
            {packSize}
          </DetailTypo>

          <QuantityGrid xs={12} container justifyContent="space-between">
            <QuantityContentGrid item xs={4}>
              <DetailHeader>Quantity</DetailHeader>
              <InputButtonGroup size="small" aria-label="small button group">
                <ActionButton
                  onClick={() => handleActionButtons(-1)}
                  disabled={counter === 0}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <MinusIcon />
                </ActionButton>
                <ActionTextField
                  InputProps={{ disableUnderline: true, style: { fontSize: isSmall && '2rem' } }}
                  variant="standard"
                  placeholder={0}
                  value={counter || ''}
                  onChange={(e) => handleChange(Number(e.target.value))}
                />
                <ActionButton
                  onClick={() => handleActionButtons(1)}
                >
                  <PlusIcon />
                </ActionButton>
              </InputButtonGroup>
            </QuantityContentGrid>
            <QuantityContentGrid item xs={4}>
              <DetailHeader>Price</DetailHeader>
              <Typo>
                {`${currencyFormatter(marketSellingPrice)}`}
              </Typo>
            </QuantityContentGrid>
          </QuantityGrid>
          <AddButton
            fullWidth
            startIcon={<ShoppingCartImg />}
            className="joy-add-to-cart"
            variant="outlined"
            disableElevation
            onClick={handleAddToCart}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={24}
                thickness={4}
              />
            ) : <AddCartTypo> Add to cart </AddCartTypo>}
          </AddButton>
        </Content>
      </ContentGrid>
    </DetailGrid>
  );
}

ProductDetail.propTypes = {
  product: PropTypes.instanceOf(Object),
};

ProductDetail.defaultProps = {
  product: {},
};
