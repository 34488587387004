import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TableRow,
  TableBody,
  TableContainer,
  Box,
  Table
} from '@mui/material';
import {
  CardTitle,
  TableCellStyled,
  TableCellBodyStyled,
  TableRowStyled,
  TableHeadStyled,
  Stat,
  TableCellBorderStyled
} from './productBatchList.styles';
import currencyFormatter from '../../shared/currencyFormatter';
import ReturnRow from './returnRow';

const ProductBatchList = ({
  info: {
    batches: rows, sellingPrice
  }
}) => {
  const qtyInStock = rows?.reduce((acc, num) => acc + num.quantityInStock, 0);
  const grandTotal = qtyInStock * sellingPrice;

  return (
    <Grid style={{ marginTop: '2rem' }}>
      <Box p={4} bgcolor="background.paper" borderRadius={8}>
        <CardTitle className="erp-uat-batch-details">Batch Details</CardTitle>
        <TableContainer style={{ marginTop: '1rem' }}>
          <Table style={{ width: '100%' }}>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled>Date Received</TableCellStyled>
                <TableCellStyled>Batch No.</TableCellStyled>
                <TableCellStyled>Expiry Date</TableCellStyled>
                <TableCellStyled>Batch Qty</TableCellStyled>
                <TableCellStyled>Selling Price</TableCellStyled>
                <TableCellStyled>Item Price</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>

            {rows?.length > 0 ? (
              <TableBody>
                {rows?.map((row) => (
                  <ReturnRow key={row.id} row={row} sellingPrice={sellingPrice} />
                ))}
                <TableCellBodyStyled colSpan={2} />
                <TableCellBorderStyled>
                  <Stat>Qty in stock</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{qtyInStock}</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>Grand Total</Stat>
                </TableCellBorderStyled>
                <TableCellBorderStyled>
                  <Stat>{`₦ ${currencyFormatter(grandTotal)}`}</Stat>
                </TableCellBorderStyled>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCellBodyStyled
                    colSpan={7}
                    style={{ textAlign: 'center' }}
                  >
                    No Batches for this product yet
                  </TableCellBodyStyled>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

ProductBatchList.propTypes = {
  info: PropTypes.instanceOf(Object),
};

ProductBatchList.defaultProps = {
  info: {}
};

export default ProductBatchList;
