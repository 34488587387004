import {
  createTheme, styled, adaptV4Theme
} from '@mui/material/styles';
import { Typography, Grid, Box } from '@mui/material';
import { BlueUploadCloud } from '../../assets/svgs';

const isSmall = window.matchMedia('(max-width: 991px)').matches;

export const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        paddingTop: '10px'
      },
      root: {
        backgroundColor: '#fff',
        border: isSmall ? '2px dashed #F3F9FF' : '1px dashed #F3F9FF',
        borderColor: '#78AADA',
        height: isSmall && '33rem',
        borderRadius: '8px'
      }
    }
  }
}));

export const TitleText = styled(Typography)`
    font-weight: 400;
    font-size: 11px;
    font-family: Montserrat,medium,sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.4rem;

    @media (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
`;

export const TitleDesc = styled(Typography)`
    font-weight: 400;
    font-size: 10px;
    font-family: Montserrat,medium,sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    color: rgba(0, 0, 0, 0.4);

    @media (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
`;

export const Dash = styled(Box)`
    height: 3px;
    width: 8%;
    margin: 2px 8px;
    background: #CECECE;
`;

export const MiddleText = styled(Typography)`
    font-family: Montserrat,medium,sans-serif;
    font-size: 17px;
`;
export const BackgroundImage = styled('img')`
    height: 70px;
    margin-bottom: 20px;
`;

export const MiddleGroup = styled(Grid)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 30px 0 40px;
`;

export const BrowseFileButton = styled(Grid)`
  width: 100px;
  height: 35px;
  line-height: 35px;
  background: #FFF;
  border: 1.5px solid #235A91;
  border-radius: 3.96671px;
  font-weight: 600;
  font-size: 9px;
  margin: 0 auto;
  color: #235A91;

  @media (max-width: 991px) {
    border-radius: 1rem;
    font-size: 1.7rem;
    border: 3px solid #235A91;
    height: 6rem;
    padding-top: 2rem;
    width: 12rem;
  }
`;

export const DropZoneContainer = styled(Grid)`
`;

export const UploadIcon = styled(BlueUploadCloud)`
  fill: none;
  font-size: 2.5rem;

  @media (max-width: 991px) {
    font-size: 8rem;
    margin-bottom: 2rem;
  }
`;
