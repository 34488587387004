import {
  Grid, Typography, IconButton, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f3f9ff;
    }

    @media(min-width: 992px) {
      padding: 0px;
      width: 13rem;
    }
`;

export const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: .875rem;
  padding: 6px 12px;
  color: #606060;
  font-weight: 400;
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #C5C5C5;
  margin: 1px;
`;
