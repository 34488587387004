import {
  Grid, Typography, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Grid)`
    padding: 5px 25px;
    width: 24rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 230px
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1.7rem;

  @media(min-width: 992px) {
    font-size: 0.875rem;
    padding: 0px 6px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 95%;
    display: flex;
    margin: 0px 5px;
    justify-content: flex-start;
    &:hover {
      background-color: #F1F8FF;
    }

`;
