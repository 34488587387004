import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { KeyboardArrowDown } from '@mui/icons-material';
import MainContent from '../../../customComponents/mainContent';
import {
  UserGridContainer, BackArrowIconContainer, KeyboardArrowLeft,
  HeaderText, HeaderBox, SubHeaderText, BackText,
  DetailsPaper, UsernameText, DetailFieldGrid,
  VerifyButton, AdornmentStyled, NameBox
} from './buyerDetails.styles';
import { VERIFY_BUSINESS } from '../../../../mutations/business';
import { GET_BUYER_BUSINESS } from '../../../../queries/businesses';
import { CircularProgressLoader } from '../../../auth/login.styles';
import Loader from '../../../shared/loader';
import { CustomInputBase, CustomSelectInputBase } from '../../../shared/customInput';
import IdUploads from './idUpload';
import { JSONParse } from '../../../../utils/json';
import ConfirmDialog from '../../../shared/confirmDialog';
import BuyerProfile from './buyerProfile';

const BuyerAccountDetails = () => {
  const { id: businessId } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [tab, setTab] = useState('profile');

  const [verifyAccount, { loading: vLoading }] = useMutation(VERIFY_BUSINESS);

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const handleChangeTab = () => {
    if (tab === 'profile') {
      setTab('orders');
    } else {
      setTab('profile');
    }
  };

  const {
    loading, error: businessError, data, refetch
  } = useQuery(GET_BUYER_BUSINESS, {
    variables: { businessId: Number(businessId) },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;

  const {
    name, location, buyerContact, fullname, buyerMeta,
    settings, createdBy, contact, businessType,
  } = data?.business || {};

  if (businessError) return <div>{businessError.message}</div>;

  const {
    city, contactPersonName, deliveryAddress, emailAddress,
    landmark, phoneNumber, salesRepresentativeName: salesRep
  } = buyerContact || {};
  const { meta } = createdBy || {};

  const parseSettings = JSONParse(settings);
  const parseMeta = JSONParse(meta);
  const parseContact = JSONParse(contact);

  const { kyc } = parseSettings;
  const { business_type: busType, files } = kyc || {};
  const { gender: userGender } = parseMeta;
  const { buyer_contact: userContact } = parseContact;
  const { contact_person_phone_number: contactPersonNumber } = userContact || {};

  const { verificationStatus: status } = buyerMeta || {};

  const formatText = (inputString) => (
    inputString
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(' ')
  );

  const formatState = () => {
    const words = location?.split('_');
    const formattedLocation = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    return formattedLocation;
  };

  const infoField = [
    {
      name: 'fullName', label: 'Full Name', secured: true, show: true,
      val: formatText(fullname), type: 'text',
    },
    {
      name: 'emailAddress', label: 'Email Address', secured: true, show: true,
      val: emailAddress, type: 'text',
    },
    {
      name: 'phoneNumber', label: 'Phone Number', secured: true, show: true,
      val: phoneNumber, type: 'number', sAdornment: phoneAdornment
    },
    {
      name: 'gender', label: 'Gender', placeholder: 'Select Gender', val: userGender,
      options: ['Male', 'Female'], show: true, secured: true
    },
    {
      name: 'businessName', label: 'Business Name', secured: true, show: true,
      val: formatText(name), type: 'text'
    },
    {
      name: 'contactName', label: 'Contact Person Full Name', secured: true, show: true,
      val: formatText(contactPersonName), type: 'text',
    },
    {
      name: 'contactPersonPhoneNumber', label: 'Contact Person Phone Number', secured: true, show: true,
      val: contactPersonNumber, type: 'text', sAdornment: phoneAdornment, placeholder: 'e.g. 07012345678'
    },
    {
      name: 'salesRep', label: 'Sales Representative', secured: true, show: true,
      val: formatText(salesRep), type: 'text',
    },
    {
      name: 'deliveryState', label: 'Delivery State', secured: true, show: true,
      val: formatState(), type: 'text',
    }
  ];

  const filesField = [
    {
      name: 'deliveryCity', label: 'Delivery City', secured: true, show: true,
      val: formatText(city), type: 'text',
    },
    {
      name: 'deliveryAddress', label: 'Delivery Address', secured: true, show: true,
      val: formatText(deliveryAddress), type: 'text',
    },
    {
      name: 'landmark', label: 'Landmark', secured: true, show: true,
      val: formatText(landmark), type: 'text',
    },
    {
      name: 'businessType', label: 'Business Type', placeholder: 'e.g. Retail',
      options: ['Male', 'Female'], show: true, secured: true, val: busType ?? businessType
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      isTooltip, tooltipDesc, sAdornment
    } = field;

    if (['gender', 'businessType'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          disabled={secured}
          value={val}
          placeholder={placeholder}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }

    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        isTooltip={isTooltip}
        tooltipDesc={tooltipDesc}
        sAdornment={sAdornment}
      />
    );
  };

  const handleVerifyAccount = () => {
    verifyAccount({
      variables: { businessId: Number(businessId), businessType: 'BUYER' }
    }).then(({ data: v }) => {
      const { message } = v.verifyBusiness;
      refetch();
      setOpenModal(false);
      toast.success(message);
    }).catch((err) => toast.error(err?.message));
  };

  return (
    <MainContent>
      <UserGridContainer container>
        <BackArrowIconContainer onClick={() => navigate(-1)}>
          <KeyboardArrowLeft />
          <BackText>Back</BackText>
        </BackArrowIconContainer>
        <HeaderBox>
          <HeaderText>
            {formatText(name)}
          </HeaderText>
          <SubHeaderText>
            View profile, documents & Orders
          </SubHeaderText>
        </HeaderBox>

        <DetailsPaper>
          {status !== 'ACTIVE' ? (
            <>
              <NameBox>
                <Grid container>
                  <Grid item xs={7}>
                    <UsernameText data-testid="username">
                      {formatText(name)}
                    </UsernameText>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: 'right' }}>
                    <VerifyButton onClick={() => setOpenModal(true)} data-testid="verifyButton">
                      {vLoading ? <CircularProgressLoader />
                        : 'Verify Account'}
                    </VerifyButton>
                  </Grid>
                </Grid>
              </NameBox>

              <Box style={{ padding: '0 2rem' }}>
                <DetailFieldGrid container spacing={5}>
                  <Grid item xs={6} container>
                    {infoField.filter((item) => item.show).map((field) => (
                      <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem', width: '97%' }}>{returnTextField(field)}</Grid>
                    ))}
                  </Grid>

                  <Grid item xs={6}>
                    {filesField.filter((item) => item.show).map((field) => (
                      <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem', width: '97%' }}>{returnTextField(field)}</Grid>
                    ))}
                  </Grid>
                </DetailFieldGrid>
              </Box>
            </>
          ) : (
            <BuyerProfile
              userData={data}
              businessId={+businessId}
              accountType="BUYER"
              tab={tab}
              changeTab={handleChangeTab}
            />
          )}
        </DetailsPaper>

        {tab === 'profile' && (
          <DetailsPaper>
            <IdUploads
              files={files}
              businessId={+businessId}
              accountType="BUYER"
              status={status}
            />
          </DetailsPaper>
        )}

      </UserGridContainer>
      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Verify Account?"
        desc="Are you sure you want to verify this account?"
        options={['Cancel', 'Yes, Verify']}
        buttonAction={handleVerifyAccount}
        loading={vLoading}
      />
    </MainContent>
  );
};

export default BuyerAccountDetails;
