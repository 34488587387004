import {
  Button, MenuItem, Grid, Typography, Box, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItemStyled = styled(MenuItem)`
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 15px;
`;

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 17rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 14rem;
      height: 3.063rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;

export const FilledButton = styled(IconButton)`
  display: flex;
  width: 18rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #235A91;
  color: #ffffff;
  border-radius: 8px;
  height: 75px;
  &:hover {
    background-color: #235A91;
  }
  &:disabled {
    color: #FFFFFF;
    background-color: #235A91;
    opacity: 0.6;
  }
  @media(min-width: 992px) {
    height: 47px;
    width: 12rem;
    padding: 0px 25px;
    border-radius: 8px;
  }
`;

export const FilledButtonText = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  @media (min-width: 992px) {
    font-size: 12px;
  }
`;
