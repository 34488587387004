import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import {
  InputWrapper
} from '../../register.styles';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import CustomInputBase from '../../../customComponents/customInputBase';
import TermsAndConditionsPopUp from '../../alerts/TermsAndConditionsPopUp';
import {
  TermsStyled, TermsDescription, TermsContent, TermsTitle,
  CheckBoxContainer, ViewTerms,
  CustomCheckbox
} from './buyer.styles';

const initialErrorState = {
  deliveryStateError: false,
  deliveryCityError: false,
  deliveryAddressError: false,
  landmarkError: false,
};

const DeliverySection = ({ state, setState, setProceed }) => {
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);

  const [errorState, setErrorState] = useState(initialErrorState);

  const {
    deliveryStateError, deliveryCityError, deliveryAddressError, landmarkError
  } = errorState;

  const {
    deliveryState, deliveryCity, deliveryAddress, landmark
  } = state;

  const validateFields = () => (
    deliveryState.length && deliveryCity.length && deliveryAddress.length && landmark.length && terms
  );

  const validateState = (name, value) => {
    switch (name) {
      case 'deliveryState':
      case 'deliveryCity':
      case 'deliveryAddress':
      case 'landmark':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    validateFields();
    if (validateFields()) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [state, terms]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({
      ...state,
      [name]: value
    });
  };

  const availLocations = [
    'Rivers State', 'Akwa Ibom State', 'Edo State', 'Lagos State', 'Oyo state',
    'Ogun State', 'Kwara State', 'Kano State', 'Abuja (FCT)'
  ];

  const fields = [
    {
      name: 'deliveryState', label: 'Delivery State', options: availLocations, secured: false, show: true, val: deliveryState,
      onChange: handleChange, placeholder: 'Select', required: true, error: deliveryStateError, helperText: 'Delivery state required'
    },
    {
      name: 'deliveryCity', label: 'Delivery City', secured: false, show: true, val: deliveryCity,
      onChange: handleChange, placeholder: 'e.g Port-Harcourt', required: true, type: 'text', error: deliveryCityError, helperText: 'Delivery city required'
    },
    {
      name: 'deliveryAddress', label: 'Delivery Address', secured: false, show: true, val: deliveryAddress,
      onChange: handleChange, placeholder: 'e.g Taime Elisa', required: true, type: 'text', error: deliveryAddressError, helperText: 'Delivery address required',
      isTooltip: true, tooltipDesc: 'Delivery address is where your orders will be delivered to when placed.'
    },
    {
      name: 'landmark', label: 'Landmark', secured: false, show: true, val: landmark,
      onChange: handleChange, placeholder: 'e.g Shoprite', required: true, type: 'text', error: landmarkError, helperText: 'Landmark required',
      isTooltip: true, tooltipDesc: 'Landmark is a well-known building or a unique crossroads closer to your business'
    }
  ];

  const returnInputProps = (name) => {
    switch (name) {
      case '':
        return {
          startAdornment: (
            <InputAdornment position="start">
              <span>₦</span>
            </InputAdornment>
          )
        };
      default:
        return null;
    }
  };
  const confirmTermChange = () => {
    if (terms) setTerms(false);
    else setTerms(true);
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      isTooltip, tooltipDesc
    } = field;
    const value = state[fieldName];
    if (['deliveryState'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={returnInputProps(fieldName)}
        cSize="lg"
        readOnly={false}
        isTooltip={isTooltip}
        tooltipDesc={tooltipDesc}
      />
    );
  };

  return (
    <>
      <InputWrapper item container>
        <Grid item xs={12} lg={12}>
          {fields.filter((item) => item.show).map((field) => (
            <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem' }}>{returnTextField(field)}</Grid>
          ))}
        </Grid>
        <TermsStyled>
          <CheckBoxContainer>
            <CustomCheckbox
              checked={terms}
              onChange={confirmTermChange}
            />
            {/* <FormGroup>
            <FormLabel
              control={(
                <Checkbox
                  checked={terms}
                  onChange={confirmTermChange}
                />
              )}
            />
          </FormGroup> */}
          </CheckBoxContainer>
          <TermsDescription>
            <TermsTitle>Terms and Conditions</TermsTitle>
            <TermsContent>
              By clicking the checkbox, you agree to the terms and conditions on OGApharmacy marketplace.
              {' '}
              <ViewTerms onClick={() => setOpen(true)}>Click here</ViewTerms>
            </TermsContent>
          </TermsDescription>
        </TermsStyled>
      </InputWrapper>
      <TermsAndConditionsPopUp
        open={open}
        setOpen={setOpen}
        terms={terms}
        confirmTermChange={confirmTermChange}
      />
    </>
  );
};

DeliverySection.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired,
};

export default DeliverySection;
