import React from 'react';
import PropTypes from 'prop-types';
import { RenderPopper } from '../../../utils/utils';
import {
  GridContainer, GridWrapper, ItemWrapper, ItemTypo
} from './actionPopper.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { BlueEye, CartGreen, TrackIcon } from '../../../assets/svgs';

export default function ActionPopper({
  action, handleAction, handleClose
}) {
  const open = Boolean(action);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  return (
    <RenderPopper
      open={open}
      anchorEl={action}
      popperPlacement="right"
      onClickAway={handleClose}
      marginTop="4rem !important"
      marginRight="-2.5rem !important"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      <GridWrapper container item>
        <GridContainer
          container
          item
          onClick={(e) => handleAction(e, 'Details')}
        >
          <ItemWrapper>
            <BlueEye />
            <ItemTypo variant="subtitle1">
              View Order Details
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer container item onClick={(e) => handleAction(e, 'Track')}>
          <ItemWrapper>
            <TrackIcon />
            <ItemTypo variant="subtitle1">
              Track Order
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
        <GridContainer container item onClick={(e) => handleAction(e, 'Re-order')}>
          <ItemWrapper>
            <CartGreen />
            <ItemTypo variant="subtitle1">
              Re-Order
            </ItemTypo>
          </ItemWrapper>
        </GridContainer>
      </GridWrapper>
    </RenderPopper>
  );
}

ActionPopper.propTypes = {
  action: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};
