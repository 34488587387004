import * as React from 'react';
import PropTypes from 'prop-types';
import { MenuItemStyled, MenuStyled } from './actionPopper.styles';
import {
  Dots, Track, View, Reorder
} from '../../assets/svgs';

export default function ActionPopper({
  row, handleAction
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dots
        fontSize="large"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <MenuStyled
        elevation={1}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItemStyled
          onClick={(e) => { handleAction(e, 'view-order', row); handleClose(); }}
        >
          <View />
          View Order Details
        </MenuItemStyled>
        <MenuItemStyled
          onClick={(e) => { handleAction(e, 'tracked-order', row); handleClose(); }}
        >
          <Track />
          Track Order
        </MenuItemStyled>
        <MenuItemStyled
          onClick={(e) => { handleAction(e, 're-order', row); handleClose(); }}
        >
          <Reorder />
          Re-Order
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  row: PropTypes.shape(Object).isRequired,
};
