import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from '../../../shared/currencyFormatter';
import { Dots } from '../../../../assets/svgs';
import { JSONParse } from '../../../../utils/json';
import {
  BodyCell, MainTableRow, ColorGrid, Dot
} from './returnRow.styles';
import ActionPopper from './actionPopper';

const ReturnRow = ({
  row, index
}) => {
  const {
    id, datePlaced, buyerStatus, sortedValue: sortValue, paymentSummary
  } = row;
  const navigate = useNavigate();
  const [action, setAction] = useState(null);
  const parseSummary = JSONParse(paymentSummary);
  const { total_amount: total } = parseSummary;

  const date = moment(datePlaced).format('DD/MM/YY (h:mm a)');
  const orderId = `#${id}`;
  const totalOrderValue = `₦ ${currencyFormatter(total)}`;
  const sortedValue = `₦ ${currencyFormatter(sortValue)}`;

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    switch (buyerStatus) {
      case 'ORDER_PLACED':
        return getColorGrid('#07DE10', '#F29053', 'Order placed');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#F29053', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#F29053', 'Ready for ...');
      case 'ORDER_REJECTED':
        return getColorGrid('#606060', '#FF0F0F', 'Rejected');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#F29053', 'Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Order sent');
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#F29053', 'Completed');
      case 'ORDER_AWAITING_APPROVAL':
        return getColorGrid('#606060', '#F29053', 'Awaiting Approval');
      case 'CLOSED':
        return getColorGrid('#07DE10', '#00B588', 'Completed');
      default:
        return getColorGrid('#606060', '#00B588', 'Received');
    }
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleAction = () => navigate(`/package-list/${id}`);

  return (
    <MainTableRow item container style={index % 2 ? { backgroundColor: '#F3F9FF' } : { backgroundColor: '#FFF' }}>
      <BodyCell>{date}</BodyCell>
      <BodyCell>{orderId}</BodyCell>
      <BodyCell>{totalOrderValue}</BodyCell>
      <BodyCell>{sortedValue}</BodyCell>
      <BodyCell>{getStatus()}</BodyCell>
      <BodyCell>
        <Dots style={{ cursor: 'pointer' }} fontSize="large" onClick={handleOptions} />
      </BodyCell>
      <ActionPopper
        action={action}
        handleAction={handleAction}
        row={row}
        handleClose={() => setAction(false)}
      />
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
