import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentWrapper = styled(Grid)`
  padding: 2rem 3rem;
`;

export const TitleText = styled(Typography)`
  color: #303030;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: .5rem;
`;
