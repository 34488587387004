import {
  Grid, Typography, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 30px;
  background-color:  #F7FBFF;
  min-height: 80vh;
  @media(max-width: 991px) {
    padding: 0px 30px;
    background-color: #fff;
  }
`;

export const OrderWrapper = styled(Grid)`
  background: #FFFFFF;
  box-shadow: 0px 11px 18px 0px #0000000A;
  border-radius: 12px;
  padding: 20px 30px 5rem;
  margin-top: 1rem;
  @media(max-width: 991px) {
    background: none;
  }
`;

export const TitleGridContainer = styled(Grid)`
  padding: 1rem 3rem 1rem .7rem;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled(Grid)`
`;

export const ButtonsPaper = styled(Paper)`
  border-bottom: 3px solid #EEEEEE;
  width: 100%;
`;

export const TitleTextGridContainer = styled(Grid)`
  align-items: center;
  display: flex;
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  line-height: 29px;
  color: #303030;


  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const SubTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.99px;
  color: #606060;
  margin-top: 10px;



  @media(min-width: 992px) {
    font-size: 0.9rem;
  }
`;
