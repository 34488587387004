import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, DialogContentText, Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  ContainerHead, Content, TextGrid, BoldGenText,
  TermsText, ProcureText, DialogCon, TermsDialog, PaymentContainer,
  GenText, PaymentText, PaymentTitle, PaymentSub, TextBullet
} from './TermsAndConditions.style';
import { DoubleMark } from '../../../assets/svgs';

const TermsAndConditionsPopUp = ({
  open, setOpen, terms, confirmTermChange
}) => {
  const handleClose = () => setOpen(false);

  return (
    <TermsDialog
      open={open}
      onClose={handleClose}
    >
      <ContainerHead>
        <TermsText>Terms & Conditions</TermsText>
        <Close
          fontSize="large"
          style={{ cursor: 'pointer', fill: '#235a91' }}
          onClick={handleClose}
        />
      </ContainerHead>
      <DialogCon>
        <Content>
          <DialogContentText
            id="scroll-dialog-description"
          >
            <ProcureText>OGApharmacy Procurement Pure Marketplace</ProcureText>
            <GenText style={{ marginTop: '.8rem' }}>
              Please note that payment/credit terms are noted in the OGApharmacy  Registration documents.
              <br />
              Please send a notification within 72 hours to the OGApharmacy seller representative in the case of any supply
              <br />
              discrepancies or other concerns. There will be no refunds for products not received in good condition.
            </GenText>
            <PaymentContainer>
              <PaymentText>Payment details are:</PaymentText>
              <hr style={{ border: '1px solid rgba(229, 229, 229, 1)', marginBottom: '1rem' }} />
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <PaymentTitle>Account Name:</PaymentTitle>
                  <PaymentTitle>Bank Name:</PaymentTitle>
                  <PaymentTitle>Bank Number:</PaymentTitle>
                </Grid>
                <Grid item xs={6}>
                  <PaymentSub>Banyi Lifestores Pharmacy Limited</PaymentSub>
                  <PaymentSub>Zenith Bank Plc.</PaymentSub>
                  <PaymentSub>1016914552</PaymentSub>
                </Grid>

              </Grid>
            </PaymentContainer>

            <Box>
              <ProcureText>Terms and Conditions</ProcureText>
              <GenText style={{ marginTop: '1rem' }}>
                These terms and conditions create a contract between you (the
                <span style={{ fontWeight: '700' }}> “Buyer”</span>
                ) and Lifestores (the
                <span style={{ fontWeight: '700' }}> “Agreement”</span>
                ). If you
                <br />
                intend to also be a seller on the platform, please refer separately to the ”Seller contract”. By using the Services, you
                <br />
                agree to comply with and be bound by this Agreement, and agree that you have read and understood them and the
                <br />
                documents they refer to. If you do not agree with these Terms and Conditions, you must not access or use the
                <br />
                Services.
              </GenText>

              <ProcureText style={{ marginTop: '1.5rem' }}>Definitions</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Agreement” </span>
                    means these terms and conditions as amended, updated and/or substituted from time to time.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Authorized User” </span>
                    means any individual to whom the Buyer grants access authorization to use the Service that is an employee, agent, contractor or representative of Buyer, Buyer&apos;s Affiliates, or Buyer&apos;s and Buyer&apos;s Affiliates&apos; Business Partners.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Business Day” </span>
                    means a day other than a Saturday, Sunday or public holiday in Nigeria when banks in Lagos are open for business.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Business Partner” </span>
                    means a legal entity that requires use of the Service in connection with Buyer’s and its Affiliates’ internal business operations. These may include Buyers, distributors, service providers and/or suppliers of Buyer and its Affiliates.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Buyer” </span>
                    means any legal entity in which Buyer, directly or indirectly, holds more than fifty percent (50% of the entity’s shares or voting rights). Any legal entity will be considered an Affiliate as long as that interest is maintained.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Confidential Information” </span>
                    means all information which the disclosing party protects against unrestricted disclosure to others that (i) the disclosing party or its representatives designates as confidential and/or internal and/or proprietary at the time of disclosure, or (ii) should reasonably be understood to be confidential at the time of disclosure given the nature of the information and the circumstances surrounding its disclosure.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Buyer Data” </span>
                    means the data inputted by the Buyer, Authorised Users, or Lifestores on the Buyer&apos;s behalf for the purpose of using the Services or facilitating the Buyer&apos;s use of the Services as well as any data that Buyer derives from its use of the Service (e.g. Buyer-specific reports). Buyer Data and its derivatives will not include Lifestores&apos; Confidential Information.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Documentation” </span>
                    means the document made available to the Buyer by Lifestores online which sets out a description of the Services and the user instructions for the Services.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Intellectual Property Rights” </span>
                    means patents of any type, design rights, utility models or other similar invention rights, copyrights and related rights, trade secret, know-how or confidentiality rights, trademarks, trade names and service marks and any other intangible property rights, whether registered or unregistered, including applications (or rights to apply) and registrations for any of the foregoing, in any country, arising under statutory or common law or by contract and whether or not perfected, now existing or hereafter filed, issued, or acquired.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Lifestores” </span>
                    means the OGApharmacy division of Banyi Lifestores Pharmacy Limited, or any successor entity thereto or Affiliate entity thereof, through which the PharmIQ Software is owned, managed and controlled and, which is responsible for providing the Services.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Lifestores Materials” </span>
                    mean any materials (including statistical reports) provided or developed by Lifestores (independently or with Buyer’s cooperation) in the course of performance under the Agreement, including in the delivery of any support to Buyer. Lifestores Materials do not include the Buyer Data, Buyer Confidential Information or the Service.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Normal Business Hours” </span>
                    means 8.00 am to 6.00 pm local Nigerian time, each Business Day.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Services” </span>
                    mean the subscription services provided by Lifestores to the Buyer under this Agreement, as more particularly described in the Documentation.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    <span style={{ fontWeight: '700' }}>“Software” </span>
                    means the online software applications provided by Lifestores as part of the Services.
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Usage Rights and Restrictions</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Grants of Rights
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Lifestores grants to Buyer a non-exclusive non-transferable right, without the right to grant sublicenses, to permit the Authorised Users to use the Services and the Documentation solely for Buyer&apos;s and its Affiliates&apos; internal business operations.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Authorized Users
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer may permit Authorized Users to use the Service. Usage is limited to the usage metrics and volumes stated in the Documentation. Access credentials for the Service may not be used by more than one individual but may be transferred from one individual to another if the original user is no longer permitted to use the Service. Buyer is responsible for breaches of the Agreement caused by Authorized Users.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Suspension of Service
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Lifestores may suspend or limit use of the Service if continued use may result in material harm to the Service or its users. Lifestores will promptly notify Buyer&apos; of the suspension or limitation. Lifestores will limit a suspension or limitation in time and scope as reasonably possible under the circumstances.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Restrictions
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    The Buyer shall not except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under this agreement:
                  </GenText>
                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any form or media or by any means, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Use the Services and/or Documentation to provide services to third parties, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        License, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised Users, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Introduce or permit the introduction of, any virus into Lifestores’ network and information systems.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Services</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Lifestores shall, provide the Services and make available the Documentation to the Buyer on and subject to the terms of this agreement.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Lifestores shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Planned maintenance carried out during the maintenance window of 10.00 pm to 2.00 am, and unscheduled maintenance performed outside Normal Business Hours, provided that Lifestores has used reasonable endeavours to give the Buyer at least 6 Normal Business Hours&apos; notice in advance.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Lifestores will, as part of the Services provide the Buyer with Buyer support services during Normal Business Hours
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Buyer and Personal Data</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Buyer Data
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer is responsible for the Buyer Data and entering it into the Service. Buyer grants to Lifestores (and its Affiliates) a non-exclusive right to process and use Buyer Data to provide and support the Service and as set out in the Agreement.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Personal Data
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer&apos; will collect and maintain all personal data contained in the Buyer Data in compliance with applicable data privacy and protection laws.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Security
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer will maintain reasonable security standards for its Authorized Users’ use of the Service.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Access to Buyer Data
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer can access its Buyer Data at any time. Buyer may export and retrieve its Buyer Data in a standard format. Export and retrieval may be subject to technical limitations
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Processing Buyer Data
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    By entering into this agreement, Buyer consents to (and shall procure all required consents, from its personnel, representatives and agents, in respect of) all actions taken by Lifestores in connection with the processing of Buyer Data.
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Without prejudice to the generality of the above clause, Buyer will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of Buyer Data to Lifestores and lawful collection of the same by Lifestores for the duration and purposes of this Agreement.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Third Party Providers</ProcureText>
              <GenText style={{ marginTop: '.5rem' }}>
                Buyer acknowledges that the Services may enable or assist it to access the website content of, correspond with, third parties via third-party websites and that it does so solely at its own risk. Lifestores makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website
              </GenText>

              <ProcureText style={{ marginTop: '1.5rem' }}>Lifestores&apos; Obligations</ProcureText>
              <GenText style={{ marginTop: '.5rem' }}>
                Lifestores undertakes that the Services will be performed substantially in accordance with the Documentation and with reasonable skill and care.
              </GenText>
              <Grid container spacing={2} style={{ marginTop: '0px' }}>
                <Grid item xs={0.3}>
                  <DoubleMark style={{ fontSize: '.9rem' }} />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    The above undertaking shall not apply to the extent of any non-conformance which is caused by use of the Services contrary to Lifestores&apos; instructions, or modification or alteration of the Services by any party other than Lifestores or the Lifestores&apos; duly authorised contractors or agents.
                  </GenText>
                </Grid>
              </Grid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Buyer&apos;s Obligations</ProcureText>
              <GenText style={{ marginTop: '.5rem' }}>
                The Buyer shall provide Lifestores with:
              </GenText>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    All necessary cooperation in relation to this agreement, and
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    All necessary access to such information as may be required by Lifestores; in order to provide the Services, including but not limited to Buyer Data, security access information and configuration services;
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Without affecting its other obligations under this agreement, comply with all applicable laws and regulations with respect to its activities under this agreement;
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and conditions of this agreement and shall be responsible for any Authorised User&apos;s breach of this agreement;
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Check all details of proforma invoice for completeness and accuracy before approval to supply
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Check all supplies for completeness, quality, accuracy etc at point of supply for purchases made directly from sellers like Manufacturers, Importers and Distributors and report any discrepancies immediately, maximum of 72 hours to Lifestores through the Buyer support channels.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Check all supplies for completeness, quality, accuracy etc at point of supply for purchases made from Lifestores and report any discrepancies within 72 hours to Lifestores.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Sign all delivery documents and/ or make necessary comments.
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Warranties</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Compliance with Law
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Each party warrants its current and continuing compliance with all laws and regulations applicable to it in connection with:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        In the case of Lifestores, the operation of Lifestores’ business as it relates to the Service, and
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        In the case of Buyer, the Buyer Data and Buyer&apos;s use of the Service.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Disclaimer
                  </BoldGenText>
                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Except as expressly provided in the Agreement, neither Lifestores nor its subcontractors make any representation or warranties, express or implied, statutory or otherwise, regarding any matter, including the merchantability, suitability, originality, or fitness for a particular use or purpose, non-infringement or results to be derived from the use of or integration with any products or services provided under the Agreement, or that the operation of any products or services will be secure, uninterrupted or error free.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Delivery</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Deliveries for an order may not all come at once; the Buyer agrees that deliveries may come in multiple packages and at different times, on different days.
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Intellectual Property Right</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Lifestores Ownership
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Except for any rights expressly granted to Buyer under the Agreement, Lifestores owns all Intellectual Property Rights in and derivative works of:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        The Service,
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Lifestores Materials
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Documentation, and
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Any design contributions, related knowledge or processes, whether or not developed for Buyer.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Buyer Ownership
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Buyer retains all rights in and related to the Buyer Data. Lifestores may use Buyer-provided trademarks solely to provide and support the Service.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Acceptable Use Policy
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    With respect to the Service, Buyer will not:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Enter, store, or transfer any content or data on or via the Service that is unlawful or infringes any Intellectual Property Rights, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Remove Lifestores&apos; copyright and authorship notices
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Returns</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Lifestores operates a strictly no return policy for products purchased directly from Importers, manufacturers and distributors except for an error in the orders or product quality issues. (damaged products, oversupply or undersupply)
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Retailer buyers will be permitted to return products to wholesalers within a 72 hour period from date of delivery.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Products from retailer buyers can be returned by contacting Lifestores Buyer support lines.
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Transition from Managed Marketplace to Pure Marketplace</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    All eligible Buyers from our managed marketplace
                    {' '}
                    <a href="https://marketplace.ogapharmacy.com/">&quot;https://marketplace.ogapharmacy.com/&quot;</a>
                    {' '}
                    will have account information transferred to the pure marketplace. Once such account has been transferred, existing eligible Buyers will no longer have access to the existing marketplace. Eligible buyers for the purposes of this clause are Buyers located in Rivers, Akwa Ibom, Bayelsa and Edo States.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    New Buyers will need to register to use the pure marketplace platform.
                  </GenText>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Confidentiality</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Use of Confidential Information
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    The receiving party shall:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Maintain all Confidential Information of the disclosing party in strict confidence, taking steps to protect the disclosing party&apos;s Confidential Information substantially similar to those steps that the receiving party takes to protect its own Confidential Information, which shall not be less than a reasonable standard of care;
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Not disclose or reveal any Confidential Information of the disclosing party to any person other than its Representatives whose access is necessary to enable it to exercise its rights or perform its obligations under the Agreement and who are under obligations of confidentiality substantially similar to those in clause 10;
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Not use or reproduce any Confidential Information of the disclosing party for any purpose outside the scope of the Agreement;
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Retain any and all confidential, internal, or proprietary notices or legends which appear on the original and on any reproductions.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Buyer shall not disclose any information about the Agreement, its terms and conditions, the pricing or any other related facts to any third party.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Confidential Information of either party disclosed prior to execution of the Agreement will be subject to Clause 10.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Compelled Disclosure
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    The receiving party may disclose the disclosing party&apos;s Confidential Information to the extent required by law, regulation, court order or regulatory agency; provided, that the receiving party required to make such a disclosure uses reasonable efforts to give the disclosing party reasonable prior notice of such required disclosure (to the extent legally permitted) and provides reasonable assistance in contesting the required disclosure, at the request and cost of the disclosing party. The receiving party and its Representatives shall use commercially reasonable efforts to disclose only that portion of the Confidential Information which is legally requested to be disclosed and shall request that all Confidential Information that is so disclosed is accorded confidential treatment.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Exceptions
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    The restrictions on use or disclosure of Confidential Information will not apply to any Confidential Information that:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Is independently developed by the receiving party without reference to the disclosing party&apos;s Confidential Information, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Has become generally known or available to the public through no act or omission by the receiving party, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        At the time of disclosure, was known to the receiving party free of confidentiality restrictions, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        Is lawfully acquired free of restriction by the receiving party from a third party having the right to furnish such Confidential Information, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        The disclosing party agrees in writing is free of confidentiality restrictions.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Destruction and Return of Confidential Information
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    Upon the disclosing party&apos;s request, the receiving party shall promptly destroy or return the disclosing party&apos;s Confidential Information, including copies and reproductions of it. The obligation to destroy or return Confidential Information shall not apply:
                  </GenText>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        If legal proceedings related to the Confidential Information prohibit its return or destruction, until the proceedings are settled, or a final judgment is rendered;
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        To Confidential Information held in archive or back-up systems under general systems archiving or back-up policies, or
                      </GenText>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '0px' }}>
                    <Grid item xs={0.3}>
                      <DoubleMark style={{ fontSize: '.9rem' }} />
                    </Grid>
                    <Grid item xs={11.7}>
                      <GenText>
                        To Confidential Information the receiving party is legally entitled or required to retain.
                      </GenText>
                    </Grid>
                  </Grid>
                </Grid>
              </TextGrid>

              <ProcureText style={{ marginTop: '1.5rem' }}>Miscellaneous</ProcureText>
              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <GenText>
                    Changes to these Terms and Conditions: Lifestores may post changes to these Terms and Conditions via the Services, and such changes shall be effective from the next time you use the Services.
                  </GenText>
                </Grid>
              </TextGrid>

              <TextGrid container spacing={0}>
                <Grid item xs={0.3}>
                  <TextBullet />
                </Grid>
                <Grid item xs={11.7}>
                  <BoldGenText>
                    Governing Law :
                  </BoldGenText>
                  <GenText style={{ marginTop: '.5rem' }}>
                    These terms are governed by Nigerian law.
                  </GenText>
                </Grid>
              </TextGrid>

            </Box>
          </DialogContentText>
        </Content>
      </DialogCon>

      {/* <DialogActionsWrapper>
        <FormGroup>
          <FormLabel
            control={(
              <Checkbox
                checked={terms}
                onChange={confirmTermChange}
                className="mpfe-uat-register-checkbox"
              />
)}
            label="By ticking this box and clicking on agree and continue, you confirm that you have read and understood the terms of the PharmIQ Customer Agreement"
          />
          <CustomButton
            disabled={!terms}
            onClick={handleClose}
            type="submit"
            className="mpfe-uat-register-submit"
            style={{ marginTop: '20px' }}
          >
            Agree & Create Account
          </CustomButton>
        </FormGroup>
      </DialogActionsWrapper> */}
    </TermsDialog>

  );
};

TermsAndConditionsPopUp.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  terms: PropTypes.bool,
  confirmTermChange: PropTypes.func.isRequired,
};

TermsAndConditionsPopUp.defaultProps = {
  open: true,
  terms: false
};

export default TermsAndConditionsPopUp;
