import React from 'react';
import { Box, Table, TableBody } from '@mui/material';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  TableGrid, MainTableHead, THeader, TableComponent
} from './ordersPage.styles';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import CustomToolTip from '../../../shared/toolTip';
import ReturnRow from './returnRow';
import { PARTICULAR_ORDER } from '../../../../queries/orders';

const ordersHeader = [
  { name: 'Order Date' },
  { name: 'Order Number' },
  { name: 'Order Value' },
  { name: 'Sorted Value', toolTipDesc: 'OGApharmacy charges a mark up on the invoice amount for every transaction.' },
  { name: 'Order Status' },
  { name: 'Action' }
];

const BuyersOrder = ({ id }) => {
  const variables = { tab: 'BUYER', businessId: id };
  const {
    data, loading, error
  } = useQuery(PARTICULAR_ORDER, {
    fetchPolicy: 'no-cache',
    variables,
  });

  const orders = data?.allOrders || [];

  if (error) return `Error! ${error.message}`;

  const returnHeaders = () => ordersHeader.map((header) => {
    const { name, toolTipDesc } = header;
    return (
      <THeader key={name}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {name}
          { toolTipDesc && <CustomToolTip title={name} description={toolTipDesc} /> }
        </Box>
      </THeader>
    );
  });

  return (
    <TableGrid item container>
      {loading ? <ProductsTableLoader /> : (
        <TableComponent item container aria-label="Orders table">
          <Table style={{ width: '100%' }} data-testid="puremp-uat-manageOrders">
            <MainTableHead>
              {returnHeaders()}
            </MainTableHead>
            <TableBody>
              {orders?.map((order) => (
                <ReturnRow key={order} row={order} />
              ))}
            </TableBody>
          </Table>
        </TableComponent>
      )}
    </TableGrid>
  );
};

BuyersOrder.propTypes = {
  id: PropTypes.number.isRequired,
};

export default BuyersOrder;
