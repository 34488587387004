import {
  DialogContent, Dialog, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseNewIcon } from '../../assets/svgs';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    box-shadow: none;
    border-radius: 12px;
  }
`;

export const CloseStyled = styled(CloseNewIcon)`
  cursor: pointer;
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 650px;
  height: auto;
  padding: 2rem 1.5rem;
`;

export const HeaderText = styled(Typography)`
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const ActionStyled = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContentHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const FooterStyled = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

export const ActionButton = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;
