import {
  Box,
  Grid, IconButton, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NewMpLogo } from '../../../../assets/svgs';
import CloseIcon from '@mui/icons-material/CloseRounded';


export const OrderGridContainer = styled(Grid)`
  position: relative;
  margin-top: 5rem;
`;

export const Header = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;

`;

export const ContentBox = styled('div')`
  padding: 0rem;
  background: #ffffff;
  @media (max-width: 991px) {
  padding: 5rem 1.5rem 1rem 1.5rem;
  margin-top: 5rem;

  }
`;

export const ProductsBox = styled('div')`
  width: 100%;
  padding: 0rem 5rem;
`;

export const HeaderWrapper = styled(Grid)`
  width: 100%;
  padding: 2.5rem 0rem 0rem 5rem;
`;

export const SubHeaderWrapper = styled(Grid)`
justify-content: space-between;
padding: 2.2rem 0rem 1rem;

`;

export const MpLogo = styled(NewMpLogo)`
  margin: auto;
  &.MuiSvgIcon-root {
    width: 70%;
    height: 90%;
  }

  @media (min-width: 992px) {
    &.MuiSvgIcon-root {
      width: 18%;
      height: 75px;
    }
  }
`;

export const GoBack = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  color: #255B91;
  padding: 0.5rem 0;
  margin-bottom: -1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  cursor: pointer;

`;

export const SearchContainer = styled(Box)`
  align-items: center;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius:11px;
  width: 100%;
  border: .5px solid #E7E8E9;


  & .MuiOutlinedInput-input {
    font-size: 2.2rem;
    margin-top: 5px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 2px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    height: 2.7rem;
    & .MuiOutlinedInput-input {
      font-size: .9rem;
      font-weight: 500;
      text-align: left;
    };
    & .MuiOutlinedInput-adornedStart {
      padding-left: 30px;
    }
    & .MuiOutlinedInput-root {
      height: 2.5rem;
    };
  }

  @media (max-width: 991px) {
    border: 2px solid #E7E8E9;
    height: 110px;
    border-radius: 4.2rem;
  }

`;

export const SearchButton = styled(IconButton)`
  padding: 19px 28px;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:disabled {
    color: #424242;
  }

  @media(min-width: 992px) {
    padding: 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 30px;

  @media(min-width: 992px) {
    width: 20px;
  }
`;

export const ClearIcon = styled(CloseIcon)`
  font-size: 22px;

  @media(max-width: 992px) {
    font-size: 3.8rem;
  }
`;

export const SearchBox = styled(Grid)`
  width: 29%;
`;
