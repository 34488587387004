import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import { Grid, InputAdornment } from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  BatchDialog, DialogWrapper, SubText, ProductName, DialogHeader,
  HeaderGrid, ContinueStyled,
  DialogBody,
  BatchItems,
  CustomCheckbox,
  ItemGrid,
  ColumnHeader,
  Details,
  CustomInputBase
} from './selectBatchDialog.styles';
import { CaretDownIcon, CaretUpIcon } from '../../assets/svgs';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SelectBatchDialog({
  open, toggleBatchDialog, order, handleBatchSelection
}) {
  const {
    id, name, searchablePackSize, quantityConfirmed, batches
  } = order;

  const [batchItem, setBatchItem] = useState([]);

  const totalQtyToSell = batchItem?.reduce((accumulator, currentValue) => accumulator + currentValue.qtyToSell, 0);

  const value = (batchId) => batchItem.find((item) => item.batchId === batchId)?.qtyToSell;

  const handleIncrement = (batchId, batchNo, expiryDate) => {
    const existingItem = batches?.find((item) => item.id === batchId);
    if (totalQtyToSell >= quantityConfirmed) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newValue = 0;
    const index = batchItem?.findIndex((item) => item.batchId === batchId);
    if (index === -1) {
      setBatchItem((prevBatchItem) => [...prevBatchItem, {
        batchId, batchNo, expiryDate, qtyToSell: newValue + 1
      }]);
    } else {
      const updatedBatchItem = [...batchItem];
      const updatedBatch = { ...updatedBatchItem[index] };
      if (updatedBatch.qtyToSell >= existingItem?.quantityInStock) return false;
      updatedBatch.qtyToSell += 1;
      updatedBatchItem[index] = updatedBatch;
      setBatchItem(updatedBatchItem);
    }
  };

  const handleDecrement = (batchId, batchNo, expiryDate) => {
    const index = batchItem?.findIndex((item) => item.batchId === batchId);
    if (index === -1) {
      if (Number(value(batchId)) === 0) return;
      setBatchItem((prevBatchItem) => [...prevBatchItem, {
        batchId, batchNo, expiryDate, qtyToSell: Number(value(batchId))
      }]);
    } else {
      const updatedBatchItem = [...batchItem];
      const updatedBatch = { ...updatedBatchItem[index] };
      if (updatedBatch.qtyToSell <= 0) return;
      updatedBatch.qtyToSell -= 1;
      updatedBatchItem[index] = updatedBatch;
      setBatchItem(updatedBatchItem);
    }
  };

  const handleChange = (e, batchId, batchNo, expiryDate) => {
    const totalQty = batchItem?.filter((item) => item.batchId !== batchId)?.reduce((accumulator, currentValue) => accumulator + currentValue.qtyToSell, 0);
    const changedValue = Number(e?.target.value);
    let newValue = 0;
    if (changedValue) {
      newValue = changedValue;
    }
    if (changedValue < 0) return;
    if ((totalQty + newValue) > quantityConfirmed) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newBatchItem = {
      batchId, qtyToSell: Number(e?.target.value), batchNo, expiryDate
    };
    const index = batchItem.findIndex((item) => item.batchId === batchId);

    if (index === -1) {
      setBatchItem((prevBatchItem) => [...prevBatchItem, newBatchItem]);
    } else {
      const updatedBatchItem = [...batchItem];
      updatedBatchItem[index] = newBatchItem;
      setBatchItem(updatedBatchItem);
    }
  };

  const handleSelect = (batchId, batchNo, expiryDate) => {
    if (batchItem.find((item) => item.batchId === batchId)) {
      const newBatchItems = batchItem.filter((item) => item.batchId !== batchId);
      setBatchItem(newBatchItems);
    } else {
      const newBatchItems = [...batchItem, {
        batchId, qtyToSell: 0, batchNo, expiryDate
      }];
      setBatchItem(newBatchItems);
    }
  };

  const handleDone = () => {
    const filteredData = batchItem?.filter((item) => item.qtyToSell > 0);
    const newData = batchItem?.length > 1 ? batchItem : filteredData;
    handleBatchSelection(id, newData);
    toggleBatchDialog();
  };

  const renderBatchItems = () => (
    <DialogBody item container>
      {
        batches?.map((batch) => {
          const {
            batchNo, expiryDate, quantityInStock, orderCost, qtyToSell
          } = batch;
          return (
            <BatchItems item container key={batchNo}>
              <ItemGrid item xs={1}>
                <CustomCheckbox
                  size="small"
                  name="batchDetails"
                  onClick={() => handleSelect(batch.id, batch?.batchNo, moment(batch?.expiryDate).format('YYYY-MM-DD'))}
                  checked={batch.checked}
                  onC
                />
              </ItemGrid>
              <ItemGrid item xs={3}>
                <ColumnHeader>Batch #</ColumnHeader>
                <Details>{batchNo}</Details>
              </ItemGrid>
              <ItemGrid item xs={2}>
                <ColumnHeader>Expiry Date</ColumnHeader>
                <Details>{moment(expiryDate).format('YYYY-MM-DD')}</Details>
              </ItemGrid>
              <ItemGrid item xs={2}>
                <ColumnHeader>Qty in Stock</ColumnHeader>
                <Details>{quantityInStock}</Details>
              </ItemGrid>
              <ItemGrid item xs={2}>
                <ColumnHeader>Qty Sent</ColumnHeader>
                <Details>
                  <CustomInputBase
                    type="number"
                    placeholder="0"
                    value={(value(batch?.id) || value(batch?.id) === 0) ? Number(value(batch?.id)) : 0}
                    onChange={(e) => handleChange(e, batch?.id, batch?.batchNo, moment(batch?.expiryDate).format('YYYY-MM-DD'))}
                    size="medium"
                    endAdornment={(
                      <InputAdornment position="end">
                        <Grid item container direction="column">
                          <CaretUpIcon
                            onClick={() => handleIncrement(batch?.id, batch?.batchNo, moment(batch?.expiryDate).format('YYYY-MM-DD'))}
                            style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          />
                          <CaretDownIcon
                            onClick={() => handleDecrement(batch?.id, batch?.batchNo, moment(batch?.expiryDate).format('YYYY-MM-DD'))}
                            style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          />
                        </Grid>
                      </InputAdornment>
                  )}
                  />
                </Details>
              </ItemGrid>
              <ItemGrid item xs={2}>
                <ColumnHeader>Order Cost</ColumnHeader>
                <Details>{orderCost || 'N/A'}</Details>
              </ItemGrid>
            </BatchItems>
          );
        })
      }
    </DialogBody>
  );

  return (
    <BatchDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleBatchDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container justifyContent="space-between">
          <HeaderGrid item xs={6}>
            <ProductName item xs={12}>{name}</ProductName>
            <SubText item xs={12} variant="caption">
              Pack Size:
              &nbsp;
              {searchablePackSize}
            </SubText>
          </HeaderGrid>
          <Grid item container xs={6} justifyContent="flex-end">
            <ContinueStyled
              type="tertiary"
              onClick={handleDone}
            >
              Continue
            </ContinueStyled>
          </Grid>
        </DialogHeader>
        {renderBatchItems()}
      </DialogWrapper>
    </BatchDialog>
  );
}

SelectBatchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleBatchDialog: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired
};
