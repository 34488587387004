import React from 'react';
import PropTypes from 'prop-types';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './tabsButtons.styles';

const TabsButtons = ({ active, handleStatusValueChange }) => (
  <StatusesGrid container item>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('buyers')}
      active={active === 'buyers'}
      width="7%"
      className="mpAdmin-uat-ordersPageTab-buyers-orders"
    >
      <Typo active={active === 'buyers'}>Buyers</Typo>
    </UnderlineGrid>
    <UnderlineGrid
      item
      container
      onClick={() => handleStatusValueChange('sellers')}
      active={active === 'sellers'}
      width="7%"
      className="mpAdmin-uat-ordersPageTab-sellers-orders"
    >
      <Typo active={active === 'sellers'}>Sellers</Typo>
    </UnderlineGrid>
    <UnderlineGrid container width="86%" marginTop="1.3rem">
      <Typo>{' '}</Typo>
    </UnderlineGrid>
  </StatusesGrid>
);
TabsButtons.propTypes = {
  active: PropTypes.string,
  handleStatusValueChange: PropTypes.func.isRequired
};

TabsButtons.defaultProps = {
  active: '',
};

export default TabsButtons;
