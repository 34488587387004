import {
  Dialog, Grid, Typography, CircularProgress,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    min-height: 48rem;
    min-width: 53.25rem;
    border-radius: 12px;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-weight: 700;
  color: #303030;
  font-size: 1.5rem;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #fff;
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  width: 11.125rem;
  height: 3rem;
  border: 1.5px solid #235a91;
  border-radius: 8px;
  font-weight: 700;
  font-size: .875rem;
`;
