import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography,
  TableCell,
  TableRow,
  InputBase,
  Button,
  Checkbox,
} from '@mui/material';

export const MainTableRow = styled(TableRow)`
  border: 0px;
`;

export const ProductDetail = styled(TableCell)`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

export const ProductDetailImage = styled('img')`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;

  @media (min-width: 992px) {
    width: 80px;
    max-height: 100px;
    margin-right: 20px;
  }
`;

export const ProductDetailNameMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991px) {
    height: 15rem;
  }
`;

export const ImageWrapper = styled(Grid)`
  width: 11rem;
  max-height: 11rem;
  margin-right: 20px;
  border-radius: 10px;
  text-align: center;

  @media (min-width: 992px) {
    width: 80px;
    max-height: 100px;
    margin-right: 18px;
    margin-top: 0rem;
  }
`;

export const ProductDetailPackSizeText = styled('p')`
  margin: 0;
  color: #424242;
  font-size: 14px;
  line-height: 23px;

  @media (max-width: 991px) {
    color: #868686;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 300;
  }
`;

export const ProductDetailBrandNameText = styled('p')`
  color: #424242;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    width: 13rem;
    line-height: 23px;
    font-size: 14px;
    margin-right: 0.5rem;
  }

  @media (min-width: 1200px) {
    width: 13rem;
    line-height: 23px;
    font-size: 17px;
    margin-right: 1rem;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  padding: 1.6rem 15px;
  @media (min-width: 992px) {
    font-weight: 600;
    font-size: 17px;
  }
`;

export const QuantityText = styled(Typography)`
    font-weight: 600;
    font-size: 16px;
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 4rem;
  border-radius: 6px;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
    font-size: 16px;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 5px;
    margin-left: 0px;
  }
`;

export const ProductDetailMainContainer = styled('div')`
  width: 250px;
  margin-right: 20px;

  @media (min-width: 992px) {
    width: 100px;
    margin-right: 0px;
  }
`;

export const ActionButton = styled(Button)`
  width: 130px;
  height: 45px;
  background: none;
  border-radius: 5px;
  color: #235A91;
  font-size: .8rem;
  font-weight: 500;
  border: 1px solid #235A91;

  &:hover {
    background-color: none;
    color: #606060;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: none;
    border: 1px solid #C0C0C1;
  }

  @media(max-width: 991px) {
    margin: 12px 22px;
    width: 93%;
    height: 125px;
    font-size: 2.1rem;
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #235A91;
  padding: 6px;
  margin-left: 2.5rem;
`;
