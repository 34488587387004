import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BodyCell,
  MainTableRow,
  ViewReport,
  StatusColor,
  StatusContainer
} from './returnRow.styles';
import { BlueEyeIcon } from '../../assets/svgs';
import FailedProductDialog from './failedProductDialog';
import { parseJson } from '../affiliates/utils';

const ReturnRow = ({ row, sn }) => {
  const {
    status, logs, initiatedBy, dateCreated, fileName, meta, failedEntries, totalEntries
  } = row;

  const errorLogs = JSON.parse(JSON.parse(logs));
  const { upload_type: uploadType } = JSON.parse(meta);

  const name = parseJson(initiatedBy.profile)?.first_name || 'N/A';
  const dateTime = moment(dateCreated).format('DD MMM YYYY hh:mm A');

  const uploadedDate = moment(dateCreated).format('MMM YYYY');
  const description = `Product uploaded on ${uploadedDate}`;

  const [openDialog, setOpenDialog] = useState(false);
  const toggleHandler = () => setOpenDialog(!openDialog);

  const renderStatus = (_status) => (
    <StatusContainer>
      <StatusColor status={_status} />
      <span>{status.toLowerCase()}</span>
    </StatusContainer>
  );

  const getStatus = () => renderStatus(status);

  return (
    <>
      <MainTableRow>
        <BodyCell>{sn}</BodyCell>
        <BodyCell>{dateTime}</BodyCell>
        <BodyCell>{fileName}</BodyCell>
        <BodyCell>{name}</BodyCell>
        <BodyCell>{getStatus()}</BodyCell>
        <BodyCell>
          <ViewReport onClick={toggleHandler}>
            <BlueEyeIcon />
            View Report
          </ViewReport>
        </BodyCell>
        <BodyCell />
      </MainTableRow>
      <FailedProductDialog
        openDialog={openDialog}
        toggleHandler={toggleHandler}
        data={errorLogs}
        title={description}
        type={uploadType}
        totalEntries={totalEntries}
        failedEntries={failedEntries}
      />
    </>
  );
};
ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sn: PropTypes.number.isRequired
};

ReturnRow.defaultProps = {
  row: {}
};

export default ReturnRow;
