import {
  Grid, Typography, Button, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridContainer = styled(Button)`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 13rem;
      height: 3rem;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: #F3F9FF;
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const ItemTypo = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 1.3rem;
  color: #606060;
`;

export const ItemWrapper = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 0rem;
    padding-bottom: 0rem;
`;
