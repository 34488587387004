import CartActionTypes from './cartTypes';
import { addItemToCart, updateCartCounter, updateCartCount } from './cartUtils';

const cartReducer = (cart, action) => {
  switch (action.type) {
    case CartActionTypes.ADD_TO_CART:
      return {
        ...cart,
        cartItems: addItemToCart(cart.cartItems, action.payload)
      };
    case CartActionTypes.CART_COUNTER:
      return {
        ...cart,
        cartCounter: updateCartCounter(action.payload)
      };
    case CartActionTypes.CART_COUNT:
      return {
        ...cart,
        cartCounter: updateCartCount(action.payload)
      };
    case CartActionTypes.CART_BACK_ORDER_COUNTER: {
      const { cartCounter } = cart;
      return {
        ...cart,
        cartCounter: cartCounter + action.payload
      };
    }
    case CartActionTypes.UPDATE_IS_MANUAL:
      return {
        ...cart,
        isManualOrder: action.payload
      };
    case CartActionTypes.CART_STATUS:
      return {
        ...cart,
        cartStatus: action.payload
      };
    case CartActionTypes.UPDATE_CART_ITEMS:
      return {
        ...cart,
        productCartItems: action.payload
      };
    default:
      return cart;
  }
};

export default cartReducer;
