import {
  Button,
  CircularProgress,
  DialogActions, DialogContent, Grid
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { blockInvalidChar } from '../../utils/mobileCheck';
import CustomInputBase from '../customComponents/customInputBase';
import {
  CDialog,
  CloseIcon,
  DialogTitleText,
  GridWrapper
} from './singleBatchDialog.styles';
import { WHOLESALER_BATCH } from '../../mutations/orders';
import SuccessDialog from '../shared/successDialog';

const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const SingleBatchDialog = ({
  singleBatchItem, open, onClose, brandName, productId, refetch, quantity: qtyOrder, singleBatchDetails
}) => {
  const [quan, setQuan] = useState(0);

  const initialState = {
    orderProductId: productId,
    batchNo: '',
    quantityReceived: 0,
    expiryDate: defaultExp,
  };

  const initialErrorState = {
    batchNoError: false,
    quantityError: false,
    orderCostError: false
  };

  const [batch, setBatch] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (singleBatchItem) {
      const batchQty = singleBatchItem.map(({ quantity }) => Number(quantity)).reduce((a, b) => a + b, 0);
      const q = qtyOrder - batchQty;
      if (singleBatchDetails) {
        const {
          batchNumber, batches
        } = singleBatchDetails;
        const editBatchItem = batches?.find((item) => item?.batch_no === batchNumber);
        const {
          batch_no: batchNo, expiry_date: expiryDate, quantity: quantityReceived
        } = editBatchItem;
        const totalQty = singleBatchItem?.filter((item) => item?.batch_no !== batchNumber)?.map(({ quantity }) => Number(quantity)).reduce((a, b) => a + b, 0);
        setBatch({ batchNo, expiryDate, quantityReceived });
        setQuan(totalQty);
      } else { setBatch({ ...initialState, quantityReceived: q }); setQuan(batchQty); }
    } else {
      setBatch({ ...initialState, quantityReceived: Number(qtyOrder) });
    }
  }, [singleBatchItem, singleBatchDetails]);

  const validateState = (name, value) => {
    switch (name) {
      case 'batchNo':
        return setErrorState({ ...errorState, batchNoError: !value });
      case 'quantityReceived':
        return setErrorState({ ...errorState, quantityError: !value });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'quantityReceived' && (((Number(value) + quan) > qtyOrder) || Number(value) < 0)) return toast.error('Total batch quantity cannot exceed the order quantity');
    setBatch({ ...batch, [name]: value });
  };

  const handleKeyUp = (e, field) => {
    if (field === 'quantityReceived') { blockInvalidChar(e); }
  };

  const [createBatch] = useMutation(WHOLESALER_BATCH);

  const handleClose = () => {
    onClose();
    setBatch(initialState);
  };

  const handleSave = () => {
    setIsLoading(true);
    const {
      quantityReceived
    } = batch;
    createBatch({
      variables: {
        ...batch,
        quantity: parseFloat(quantityReceived),
        orderProductId: +productId,
        action: singleBatchDetails ? 'EDIT' : 'ADD'
      }
    }).then(({ data: { manageBatchOrderProduct: { message } } }) => {
      toast.success(message);
      setSuccessOpenDialog(true);
      if (refetch) refetch();
      handleClose();
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.message);
    });
  };

  const { batchNoError, quantityError } = errorState;

  const modalFields = [
    {
      name: 'batchNo', label: 'Batch No.', required: true, placeholder: 'Enter Batch No.', error: batchNoError,
      helperText: 'Batch number field is required'
    },
    {
      name: 'quantityReceived', label: 'Quantity Available', type: 'number', required: true, placeholder: 'Enter Quantity',
      error: quantityError, helperText: 'Quantity  field is required'
    },
  ];

  const bottomFields = [
    {
      name: 'expiryDate', label: 'Expiry Date', required: true, placeholder: 'Select Date', type: 'date'
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, error, helperText, placeholder, type
    } = field;
    const value = batch[fieldName];
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type || 'text'}
        error={error || false}
        helperText={error && helperText}
        name={fieldName}
        onChange={handleChange}
        onKeyUp={(e) => handleKeyUp(e, fieldName)}
        placeholder={placeholder}
        cSize="lg"
        greyLabel="grey"
      />
    );
  };

  return (
    <>
      <CDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        fullWidth
      >
        <GridWrapper container>
          <Grid container>
            <Grid item container xs={6}>
              <DialogTitleText>{brandName}</DialogTitleText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item container spacing={2}>
              <Grid container spacing={2}>
                {modalFields.map((field) => (
                  <Grid item key={field?.name} xs={6}>
                    { returnTextField(field) }
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} marginTop="5px">
                {bottomFields.map((field) => (
                  <Grid item key={field?.name} xs={12}>
                    { returnTextField(field) }
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} disabled={batch?.quantityReceived < 1} style={{ width: '11rem', height: '2.7rem' }}>
              {
            isLoading ? (
              <CircularProgress
                disableShrink
                size={18}
                thickness={4}
                style={{ color: '#fff' }}
              />
            )
              : singleBatchDetails ? 'Update batch' : 'Add batch'
          }
            </Button>
          </DialogActions>
        </GridWrapper>
      </CDialog>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={`${singleBatchDetails ? 'Batch Updated!' : 'Batch Added'}`}
        desc={`${singleBatchDetails ? 'Batch updated successfully' : 'A batch has been set to this product'}`}
        option="ok"
        refetch={refetch}
      />
    </>
  );
};

SingleBatchDialog.propTypes = {
  quantity: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  singleBatchDetails: PropTypes.instanceOf(Object),
  brandName: PropTypes.string.isRequired,
  singleBatchItem: PropTypes.instanceOf(Array).isRequired,
  productId: PropTypes.string.isRequired,

};

SingleBatchDialog.defaultProps = {
  onClose: () => {},
  refetch: () => {},
  singleBatchDetails: {}
};

export default SingleBatchDialog;
