import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Products from './products/products';
import MainContent from '../customComponents/mainContent';
import Filter from './filter';
import {
  OrderGridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid,
  TCustomButton, PaperWrapper
} from './inventoryContainer.styles';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';
import CreateProduct from '../sales/createProduct';
import ProductButtonsPopper from './productButtonsPopper';
import UploadFlow from './products/uploadFlow';
import { OgaToken } from '../../graphql/token';
import BatchDetails from '../batchDetails/batchDetails';
import CreateBatches from '../sales/createBatches';
import { UPDATE_PRODUCTS_QUANTITY_MUTATION } from '../../mutations/products';
import ConfirmDialog from '../shared/confirmDialog';
import SuccessDialog from '../shared/successDialog';

const InventoryContainer = () => {
  const [{
    product: { addProductDialogOpen },
    user: { session: { businessType } }
  }, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const navigate = useNavigate();
  const { tab } = useParams() || '';
  const [tabVal, setTabVal] = useState('products');
  const [loading, setLoading] = useState(false);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [productState, setProductState] = useState([]);
  const [select, setSelect] = useState([]);
  const [uploadDescDialog, setUploadDescDialog] = useState(false);
  const [uploadBatchesCsvDialog, setUploadBatchesDialog] = useState(false);
  const isWholesaler = businessType === 'WHOLESALER';
  const [productQuantities, setProductQuantities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);

  const [updateProductQuantity, { loading: saveLoading }] = useMutation(UPDATE_PRODUCTS_QUANTITY_MUTATION);

  const {
    dateFrom, dateTo, sku, productName, status
  } = productState;

  const inventoryTab = [
    {
      label: 'Products', value: 'products', width: '8%', show: true
    },
    {
      label: 'Batches', value: 'batch-details', width: '8%', show: !isWholesaler
    },
    {
      label: '', value: '', width: isWholesaler ? '92%' : '84%', marginTop: '1.3rem', show: true
    },
  ];

  const handleTabChange = (val) => {
    if (!val) return;
    navigate(`/inventory/${val}`);
    setTabVal(val);
  };

  useEffect(() => {
    if (tab) setTabVal(tab);
  }, [tab]);

  const handleExport = async () => {
    const serverUrl = `${OgaToken.SERVER_LINK}`;
    const token = localStorage.getItem('oga_rest_token');
    axios.get(`${serverUrl}handle-csv-export/`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      params: {
        status,
        date_from: dateFrom,
        date_to: dateTo,
        product_name: productName,
        sku,
      },
      paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'repeat' })
    })
      .then((res) => {
        const filename = 'handle-csv-export';
        const blob = new Blob([res.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log('err :>> ', err);
      });
  };

  const handleCsvDialog = () => setUploadCsvDialog(!uploadCsvDialog);
  const handleUploadDescDialog = () => setUploadDescDialog(!uploadDescDialog);
  const handleBatchesCsvDialog = () => setUploadBatchesDialog(!uploadBatchesCsvDialog);

  const productButtons = [
    {
      name: 'Save Quantity ', label: 'save_quantity'
    },
    {
      name: 'Preview Uploads', label: 'preview_upload', loading: false
    },
    {
      name: 'Export', label: 'export'
    },
  ];

  const batchButtons = [
    {
      name: 'Add Batches', label: 'add_batches'
    },
  ];

  const handleProductQuantity = () => {
    updateProductQuantity({
      variables: {
        products: productQuantities,
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductQuantity || {};
        setOpenModal(false);
        setSuccessOpenDialog(true);
        toast.success(message);
      });
  };

  const actionButtonHandler = (pref) => {
    switch (pref) {
      case 'preview_upload':
        return navigate('/product-preview');
      case 'export':
        return handleExport();
      case 'add_batches':
        return handleBatchesCsvDialog();
      case 'save_quantity':
        return setOpenModal(true);
      default:
        break;
    }
  };

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const productActionHandler = (action) => {
    switch (action) {
      case 'add_individually':
        return toggleIndividualDialog();
      case 'upload_order_csv':
        return handleUploadDescDialog();
      default:
        break;
    }
  };

  const displayTab = () => {
    switch (tabVal) {
      case 'products':
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} setProductQuantities={setProductQuantities} />;
      case 'batch-details':
        return <BatchDetails />;
      default:
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} />;
    }
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={3}>
            <PrimaryTitle variant="h5">Inventory</PrimaryTitle>
          </TitleTextGridContainer>
          {tabVal === 'products' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              {productButtons?.map((item) => {
                const {
                  name, label, loading: loadStatus, permission
                } = item;
                if (label === 'save_quantity' && !isWholesaler) {
                  return null;
                }
                return (
                  <TCustomButton
                    key={name}
                    className={`pure-mp-uat-${label}`}
                    type="secondary"
                    header
                    disabled={permission || loadStatus}
                    style={{ width: '10rem', marginRight: '.8rem' }}
                    onClick={() => actionButtonHandler(label)}
                    data-testid={`pure-mp-uat-inventory-${label}`}
                  >
                    {loadStatus ? 'Loading...' : name }
                  </TCustomButton>
                );
              })}
              <ProductButtonsPopper actionHandler={productActionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'batch-details' && (
          <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
            {batchButtons?.map((item) => {
              const {
                name, label, loading: loadStatus, permission
              } = item;
              return (
                <TCustomButton
                  key={name}
                  className={`pure-mp-uat-${label}`}
                  type="secondary"
                  header
                  disabled={permission || loadStatus}
                  style={{ width: '10rem', marginRight: '.8rem' }}
                  onClick={() => actionButtonHandler(label)}
                  data-testid={`pure-mp-uat-inventory-${label}`}
                >
                  {loadStatus ? 'Loading...' : name }
                </TCustomButton>
              );
            })}
          </Grid>
          )}
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
            tab={inventoryTab?.filter((item) => item.show)}
          />
          {displayTab()}
        </PaperWrapper>
      </OrderGridContainer>

      <UploadFlow openDialog={uploadDescDialog} toggleHandler={handleUploadDescDialog} handleUploadModal={handleCsvDialog} />
      {businessType && (
        <CreateProduct
          openProdDialog={uploadCsvDialog}
          toggleHandler={handleCsvDialog}
        />
      )}
      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />

      <CreateBatches
        openDialog={uploadBatchesCsvDialog}
        toggleHandler={handleBatchesCsvDialog}
      />

      <ConfirmDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Save Quantity?"
        desc="Are you sure that you want to save the quantities you just added on the products?."
        options={['Cancel', 'Yes, Proceed']}
        buttonAction={handleProductQuantity}
        loading={saveLoading}
      />

      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="Product(s) Quantity saved!"
        desc="Your product(s) quantity have been successfully saved."
        option="Ok"
      />

    </MainContent>
  );
};

export default InventoryContainer;
