import {
  Grid, Typography, IconButton, TextField, Box, Link,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Drawer from '@mui/material/Drawer';

export const OrderGridContainer = styled(Grid)`
  padding: 1rem;

  @media (min-width: 992px) {
    padding: 0rem 2rem 1.3rem;
  }
`;

export const FilterSide = styled('div')`
  width: 18%;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const Header = styled(Grid)`
  align-items: center;
  justify-content: space-between;

`;

export const ProductSide = styled('div')`
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const TitleHeader = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;

  @media (max-width: 991px) {
    font-size: 3rem;

  }


`;

export const BoxGrid = styled('div')`
width: 100%;
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const OrderSubHeader = styled(Grid)`
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 991px) {
    justify-content: space-between;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  border: 1px solid #E7E8E9;
  border-radius: 3.7rem;
  height: 105px;
  width: 100%;
`;

export const SearchBox = styled(Grid)`
`;

export const FilterImg = styled('img')`
  width: 3rem;
`;

export const LeftDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    width: 100%;
  }
`;

export const SearchContainer = styled(Box)`
  align-items: center;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 11px;
  width: 100%;
  border: 1px solid #E7E8E9;


  & .MuiOutlinedInput-input {
    font-size: 2.2rem;
    margin-top: 5px;
  };
  & .MuiOutlinedInput-root {
    border-radius: 10px;
  };
  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  };
  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  };

  @media(min-width: 992px) {
    height: 3rem;
    & .MuiOutlinedInput-input {
      font-size: .9rem;
      font-weight: 500;
      text-align: left;
    };
    & .MuiOutlinedInput-adornedStart {
      padding-left: 30px;
    }
    & .MuiOutlinedInput-root {
      height: 2.5rem;
    };
  }

  @media (max-width: 991px) {
    border: 2px solid #E7E8E9;
    height: 110px;
    border-radius: 4.2rem;
  }

`;

export const SearchButton = styled(IconButton)`
  padding: ${(props) => (props.small ? '15px 20px' : '19px 28px')};
  color: #235A91;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:disabled {
    color: #235A91;
  }

  @media(min-width: 992px) {
    padding: ${(props) => (props.small ? '5px 8px' : '10px 12px')};
  }
`;

export const SearchImage = styled('img')`
  width: 40px;

  @media(min-width: 992px) {
    width: ${(props) => (props.small ? '20px' : '22px')};
  }
`;

export const ClearIcon = styled(CloseIcon)`
  font-size: ${(props) => (props.small ? '1.25rem' : '22px')};

  @media(max-width: 992px) {
    font-size: ${(props) => (props.small ? '2.5rem' : '3.8rem')};
  }
`;

export const ContentBox = styled('div')`
  padding: 2rem 3rem 0rem;
  background: #ffffff;
  min-height: 90vh;
  @media (max-width: 991px) {
  padding: 2rem 1.5rem 1rem 1.5rem;

  }
`;

export const CategoriesBox = styled('div')`
  // padding: 1rem;

  @media (max-width: 991px) {
  }

  // @media (min-width: 992px) {
  //   padding: 1rem;
  // }
`;

export const CategoriesTitle = styled(Typography)`
  font-size: 2.5rem;
  font-weight: 650;

  @media(min-width: 992px) {
    font-size: 1.5em;
    font-weight: 650;
    margin-bottom: 0;
  }
`;

export const ProductsBox = styled('div')`
`;

export const CheckboxImage = styled('img')`
  width: 3rem;

  @media (min-width: 992px) {
    width: 17px;
  }
`;

export const BannerContainer = styled(Grid)`
padding: 13rem 1.5rem 0rem 2rem;
background: #ffffff;

@media(min-width: 992px) {
  padding: 6rem 2.6rem 0rem 2.7rem;
}


`;

export const ImageLink = styled(Link)`
width: 100%;
  &:hover {
  color: #676767;
    cursor: pointer;
  }
`;

export const FilterDropButton = styled(Button)`
  border: 1px solid #DFDFE0;
  margin-left: 5px;
    height: 45px;
  @media (max-width: 991px) {
    margin-left: 30px;
    width: 250px !important;
    height: 100px !important;
    font-size: 2rem !important;
  }
`;

export const FilterDropGrid = styled(Grid)`
  text-align: right;
`;

export const FilterImage = styled('img')`
padding-right: 6px;
@media(max-width: 991px) {
  height: 3rem;
}
`;
