import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabsGrid = styled(Grid)`
  align-items: center;
  border-radius: 2px;
  padding-bottom: 1rem;
`;

export const UnderlineGrid = styled(Grid)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ active }) => (active ? '5px solid #235A91' : '5px solid #ECF6FF')};
  width: ${({ width }) => width};
  border-radius: 3px;
`;

export const Typo = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ active }) => (active ? '600' : '400')};;
  padding: 10px 15px;
  color: #475467;
  display: flex;
  align-items: center;
`;

export const TotalsGrid = styled('div')`
  border-radius: 30%/50%;
  height: 14px;
  margin-left: 5px;
  background: #ee4223;
  color: #fff;
  text-align: center;
  font-size: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 2px;
`;
