import {
  Grid, Box, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserGridContainer = styled(Grid)`
  background: #F3F9FF;
  align-items: center;
  flex-direction: column;
`;

export const DetailBox = styled(Box)`
  margin-top: 25vh;
  text-align: center;
`;

export const EmptyImage = styled('img')`
`;

export const TitleText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #303030;
  line-height: 30px;
`;

export const SubText = styled(Typography)`
  font-size: .875rem;
  font-weight: 400;
  line-height: 24px;
  margin-top: .5rem;
`;
