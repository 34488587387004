import React from 'react';
import { UnverifyContainer, UnverifyHeader, UnverifyText } from './unverifiedComponent.style';
import { EmptyBag } from '../../../assets/svgs';

const UnverifiedComponent = () => (
  <UnverifyContainer>
    <EmptyBag style={{ width: '15rem', height: '15rem' }} />
    <UnverifyHeader>
      Account not verified yet!
    </UnverifyHeader>
    <UnverifyText>
      You can’t buy or sell any item until your account has been verified. Please
      <br />
      reach out to our customer support
      <a href="mailto:support@ogapharmacy.com"> support@ogapharmacy.com </a>
      if your
      <br />
      verification delays after 72hrs.
    </UnverifyText>
  </UnverifyContainer>
);

export default UnverifiedComponent;
