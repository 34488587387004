import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import MainContent from '../../customComponents/mainContent';
import { ORDERS_READY_FOR_DISPATCH } from '../../../queries/orders';
import Loader from '../../shared/loader';
import {
  BackArrowIconContainer, ContainerHeader, FieldCheckbox, FilledButtonText, GridContainer,
  KeyboardArrowLeftStyled, PageTitleContainer, PageTitleSubText, PageTitleText
} from '../manageOrdersDispatch.styles';
import {
  DispatchButton, MultiSelectContainer, MulTextStyled, OrderListStyled,
} from './dispatchOrderContainer.styles';
import ConfirmDialog from '../../shared/dialog/confirmDialog';
import ReturnRow from './returnRow';
import { DISPATCH_ORDERS } from '../../../mutations/orders';

const DispatchOrderContainer = () => {
  const navigate = useNavigate();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [selected, setSelected] = useState([]);
  const [orderIds, setOrderIds] = useState([]);

  const {
    loading, data, error, refetch
  } = useQuery(ORDERS_READY_FOR_DISPATCH, { fetchPolicy: 'network-only' });

  const totalOrders = data?.allOrdersReadyForDispatchTotalNumber || 0;
  const orders = data?.allOrdersReadyForDispatch ?? [];

  useEffect(() => {
    const ids = orders.map((item) => item.id);
    setOrderIds(ids);
  }, [data]);

  const handleSelectAll = (e) => {
    const chk = e.target.checked;
    if (chk) setSelected(orderIds);
    else setSelected([]);
  };

  const handleSelect = (e, val) => {
    const chk = e.target.checked;
    if (chk) {
      setSelected((_s) => ([..._s, val]));
    } else {
      const ids = selected.filter((id) => id !== val);
      setSelected(ids);
    }
  };

  const [dispatchOrders, { loading: dispatchLoading }] = useMutation(DISPATCH_ORDERS);

  useEffect(() => {
    if (confirmStatus) {
      if (selected.length < 1) return toast.error('Kindly select at least one order');
      dispatchOrders({
        variables: { businessOrderIds: selected }
      }).then(({ data: dispatchResponse }) => {
        setConfirmStatus(false);
        setOpenConfirmDialog(false);
        const { message } = dispatchResponse.dispatchOrders;
        toast.success(message);
        refetch();
      }).catch((err) => toast.error(err.message));
    }
  }, [confirmStatus]);

  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <MainContent>
      <GridContainer>
        <BackArrowIconContainer onClick={() => navigate('/manage-orders')}>
          <KeyboardArrowLeftStyled />
          Back
        </BackArrowIconContainer>

        <ContainerHeader container md={12}>
          <PageTitleContainer>
            <PageTitleText>Dispatch Orders</PageTitleText>
            <PageTitleSubText>Bulk Dispatch Orders</PageTitleSubText>
          </PageTitleContainer>
          <DispatchButton onClick={() => setOpenConfirmDialog(true)}>
            <FilledButtonText>Dispatch Selected Orders</FilledButtonText>
          </DispatchButton>
        </ContainerHeader>

        <MultiSelectContainer>
          <MulTextStyled>
            {`${totalOrders}  Order(s)`}
          </MulTextStyled>
          <Box sx={{ display: 'flex', alignItems: 'center' }} gap={1}>
            <FieldCheckbox checked={selected.length === orderIds.length} onClick={handleSelectAll} />
            <MulTextStyled>Select All</MulTextStyled>
          </Box>
        </MultiSelectContainer>

        <OrderListStyled container>
          {orders.map((order) => (
            <ReturnRow key={order?.id} row={order} selected={selected} handleSelect={handleSelect} />
          ))}
        </OrderListStyled>
      </GridContainer>

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Dispatch Order"
        desc="Are you sure you want to mark this order ready for dispatch?"
        options={['Cancel', 'Yes, Dispatch Order']}
        setStatus={setConfirmStatus}
        loading={dispatchLoading}
      />

    </MainContent>
  );
};

export default DispatchOrderContainer;
