import React from 'react';
import {
  DialogContent,
  Slide,
  Box, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import warning from '../../../assets/images/Warning.gif';
import {
  DialogContainer,
  DialogContentStyled,
  TitleStyled,
  DescStyled,
  FooterStyled
} from './confirmDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmDialog = ({
  openDialog,
  setOpenDialog,
  title,
  desc,
  options,
  loading,
  setStatus
}) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const confirmHandler = () => {
    setStatus(true);
  };

  return (
    <DialogContainer
      open={openDialog}
      data-testid="confirmDialog"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={2}>
              <img src={warning} alt="warning" width={155} />
            </Box>
            <TitleStyled>{title}</TitleStyled>
            <DescStyled>{desc}</DescStyled>
          </Box>
        </DialogContent>
        <FooterStyled>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{
              width: '178px',
              height: '43px',
              fontSize: '12px'
            }}
          >
            {options[0]}
          </Button>
          <Button
            variant="contained"
            style={{
              width: '178px',
              height: '43px',
              fontSize: '12px'
            }}
            disabled={loading}
            onClick={confirmHandler}
          >
            {loading ? 'Loading...' : options[1]}
          </Button>
        </FooterStyled>
      </DialogContentStyled>
    </DialogContainer>
  );
};

ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  setStatus: PropTypes.func
};

ConfirmDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  title: '',
  desc: '',
  options: [],
  loading: false,
  setStatus: () => false
};

export default ConfirmDialog;
