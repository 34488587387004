import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CircularProgressLoader = styled(CircularProgress)`
  color: #235A91;
  margin-top: 4px;
  .MuiCircularProgress-circle {
    stroke:  #235A91;
  },
  &.MuiCircularProgress-circleDeterminate {
    stroke:  #ECF6FF;
  },
`;

export const DialogHeader = styled(Typography)`
  font-size: 2rem;
  color: #303030;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
`;

export const DialogDesc = styled(Typography)`
  font-size: 2rem;
  color: #606060;
  font-weight: 400;
  padding: 20px 40px;
  width: 99%;
  margin: 10px 0px;

  @media (min-width: 992px) {
    font-size: 0.875rem;
    margin: 0px;

  }
`;
