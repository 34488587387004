import React from 'react';
import PropTypes from 'prop-types';
import { Grow, ClickAwayListener } from '@mui/material';
import { PopperContainer, WrapperPaper, Arrow } from './utils.styles';
import { JSONParse } from './json';
import { OgaToken } from '../graphql/token';

export const RenderPopper = ({
  anchorEl, onClickAway, open, children, popperPlacement, styles,
  modifiers, showArrow, productInfoPopper, marginLeft, marginTop,
  width, marginRight
}) => (
  <PopperContainer
    open={open}
    placement={popperPlacement}
    anchorEl={anchorEl}
    transition
    disablePortal
    modifiers={modifiers}
    style={styles}
    marginLeft={marginLeft}
    marginTop={marginTop}
    width={width}
    marginRight={marginRight}
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        {...placement}
        id="menu-list-grow"
        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
      >
        <WrapperPaper elevation={2} productInfoPopper={productInfoPopper}>
          <ClickAwayListener onClickAway={onClickAway}>
            {children}
          </ClickAwayListener>
          {(showArrow && <Arrow />)}
        </WrapperPaper>
      </Grow>
    )}
  </PopperContainer>
);

RenderPopper.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClickAway: PropTypes.func,
  children: PropTypes.element,
  popperPlacement: PropTypes.string,
  open: PropTypes.bool.isRequired,
  modifiers: PropTypes.instanceOf(Object),
  showArrow: PropTypes.bool,
  productInfoPopper: PropTypes.bool,
  styles: PropTypes.instanceOf(Object),
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  width: PropTypes.string,
  marginRight: PropTypes.string,
};

RenderPopper.defaultProps = {
  onClickAway: () => null,
  anchorEl: <span />,
  popperPlacement: 'bottom',
  children: <span />,
  modifiers: {},
  showArrow: false,
  productInfoPopper: false,
  styles: {},
  marginLeft: '',
  marginTop: '',
  width: '',
  marginRight: '',
};

export const isUserLoggedIn = () => {
  const authToken = localStorage.getItem('oga_auth_token');
  return Boolean(authToken);
};

export const getCustomerObject = (customer) => {
  const parsedContacts = JSONParse(customer?.contacts?.replace(/'/g, '"'));
  const parsedMeta = JSON.parse(customer?.meta?.replace(/'/g, '"'));
  const { email, address_line_one: addressLineOne, mobile_number: phoneNo } = parsedContacts;
  const {
    region, city, photo, notes
  } = parsedMeta;
  const { name, loyaltyPoint, storeCredit } = customer;

  return {
    id: customer.id, name, email, phoneNo, loyaltyPoint, addressLineOne, region, city, photo,
    notes, rawCustomerData: customer, storeCredit, business: customer?.business
  };
};

export const connectionCheck = () => {
  const API_HOST = `${OgaToken.APP_LINK}`;
  const condition = navigator.onLine ? 'online' : 'offline';
  if (condition === 'online') {
    fetch(API_HOST, {
      method: 'POST',
      body: JSON.stringify({ query: '{online}' }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => true).catch(() => false);
  }
  return false;
};

export const renderPoints = (points) => (points ? points.toFixed(2) : points);

export const isERP = () => {
  const role = localStorage.getItem('oga_user_role');
  return role === 'store-manager' || role === 'chain-manager';
};

export const camelToSnakeCase = (key) => {
  const result = key.replace(/([A-Z])/g, ' $1');
  return result.trim().split(' ').join('_').toLowerCase();
};

export const getUser = (user) => user?.fullName;

export const cleanText = (text) => text.replace(/[^\w\s]/gi, '');

export const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
