import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export default styled(({ ...otherProps }) => (
  <TextField variant="outlined" size="small" {...otherProps} />
))`
  margin-bottom: .5rem;

  & .MuiInputBase-root {
    height: 7rem;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
  }
  & .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(5px, 7px) scale(0.6);
  }
  & .MuiFormHelperText-marginDense {
    margin-top: 0;
    margin-bottom: 4px;
  }
  & .MuiFormHelperText-root.Mui-error {
    margin-top: 0;
    margin-bottom: 4px;
    color: #f44336 !important;
  }
  
  & .MuiFormHelperText-root {
    color: #424242 !important;
    font-family: Montserrat,medium,sans-serif !important;
    font-size: 14px !important;
  }
  & .MuiFilledInput-input {
    font-family: Montserrat,medium,sans-serif;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }

  & .custom-input-root .MuiOutlinedInput-root {
    background-color: #ffffff; /* Set your desired background color here */
  }

  & .MuiOutlinedInput-root {
    background-color: #ffffff; /* Set your desired background color here */

    &.Mui-focused fieldset {
      border: ${({ error }) => (error ? '0.5px solid #ff0000' : '0.5px solid #d8d8d8')};
    }
  }

  @media (min-width: 992px) {
    & .MuiFormHelperText-root {
      font-size: 14px !important;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    & .MuiInputBase-root {
      font-size: 1rem;
      height: 2.875rem;
      font-weight: 500;
      color: #101828;
      line-height: 3.2;
    }
  }
`;
