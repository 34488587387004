import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DialogContainer,
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  ItemWrapper,
  ItemHeader,
  ItemDesc,
  DialogCancelButton,
  DialogActionButtonText,
  DialogOkButton,
  DialogActionWrapper,
  AddFile,
  UploadSvg,
  RocketSvg,
  ItemContainer,
  Image,
  ItemHeaderDesc,
  Header
} from './uploadFlow.style';

import DownloadIcon from '../../../assets/images/DownloadIcon.png';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function UploadFlow({ openDialog, toggleHandler, handleUploadModal }) {
  const handleUpload = () => {
    handleUploadModal();
    toggleHandler();
  };

  return (
    <DialogContainer
      data-testid="product-dialog"
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Bulk Upload</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>Amazing feature that allow users to purchase orders on the marketplace in bulk by uploading a CSV file.</TitleDesc>
          </Grid>
          <ItemContainer container>
            <RocketSvg />
            <Grid>
              <Header>Place orders quickly in THREE (3) steps</Header>
              <ItemHeaderDesc>Follow the simple procedure outlined below, and you&apos;ll be done before you know it.</ItemHeaderDesc>
            </Grid>
          </ItemContainer>
          <ItemWrapper container>
            <Image
              src={DownloadIcon}
              alt="image"
            />
            <Grid>
              <ItemHeader>Download CSV</ItemHeader>
              <ItemDesc>
                Click the Order Via CSV button below to download the CSV template made
                <br />
                available on the upload modal.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <ItemWrapper container>
            <AddFile />
            <Grid>
              <ItemHeader>Add products and quantity to CSV</ItemHeader>
              <ItemDesc>
                Check for products you want to buy with their names and edit the quantity tab
                <br />
                of the product with the quantity you want to buy, If there are no quantity limits,
                <br />
                you are free to order as many as you want.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <ItemWrapper container>
            <UploadSvg />
            <Grid>
              <ItemHeader>Re-upload CSV file into the system</ItemHeader>
              <ItemDesc>
                You will see the details of your upload in your upload tab and products added to
                <br />
                cart.
              </ItemDesc>
            </Grid>
          </ItemWrapper>
          <Grid style={{ marginTop: '2rem' }}>
            <DialogActionWrapper container>
              <DialogCancelButton
                onClick={toggleHandler}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={handleUpload}
              >
                <DialogActionButtonText>
                  Order via CSV
                </DialogActionButtonText>
              </DialogOkButton>
            </DialogActionWrapper>
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
}

UploadFlow.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  handleUploadModal: PropTypes.func.isRequired
};

UploadFlow.defaultProps = {
  openDialog: false
};
