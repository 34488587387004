import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import {
  GridContainer, KeyboardArrowLeftStyled, BackArrowIconContainer, ContainerHeader, PageTitleContainer,
  PageTitleText, PageTitleSubText, GroupButtonStyled, FilledButton, FilledButtonText, OutlinedButtonText,
  OutlinedButton, SyncBox, Sync
} from './manageOrdersDispatch.styles';
import Stepper from './stepper';
import DeliveryContainer from './delivery';
import DeliveryAddressContainer from './deliveryAddress';
import DeliveryNote from './deliveryNote';
import ProductsContainer from './productsContainer';
import { GET_MANAGE_ORDER } from '../../queries/orders';
import { Product } from '../../providers/reducers/product/classes/Product';
import { ORDER_SORTING, READY_FOR_DISPATCH } from '../../mutations/orders';
import ConfirmDialog from '../shared/dialog/confirmDialog';
import ConfirmModal from '../shared/confirmDialog';
import { useStateValue } from '../../providers/stateProvider';
import { OgaToken } from '../../graphql/token';
import { JSONParse } from '../../utils/json';
import { removeLeadingZeros } from '../../utils/funcs';

const initialState = {
  pageCount: 10,
  pageNumber: 1,
  searchText: '',
  cart: [],
};

const ManageOrdersDispatch = () => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();

  const [{
    user: { session: { id: userId } }
  }] = Object.values(useStateValue());

  const [activeStep, setActiveStep] = useState(-1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [state, setState] = useState(initialState);
  const [qtyConfirmed, setQtyConfirmed] = useState([]);
  const [quantityRecieve, setQuantityRecieve] = useState([]);
  const { pageCount, pageNumber, searchText } = state;
  const [openModal, setOpenModal] = useState(false);

  const {
    loading, data, refetch
  } = useQuery(GET_MANAGE_ORDER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: Number(orderId),
      pageCount: Number(pageCount),
      pageNumber: Number(pageNumber),
      search: searchText.length >= 3 ? searchText : ''
    },
  });

  const { status: businessOrderStatus, id: businessOrderId } = data?.businessOrder ?? {};
  const businessOrder = data?.businessOrder ?? {};
  const total = data?.orderProductsTotal ?? 0;
  const orderProducts = data?.orderProducts || {};
  const { businessType } = businessOrder?.business || {};

  const reformatJson = (str) => JSONParse(str.replace(/\"*([\w&.+×'÷=/-<>\[\]()])+\"/g, (m, _) => m.replaceAll("'", '`')));

  const retrieveProducts = (orderSet) => {
    let products = [];
    const filterOrder = orderSet?.filter((orderProductObj) => {
      const orderP = reformatJson(orderProductObj?.meta) || '';
      if (orderP?.status !== 'DISPATCHED') return orderProductObj;
      return null;
    });
    const orderProductSet = filterOrder?.map((orderP) => {
      const orderProduct = { ...orderP };
      const updatedOrderProduct = {
        ...orderProduct,
        orderSupplierSetId: orderSet?.id
      };
      return updatedOrderProduct;
    });
    products.push(...orderProductSet);

    products = products.map((p) => {
      const {
        id, // orderProductId not Product Id
        name,
        image,
        quantity,
        quantityReceived,
        quantityConfirmed,
        orderSupplierSetId,
        meta: orderMeta
      } = p;

      return {
        ...new Product(p.product),
        id,
        name,
        image,
        quantity,
        quantityReceived,
        orderSupplierSetId,
        received: quantityReceived > 0,
        condition: 'Good',
        initialQuantity: quantity,
        quantityConfirmed,
        orderMeta
      };
    });
    setState((prev) => ({
      ...prev,
      cart: products,
    }));
  };

  useEffect(() => {
    if (businessOrder?.business) {
      const {
        business, dateCreated, id
      } = businessOrder || {};

      const { id: businessId, name, logo } = business || {};
      setState((prev) => ({
        ...prev,
        id,
        businessId,
        name,
        logo,
        dateCreated,
      }));
      retrieveProducts(orderProducts);
    }
  }, [businessOrder]);

  const handleBatchSelection = (id, value) => {
    if (!value.length) return;
    const {
      cart
    } = state;
    let qtyReceived = 0;
    if (value?.length) {
      qtyReceived = value?.reduce((acc, b) => acc + b.qtyToSell, 0);
    }
    setState({
      ...state,
      cart: cart.map((cartItem) => (cartItem.id === id
        ? { ...cartItem, quantityReceived: qtyReceived }
        : cartItem))
    });
    if (quantityRecieve.some((item) => item.productId === id)) {
      const items = quantityRecieve.map(({ quantityReceived, productId, batches }) => {
        if (productId === id) return ({ productId, quantityReceived: qtyReceived, batches: value });
        return ({ productId, quantityReceived, batches });
      });
      setQuantityRecieve(items);
    } else {
      setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: qtyReceived, batches: value }]);
    }
  };

  const handleQuantityConfirmedUpdate = (id) => {
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (qtyConfirmed.some((item) => item.productId === id)) {
          const items = qtyConfirmed.map(({ productId, quantityConfirmed }) => {
            if (productId === id) return ({ productId, quantityConfirmed: cartItem.quantityConfirmed });
            return ({ productId, quantityConfirmed });
          });
          setQtyConfirmed(items);
        } else {
          setQtyConfirmed([...qtyConfirmed, { productId: id, quantityConfirmed: cartItem.quantityConfirmed }]);
        }
      }
    });
  };

  const handleQuantitySentUpdate = (id) => {
    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (quantityRecieve.some((item) => item.productId === id)) {
          const items = quantityRecieve.map(({ productId, quantityReceived, batches }) => {
            if (productId === id) return ({ productId, quantityReceived: cartItem.quantityReceived, ...(batches && { batches }) });
            return ({ productId, quantityReceived, ...(batches && { batches }) });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: cartItem.quantityReceived }]);
        }
      }
    });
  };

  const handleQtyConfirmedIncrement = (id) => {
    const {
      cart
    } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    // eslint-disable-next-line consistent-return
    if (existingCartItem?.quantityConfirmed >= existingCartItem?.initialQuantity) return toast.error('Quantity Confirmed cannot be more than Quantity Ordered');
    const newCart = cart.map((item) => {
      if (item.id === id) {
        if (item.quantityConfirmed === item.quantityReceived) {
          item.quantityConfirmed += 1;
          item.quantityReceived = item.quantityConfirmed;
        } else {
          item.quantityConfirmed += 1;
        }
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleQtyConfirmedDecrement = (id) => {
    const {
      cart
    } = state;
    const newCart = cart.map((item) => {
      if (item.id === id) {
        if (item?.quantityConfirmed > 0) {
          if (item.quantityConfirmed === item.quantityReceived) {
            item.quantityConfirmed -= 1;
            item.quantityReceived = item.quantityConfirmed;
          }
          if (item.quantityConfirmed > item.quantityReceived) {
            item.quantityConfirmed -= 1;
          }
          if (item.quantityConfirmed < item.quantityReceived) {
            item.quantityConfirmed -= 1;
            item.quantityReceived = item.quantityConfirmed;
          }
        }
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleConfirmedQtyInputValue = (id, value) => {
    let newValue = 0;
    const {
      cart
    } = state;
    if (Number(value)) {
      newValue = Number(value);
    }
    const existingCartItem = cart.find((item) => item.id === id);
    if (newValue > existingCartItem?.initialQuantity) return toast.error('Quantity Confirmed Cannot be more than Quantity Ordered');
    // eslint-disable-next-line consistent-return
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityConfirmed = removeLeadingZeros(newValue);
        item.quantityReceived = removeLeadingZeros(newValue);
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });
    handleQuantityConfirmedUpdate(id);
    handleQuantitySentUpdate(id);
  };

  const handleQuantitySentInputValue = (id, value) => {
    const { cart } = state;
    let newValue = 0;

    if (Number(value)) newValue = Number(value);

    const existingCartItem = cart.find((item) => item.id === id);
    if (newValue > existingCartItem?.quantityConfirmed) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityReceived = removeLeadingZeros(newValue);
        return item;
      }
      return item;
    });
    setState({ ...state, cart: newCart });

    handleQuantitySentUpdate(id);
  };

  const handleQtySentIncrement = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find((item) => item.id === id);
    // eslint-disable-next-line consistent-return
    if (existingCartItem?.quantityReceived >= existingCartItem?.quantityConfirmed) return toast.error('Quantity Sent cannot be more than Quantity Confirmed');
    const newCart = state.cart.map((item) => {
      if (item.id === id) {
        item.quantityReceived += 1;
        return item;
      }
      return item;
    });
    handleQuantitySentUpdate(id);
    setState({ ...state, cart: newCart });
  };

  const handleQtySentDecrement = (id) => {
    const {
      cart
    } = state;

    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === id
    );
    if (existingCartItem.quantity > 1) {
      const newCart = state.cart.map((item) => {
        if (item.id === id) {
          if (item?.quantityReceived > 0) { item.quantityReceived -= 1; }
          return item;
        }
        return item;
      });
      setState({ ...state, cart: newCart });
      handleQuantitySentUpdate(id);
    }
  };

  const handleChangeCondition = (id, value, key) => {
    const {
      cart
    } = state;
    const existingCartItem = cart.find((item) => item.id === id);
    if (!existingCartItem.received && key !== 'received') return;

    setState((prevState) => ({
      ...prevState,
      cart: prevState.cart.map((cartItem) => (cartItem.id === existingCartItem.id
        ? { ...cartItem, [key]: value, quantityReceived: value ? cartItem.quantity : 0 }
        : cartItem))
    }));

    state.cart.forEach((cartItem) => {
      if (cartItem.id === id) {
        if (quantityRecieve.some((item) => item.productId === id)) {
          const items = quantityRecieve.map(({ quantityReceived, productId }) => {
            if (productId === id && value) return ({ productId, quantityReceived: cartItem.quantity });
            if (productId === id) return ({ productId, quantityReceived: 0 });
            return ({ productId, quantityReceived });
          });
          setQuantityRecieve(items);
        } else {
          setQuantityRecieve([...quantityRecieve, { productId: id, quantityReceived: !cartItem.quantityReceived ? cartItem.quantity : 0 }]);
        }
      }
    });
  };

  const [sorting, { loading: sortLoading }] = useMutation(ORDER_SORTING);
  const [dispatch, { loading: dispatchLoading }] = useMutation(READY_FOR_DISPATCH);

  useEffect(() => {
    if (confirmStatus) {
      dispatch({
        variables: { businessOrderId: Number(businessOrderId) }
      })
        .then(({ data: sortResponse }) => {
          setConfirmStatus(false);
          setOpenConfirmDialog(false);
          refetch();
          toast.success(sortResponse?.markOrderReadyForDispatch?.message);
        })
        .catch((e) => toast.error(e.message));
    }
  }, [confirmStatus]);

  const handleOrderSorting = (status, cancelOrder = false) => {
    const combinedArray = quantityRecieve.map((receivedItem) => {
      const confirmedItem = qtyConfirmed.find((confirmed) => confirmed.productId === receivedItem.productId);
      const combinedItem = {
        orderProductId: Number(receivedItem.productId),
        quantityReceived: receivedItem.quantityReceived,
        ...(confirmedItem && { quantityConfirmed: confirmedItem.quantityConfirmed })
      };
      if (receivedItem.batches) {
        combinedItem.batches = receivedItem.batches;
      }
      return combinedItem;
    });

    let request = {};

    if (businessType === 'MANUFACTURER') {
      request = {
        businessOrderId: Number(businessOrderId),
        status,
        changes: combinedArray
      };

      request?.changes?.forEach((c) => {
        c?.batches?.forEach((b) => {
          b.batchId = Number(b.batchId);
          delete b.batchNo;
          delete b.expiryDate;
        });
      });
    } else {
      request = {
        businessOrderId: Number(businessOrderId),
        status,
        changes: combinedArray
      };
      request.changes.forEach((c) => {
        delete c.batches;
      });
    }

    if (cancelOrder) {
      request.forCancellation = true;
    }

    sorting({
      variables: { ...request }
    }).then(({ data: sortResponse }) => {
      refetch();
      setOpenModal(false);
      toast.success(sortResponse?.updateOrderStatus?.message);
    }).catch((e) => toast.error(e.message));
  };

  const handlePrintInvoice = () => {
    window.open(
      `${OgaToken.SERVER_LINK}print-invoice-html/${orderId}/?user_id=${userId}`,
      '_blank'
    );
  };

  const handleNextStep = (s) => {
    switch (s) {
      case 'ORDER_SORTED':
        return setActiveStep(0);
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return setActiveStep(1);
      case 'ORDER_DISPATCHED':
      case 'CLOSED':
        return setActiveStep(2);
      default: return setActiveStep(-1);
    }
  };

  useEffect(() => {
    handleNextStep(businessOrderStatus);
  }, [businessOrderStatus]);

  const handleGenerateProforma = () => {
    const { id, businessId } = state;
    window.open(`${OgaToken.SERVER_LINK}download-proforma-xlsx/${id}/?user_id=${userId}&proforma_type='single'`, '_blank');
  };

  const handleCancelOrder = () => {
    handleOrderSorting('CLOSED', true);
  };
  const handleCancelOrderModal = () => {
    setOpenModal(true);
  };

  return (
    <MainContent>
      <GridContainer>
        <BackArrowIconContainer onClick={() => navigate('/manage-orders')}>
          <KeyboardArrowLeftStyled />
          Back
        </BackArrowIconContainer>

        <ContainerHeader container md={12}>
          <PageTitleContainer>
            <PageTitleText>Order Detail</PageTitleText>
            <PageTitleSubText>View Order details</PageTitleSubText>
          </PageTitleContainer>
          {
            businessOrderStatus === 'ORDER_DISPATCHED' || businessOrderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || businessOrderStatus === 'CLOSED'
              ? (
                <GroupButtonStyled>
                  <OutlinedButton
                    onClick={handlePrintInvoice}
                  >
                    <OutlinedButtonText>Print Invoice</OutlinedButtonText>
                  </OutlinedButton>
                  <FilledButton
                    disabled={businessOrderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || businessOrderStatus === 'CLOSED'}
                    onClick={() => handleOrderSorting('CLOSED')}
                  >
                    <FilledButtonText>Complete Order</FilledButtonText>
                  </FilledButton>
                </GroupButtonStyled>
              )
              : (
                <GroupButtonStyled>
                  {
                    businessOrderStatus === 'ORDER_SORTED'
                      ? (
                        <>
                          <FilledButton
                            disabled={dispatchLoading}
                            onClick={() => setOpenConfirmDialog(true)}
                          >
                            <FilledButtonText>{sortLoading ? 'loading ...' : 'Ready For Dispatch' }</FilledButtonText>
                          </FilledButton>

                          <FilledButton onClick={handleGenerateProforma}>
                            <FilledButtonText>Generate Proforma</FilledButtonText>
                          </FilledButton>
                        </>
                      )
                      : (
                        <FilledButton
                          disabled={sortLoading || businessOrderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || businessOrderStatus === 'ORDER_AWAITING_APPROVAL'}
                          onClick={() => handleOrderSorting('ORDER_SORTED')}
                        >
                          <FilledButtonText>{sortLoading ? 'loading ...' : 'Complete Sorting' }</FilledButtonText>
                        </FilledButton>
                      )
                    }
                  <OutlinedButton
                    disabled={sortLoading || businessOrderStatus === 'ORDER_QUEUED_FOR_DISPATCH' || businessOrderStatus === 'ORDER_AWAITING_APPROVAL'}
                    onClick={() => handleOrderSorting('ORDER_RECEIVED')}
                  >
                    <OutlinedButtonText>{sortLoading ? 'loading ...' : 'Save Changes' }</OutlinedButtonText>
                  </OutlinedButton>
                  <SyncBox>
                    <Sync fontSize="small" />
                  </SyncBox>
                </GroupButtonStyled>
              )
            }

          <Stepper activeStep={activeStep} />
        </ContainerHeader>

        <Grid container md={12} gap="1%" style={{ marginTop: '1rem' }}>
          <Grid item sx={{ width: '32%' }}>
            <DeliveryContainer businessOrder={businessOrder} />
          </Grid>
          <Grid item sx={{ width: '34%' }}>
            <DeliveryAddressContainer businessOrder={businessOrder} />
          </Grid>
          <Grid item sx={{ width: '32%' }}>
            <DeliveryNote />
          </Grid>
        </Grid>

        <ProductsContainer
          loading={loading}
          businessOrderStatus={businessOrderStatus}
          handleOrderSorting={handleOrderSorting}
          businessOrder={businessOrder}
          handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
          handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
          handleQtySentIncrement={handleQtySentIncrement}
          handleQtySentDecrement={handleQtySentDecrement}
          handleConditionChange={handleChangeCondition}
          state={state}
          setState={setState}
          total={total}
          quantityRecieve={quantityRecieve}
          handleBatchSelection={handleBatchSelection}
          handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
          handleQuantitySentInputValue={handleQuantitySentInputValue}
          quantityReceive={quantityRecieve}
          refetchData={refetch}
          handleCancelOrderModal={handleCancelOrderModal}
        />

      </GridContainer>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="Ready for Dispatch?"
        desc="Mark order as ready for dispatch"
        options={['Cancel', 'Proceed']}
        setStatus={setConfirmStatus}
        loading={dispatchLoading}
      />

      <ConfirmModal
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        title="Cancel Order"
        desc="Are you sure you want to cancel this order?"
        options={['Cancel', 'Yes, Cancel Order']}
        buttonAction={handleCancelOrder}
        loading={sortLoading}
      />
    </MainContent>
  );
};

export default ManageOrdersDispatch;
