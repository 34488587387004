import { styled } from '@mui/material/styles';
import { Grid, Typography, Button } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const sm = '960px';
export const CardHeaderGrid = styled(Grid)`
  padding: 40px 0 15px 0;
  border-bottom: 1px solid #E8E8E8;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    padding: 1rem 0;
  }
`;

export const CardHeaderText = styled(Typography)`
  color: #303030;
  font-size: 1.125rem;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const CardGridText = styled(Grid)`
  font-size: .875rem;
  color: #303030;
  font-weight: 400;
  margin-bottom: 1rem;
  gap: .5rem;
  display: flex;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const CardGridSubText = styled(Grid)`
  font-size: .875rem;
  color: #303030;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const GrandTotalGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 15px;
  border-top: 1px solid #E8E8E8;
  padding-top: 20px;
  margin-top: 20px;

  @media (max-width: 991px) {
    padding-top: 35px;
    border-top: 2px solid #ECECEC;
  }
`;

export const PaymentSummaryRowTitle = styled('p')`
  color: #424242;
  font-size: 2rem;
  line-height: 32px;
  margin: 0;

  &:nth-child(2n) {
    margin-left: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 18px
  }
`;

export const PaymentSavedTypo = styled(Typography)`
  font-size: 27px;

  @media (min-width: 992px) {
    font-size: 15px
  }
`;

export const PaymentSummaryRowBoldTitle = styled('p')`
color: #424242;
font-size: 2rem;
line-height: 32px;
font-weight: 600;
margin: 0;

&:nth-child(2n) {
  margin-left: 4rem;
}

@media (min-width: 992px) {
  font-size: 20px;
}
`;

export const PaymentSummaryGrandTotalRow = styled('div')`
display: flex;
align-items: center;
border-top: 1px solid #E8E8E8;
padding-top: 20px;
margin-top: 20px;

@media (max-width: 991px) {
  padding-top: 35px;
  border-top: 2px solid #ECECEC;
}
`;

export const PayNowButton = styled(Button)`
  background-color: #333333;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;

  @media (max-width: 991px) {
    font-size: 1.8rem;
  }
`;

export const TipTool = styled(ErrorOutline)`
  color: #3498DB;
  cursor: pointer;
  transform: rotate(180deg);
  width: 15px;
  margin-top: -2px;
`;

export const TotalText = styled(Grid)`
  font-size: 1rem;
  font-weight: 600;
  color: #303030;
`;
