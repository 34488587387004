import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { InputWrapper, ProceedBtn } from '../register.styles';
import TabSwitch from '../tabSwitch';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';

const CategoryRegStep = ({ state, setState }) => {
  const { category } = state;
  const [tab, setTab] = useState('buyer');
  const [options, setOptions] = useState([]);

  const handleTabChange = (val) => {
    setTab(val);
    setState({
      ...state,
      category: '',
      catType: ''
    });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
      catType: tab
    });
  };

  const handleChangeStep = () => {
    setState({ ...state, currentStep: 'step1' });
  };

  useEffect(() => {
    if (tab === 'buyer') {
      setOptions(['Wholesaler', 'Retailer']);
    } else {
      setOptions(['Importer', 'Manufacturer', 'Wholesaler']);
    }
  }, [tab]);

  const categoryField = [
    {
      name: 'category', label: 'Category', options, secured: false, show: true,
      onChange: handleSelectChange, placeholder: 'Select', required: true, val: category
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val
    } = field;
    const value = state[fieldName];
    if (['category'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
  };

  return (
    <>
      <TabSwitch
        tabVal={tab}
        handleTabChange={handleTabChange}
      />
      <InputWrapper item container>
        <Grid item xs={12} lg={12}>
          {categoryField.filter((item) => item.show).map((field) => (
            <Grid item key={field?.name} xs={12}>{returnTextField(field)}</Grid>
          ))}
        </Grid>
      </InputWrapper>
      <ProceedBtn disabled={state.category === ''} onClick={handleChangeStep}>Proceed</ProceedBtn>
    </>
  );
};

CategoryRegStep.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

export default CategoryRegStep;
