import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const sm = '960px';

export const ProductCardsContainer = styled(Grid)`
  // @media (min-width: 992px) {
  //   margin: auto;
  // }
`;

export const ProductCardGrid = styled(Grid)`
  width: 20%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  @media (max-width: 1200px) {
    width: 33%;
  }
  @media (max-width: 991px) {
    margin: auto;
    width: 100%;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin: 15px 0px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const NoResultsText = styled(Typography)`
  font-size: 18px;
  color: #4D4F5C;
  padding: 10px 15px;

  @media(max-width: ${sm}) {
    font-size: 14px;
  }
`;

export const NoResultsContainer = styled(Grid)`
align-items: center;
margin: 20% auto;
width: 65%;
text-align: center;

`;

export const NoResultsHeader = styled(Typography)`
font-size: 22px;
font-weight: 700;
color: #303030;
padding: 10px;

@media(max-width: ${sm}) {
  font-size: 14px;
}
`;
