export function generateRandomNumber(n) {
  if (n <= 0) {
    return 0;
  }
  const min = 10 ** (n - 1); // Minimum number with n digits
  const max = 10 ** n - 1; // Maximum number with n digits

  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function rmDuplicateItemsFromArr(arr) {
  const newArr = arr.filter((v, i, self) => i === self.indexOf(v));

  // check if it contains an undefined value/element
  const outputArray = newArr.filter((element) => element !== undefined);

  return outputArray;
}

export function validateEmailAddress(email) {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(email);
}

export function containsLowerCase(str) {
  return /[a-z]/.test(str);
}

export function containsUpperCase(str) {
  return /[A-Z]/.test(str);
}

export function containsNumber(str) {
  return /[0-9]/.test(str);
}

export function minStrLength(str, n) {
  if (str.length < n) return false;
  return true;
}

export function containsSpecialCharacter(str) {
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);
}

export function removeLeadingZeros(number) {
  let numString = number.toString();
  numString = numString.replace(/^0+/, '');
  return parseInt(numString, 10);
}
