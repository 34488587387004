import {
  Typography, Checkbox, FormControl, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { HelpOutlineRounded } from '@mui/icons-material';

export const CFormControl = styled(FormControl)`
  & .MuiFilledInput-root {
    background-color: transparent;
    border: 0.5px solid #d8d8d8;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #606060;
    height: 2.875rem;
    line-height: .5rem;
    padding-left: 5px;

    &::before {
      content: " ";
      border-bottom: 0;
    }
  }
`;

export const Label = styled(Typography)`
  color: ${({ error }) => (error ? '#ff0000' : '#303030')};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: .2rem;
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #FCED50;
  padding: 6px;
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;

export const CheckboxImage = styled('img')`
  width: 17px;
`;

export const MenuItems = styled(MenuItem)`
  &.Mui-selected, .Mui-selected:hover {
    background-color: #F3F9FF;
  }
  &:hover {
    background-color: #F3F9FF;
  }
`;

export const HelperText = styled(Typography)`
  font-size: 10px;
  color: #ff0000;
  opacity: 0.8;
  margin-left: 10px;
`;

export const HelpOutlineStyled = styled(HelpOutlineRounded)`
  width: 15px;
  height: 15px;
  color: #78aada;
`;

export const TooltipTitle = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #F5F5F5;
`;

export const ToolTipContent = styled(Typography)`
  font-size: .75rem;
  font-weight: 400;
  color: #ECF6FF;
`;
