import { styled } from '@mui/material/styles';
import {
  DialogContentText, DialogActions, Grid,
  Typography, Dialog, DialogContent, Box
} from '@mui/material';

export const TermsDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 11px;
    min-width: 58rem;
    padding: 1rem;
  }
`;

export const ContainerHead = styled(Box)`
  margin:20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding:0;
`;

export const DialogCon = styled(DialogContent)`
  padding: 20px 15px;
  margin-top: 1rem;
`;

export const DialogActionsWrapper = styled(DialogActions)`
  padding: 20px 0px 20px 30px;

`;

export const Content = styled(DialogContentText)`
  margin:5px 10px;
`;

export const TermsText = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  color: #235a91;
`;

export const ProcureText = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 700;
  color: #303030;
`;

export const GenText = styled(Typography)`
  font-size: .875rem;
  font-weight: 400;
  color: #606060;
`;

export const PaymentContainer = styled(Box)`
  background: #f3f9ff;
  border-radius: 9px;
  padding: 1.5rem 2rem;
  height: 12.688rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const PaymentText = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  color: #303030;
  margin-bottom: 1rem;
`;

export const PaymentTitle = styled(Typography)`
  color: #303030;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: .7rem;
`;

export const PaymentSub = styled(Typography)`
  color: #606060;
  font-weight: 400;
  font-size: .875rem;
  margin-bottom: 1rem;
`;

export const TextGrid = styled(Grid)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const TextBullet = styled(Box)`
  width: .563rem;
  height: .563rem;
  background: #3498db;
  border-radius: 50%;
  margin-top: .4rem;
`;

export const BoldGenText = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #606060;
`;
