import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from '@apollo/client';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ItemGrid, ItemContent, ItemTypo, DetailContent, ImgContent,
  DetailsContent, ActionItem, CardLogo, DateTypo
} from './returnRow.styles';
import { VerticalDots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import { BUYER_REORDER } from '../../../mutations/orders';
import { GET_CART_TOTAL_COUNT } from '../../../queries/cart';

const ReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  const {
    id, total, datePlaced: date
  } = orderItem;

  const [action, setAction] = useState(null);

  const [productReorder] = useMutation(BUYER_REORDER);

  const datePlaced = moment(date).format('MMMM DD, YYYY [at] h:mma');
  const amount = `₦${currencyFormatter(total)}`;

  const { data: cartTotal, refetch } = useQuery(GET_CART_TOTAL_COUNT, {
    fetchPolicy: 'cache-and-network'
  });

  const handleViewDetails = (_id) => {
    navigate(`/orders/${_id}/affiliate`);
  };

  const handleTrackOrder = (_id) => {
    navigate('/track-order', {
      state: { orderId: _id }
    });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleReorder = () => {
    productReorder({
      variables: {
        orderId: +id,
      }
    }).then(({ data }) => {
      const { message } = data?.repeatOrder ?? '';
      toast.success(message);
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleAction = (event, type) => {
    if (type === 'Details') {
      handleViewDetails(id);
    } else if (type === 'Re-order') {
      return handleReorder();
    } else {
      handleTrackOrder(id);
    }
    handleOptions(event);
  };

  const handleCloseModal = () => {
    setAction(false);
  };
  return (
    <ItemGrid container className={`mpFe-uat-orders-cards-${index}`}>
      <ItemContent item xs={12} md={1}>
        <ImgContent>
          <CardLogo />
        </ImgContent>
      </ItemContent>
      <ItemContent item xs={12} md={6}>
        <ItemTypo>
          #
          {id}
        </ItemTypo>
        <ItemTypo>
          Date Placed: &nbsp;
          <DateTypo>
            {datePlaced}
          </DateTypo>
        </ItemTypo>
      </ItemContent>
      <ItemContent item xs={12} md={5}>
        <ActionItem container>
          <DetailsContent>
            <Hidden mdDown>
              <VerticalDots style={{ cursor: 'pointer' }} fontSize="medium" onClick={handleOptions} />
            </Hidden>
            <ActionPopper
              action={action}
              handleAction={handleAction}
              handleClose={handleCloseModal}
            />
          </DetailsContent>
          <Hidden mdDown>
            <DetailContent>
              {amount}
            </DetailContent>
          </Hidden>
        </ActionItem>
      </ItemContent>
    </ItemGrid>
  );
};

ReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
  index: PropTypes.number.isRequired
};

ReturnRow.defaultProps = {
  orderItem: {},
};

export default ReturnRow;
