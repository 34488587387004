import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import { TableCellBodyStyled } from './productInfo.style';
import { TableRows } from './returnRow.styles';

const ReturnRow = ({
  row, sellingPrice
}) => {
  const dateReceived = moment(row.dateRecieved).format('DD/MM/YYYY');
  const expiryDate = moment(row.expiryDate).format('DD/MM/YYYY');

  return (
    <TableRows>
      <TableCellBodyStyled>{dateReceived}</TableCellBodyStyled>
      <TableCellBodyStyled>{row.batchNo}</TableCellBodyStyled>
      <TableCellBodyStyled>{expiryDate}</TableCellBodyStyled>
      <TableCellBodyStyled>{row.quantityInStock}</TableCellBodyStyled>
      <TableCellBodyStyled>{`₦ ${currencyFormatter(sellingPrice)}`}</TableCellBodyStyled>
      <TableCellBodyStyled>{`₦ ${currencyFormatter(row.quantityInStock * sellingPrice)}`}</TableCellBodyStyled>
    </TableRows>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  sellingPrice: PropTypes.number
};

ReturnRow.defaultProps = {
  row: {},
  sellingPrice: 0
};

export default ReturnRow;
