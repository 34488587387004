import { Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExportIcon } from '../../assets/svgs';

export const FiltersGrid = styled(Grid)`
  padding: 1.8rem 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 0.7rem;

  @media (min-width: 992px) {
    padding: 0rem 0px 1.4rem 0px;
  }
`;

export const MenuItems = styled(MenuItem)`
  &.Mui-selected,
  .Mui-selected:hover {
    background-color: #f3f9ff;
  }
  &:hover {
    background-color: #f3f9ff;
  }
`;

export const ExportIconStyled = styled(ExportIcon)`
  font-size: 1.2rem;
  color: '#606060';
  margin-right: 5px;
`;

export const ExportText = styled('span')`
  font-size: 13px;
  font-weight: 400;
  color: #235a91;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;
