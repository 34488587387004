import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const UnverifyText = styled(Typography)`
  font-size: 14px;
  color: #475467;
  padding: 5px 15px;
  font-weight: 400;


  @media(max-width: 991px) {
    font-size: 14px;
  }
`;

export const UnverifyContainer = styled(Grid)`
align-items: center;
margin: 10% auto;
width: 50%;
text-align: center;

`;

export const UnverifyHeader = styled(Typography)`
font-size: 18px;
font-weight: 700;
color: #303030;
padding: 7px;

@media(max-width: 991px) {
  font-size: 14px;
}
`;
