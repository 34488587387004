import { createTheme, styled, adaptV4Theme } from '@mui/material/styles';
import {
  Typography, Grid, Box,
  Button
} from '@mui/material';
import { BlueUploadCloud } from '../../../assets/svgs';

export const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiDropzoneArea: {
      icon: {
        display: 'none'
      },
      textContainer: {
        height: '100%',
        marginTop: '15px'
      },
      root: {
        backgroundColor: '#FFF',
        width: '100%',
        cursor: 'pointer',
        borderRadius: '7.93px',
        border: '0.79px dashed #78aada !important',
        minHeight: '0',
        height: '11rem',
        marginBottom: '1rem'
      }
    }
  }
}));

export const MiddleText = styled(Typography)`
  font-size: .595rem;
  color: #00000066;
  font-weight: 400;
  font-family: Montserrat,medium,sans-serif;
`;

export const DropZoneContainer = styled(Grid)`
`;

export const UploadText = styled(Typography)`
  color: #000000;
  font-size: .644rem;
  font-weight: 400;
  text-align: center;
  font-family: Montserrat,medium,sans-serif;
  margin-bottom: .7rem;
`;

export const UploadBox = styled(Box)`
  text-align: center;
`;

export const UploadIcon = styled(BlueUploadCloud)`
  fill: none;
  font-size: 2.5rem;
`;

export const SelectButton = styled(Button)`
  border: 1.5px solid rgba(35, 90, 145, 1);
  font-size: .496rem;
  font-weight: 600;
  text-align: center;
  height: 1.815rem;
  margin-top: 1rem;
  border-radius: 4px;
`;

export const FileText = styled(Typography)`
  font-size: .875rem;
  font-weight: 600;
  color: #344054;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Montserrat,medium,sans-serif;
  margin: 1rem 0;
`;

export const UploadedText = styled(Typography)`
  font-size: .875rem;
  color: #475467;
  font-weight: 400;
  font-family: Montserrat,medium,sans-serif;
`;
