import { gql } from '@apollo/client';

export const ALL_BUSINESSES_QUERY = gql`
  query noPaginationBusinesses($search: String) {
    noPaginationBusinesses(name: $search) {
      id
      name
      status
      profile
      contacts
      dateCreated
      createdBy {
        id
        username
      }
    }
  }
`;

export const ALL_BUSINESS_USER_QUERY = gql`
  query allBusinessUsers($pageNumber: Int, $pageCount: Int) {
    allBusinessUsers(pageNumber: $pageNumber, pageCount: $pageCount) {
      id
      permissionNames
      user {
        username
        profile
      }
      permissions
      meta
      business {
        id
        name
      }
      role {
        id
        name
      }
    }
    allBusinessUsersTotalNumber
  }
`;

export const ALL_BUSINESS_USER_QUERY_UPDATED = gql`
  query allBusinessUsers($pageCount: Int, $pageNumber: Int) {
    allBusinessUsers(pageCount: $pageCount, pageNumber: $pageNumber) {
      id
      status
      fullName
      user {
        username
        profile
      }
      permissions
      permissionIds
      permissionNames
      meta
      business {
        id
        name
      }
      role {
        id
        name
      }
    }
    allBusinessUsersTotalNumber
  }
`;

export const GET_BUSINESS_QUERY = gql`
  query busniess($id: Int!) {
    business(id: $id) {
      name
      contacts
    }
  }
`;

export const GET_ALL_BUSINESS_SUBS_QUERY = gql`
  query affiliates {
    subBusinesses {
      id
      name
      status
      contacts
      settings
      profile
      dateCreated
    }
    allRoles {
      id
      name
      permissions
    }
  }
`;

export const BUSINESS_ROLES_QUERY = gql`
  query businessRoles($businessId: Int!, $pageCount: Int, $pageNumber: Int) {
    businessRoles(
      businessId: $businessId
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      id
      business {
        id
      }
      role {
        id
        name
        permissions
      }
      permissions
    }
    businessRolesTotalNumber
  }
`;

export const BUSINESS_HISTORY_QUERY = gql`
  query businessHistory(
    $id: Int!, $pageCount: Int, $pageNumber: Int
    ){
    businessHistory(
      businessId: $id, pageCount: $pageCount, pageNumber: $pageNumber
      ) {
      dateCreated
      actionDone
      editedBy
      reasonForUpdate
    }
    businessHistoryTotalNumber
  }
`;

export const MANUFACTURER_QUERY = gql`
  query scManufacturers($pageCount: Int, $pageNumber: Int) {
    scManufacturers (pageCount: $pageCount, pageNumber: $pageNumber) {
      name
      id
    }
    scManufacturersTotalNumber
  }
`;

export const BUSINESS_USERS_QUERY = gql`
  query businessUsers($search: String, $roleId: Int, $pageNumber: Int, $pageCount: Int) {
    businessUsers(search: $search, roleId: $roleId, pageNumber: $pageNumber, pageCount: $pageCount) {
      id
      permissionNames
      user {
        username
        profile
      }
      permissions
      fullName
      businessRole {
        role {
          name
        }
      }
      systemRole {
        name
      }
      role {
        id
        name
      }
    }
    businessUsersCount
  }
`;

export const ALL_BUSINESS_ROLES_QUERY = gql`
  query allSystemRoles($search: String $pageNumber: Int, $pageCount: Int) {
    allSystemRoles(
      search: $search pageNumber: $pageNumber, pageCount: $pageCount
    ) {
      id
      name
      businessUserCount
      rolePermissions {
        id
        name
      }
    }
    systemRolesCount
  }
`;

export const ROLES_NAME_AND_ID = gql`
  query allSystemRoles($pageNumber: Int, $pageCount: Int) {
    allSystemRoles(
       pageNumber: $pageNumber, pageCount: $pageCount
    ) {
      id
      name
      businessUserCount
    }

  }
`;

export const USERINFOSTATUS = gql`
query userInfoAndStatus($email: String, $password: String) {
  userInfoAndStatus(
    email: $email,
    password: $password
  ){
    message
  }
}
`;

export const GET_BUSINESS = gql`
  query business ($businessId: Int!) {
    business(businessId: $businessId){
      name
      location
      contact
      meta
      sellerMeta {
        cac {
          url
          fileName
          fileSize
        }
        nafdac {
          url
          fileName
          fileSize
        }
        pharmacyCouncilRegistrationLicence{
          url
          fileName
          fileSize
        }
        registrationNumber
      }
    }
  }
`;

export const GET_BUYER_BUSINESS = gql`
  query business ($businessId: Int!) {
    business(businessId: $businessId){
      id
      name
      status
      location
      businessType
      fullname
      contact
      settings
      meta
      buyerMeta {
        verificationStatus
      }
      buyerContact {
        emailAddress
        phoneNumber
        contactPersonName
        salesRepresentativeName
        deliveryAddress
        city
        landmark
      }
      createdBy{
        id
        email
        fullName
        meta
      }
    }
  }
`;

export const GET_SELLER_BUSINESS = gql`
  query business ($businessId: Int!) {
    business(businessId: $businessId){
      id
      name
      status
      location
      businessType
      fullname
      contact
      settings
      meta
      sellerMeta {
        verificationStatus
        registrationNumber
        cac {
          url
          fileName
          fileSize
        }
        nafdac {
          url
          fileName
          fileSize
        }
        pharmacyCouncilRegistrationLicence{
          url
          fileName
          fileSize
        }
      }
      sellerContact {
        emailAddress
        phoneNumber
        contactPersonName
        salesRepresentativeName
        storeAddress
        city
        landmark
      }
      createdBy{
        id
        email
        fullName
        meta
      }
    }
  }
`;
