import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  DialogContainer, CardTitle, DialogContentStyled, TitleDesc,
  SaveButton, CircularProgressLoader
} from './uploadFile.styles';
import { CustomSelectInputBase } from './customInput';
import { UPDATE_BUSINESS_PROFILE } from '../../../mutations/business';
import SuccessDialog from '../../shared/successDialog';
import DropZone from './fileDropzone';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function UploadFile({
  open, close, type, handleFile, state,
  businessId, accountType, setState
}) {
  const [cardTitle, setCardTitle] = useState('');
  const [titleDesc, setTitleDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successText, setSuccessText] = useState({});
  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_PROFILE);

  useEffect(() => {
    switch (type) {
      case 'ownerIdCard':
        setCardTitle("Owner's Means of Identification");
        setTitleDesc('Complete the details below');
        setSuccessText({ title: "Owner's Means of Identification", desc: 'Your Means of Identification has been updated successfully!' });
        break;
      case 'storeFrontagePicture':
        setCardTitle('Store Frontage Picture');
        setTitleDesc('Upload your store frontage picture');
        setSuccessText({ title: 'Store Frontage Picture', desc: 'Your Store Picture has been updated successfully!' });
        break;
      case 'ogapharmacyAgreementForm':
        setCardTitle('OGApharmacy Agreement Form');
        setTitleDesc('Upload your OGApharmacy Agreement form (front & back picture)');
        setSuccessText({ title: 'OGApharmacy Agreement Form', desc: 'Your OGApharmacy Agreement Form has been updated successfully!' });
        break;
      default:
        setCardTitle('Complete the details below');
        setTitleDesc('Complete the details below');
        setSuccessText({ title: "Owner's Means of Identification", desc: 'Your Means of Identification has been updated successfully!' });
    }
  }, [type]);

  const idUpload = [
    {
      name: 'identification', label: "Owner's Means of Identification", required: true, placeholder: 'e.g National ID (NIN)',
      error: '', helperText: 'Identification required', secured: false, show: true,
      options: ['National ID (NIN)', 'International Passport', "Voter's Card", "Driver's Licence"]
    },
  ];

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const handleFileUpload = async (file) => {
    const { data, file: { name, size } } = file[0];
    const [url] = await Promise.all([handleImageUpload(data)]);
    const result = { url, fileName: name, fileSize: size.toString() };
    if (type === 'ownerIdCard') result.fileType = state?.identification;
    return result;
  };

  const validateFiles = () => {
    if (type !== 'ogapharmacyAgreementForm' && !state[type]) {
      toast.error('Select a file to upload');
      return false;
    }
    if (type === 'ogapharmacyAgreementForm' && (!state?.ogapharmacyAgreementFormBack || !state?.ogapharmacyAgreementFormFront)) {
      toast.error('Select Front & Back file to upload');
      return false;
    }
    if (type === 'ownerIdCard' && !state?.identification) {
      toast.error('Select ID Type');
      return false;
    }
    return true;
  };

  const prepareUpdatedInfo = async () => {
    const updatedInfo = { kycFiles: {} };
    if (type === 'ogapharmacyAgreementForm') {
      updatedInfo.kycFiles = {
        [type]: {
          front: await handleFileUpload(state[`${type}Front`]),
          back: await handleFileUpload(state[`${type}Back`])
        }
      };
    } else {
      updatedInfo.kycFiles = {
        [type]: await handleFileUpload(state[type])
      };
    }
    return updatedInfo;
  };

  const handleFileSave = async () => {
    setLoading(true);

    if (!validateFiles()) {
      setLoading(false);
      return;
    }

    const updatedInfo = await prepareUpdatedInfo();

    updateBusinessInfo({
      variables: {
        businessId, updatedInfo, accountType
      }
    })
      .then(() => {
        setOpenDialog(true);
        setState({});
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  const handleIdChange = (event) => {
    const { name: fieldName, value } = event.target;
    setState({ ...state, [fieldName]: value });
  };

  const returnSelectIdType = () => {
    if (type === 'ownerIdCard') {
      const value = state?.identification;
      return (
        <Box style={{ marginBottom: '1.5rem' }}>
          {idUpload.map((field) => (
            <CustomSelectInputBase
              key={field}
              field={field}
              value={value}
              disabled={field.secured}
              placeholder={field.placeholder}
              handleChange={handleIdChange}
              handleCreditDaysOpen={() => ({})}
              creditDays={() => ({})}
              showCheckBox={false}
              error={field.error || false}
              helperText={field.error && field.helperText}
              required={field.required}
            />
          ))}
        </Box>
      );
    }
  };

  return (
    <>
      <DialogContainer
        data-testid="product-dialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid style={{ marginTop: '1rem' }}>
              <Box display="flex" justifyContent="space-between">
                <CardTitle>{cardTitle}</CardTitle>
                <Close
                  fontSize="small"
                  onClick={close}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
              <TitleDesc>{titleDesc}</TitleDesc>

              {returnSelectIdType()}

              {type === 'ogapharmacyAgreementForm' ? (
                <>
                  <DropZone name={`${type}Front`} handleFile={handleFile} state={state} />
                  <DropZone name={`${type}Back`} handleFile={handleFile} state={state} />
                </>
              ) : (
                <DropZone name={type} handleFile={handleFile} state={state} />
              )}

              <Box style={{ textAlign: 'right' }}>
                <SaveButton onClick={handleFileSave} disabled={loading}>
                  {loading ? (
                    <CircularProgressLoader
                      disableShrink
                      size={22}
                      thickness={5}
                    />
                  ) : 'Save' }
                </SaveButton>
              </Box>
            </Grid>
          </DialogContentText>
        </DialogContentStyled>
      </DialogContainer>

      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={() => setOpenDialog(false)}
        title={successText.title}
        desc={successText.desc}
        option="Ok"
      />
    </>
  );
}

UploadFile.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleFile: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object),
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired
};

UploadFile.defaultProps = {
  open: false,
  state: {}
};
