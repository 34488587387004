import {
  Grid, Box, TableCell, Paper, TableRow,
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const MainTableHead = styled(TableRow)`
  width: 100%;
`;

export const TableContainerWrapper = styled(Paper)`
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const Header = styled(TableCell)`
  padding: 17px 9px;
  background: #F3F9FF;
  color: #303030;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  & > * {
    border-bottom: none;
  }

  @media(min-width: 992px) {
    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
      min-width: 210px;
    }
  }
`;
export const TableGrid = styled(Grid)`
  margin: 1rem 0rem;
  overflow-x: auto;
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
`;

export const MobileContainer = styled(Box)`
  width: 100%;
  background-color: #fff;
`;

export const SortImg = styled('img')`
  margin-left: 15px;
  padding-bottom: 2px;
`;
