import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import Stepper from './stepper';
import { StepStyled } from './buyer.styles';
import ProfileSection from '../profile';
import { ProceedBtn } from '../../register.styles';
import ContactPersonSection from './contactPerson';
import DeliverySection from './delivery';
import SuccessDialog from '../../../shared/successDialog';
import SellerCreateBusiness from '../seller/createBusiness';
import UploadBusinessFile from '../seller/businessFile';
import { SIGNUP_MUTATION, BECOME_SELLER_MUTATION } from '../../../../mutations/signup';
import { CircularProgressLoader } from '../../login.styles';

const MainStep = ({
  state, setState, setOgaDesc, beSeller, sellerState
}) => {
  const [step, setStep] = useState(1);
  const [proceed, setProceed] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);

  const { catType } = state;

  let buttonText;

  if ((step === 3 && catType === 'buyer') || (step === 3 && beSeller)) {
    buttonText = 'Submit';
  } else {
    buttonText = 'Proceed';
  }

  const handleStepChange = (val) => {
    if (val > step) return;
    setStep(val);
  };

  useEffect(() => {
    if (beSeller) setStep(2);
  }, [beSeller]);

  const returnComponent = () => {
    switch (step) {
      case 1:
        return <ProfileSection state={state} setState={setState} setProceed={setProceed} />;
      case 2:
        if (catType === 'seller') {
          return <SellerCreateBusiness state={state} setState={setState} setProceed={setProceed} sellerState={sellerState} />;
        }
        return <ContactPersonSection state={state} setState={setState} setProceed={setProceed} />;
      case 3:
        if (catType === 'seller') {
          return <UploadBusinessFile state={state} setState={setState} setProceed={setProceed} />;
        }
        return <DeliverySection state={state} setState={setState} setProceed={setProceed} />;
      default:
        return <div />;
    }
  };

  const [createUser, { loading }] = useMutation(SIGNUP_MUTATION);
  const [becomeSeller, { loading: beSellerLoading }] = useMutation(BECOME_SELLER_MUTATION);

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;
    setImgLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        setImgLoading(false);
        return resolve(response.secure_url);
      });
    });
  };

  const submitHandler = async () => {
    const {
      location, businessName, deliveryAddress, salesRep, category, city: sellerCity,
      contactPerson, deliveryCity, landmark, fullName, email, phoneNumber, password,
      companyRegNum, storeAddress, cacFile, pharmRegFile, permitFile
    } = state;
    const rlocation = location.replace('State', '').trim();

    const handleFile = async (file) => {
      const { data, file: { name, size } } = file[0];
      const [url] = await Promise.all([handleImageUpload(data)]);
      return { url, fileName: name, fileSize: size.toString() };
    };

    let userData = {};
    let mutationVariables = {};

    if (catType === 'seller' || beSeller) {
      const cac = await handleFile(cacFile);
      const pharmacyCouncilRegistrationLicence = await handleFile(pharmRegFile);
      const nafdac = permitFile?.length ? await handleFile(permitFile) : null;

      mutationVariables = {
        city: sellerCity, contactPersonName: contactPerson, landmark, storeAddress,
        sellerInfo: {
          registrationNumber: companyRegNum,
          pharmacyCouncilRegistrationLicence,
          cac,
          nafdac
        }
      };

      if (catType === 'seller') {
        userData = {
          location: rlocation, businessName, businessType: category.toUpperCase(), storeAddress,
          contactPersonName: contactPerson, city: sellerCity, landmark, userFullName: fullName,
          email, phoneNumber, password,
          sellerInfo: {
            registrationNumber: companyRegNum,
            pharmacyCouncilRegistrationLicence,
            cac,
            nafdac
          }
        };
      }
    } else {
      userData = {
        location: rlocation, businessName, businessType: category.toUpperCase(), deliveryAddress,
        salesRepresentativeName: salesRep, contactPersonName: contactPerson, city: deliveryCity, landmark,
        userFullName: fullName, email, phoneNumber, password
      };
    }

    if (beSeller) {
      becomeSeller({ variables: mutationVariables })
        .then(() => {
          setSuccessOpenDialog(true);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      createUser({ variables: { signupData: userData } })
        .then(() => {
          setSuccessOpenDialog(true);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    setOgaDesc(true);
  };

  const handleProceed = () => {
    if (step !== 3) {
      switch (step) {
        case 1:
          setState({ ...state, currentStep: 'buyer' });
          setOgaDesc(false);
          setStep(2);
          break;
        case 2:
          setStep(3);
          break;
        default: break;
      }
    } else {
      submitHandler();
    }
  };

  const successTitle = beSeller ? 'Seller Account Created!' : 'Account Created';
  const successDesc = beSeller ? 'Your seller account has been successfully created. Your '
  + 'submitted documents are currently undergoing '
  + 'verification, and you will be notified of the outcome '
  + 'shortly.'
    : 'Your account has been created successfully, you can proceed to the OGApharmacy marketplace.';

  return (
    <>
      <StepStyled>{`Step ${step} of 3`}</StepStyled>
      <Stepper
        stepVal={step}
        handleStepChange={handleStepChange}
      />
      {returnComponent()}
      <ProceedBtn
        disabled={!proceed || loading}
        onClick={handleProceed}
        submitBtn={step === 3}
      >
        {
          (loading || imgLoading || beSellerLoading)
            ? (
              <CircularProgressLoader
                disableShrink
                size={20}
                thickness={5}
              />
            )
            : buttonText
        }
      </ProceedBtn>

      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title={successTitle}
        desc={successDesc}
        option={(catType === 'seller' || beSeller) ? 'Ok' : 'Proceed to Marketplace'}
        refetch={() => null}
        redirectUrl="/"
      />
    </>
  );
};

MainStep.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setOgaDesc: PropTypes.func.isRequired,
  beSeller: PropTypes.bool,
  sellerState: PropTypes.instanceOf(Object)
};

MainStep.defaultProps = {
  beSeller: false,
  sellerState: {}
};

export default MainStep;
