import { Grid, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableGrid = styled(Grid)`
  overflow-x: auto;
  margin-top: 2rem;
`;

export const MainTableHead = styled(TableRow)`
  width: 100%;
`;

export const TableComponent = styled(Grid)`
  width: 100%;
`;

export const THeader = styled(TableCell)`
  background: #F3F9FF;
  color: #303030;
  font-weight: 700;
  font-size: 1rem;
  border-bottom: 0;
`;
