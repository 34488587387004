import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { CancelRounded, CheckCircleOutlineRounded, KeyboardArrowDown } from '@mui/icons-material';
import {
  InputWrapper, PasswordAuth, IsValidPasswordStyled,
  AdornmentStyled
} from '../register.styles';
import CustomInputBase from '../../customComponents/customInputBase';
import PasswordTextField from '../passwordTextField';
import {
  containsLowerCase, containsUpperCase, containsSpecialCharacter, containsNumber, minStrLength,
  validateEmailAddress
} from '../../../utils/funcs';

const initialErrorState = {
  fullNameError: false,
  emailError: false,
  phoneNumberError: false,
};

const ProfileSection = ({ state, setState, setProceed }) => {
  const {
    fullName, email, phoneNumber, password
  } = state;

  const [errorState, setErrorState] = useState(initialErrorState);
  const { fullNameError, emailError, phoneNumberError } = errorState;

  const validFullName = (str) => (str.length < 5 || str.indexOf(' ') <= 0 || str.split(' ')[1].length < 2);
  const validPhoneNumber = (str) => (str.length < 9 || str.length > 13);

  const validateFields = () => (
    !validFullName(fullName) && email.length && validateEmailAddress(email) && !validPhoneNumber(phoneNumber)
    && containsLowerCase(password)
       && containsUpperCase(password)
       && containsSpecialCharacter(password)
       && containsNumber(password)
  );

  const validateState = (name, value) => {
    switch (name) {
      case 'fullName': return setErrorState({
        ...errorState,
        [`${name}Error`]: validFullName(value)
      });
      case 'email': return setErrorState({
        ...errorState,
        [`${name}Error`]: !validateEmailAddress(value)
      });
      case 'phoneNumber':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: validPhoneNumber(value)
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    if (validateFields()) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let inputValue = value;
    if (name === 'phoneNumber') {
      inputValue = inputValue.replace(/\D/g, '');
    }
    validateState(name, inputValue);
    setState({
      ...state,
      [name]: inputValue
    });
  };

  const acceptPassword = [
    { id: 1, value: 'Minimum 8 Characters', valid: minStrLength(password, 8) },
    { id: 2, value: 'One Uppercase Character', valid: containsUpperCase(password) },
    { id: 3, value: 'One Special Character', valid: containsSpecialCharacter(password) },
    { id: 4, value: 'One Lower Character', valid: containsLowerCase(password) },
    { id: 5, value: 'One Number', valid: containsNumber(password) },
  ];

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const categoryField = [
    {
      name: 'fullName', label: 'Full Name', secured: false, show: true, val: fullName,
      onChange: handleChange, placeholder: 'e.g Paul Maestri', required: true, type: 'text', error: fullNameError, helperText: 'Full name required'
    },
    {
      name: 'email', label: 'Email Address', secured: false, show: true, val: email,
      onChange: handleChange, placeholder: 'e.g Maestropaul@example.com', required: true, type: 'text', error: emailError, helperText: 'Email required'
    },
    {
      name: 'phoneNumber', label: 'Phone Number', secured: false, show: true, val: phoneNumber,
      onChange: handleChange, placeholder: '07033221133', required: true, type: 'text', error: phoneNumberError, helperText: 'Phone number required', sAdornment: phoneAdornment
    }
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type,
      sAdornment
    } = field;
    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        sAdornment={sAdornment}
      />
    );
  };

  return (
    <InputWrapper item container>
      <Grid item xs={12} lg={12}>
        {categoryField.filter((item) => item.show).map((field) => (
          <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem' }}>{returnTextField(field)}</Grid>
        ))}
      </Grid>
      <Grid sx={{ marginTop: '1rem' }}>
        <PasswordTextField
          password={state.password}
          handleChange={handleChange}
          passwordError={false}
          loading={false}
          placeholder="Create a password"
          label="Create Password"
        />
        <PasswordAuth container sm={12}>
          {acceptPassword?.map((item) => {
            const { id, value, valid } = item;
            return (
              <Grid sm={6} key={id}>
                <IsValidPasswordStyled valid={valid}>
                  {valid ? (
                    <CheckCircleOutlineRounded sx={{ width: '15px', color: '#2ECC71' }} />
                  ) : (
                    <CancelRounded sx={{ width: '15px', color: '#E74C3C' }} />
                  )}
                  {value}
                </IsValidPasswordStyled>
              </Grid>
            );
          })}
        </PasswordAuth>
      </Grid>
    </InputWrapper>
  );
};

ProfileSection.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired,
};

export default ProfileSection;
