import React from 'react';
import PropTypes from 'prop-types';
import {
  SupplierDetailCardHeader, SupplierDetailCardTitle, OrderDeliveryDetailInfoWrapperRow, OrderDeliveryInfoLabel,
  OrderDeliveryInfoText, OrderBusinessName
} from './deliveryInfoCard.styles';

const DeliveryInfoCard = ({ businessContact, businessName }) => (
  <>
    <SupplierDetailCardHeader>
      <SupplierDetailCardTitle>Delivery Location</SupplierDetailCardTitle>
    </SupplierDetailCardHeader>
    <OrderDeliveryDetailInfoWrapperRow>
      {businessName && (
        <OrderBusinessName data-testid="business-name">{businessName}</OrderBusinessName>
      )}
      <div>
        <OrderDeliveryInfoLabel>Mobile</OrderDeliveryInfoLabel>
        <OrderDeliveryInfoText>{ businessContact && businessContact.phoneNumber ? businessContact.phoneNumber : 'N/A' }</OrderDeliveryInfoText>
      </div>
      <div>
        <OrderDeliveryInfoLabel>Email Address</OrderDeliveryInfoLabel>
        <OrderDeliveryInfoText>{ businessContact && businessContact.emailAddress ? businessContact.emailAddress : 'N/A' }</OrderDeliveryInfoText>
      </div>
    </OrderDeliveryDetailInfoWrapperRow>
    <div style={{ marginTop: '20px' }}>
      <OrderDeliveryInfoLabel>Address</OrderDeliveryInfoLabel>
      <OrderDeliveryInfoText>{ businessContact && businessContact.deliveryAddress }</OrderDeliveryInfoText>
    </div>
  </>
);

DeliveryInfoCard.propTypes = {
  businessContact: PropTypes.instanceOf(Object),
  businessName: PropTypes.instanceOf(String),
};

DeliveryInfoCard.defaultProps = {
  businessContact: {},
  businessName: ''
};

export default DeliveryInfoCard;
