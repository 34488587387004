import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Slide,
  Grid,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MapCsvDialog from './mapCsvDialog';
import {
  DialogContainer,
  CardTitle,
  DialogContentStyled,
  TitleDesc
} from './createBndP.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CreateBatches = ({ openDialog, toggleHandler }) => {
  const CSV_FIELDS = [
    ['Batch No', {
      required: false, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,nullable'
    }],
    ['Product Brand Name', {
      required: false, unique: true, value: null, empty: true, touched: false, hover: false,
      rules: 'str,nullable'
    }],
    ['SKU', {
      required: true, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Pack Size', {
      required: false, unique: true, value: null, empty: true, touched: false, hover: false
    }],
    ['Quantity', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'int,nullable'
    }],
    ['Date Received', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,nullable,date'
    }],
    ['Expiry Date', {
      required: true, unique: false, value: null, empty: true, touched: false, hover: false,
      rules: 'str,nullable,date'
    }]
  ];

  return (
    <DialogContainer
      data-testid="batch-dialog"
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Import CSV</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc>Upload CSV File To Add Multiple Batches</TitleDesc>
            <MapCsvDialog
              fields={CSV_FIELDS}
              model="Batches"
              endpoint="inventory"
              backRoute="products"
            />
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
};

CreateBatches.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired
};

CreateBatches.defaultProps = {
  openDialog: false
};

export default CreateBatches;
