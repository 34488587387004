const CartActionTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  CART_COUNTER: 'CART_COUNTER',
  UPDATE_IS_MANUAL: 'UPDATE_IS_MANUAL',
  CART_BACK_ORDER_COUNTER: 'CART_BACK_ORDER_COUNTER',
  CART_COUNT: 'CART_COUNT',
  CART_STATUS: 'CART_STATUS',
  UPDATE_CART_ITEMS: 'UPDATE_CART_ITEMS'
};

export default CartActionTypes;
