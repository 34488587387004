import React from 'react';
import { Grid } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import { StepContainer, ConnectorLine, HeaderText } from './buyerSettings.styles';
import { RadioButton } from '../../../../assets/svgs';

const SettingsTracker = ({ steps }) => (
  <>
    {steps.map(({ label, active }, index) => (
      <StepContainer container key={label}>
        <Grid item xs={1}>
          {active
            ? <RadioButton style={{ fontSize: '2rem' }} />
            : (
              <RadioButtonUncheckedIcon
                style={{
                  fontSize: '1.8rem', fill: '#235a91', marginTop: '3px', marginLeft: '2px'
                }}
              />
            )}
        </Grid>
        <Grid item xs={11}>
          <HeaderText>{label}</HeaderText>
        </Grid>
        {index < steps.length - 1 && <ConnectorLine />}
      </StepContainer>
    ))}
  </>
);

SettingsTracker.propTypes = {
  steps: PropTypes.instanceOf(Object).isRequired,
};

export default SettingsTracker;
