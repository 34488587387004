import {
  TableCell, Box, Typography,
  Checkbox
} from '@mui/material';
import styled, { css } from 'styled-components';
import CustomButton from '../customComponents/customButton';

export const TableColumnRows = styled('div')`
  display: flex;

  :nth-child(even) {
    background: #F3F9FF;
  }
`;

export const TableColumnRowText = styled(TableCell)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 26.27px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 10px;
  border: 0;
  align-content: center;


  @media(min-width: 992px) {
    font-size: 16px;
  }
`;

const expiredStyles = css`
  background-color: #FF0F0F;
  // color: #606060;
`;

const sellableStyles = css`
  background-color: #00B588;
  // color: #07DE10;
`;

const damagedStyles = css`
  background-color: #FF0F0F;
  // color: #FA3636;
`;

const getStyles = ({ status }) => {
  switch (status) {
    case 'expired':
      return expiredStyles;
    case 'damaged':
      return damagedStyles;
    default:
      return sellableStyles;
  }
};

export const StatusColor = styled(Box)`
  width: 0.563rem;
  height: 0.563rem;
  // background: ${({ active }) => (active ? '#00B588' : '#FF0F0F')};
  border-radius: 50%;
  margin-top: 7px;

  ${getStyles}
`;

export const ColorGrid = styled(Typography)`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
`;

export const DropDownArrow = styled('img')`
  margin-left: 25px;
  width: 12px;
  height: 10px;
  margin-top: 7px
`;

export const TCustomButton = styled(CustomButton)`
  color: #606060;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 14px rgb(219 219 219 / 19%);
  border-radius: 4px;
  height: 2.5rem;
  width: 100%;

  & .label {
    font-weight: 500;
    font-size: 10px;
  }
`;

export const ViewMoreText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
  color: #235A91;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #78AADA;
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.4rem;
  }
`;
