import React, { useEffect, useState } from 'react';
import NotificationsActionTypes from '../../../providers/reducers/notifications/notificationsTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { Subscriber } from '../../../utils/subscriber';
import NotificationDialog from './NotificationDialog';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';


let newCount = 0;
Subscriber.subscribe('NotificationCount', (data) => {
  newCount = data;
});
const NotificationModal = () => {
  const [state, setState] = useState({
    message: null,
  });

  const [{ user: { session: { username } } }, dispatch] = Object.values(useStateValue());
  const role = localStorage.getItem('oga_user_role');

  const queueNames = role === 'oga-pharmacy-admin' ? 'COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.ORDER_APPROVAL,COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.ORDER_READY_FOR_DESPATCH,COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.ORDER_FINAL_DESPATCH,COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.NEW_PRODUCT' : 'COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.NEW_PRODUCT';

  const setMessage = (newMessage) => {
    setState((prevState) => ({
      ...prevState,
      message: newMessage,
    }));
  };

  useEffect(() => {
    if (!username) return;

    const connectWebSocket = () => {
      const socketUrl = `wss://${process.env.SOCKET_URL}?token=${process.env.SOCKET_TOKEN}&uid=${username}&generalRoutingKeys=GENERAL_NEW_PRODUCT&queueNames=COM.OGAPHARMACY.PHARMIQ.NOTIFICATION.USER_QUEUE,${queueNames}`;
      const socket = new WebSocket(socketUrl, 'text');

      socket.onopen = () => {
        console.log('Connected to WebSocket server');
      };

      socket.onmessage = (event) => {
        const newMessage = JSON.parse(event?.data);
        if(newMessage?.type === 'order_processing_completed' || newMessage?.type === 'place_order_processing_completed' || newMessage?.type === 'place_order_processing_failed'){
          return dispatch({
            type: CartActionTypes.CART_STATUS,
            payload: newMessage?.type
          });
        }
        setMessage(newMessage);
        newCount++;
        setTimeout((
          dispatch({
            type: NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT,
            payload: {
              count: newCount
            }
          })), 3000);
      };

      socket.onclose = () => {
        connectWebSocket();
      };
    };

    connectWebSocket();
  }, [username]);

  return (
    <div>
      {!state?.message ? '' : <NotificationDialog message={state?.message} setMessage={setState} />}
    </div>

  );
};

export default NotificationModal;
