import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  CardPaper, HeaderContainer, DetailsContainer, ActionsContainer,
  HeaderText, TypoContainer, ImageContainer, PackSizeTypo,
  PricingAndSaving, PricingWrapper, SavingsWrapper, PricingTypo,
  Pricing, SavingPricing, ProductImage, ActionButton, AddButton,
  CircularProgressLoader, ActionTextField, InputButtonGroup, PromoText,
  ProductVariantText, MinQtyTypo
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { UPDATE_ORDER_MUTATION } from '../../../mutations/orders';
import { PromoInfo } from './promoInfo';
import { useStateValue } from '../../../providers/stateProvider';
import { toTitleCase } from '../../../utils/toTitleCase';
import { JSONParse } from '../../../utils/json';
import { Product } from '../../../providers/reducers/product/classes/Product';

const ProductCard = ({ product, openAlert, businessId }) => {
  const [counter, setCounter] = useState(0);
  const [promo, setPromo] = useState(0);
  const [{
    cart: { isManualOrder }, user: { platform },
    affiliate: { usablePromos, pricePolicyFactor = 1, affiliate }
  }, dispatch] = Object.values(useStateValue());
  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);
  const promoObj = JSONParse(product.promo);
  let preferredPlatform = platform;
  const role = localStorage.getItem('oga_user_role');
  if (role === 'store-manager') {
    preferredPlatform = 'erp';
  } else if (promoObj.includes('GPS')) {
    preferredPlatform = 'marketplace';
  }
  const {
    id, brandName, meta, business: { id: productBusinessId },
    resolvedPriceInUseValue: actualPrice, packSize, image, orderCost, minimumOrderQuantity,
    productVariant
  } = new Product(product, preferredPlatform) || {};
  const obj = JSON.parse(meta);
  // const priceObj = JSON.parse(price);
  const {
    savings,
  } = obj || {};
  // const tagType = () => {
    // if (promoObj.includes('GMV_Boost')) {
    //   return { color: 'mediumpurple', value: 1 };
    // }
    // if (promoObj.includes('GPS')) {
    //   return { color: '#008000a6', value: 0 };
    // }
    // if (promoObj.includes('Standard_Pricing')) {
    //   return { color: '#ffa500c7', value: 2 };
    // }
  //   return {};
  // };
  let itemPrice = 0;
  if (preferredPlatform === 'erp') {
    itemPrice = orderCost;
  } else {
    itemPrice = actualPrice * pricePolicyFactor;
  }
  // useEffect(() => {
  //   const priceKeys = Object.keys(priceObj);
  //   usablePromos.forEach((eachPromo) => {
  //     const exist = priceKeys.find((pk) => pk === eachPromo);
  //     if (exist) {
  //       setPromo(promo + 1);
  //     }
  //   });
  // }, []);
  const handleActionButtons = (value) => {
    setCounter(counter + value);
  };
  const handleChange = (value) => {
    setCounter(value);
  };

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId: id,
        quantity: counter,
        isManualOrder,
        overwriteQuantity: true
      }
    })
      .then(({ data }) => {
        const { message, totalProductCount } = data?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: totalProductCount
        });
        openAlert(message);
      });
  };

  // const promoType = tagType();

  return (
    <CardPaper elevation={2}>
      {(productVariant === 'onRequest')
        ? (
          <ProductVariantText>On Request</ProductVariantText>
        )
        : (
          <ProductVariantText>Readily Available</ProductVariantText>
        )}
      {/* <PromoText style={{ background: promoType.color }}>
        { promoType.value === 1 ? 'Ideal' : null}
        { promoType.value === 2 ? 'Standard Pricing' : null}
        { promoType.value === 0 ? 'GPS' : null }
      </PromoText> */}
      <HeaderContainer>
        <HeaderText variant="subtitle2">
          {toTitleCase(brandName)}
        </HeaderText>
        {promo < 1 ? '' : <PromoInfo info={`${promo} offer${promo > 0 ? 's' : ''} available`} />}
      </HeaderContainer>
      <DetailsContainer container>
        <TypoContainer item container xs={12}>
          <PackSizeTypo variant="body1">
            Pack Size:
            {' '}
            &nbsp; &nbsp;
            {packSize}
          </PackSizeTypo>
          {(productVariant === 'onRequest')
            ? (
                <MinQtyTypo variant="body1">
                  Minimum Quantity:
                  {' '}
                  &nbsp;
                  {minimumOrderQuantity}
                </MinQtyTypo>
            ) : '' }
          <PricingAndSaving item container>
            <PricingWrapper item xs={6}>
              <PricingTypo>
                Price
              </PricingTypo>
              <Pricing>
                {currencyFormatter(itemPrice)}
              </Pricing>
            </PricingWrapper>
            {`${productBusinessId}` === '1' && (
            <SavingsWrapper item xs={6}>
              <PricingTypo>
                Savings
              </PricingTypo>
              <SavingPricing>
                {currencyFormatter(savings || 0)}
              </SavingPricing>
            </SavingsWrapper>
            )}
          </PricingAndSaving>
        </TypoContainer>
        <ImageContainer item container xs={4}>
          <ProductImage imageUrl={image} />
        </ImageContainer>
      </DetailsContainer>
      <ActionsContainer item container>
        <InputButtonGroup size="large" aria-label="button group" className="joy-update-quantity">
          <ActionButton
            onClick={() => handleActionButtons(-1)}
            disabled={counter === 0}
          >
            -
          </ActionButton>
          <ActionTextField
            variant="outlined"
            label=""
            placeholder={0}
            value={counter || ''}
            onChange={(e) => handleChange(Number(e.target.value))}
          />
          <ActionButton
            onClick={() => handleActionButtons(1)}
          >
            +
          </ActionButton>
        </InputButtonGroup>
        <AddButton
          className="joy-add-to-cart"
          variant="contained"
          size="large"
          disableElevation
          disabled={productVariant === 'JIT' ? (counter < minimumOrderQuantity || counter === 0) : counter === 0}
          color="primary"
          onClick={handleAddToCart}
        >
          {loading ? (
            <CircularProgressLoader
              disableShrink
              size={24}
              thickness={4}
            />
          ) : 'Add'}
        </AddButton>
      </ActionsContainer>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  openAlert: PropTypes.func.isRequired
};

ProductCard.defaultProps = {
  product: {}
};

export default ProductCard;
