import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  SubText, ProfilePaper, BusinesssName, SettingsPaper, EditButton,
  EditButtonText, ProfileBox, ProfileHeader, ProfileSub,
} from './buyerSettings.styles';
import { Editicon } from '../../../../assets/svgs';
import SettingsTracker from './buyerStepper';
import EditProfileDialog from './editProfileDialog';
import IdUploads from '../idUploads';
import { JSONParse } from '../../../../utils/json';

const BuyerSettings = ({ userData, businessId, accountType }) => {
  const [editDialog, setEditDialog] = useState(null);
  const [profileData, setProfileData] = useState({});

  const {
    name: businessName, location, buyerContact, fullname,
    settings, createdBy, businessType
  } = userData?.business || {};

  const { meta } = createdBy || {};
  const parseMeta = JSONParse(meta);
  const parseSettings = JSONParse(settings);
  const { kyc } = parseSettings;
  const { business_type: busType, files } = kyc || {};
  const { gender } = parseMeta;

  const {
    city, contactPersonName, deliveryAddress, emailAddress,
    landmark, phoneNumber, salesRepresentativeName: salesRep
  } = buyerContact || {};

  const ownerIdCard = files?.owner_id_card || {};
  const storePicture = files?.store_frontage_picture || {};
  const agreementForm = files?.ogapharmacy_agreement_form || {};
  const { back: ogapharmacyAgreementFormBack } = agreementForm ?? {};

  const { url: idCardUrl } = ownerIdCard ?? {};
  const { url: storePicUrl } = storePicture ?? {};
  const { url: backFormUrl } = ogapharmacyAgreementFormBack ?? {};

  const completedSteps = {
    businessInfo: true,
    meansOfId: idCardUrl,
    storePicture: storePicUrl,
    agreementForm: backFormUrl,
  };

  const leftField = [
    { name: 'Full Name', value: fullname },
    { name: 'Email Address', value: emailAddress },
    { name: 'Phone Number', value: phoneNumber, },
    { name: 'Sales Representative', value: salesRep },
    { name: 'State', value: location },
  ];

  const rightField = [
    { name: 'Business Name', value: businessName },
    { name: 'Delivery Address', value: deliveryAddress },
    { name: "Contact Person's Full Name", value: contactPersonName, },
    { name: 'Landmark', value: landmark },
    { name: 'City', value: city },
  ];

  const infoFields = [
    { name: 'businessInfo', label: 'Business Info.', active: true },
    { name: 'idCardUrl', label: 'Means of Identification', active: idCardUrl },
    { name: 'storePicUrl', label: 'Store Picture', active: storePicUrl },
    { name: 'backFormUrl', label: 'Agreement Form', active: backFormUrl },
  ];

  const handleEditButtonClick = () => {
    setProfileData({
      fullName: fullname,
      businessName,
      emailAddress,
      phoneNumber,
      salesRep,
      location,
      deliveryAddress,
      contactPersonName,
      landmark,
      city,
      gender,
      businessType: busType ?? businessType
    });
    setEditDialog(true);
  };

  return (
    <>
      <SubText>Manage your business profile</SubText>

      <Grid container spacing={4}>
        <Grid item xs={2.3}>
          <SettingsPaper elevation={0}>
            <Grid container>
              <SettingsTracker steps={infoFields} completedSteps={completedSteps} />
            </Grid>
          </SettingsPaper>
        </Grid>

        <Grid item xs={9.7}>
          <ProfilePaper elevation={0}>
            <Grid container style={{ marginBottom: '1.5rem' }}>
              <Grid item xs={6}>
                <BusinesssName>Business Information</BusinesssName>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <EditButton startIcon={<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />} onClick={handleEditButtonClick}>
                  <EditButtonText>Edit Profile Info</EditButtonText>
                </EditButton>
              </Grid>
            </Grid>
            <hr style={{ border: '1px solid #e8e8e8' }} />

            <Grid container style={{ marginTop: '2rem' }}>
              <Grid item xs={6}>
                {leftField.map(({ name, value }) => (
                  <ProfileBox key={name}>
                    <ProfileHeader>{name}</ProfileHeader>
                    <ProfileSub>{value || 'N/A'}</ProfileSub>
                  </ProfileBox>
                ))}
              </Grid>
              <Grid item xs={6}>
                {rightField.map(({ name, value }) => (
                  <ProfileBox key={name}>
                    <ProfileHeader>{name}</ProfileHeader>
                    <ProfileSub>{value || 'N/A'}</ProfileSub>
                  </ProfileBox>
                ))}
              </Grid>
            </Grid>
          </ProfilePaper>

          <IdUploads businessId={businessId} accountType={accountType} files={files} />
        </Grid>
      </Grid>

      <EditProfileDialog
        dialogOpen={editDialog}
        closeDialog={() => setEditDialog(null)}
        businessId={businessId}
        accountType={accountType}
        profileData={profileData}
      />
    </>
  );
};

BuyerSettings.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired
};

export default BuyerSettings;
