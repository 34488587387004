import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Dots } from '../../assets/svgs';
import {
  GridContainer, GridWrapper, ItemTypo, ItemWrapper
} from './actionPopper.styles';
import { useStateValue } from '../../providers/stateProvider';
import { RenderPopper } from '../../utils/utils';

export default function ActionPopper({ handleAction }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box ref={anchorRef} style={{ display: 'flex', cursor: 'pointer' }} onClick={handleToggle}>
        <Dots fontSize="large" onClick={handleToggle} style={{ cursor: 'pointer' }} />
      </Box>
      <RenderPopper
        open={open}
        anchorEl={anchorRef.current}
        popperPlacement="bottom"
        onClickAway={handleClose}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0vw, 2vh',
          }
        }}
      >
        <GridWrapper container item>
          <GridContainer
            container
            item
            data-testid="delete_batch"
            onClick={() => handleAction('delete_batch', setOpen)}
          >
            <ItemWrapper>
              <ItemTypo variant="subtitle1">
                Delete Batch
              </ItemTypo>
            </ItemWrapper>
          </GridContainer>
        </GridWrapper>
      </RenderPopper>
    </>
  );
}

ActionPopper.propTypes = {
  handleAction: PropTypes.func.isRequired,
  // row: PropTypes.shape(Object).isRequired,
};
