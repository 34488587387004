import styled from 'styled-components';

const sm = '960px';

export const SupplierDetailCardHeader = styled('div')`
padding-top: 2.5rem;
padding-bottom: 15px;
border-bottom: 1px solid #E8E8E8;
display: flex;
justify-content: space-between;

@media (max-width: 991px) {
  padding-top: 10px;
  padding-bottom: 20px;
}
`;

export const SupplierDetailCardTitle = styled('p')`
  color: #303030;
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const OrderDeliveryDetailInfoWrapperRow = styled('div')`
  margin-top: 20px;

  & > * {
    margin-bottom: 20px;
  }

  @media (max-width: ${sm}) {
    display: flex;

    align-items: center;
    justify-content: space-between;

    & > * {
      margin-bottom: unset;
    }
  }
`;

export const OrderDeliveryInfoLabel = styled('p')`
  font-weight: 400;
  font-size: .875rem;
  color: #606060;
  margin: 0 0 1rem 0;

  @media (max-width: 991px) {
    font-size: 2rem;
    margin: 0 0 1.5rem 0;
  }
`;

export const OrderDeliveryInfoText = styled('p')`
  margin: 0;
  font-weight: 700;
  font-size: 2.3rem;
  color: #606060;
  margin: 0 0 1.5rem 0;

  @media (min-width: 992px) {
    font-size: .875rem;
    color: #303030;
    margin: 0;
  }
`;

export const DeliveryStatusText = styled('p')`
  color: #A3A3A3;
  font-size: 27px;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;

  @media (min-width:992px) {
    font-size: 16px;
  }
`;

export const OrderBusinessName = styled('p')`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #606060;

  @media (min-width: 992px) {
    font-size: 22px;
    margin-bottom: 15px;
  }
`;
