import styled from 'styled-components';
import { Typography } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';

export const TooltipTitle = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 700;
  color: #f5f5f5;
`;

export const ToolTipContent = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  color: #ecf6ff;
`;

export const HelpOutlineStyled = styled(ErrorOutlineOutlined)`
  width: 20px;
  height: 20px;
  color: #235A91;
  transform: rotate(180deg);
  cursor: pointer;
`;
