import {
  Box, Grid, Paper, Typography, Button,
  CircularProgress
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const ContentWrapper = styled(Grid)`
  padding: 2rem 3rem;
`;

export const OutletItems = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const OutletContentWrapper = styled(Grid)`
    display: flex;
    justify-content: space-between;
    flex-wrap: no-wrap;
    padding: 30px 30px 10px;

`;
export const OutletWrapper = styled(Grid)`
  width: 100%;
  flex-direction: column;
  min-height: 73vh;
  margin-left:35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const OutletItem = styled(Grid)`
  margin: 0 20px;
`;

export const FooterGrid = styled(Grid)`
  display: flex;
  height: 75px;
  justify-content: flex-end;
  padding-left: 20px;
  align-items: center;
`;
export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const TitleText = styled(Typography)`
  color: #303030;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: .5rem;
`;

export const SubText = styled(Typography)`
  color: #606060;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

export const SettingsPaper = styled(Paper)`
  height: 6.625rem;
  gap: 10px;
  border-radius: 12px;
  padding: 1.5rem 0;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
`;

export const SettingsBox = styled(Box)`
  background: #f1f8ff;
  width: 100%;
  display: flex;
  padding: 1rem;
`;

export const SideDesign = styled(Box)`
  background-color: #235a91;
  border-radius: 0px 8px 8px 0px;
  width: .438rem;
  height: 3.625rem;
`;

export const ProfileText = styled(Typography)`
  color: #235a91;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
`;

export const ProfilePaper = styled(Paper)`
  padding: 1.5rem;
  min-height: 67.688rem;
  box-shadow: 0px 11px 18px 0px rgba(163, 171, 185, 0.12);
  margin-bottom: 2rem;
  border-radius: 12px;
`;

export const HeaderBox = styled(Box)`
  background-color: #f3f9ff80;
  height: 7.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

export const BusinesssName = styled(Typography)`
  color: #000000;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: .6rem;
`;

export const VerifyBox = styled(Box)`
  width: ${({ active }) => (active === 'ACTIVE' ? '10.875rem' : '13.75rem')};
  height: 2.875rem;
  background: ${({ active }) => (active === 'ACTIVE' ? '#dcfedd' : '#ffff00')};
  display: flex;
  padding: .625rem;
  border-radius: 8px;
`;

export const Bullet = styled(Box)`
  border-radius: 50%;
  background: ${({ active }) => (active === 'ACTIVE' ? '#00b588' : '#ff0000')};
  height: .563rem;
  width: .563rem;
  margin-top: .4rem;
`;

export const VerifiedText = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  color: #344054;
  margin-left: .5rem;
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const EditButton = styled(Button)`
  height: 2.75rem;
  min-width: 3rem;
  border-radius: 5px;
  border: 1px solid #235a91;
  padding: 10px;
  gap: 10px;
`;

export const EditButtonText = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #235a91;
`;

export const CircularProgressLoader = styled(CircularProgress)`
`;
