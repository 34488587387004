import { Dialog, DialogContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px !important;
    min-height: 24.938rem;
  }
`;

export const CardTitle = styled('span')`
  font-size: 1.125rem;
  font-weight: 700;
  color: #303030;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  height: auto;
  box-shadow: none;
`;

export const TitleDesc = styled(Typography)`
  color: #606060;
  font-size: .875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

export const IdImage = styled('img')`
  height: 16rem;
  width: 100%;
`;
