import React, { useEffect, useState } from 'react';
import {
  Box, Grid, TableBody
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ProductsCard, Header, MainTableRow, TableComponent, TableHeader,
  OutlinedButtonText, MarkComplete, MarkAsComplete, FieldCheckbox
} from './manageOrdersDispatch.styles';
import CustomSearchField from '../shared/CustomSearchField';
import ReturnProductRow from './returnProductRow';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  ReviewContainer, ReviewText, DeliveryText, DeliveryNo, ReviewCol, OrderTitle, OrderText, OutlinedButton
} from './productsContainer.styles';
import { EyeOutline } from '../../assets/svgs';
import TablePagination from '../shared/tablePagination';

const ProductsContainer = ({
  loading, businessOrderStatus, businessOrder, handleQtyConfirmedIncrement, handleQtyConfirmedDecrement,
  handleQtySentIncrement, handleQtySentDecrement, handleConditionChange, total, state, setState,
  handleBatchSelection, handleConfirmedQtyInputValue, quantityReceive, handleQuantitySentInputValue, refetchData, handleCancelOrderModal
}) => {
  const orderSorted = ['ORDER_SORTED', 'ORDER_QUEUED_FOR_DISPATCH', 'ORDER_DISPATCHED', 'CLOSED'];

  const { cart } = state;
  const NoOfItems = cart.length;

  const [viewProducts, setViewProducts] = useState(false);
  const toggleViewProducts = () => setViewProducts(!viewProducts);

  const [pageCount, setPageCount] = useState(state.pageCount);
  const [pageNumber, setPageNumber] = useState(state.pageNumber);
  const [mark, setMark] = useState(false);

  const searchHandler = (val) => {
    setState((_s) => ({ ..._s, searchText: val }));
  };

  useEffect(() => {
    setState((_s) => ({ ..._s, pageCount, pageNumber }));
  }, [pageCount, pageNumber, setState]);

  const handleMarkComplete = (e) => {
    setMark(e.target.checked);
  };

  const { id: orderId } = businessOrder || {};
  const { businessType } = businessOrder?.business || {};
  const isDisabled = () => {
    switch (businessOrderStatus) {
      case 'CLOSED':
      case 'ORDER_QUEUED_FOR_DISPATCH':
      case 'ORDER_DISPATCHED':
      case 'ORDER_AWAITING_APPROVAL':
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      { orderSorted.includes(businessOrderStatus) && (
      <ProductsCard elevation={2}>
        <ReviewContainer>
          <ReviewText>Order Review</ReviewText>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <DeliveryText>Delivery #3456</DeliveryText>
            <DeliveryNo>{moment(businessOrder?.order?.dateCreated).format('DD-MMM-YYYY')}</DeliveryNo>
          </Box>
        </ReviewContainer>

        <ReviewContainer mt={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <ReviewCol>
              <OrderTitle>Order number:</OrderTitle>
              <OrderText>{orderId}</OrderText>
            </ReviewCol>
            <ReviewCol>
              <OrderTitle>No. of Items:</OrderTitle>
              <OrderText>{NoOfItems}</OrderText>
            </ReviewCol>
          </Box>
          <Box
            sx={{
              display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: 'pointer'
            }}
            onClick={toggleViewProducts}
          >
            <EyeOutline />
            <DeliveryNo>View Products</DeliveryNo>
          </Box>
        </ReviewContainer>
        <OutlinedButton disabled={isDisabled()} onClick={handleCancelOrderModal}>Cancel Order</OutlinedButton>
      </ProductsCard>
      )}
      { (!orderSorted.includes(businessOrderStatus) || viewProducts) && (
      <ProductsCard
        elevation={2}
        style={mark
          ? { opacity: 0.4, cursor: 'not-allowed' }
          : {}}
      >
        <Grid item container xs={5} mb={3}>
          <CustomSearchField
            name="search"
            value={state.searchText}
            fullWidth
            placeholder="Search"
            handleChange={(e) => searchHandler(e.target.value)}
          />
        </Grid>
        {
            loading ? <ProductsTableLoader />
              : (
                <TableComponent aria-label="table" sx={{ marginTop: '1rem' }}>
                  <TableHeader>
                    <MainTableRow item container>
                      <Header>Product Items</Header>
                      <Header>Qty in Stock</Header>
                      <Header>Qty Ordered</Header>
                      <Header>Qty Confirmed</Header>
                      <Header>Qty Sent</Header>
                      <Header>Condition</Header>
                      { businessType === 'MANUFACTURER' ? <Header>Not Received</Header> : <Header>Not Available</Header> }
                      <Header>Action</Header>
                    </MainTableRow>
                  </TableHeader>
                  <TableBody>
                    {
                      cart?.map((order) => {
                        const { id } = order;
                        return (
                          <ReturnProductRow
                            key={id}
                            order={order}
                            handleQtyConfirmedIncrement={handleQtyConfirmedIncrement}
                            handleQuantitySentInputValue={handleQuantitySentInputValue}
                            handleQtyConfirmedDecrement={handleQtyConfirmedDecrement}
                            handleQtySentIncrement={handleQtySentIncrement}
                            handleQtySentDecrement={handleQtySentDecrement}
                            handleConditionChange={handleConditionChange}
                            handleBatchSelection={handleBatchSelection}
                            handleConfirmedQtyInputValue={handleConfirmedQtyInputValue}
                            businessType={businessType}
                            businessOrderStatus={businessOrderStatus}
                            quantityReceive={quantityReceive}
                            refetchData={refetchData}
                          />
                        );
                      })
                    }
                  </TableBody>
                </TableComponent>
              )
            }
        <TablePagination
          total={total}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        <MarkComplete>
          <OutlinedButton sx={{ padding: '7px 0' }} disabled={isDisabled()} onClick={handleCancelOrderModal}>
            <OutlinedButtonText>Cancel Order</OutlinedButtonText>
          </OutlinedButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <MarkAsComplete>
              Mark as complete
            </MarkAsComplete>
            <FieldCheckbox checked={mark} onClick={handleMarkComplete} />
          </Box>
        </MarkComplete>
      </ProductsCard>
      )}
    </>
  );
};

ProductsContainer.propTypes = {
  loading: PropTypes.bool,
  cart: PropTypes.instanceOf(Array),
  businessOrderStatus: PropTypes.string,
  businessOrder: PropTypes.instanceOf(Object),
  handleQtyConfirmedIncrement: PropTypes.func.isRequired,
  handleQuantitySentInputValue: PropTypes.func.isRequired,
  handleQtyConfirmedDecrement: PropTypes.func.isRequired,
  handleQtySentIncrement: PropTypes.func.isRequired,
  handleQtySentDecrement: PropTypes.func.isRequired,
  handleCancelOrderModal: PropTypes.func.isRequired,
  handleConditionChange: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  handleConfirmedQtyInputValue: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  quantityReceive: PropTypes.number.isRequired,
  refetchData: PropTypes.func.isRequired,
};

ProductsContainer.defaultProps = {
  loading: false,
  cart: [],
  businessOrderStatus: '',
  businessOrder: {},
};

export default ProductsContainer;
