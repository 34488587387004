import {
  Box, Grid, Paper, Typography, Stepper,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubText = styled(Typography)`
  color: #606060;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

export const SettingsPaper = styled(Paper)`
  height: 17.938rem;
  gap: 10px;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
`;

export const ProfilePaper = styled(Paper)`
  padding: 2.2rem;
  min-height: 35.688rem;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  margin-bottom: 2rem;
`;

export const BusinesssName = styled(Typography)`
  color: #303030;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const HeaderText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #606060;
  margin-left: 1rem;
  margin-top: 5px;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    margin-left: 40px;
  }
`;

export const EditButton = styled(Button)`
  height: 2.75rem;
  min-width: 3rem;
  border-radius: 5px;
  border: 1px solid #235a91;
  padding: 10px;
  gap: 10px;
`;

export const EditButtonText = styled(Typography)`
  font-size: .875rem;
  font-weight: 700;
  color: #235a91;
`;

export const ProfileBox = styled(Box)`
  margin-bottom: 1rem;
`;

export const ProfileHeader = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  margin-bottom: .5rem;
`;

export const ProfileSub = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
`;

export const IdentificationPaper = styled(Paper)`
  padding: 2.2rem;
  min-height: ${({ available }) => (available ? '14.625rem' : '17.875rem')};
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  margin-bottom: 2rem;
`;

export const EmptyBox = styled(Box)`
  text-align: center;
  margin-top: 2rem;
`;

export const EmptyText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: .5rem;
`;

export const ClickAddText = styled('span')`
  font-size: 1rem;
  font-weight: 600;
  color: #235a91;
  text-decoration: underline;
  cursor: pointer;
`;

export const EmptySubText = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
`;

export const IdTypeText = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
`;

export const ContentBox = styled(Box)`
  margin-top: 1rem;
`;

export const GridPaper = styled(Grid)`
  border-radius: 12px;
  box-shadow: 0px 4px 69.5px 0px rgba(135, 135, 135, 0.12);
  height: 5.375rem;
  margin-top: .5rem;
  padding: 1.5rem;
`;

export const FileName = styled(Typography)`
  font-weight: 700;
  font-size: .875rem;
  color: #344054;
`;

export const FileSize = styled(Typography)`
  font-weight: 400;
  font-size: .875rem;
  color: #475467;
`;

export const ViewText = styled(Typography)`
  font-weight: 700;
  font-size: .875rem;
  color: #235a91;
  margin-top: 5px;
`;

export const AgreementPaper = styled(Paper)`
  padding: 2.2rem;
  min-height: ${({ available }) => (available ? '20.75rem' : '24.375rem')};
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  margin-bottom: 2rem;
`;

export const StepContainer = styled(Grid)`
  position: relative;
  padding-bottom: 2rem;

  @media (max-width: 991px) {
    padding-bottom: 3rem;
  }
`;

export const ConnectorLine = styled('div')`
  content: '';
  position: absolute;
  top: 35px;
  left: 13px;
  height: calc(100% - 35px);
  background: #92c5e7;
  min-height: 1.688rem;
  width: 5px;

  @media (max-width: 991px) {
    top: 90px;
  }
`;
