import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Grid, useMediaQuery, Hidden
} from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  CardPaper, ProductImg, ImgBox, DetailsBox, HeaderContainer,
  ActionsContainer, HeaderText, TypoContainer,
  PricingAndSaving, PricingWrapper, Pricing, ActionButton, AddButton,
  CircularProgressLoader, ActionTextField, InputButtonGroup,
  AddCartGrid, ShoppingCartImg, AddCartTypo, ChangeWrapper, MinusIcon, PlusIcon,
  CartItemIcon, CardWrapper, SubscribeButton, PackSizeTypoText,
  DividerLine,
  ManufacTypo
} from './productCard.styles';
import currencyFormatter from '../../../utils/currencyFormatter';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { ADD_TO_CART_MUTATION } from '../../../mutations/orders';
import { useStateValue } from '../../../providers/stateProvider';

const ProductCard = ({
  product, cart: cartProduct, similarAction, index, refetchCart
}) => {
  const [{
    cart: { productCartItems }, user: { session: { businessType } },
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const {
    id, brandName, expiryDate, meta, marketSellingPrice, business, quantityInStock, manufacturer
  } = product;
  const isWholesaler = businessType === 'WHOLESALER';
  const obj = meta ? JSON.parse(meta) : {};
  const { pack_size: packSize, image } = obj;
  const { name: businessName } = business || {};

  const [counter, setCounter] = useState(0);
  const [cartItemCount, setCartItemCount] = useState(0);
  const batchExpiryDate = expiryDate ? moment(expiryDate).format('MM/YY') : 'N/A';

  const [updateOrder, { loading }] = useMutation(ADD_TO_CART_MUTATION);

  const handleActionButtons = (value) => {
    const sum = counter + value;
    if (sum > quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');
    setCartItemCount(0);
    setCounter(sum);
  };
  const handleChange = (value) => {
    if (value > quantityInStock && quantityInStock !== null) return toast.error('Requested quantity not available');
    setCounter(value);
    setCartItemCount(0);
  };

  const isSmall = useMediaQuery('(max-width: 991px)');

  useEffect(() => {
    if (productCartItems) {
      const filteredProducts = productCartItems.filter((item) => Number(item.product.id) === Number(id));
      setCartItemCount(filteredProducts[0]?.quantity);
    }
  }, [productCartItems]);

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        cart: [
          { productId: Number(id), quantity: counter }
        ],
        overwriteQty: true
      }
    })
      .then(({ data }) => {
        // setCounter(0);
        const { message, order: { orderProducts } } = data?.updateCart || {};
        dispatch({
          type: CartActionTypes.CART_COUNT,
          payload: orderProducts?.length
        });
        dispatch({
          type: CartActionTypes.UPDATE_CART_ITEMS,
          payload: orderProducts
        });
        if (cartProduct) {
          refetchCart();
        }
        toast.success(message);
      });
  };

  const handleClick = () => {
    navigate(`/new-order/${id}/details`);
  };

  return (
    <CardPaper
      similarAction={similarAction}
      elevation={2}
      spacing={3}
      className={`mpFe-uat-new-order-card-${index}`}
    >
      <CardWrapper>
        <ImgBox onClick={handleClick}>
          <ProductImg component="img" src={image || 'https://res.cloudinary.com/health-id/image/upload/v1594134267/Placeholders/Product_Placeholder.png'} alt={brandName} />
        </ImgBox>
        <DetailsBox>
          <HeaderContainer>
            <HeaderText variant="subtitle2">
              {brandName}
            </HeaderText>
          </HeaderContainer>
          <Hidden mdUp>
            <TypoContainer item container xs={12}>
              {isWholesaler ? (
                <ChangeWrapper container item xs={12}>
                  <PackSizeTypoText item xs={12}>
                    Seller :
                    &nbsp;
                    {businessName}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : (
                <ChangeWrapper container item xs={12}>
                  <ManufacTypo>
                    {manufacturer}
                  </ManufacTypo>
                </ChangeWrapper>
              )}
              <ChangeWrapper container item xs={12}>
                {quantityInStock === null ? (
                  <PackSizeTypoText item xs={12}>
                    Pack Size:
                    &nbsp; &nbsp;
                    {packSize}
                  </PackSizeTypoText>
                ) : (
                  <PackSizeTypoText item xs={12}>
                    {packSize}
                    &nbsp;
                    <DividerLine />
                    {quantityInStock ? (
                      `${quantityInStock.toLocaleString()} in stock`
                    ) : (
                      'Out of stock'
                    )}
                  </PackSizeTypoText>
                )}
              </ChangeWrapper>
              {isWholesaler ? (
                <ChangeWrapper>
                  <PackSizeTypoText>
                    Expiry Date:
                    &nbsp; &nbsp;
                    {batchExpiryDate}
                  </PackSizeTypoText>
                </ChangeWrapper>
              ) : ''}
              <PricingAndSaving item container justify-content="space-between">
                <PricingWrapper item xs={12}>
                  <Pricing>
                    {currencyFormatter(marketSellingPrice)}
                  </Pricing>
                </PricingWrapper>
              </PricingAndSaving>
            </TypoContainer>
          </Hidden>
        </DetailsBox>
      </CardWrapper>
      <ActionsContainer container spacing={isSmall ? 4 : ''}>
        <Hidden mdDown>
          <TypoContainer item container xs={12}>
            {isWholesaler ? (
              <ChangeWrapper container item xs={12}>
                <PackSizeTypoText item xs={12}>
                  Seller :
                  &nbsp;
                  {businessName}
                </PackSizeTypoText>
              </ChangeWrapper>
            ) : (
              <ChangeWrapper container item xs={12}>
                <ManufacTypo>
                  {manufacturer}
                </ManufacTypo>
              </ChangeWrapper>
            )}
            <ChangeWrapper container item xs={12}>
              {quantityInStock === null ? (
                <PackSizeTypoText item xs={12}>
                  Pack Size:
                  &nbsp; &nbsp;
                  {packSize}
                </PackSizeTypoText>
              ) : (
                <PackSizeTypoText item xs={12}>
                  {packSize}
                  <DividerLine />
                  {quantityInStock ? (
                    `${quantityInStock.toLocaleString()} in stock`
                  ) : (
                    'Out of stock'
                  )}
                </PackSizeTypoText>
              )}
            </ChangeWrapper>
            {isWholesaler ? (
              <ChangeWrapper>
                <PackSizeTypoText>
                  Expiry Date:
                  &nbsp; &nbsp;
                  {batchExpiryDate}
                </PackSizeTypoText>
              </ChangeWrapper>
            ) : ''}
            <PricingAndSaving item container justify-content="space-between">
              <PricingWrapper item xs={12}>
                <Pricing>
                  {currencyFormatter(marketSellingPrice)}
                </Pricing>
              </PricingWrapper>
            </PricingAndSaving>
          </TypoContainer>
        </Hidden>
        <AddCartGrid item xs={5} md={12} lg={12}>
          <InputButtonGroup size="small" aria-label="small button group">
            <ActionButton
              onClick={() => handleActionButtons(-1)}
              disabled={counter === 0}
              className={`mpFe-uat-new-order-minus-${index}`}
              style={{ justifyContent: 'flex-start' }}
              data-testid="decrement"
            >
              <MinusIcon />
            </ActionButton>
            <ActionTextField
              InputProps={{ disableUnderline: true, }}
              variant="standard"
              placeholder={0}
              value={counter || ''}
              onChange={(e) => handleChange(Number(e.target.value))}
            />
            <ActionButton
              onClick={() => handleActionButtons(1)}
              className={`mpFe-uat-new-order-add-${index}`}
              style={{ justifyContent: 'flex-end' }}
              data-testid="increment"
            >
              <PlusIcon />
            </ActionButton>
          </InputButtonGroup>
        </AddCartGrid>
        <Grid container item xs={5} md={12}>
          {!cartItemCount ? (
            <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
              <AddButton
                startIcon={<ShoppingCartImg outOfStock={quantityInStock <= 0 && quantityInStock !== null} />}
                variant="outlined"
                disableElevation
                onClick={handleAddToCart}
                disabled={counter === 0 || (quantityInStock <= 0 && quantityInStock !== null)}
                counter={counter}
                outOfStock={quantityInStock <= 0 && quantityInStock !== null}
                className={`mpFe-uat-new-order-plus-${index}`}
                data-testid="add-to-cart"
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={24}
                    thickness={4}
                  />
                )
                  : (
                    <AddCartTypo type={similarAction && 'similarAction'}>
                      &nbsp;
                      Add To Cart
                    </AddCartTypo>
                  )}
              </AddButton>
            </AddCartGrid>
          ) : (
            <AddCartGrid item xs={12} style={{ marginTop: '3px' }}>
              <SubscribeButton
                startIcon={<CartItemIcon />}
                variant="outlined"
                disableElevation
                counter={counter}
                className={`mpFe-uat-new-order-subscription-${index}`}
                data-testid="items-number"
              >
                <AddCartTypo type="notify">
                  {cartItemCount}
                  &nbsp;
                  { cartItemCount > 1 ? 'items' : 'item' }
                  &nbsp;
                  in cart
                </AddCartTypo>
              </SubscribeButton>
            </AddCartGrid>
          )}
        </Grid>
      </ActionsContainer>
    </CardPaper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object),
  cart: PropTypes.bool,
  similarAction: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  refetchCart: PropTypes.func,
};

ProductCard.defaultProps = {
  product: {},
  cart: false,
  refetchCart: () => {},
};

export default ProductCard;
