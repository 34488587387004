import {
  Grid, Typography, Paper,
  Box, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const UserGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 1.8rem 2rem;
  background: #F3F9FF;
`;

export const HeaderBox = styled(Box)`
  margin: 1rem 0;
  line-height: 30;
`;

export const BackArrowIconContainer = styled('div')`
  padding: 10px 0px;
  margin: 0;
  background: none;
  color: #235A91;
  display: flex;
  width: 5rem;
  cursor: pointer;
`;

export const NameBox = styled(Box)`
  background-color: #F3F9FF80;
  padding: 1.5rem 2rem;
  height: 5.375rem;
`;

export const BackText = styled(Typography)`
  color: #235A91;
  font-size: .875rem;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 10px;
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 2rem;
`;

export const HeaderText = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  color: #303030;
  line-height: 29.26px;
  margin-bottom: .7rem;
`;

export const SubHeaderText = styled(Typography)`
  color: #606060;
  font-weight: 400;
  font-size: .875rem;
`;

export const DetailsPaper = styled(Paper)`
  margin-top: 2rem;
  border-radius: 10px;
  padding: 4rem 6rem;
  box-shadow: 0px 11px 18px 0px #0000000A;
`;

export const UsernameText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 22px;
`;

export const DetailFieldGrid = styled(Grid)`
  margin-top: .5rem;
  margin-bottom: 2rem;
`;

export const VerifyButton = styled(Button)`
  background-color: #235a91;
  border-radius: 8px;
  border: 1px solid #386EA3;
  box-shadow: 0px 1px 2px 0px #1018280D;
  font-size: 1rem;
  font-weight: 700;
  line-height: 26.27px;
  color: #ffffff;
  height: 2.875rem;
  width: 10rem;

  &:hover, &:disabled {
    background-color: #235a91;
    color: #ffffff;
  }
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
