import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import {
  DatePicker, FiltersGrid
} from './userFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';

const UserFilter = ({
  state, handleFilterChange, handleSetFilter, handleDateFilter, tab
}) => {
  const status = [
    { name: 'Unverified', value: 'IN_ACTIVE' },
    { name: 'Verified', value: 'ACTIVE' },
  ];

  const buyerType = [
    { name: 'Wholesaler', value: 'WHOLESALER' },
    { name: 'Retailer', value: 'RETAILER' },
  ];

  const sellerType = [
    { name: 'Importer', value: 'IMPORTER' },
    { name: 'Manufacturer', value: 'MANUFACTURER' },
    { name: 'Wholesaler', value: 'WHOLESALER' },
  ];

  const textFields = [
    { name: 'name', label: 'Business Name' },
    { name: 'accountType', label: 'Account Type', options: tab === 'BUYER' ? buyerType : sellerType },
    { name: 'email', label: 'Email Address' },
    { name: 'location', label: 'Location' },
    { name: 'status', label: 'Status', options: status },
  ];

  return (
    <Grid container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        {
          textFields.map((field) => {
            const { name, label, options } = field;
            if (['accountType', 'status'].includes(name)) {
              return (
                <CustomNewTextField
                  key={name}
                  field={{ name, label }}
                  state={state}
                  select
                  handleChange={handleFilterChange}
                  marginLeft="2rem"
                  style={{ width: '10rem' }}
                >
                  {options.map(({ name: fName, value }) => (
                    <MenuItem key={value} value={value}>
                      {fName}
                    </MenuItem>
                  ))}
                </CustomNewTextField>
              );
            } return (
              <CustomNewTextField
                key={name}
                field={{ name, label }}
                state={state}
                handleChange={handleFilterChange}
              />
            );
          })
        }
        <DatePicker>
          <CustomDateRanges
            handleSubmit={handleDateFilter}
            xs={12}
            styles={{ height: '3.2rem', width: '100%' }}
          />
        </DatePicker>
        <CustomButton
          style={{
            width: '6.5rem', height: '2.95rem', fontWeight: '700', fontSize: '0.75rem'
          }}
          onClick={handleSetFilter}
        >
          Set Filter
        </CustomButton>
      </FiltersGrid>
    </Grid>
  );
};

UserFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleDateFilter: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired
};

UserFilter.defaultProps = {
  state: {},
};

export default UserFilter;
