import {
  Box, Paper, Grid, Typography, TextField, Button, Stepper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const ContentBox = styled(Box)`
  padding: 2rem 3rem 3rem 3rem;
  min-height: 80vh;
`;

export const TopPaper = styled(Grid)`
  border-radius: 1rem;
  justify-content: space-between;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
`;

export const HeaderContainer = styled(Grid)`
`;

export const CardWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: .9rem;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const SubHeader = styled(Typography)`
  font-size: 0.875rem;
  color: #606060;
  font-weight: 400;
  margin-bottom: 1rem;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
`;

export const TrackImg = styled('img')`
  height: 100%;
`;

export const TrackText = styled(Typography)`
  font-size: .875rem;
  color: #606060;
  font-weight: 400;
  margin-bottom: .5rem;

  @media(max-width: 991px) {
    font-size: 1.7rem;
    font-weight: 400;
  }
`;

export const CTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 2rem;
  }
`;

export const TrackOrderButton = styled(Button)`
  align-items: center;
  padding: 2px 15px;
  background-color: #235A91;
  color: #FFFFFF;
  border-radius: 15px;
  height: 70px;

  &:hover {
    background-color: #235A91;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #235A91;
  }

  @media(min-width: 992px) {
    height: 3.5rem;
    padding: 0px 15px;
    border-radius: .5rem;
    width: 13rem;
  }

  @media(max-width: 991px) {
    width: 100%;
    height: 100px;
  }
`;

export const TrackOrderButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;

  @media(min-width: 992px) {
    font-size: .875rem;
    font-weight: 700;
  }
`;

export const BottomPaper = styled(Paper)`
  border-radius: 1rem;
  padding: 3rem;
  margin-top: 2rem;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  height: 31rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyBox = styled(Box)`
  text-align: center;
`;

export const Typo = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  color: #303030;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
`;

export const HeaderText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #303030;
  margin-bottom: .5rem;
  margin-left: 13px;

  @media (max-width: 991px) {
    font-size: 2.2rem;
    margin-left: 40px;
  }
`;

export const TitleText = styled(Typography)`
  color: #545454;
  font-size: .875rem;
  margin-left: 13px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 1.7rem;
    margin-left: 40px;
    margin-top: 1rem;
  }
`;

export const DateText = styled(Typography)`
  color: #545454;
  font-size: .875rem;
  font-weight: 600;

  @media (max-width: 991px) {
    margin-left: 40px;
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.7rem;
  }
`;

export const DispatchStepper = styled(Stepper)`
  padding: 0rem 0 1rem;
  width: 100%;
  background: none;
  justify-content: space-between;
  &.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    display: none;
}
`;

export const DispatchTypo = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 600;

  @media (min-width: 991px) {
    font-size: 1.2rem;
  }
`;

export const StepperIcon = styled('img')`
  width: 30px;
  @media (max-width: 991px) {
    width: 60px;
  }
`;

export const CustomStepper = styled(Stepper)`
  &.MuiStepper-vertical {
    flex-direction: column-reverse;
  }
  .MuiStep-root {
    margin-bottom: -1rem;
  }

  & .MuiStepContent-root {
    border-left: 1px solid #07DE10;
    margin-left: 16px;
  }
  & .MuiStepConnector-line {
    border-color: #07DE10;
    min-height: 50px;
    margin-left: 2px;
  }

  @media (max-width: 991px) {
    & .MuiStepContent-root {
      border-left: 1px solid #07DE10;
      margin-left: 29px;
    }
    & .MuiStepConnector-line {
      border-color: #07DE10;
      min-height: 50px;
      margin-left: 17px;
    }
  }
`;

export const IdPaper = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  height: 7.375rem;
  border-radius: .75rem;
  padding: 1.5rem 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const PackagePaper = styled(Paper)`
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  margin-bottom: 1.5rem;
  padding: 1.5rem 2rem;
  transition: min-height 0.5s ease-out, max-height 0.5s ease-out, padding 0.5s ease-out;
  min-height: ${({ isOpen, rejected }) => (!isOpen ? '6.25rem' : (rejected && isOpen) ? '21rem' : '40rem')};
  max-height: ${({ isOpen, rejected }) => (isOpen ? '40rem' : (rejected && isOpen) ? '21rem' : '6.25rem')};
  overflow: hidden;
`;

export const OrderPackTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.25rem;
  color: #303030;
  margin-bottom: .5rem;
  margin-top: ${({ active }) => (active ? '.6rem' : '0')};

`;

export const OrderPackSub = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  color: #606060;
  display: flex;
`;

export const PackageColor = styled(Box)`
  height: .563rem;
  width: .563rem;
  background-color: ${({ color }) => (color)};
  border-radius: 50%;
  margin-top: 7px;
  margin-right: 10px;
`;

export const BackArrowIconContainer = styled('div')`
  padding: 10px 0px;
  margin: 0;
  background: none;
  color: #235A91;
  display: flex;
  width: 5rem;
  cursor: pointer;
`;

export const BackText = styled(Typography)`
  color: #235A91;
  font-size: .875rem;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 8px;
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 2rem;
`;
