import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  ProductCardsContainer, ProductCardGrid, NoResultsText,
  NoResultsContainer,
  NoResultsHeader,
  FooterGrid,
} from './productPreview.styles';
import ProductCard from './productCard';
import TablePagination from '../../../shared/tablePagination';

const ProductPreview = ({
  products, pageCount, setPageCount, pageNumber, setPageNumber, productsTotalNumber
}) => (
  <Box>
    <ProductCardsContainer container spacing={2}>
      {products.length
        ? products.map((product, index) => (
          <ProductCardGrid key={product.id} item>
            <ProductCard
              product={product}
              index={index}
            />
          </ProductCardGrid>
        )) : (
          <NoResultsContainer>
            <NoResultsHeader>
              Product Not Found!
            </NoResultsHeader>
            <NoResultsText>
              Please we currently don’t have the product you searched for,
              check out our recommended products below
            </NoResultsText>
          </NoResultsContainer>
        )}
    </ProductCardsContainer>
    <FooterGrid item container className="joy-new-order-pagination">
      <TablePagination
        total={productsTotalNumber}
        pageCount={+pageCount}
        setPageCount={setPageCount}
        pageNumber={+pageNumber}
        setPageNumber={setPageNumber}
        hideRowsPerPage
        displayShowResult
      />
    </FooterGrid>
  </Box>
);

ProductPreview.propTypes = {
  products: PropTypes.instanceOf(Object).isRequired,
  productsTotalNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

export default ProductPreview;
