import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LocationRegStep from './steps/location';
import CategoryRegStep from './steps/category';
import { HaveAccount, HaveAccountTypo } from './register.styles';
import MainStep from './steps/buyer/mainStep';

const Register = ({ setOgaDesc, beSeller, sellerState }) => {
  const initialState = {
    location: '',
    category: '',
    currentStep: 'location'
  };
  const [state, setState] = useState(initialState);

  const buyerInitialState = {
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    businessName: '',
    contactPerson: '',
    salesRep: '',
    deliveryState: '',
    deliveryCity: '',
    deliveryAddress: '',
    landmark: '',
    ...state
  };

  const [infoState, setInfoState] = useState(buyerInitialState);
  const { currentStep } = infoState;

  useEffect(() => {
    setInfoState((prevState) => ({
      ...prevState,
      ...state,
      currentStep
    }));
  }, [state, currentStep]);

  useEffect(() => {
    if (beSeller) setState({ ...state, currentStep: 'step1', catType: 'seller' });
  }, [beSeller]);

  const returnComponent = () => {
    switch (state.currentStep) {
      case 'location':
        return <LocationRegStep state={state} setState={setState} />;
      case 'category':
        return <CategoryRegStep state={state} setState={setState} />;
      case 'step1':
        return <MainStep state={infoState} setState={setInfoState} setOgaDesc={setOgaDesc} beSeller={beSeller} sellerState={sellerState} />;
      default:
        return <div />;
    }
  };

  return (
    <>
      {returnComponent()}
      {
        (currentStep !== 'buyer' && !beSeller) && (
        <HaveAccount item xs={12}>
          <HaveAccountTypo variant="subtitle2" display="inline">
            Already have an account? &nbsp;
            <Link
              to="/"
              style={{ color: '#235A91', textDecoration: 'underline', fontWeight: '700' }}
            >
              Login
            </Link>
          </HaveAccountTypo>
        </HaveAccount>
        )
      }
    </>
  );
};

Register.propTypes = {
  setOgaDesc: PropTypes.func.isRequired,
  beSeller: PropTypes.bool,
  sellerState: PropTypes.instanceOf(Object)
};

Register.defaultProps = {
  beSeller: false,
  sellerState: {}
};

export default Register;
