import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import {
  InputWrapper
} from '../../register.styles';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import CustomInputBase from '../../../customComponents/customInputBase';

const initialErrorState = {
  businessNameError: false,
  contactPersonError: false,
};

const ContactPersonSection = ({ state, setState, setProceed }) => {
  const { businessName, contactPerson, salesRep } = state;

  const [errorState, setErrorState] = useState(initialErrorState);
  const { businessNameError, contactPersonError, salesRepError } = errorState;

  const validateFields = () => (
    businessName.length && contactPerson.length
  );

  const validateState = (name, value) => {
    switch (name) {
      case 'businessName':
      case 'contactPerson':
        return setErrorState({
          ...errorState,
          [`${name}Error`]: !value.length || value === undefined
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    validateFields();
    if (validateFields()) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({
      ...state,
      [name]: value
    });
  };

  const fields = [
    {
      name: 'businessName', label: 'Business Name', secured: false, show: true, val: businessName,
      onChange: handleChange, placeholder: 'e.g Fame Pharmacy', required: true, type: 'text', error: businessNameError, helperText: 'Business name required'
    },
    {
      name: 'contactPerson', label: 'Contact Person Full Name', secured: false, show: true, val: contactPerson,
      onChange: handleChange, placeholder: 'e.g John Doe', required: true, type: 'text', error: contactPersonError, helperText: 'Contact person required'
    },
    {
      name: 'salesRep', label: 'Sales Representative', secured: false, show: true, val: salesRep,
      onChange: handleChange, placeholder: 'e.g Taime Elisa', required: false, type: 'text'
    }
  ];

  const returnInputProps = (name) => {
    switch (name) {
      case 'itemPrice':
      case 'rpp':
        return {
          startAdornment: (
            <InputAdornment position="start">
              <span>₦</span>
            </InputAdornment>
          )
        };
      default:
        return null;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, required, error, helperText, secured, placeholder, onChange, val, label, type
    } = field;
    const value = state[fieldName];
    if ([''].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={required}
        disabled={secured}
        name={fieldName}
        onChange={onChange}
        placeholder={placeholder}
        InputProps={returnInputProps(fieldName)}
        cSize="lg"
        readOnly={false}
      />
    );
  };

  return (
    <InputWrapper item container>
      <Grid item xs={12} lg={12}>
        {fields.filter((item) => item.show).map((field) => (
          <Grid item key={field?.name} xs={12} sx={{ marginTop: '1rem' }}>{returnTextField(field)}</Grid>
        ))}
      </Grid>
    </InputWrapper>
  );
};

ContactPersonSection.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  setProceed: PropTypes.func.isRequired
};

export default ContactPersonSection;
